<template>
  <div>
    <div
      class="w-100-vw h-100-vh bg-muted-50 position-fixed d-flex"
      style="left: 0; top: 0; z-index: 10000"
      v-if="$store.state.NProcess"
    >
      <loading-spinner :show="true" class="m-auto"></loading-spinner>
    </div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1 p-3 pb-5">
        <CContainer xxl class="position-relative px-0 pt-4">
          <router-view />
        </CContainer>
      </div>
      <!--      <AppFooter />-->
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import LoadingSpinner from '@/components/loading/LoadingSpinner'

export default {
  name: 'AdminLayout',
  components: {
    LoadingSpinner,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>
