<template>
  <div
    class="card mb-4"
    :class="{ 'rounded-bottom-left-0 position-absolute w-100': !open }"
    ref="filter_card"
    :style="{ top: open ? 0 : -height - 19 + 'px' }"
  >
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div class="mb-3">
          <font-awesome-icon icon="fa-solid fa-filter" />
          فیلتر
        </div>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="open = false"
        ></button>
      </div>
      <div class="row">
        <!--   Switch Filters Showing   -->
        <div class="col-12 mb-4" v-if="has_basic_filter">
          <ul class="list-group list-group-horizontal-md user-select-none">
            <!--    Car Plate    -->
            <li class="list-group-item" v-if="plate">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="plate_enable"
                @change="toggle_cookie_open"
                id="plate_enable_filter"
              />
              <label
                class="form-check-label c-pointer"
                for="plate_enable_filter"
              >
                پلاک
              </label>
            </li>
            <!--    Bed User,s Name or Id    -->
            <li class="list-group-item rtl" v-if="bed != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="bed_enable"
                @change="toggle_cookie_open"
                id="bed_filter"
              />
              <label class="form-check-label c-pointer" for="bed_filter">
                بدهکار
              </label>
            </li>
            <!--    Bes User,s Name or Id    -->
            <li class="list-group-item rtl" v-if="bes != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="bes_enable"
                @change="toggle_cookie_open"
                id="bes_filter"
              />
              <label class="form-check-label c-pointer" for="bes_filter">
                بستانکار
              </label>
            </li>
            <!--    Number Of Bar    -->
            <li class="list-group-item rtl" v-if="number_bar != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="number_bar_enable"
                @change="toggle_cookie_open"
                id="number_bar_filter"
              />
              <label class="form-check-label c-pointer" for="number_bar_filter">
                بارنامه
              </label>
            </li>
            <!--    order Id    -->
            <li class="list-group-item rtl" v-if="order_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="order_id_enable"
                @change="toggle_cookie_open"
                id="order_id_filter"
              />
              <label class="form-check-label c-pointer" for="order_id_filter">
                سفارش
              </label>
            </li>
            <!--    Card Id    -->
            <li class="list-group-item rtl" v-if="card_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="card_id_enable"
                @change="toggle_cookie_open"
                id="card_id_filter"
              />
              <label class="form-check-label c-pointer" for="card_id_filter">
                شماره کارت
              </label>
            </li>
            <!--    Factory    -->
            <li class="list-group-item rtl" v-if="factory_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="factory_enable"
                @change="toggle_cookie_open"
                id="factory_filter"
              />
              <label class="form-check-label c-pointer" for="factory_filter">
                کارخانه
              </label>
            </li>
            <!--    Product    -->
            <li class="list-group-item rtl" v-if="product_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="product_enable"
                @change="toggle_cookie_open"
                id="product_filter"
              />
              <label class="form-check-label c-pointer" for="product_filter">
                محصول
              </label>
            </li>
            <!--    Daryaft Id     -->
            <li class="list-group-item rtl" v-if="daryaft_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="daryaft_id_enable"
                @change="toggle_cookie_open"
                id="daryaft_id_filter"
              />
              <label class="form-check-label c-pointer" for="daryaft_id_filter">
                ش دریافت
              </label>
            </li>
            <!--    payment Id    -->
            <li class="list-group-item rtl" v-if="payment_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="payment_id_enable"
                @change="toggle_cookie_open"
                id="payment_id_filter"
              />
              <label class="form-check-label c-pointer" for="payment_id_filter">
                ش پرداخت
              </label>
            </li>
            <!--    Amount    -->
            <li class="list-group-item rtl" v-if="amount != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="amount_enable"
                @change="toggle_cookie_open"
                id="amount_filter"
              />
              <label
                class="form-check-label c-pointer"
                for="amount_filter"
                v-html="amount_label"
              ></label>
            </li>
            <!--    Sanad Id    -->
            <li class="list-group-item rtl" v-if="snd_id != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="snd_id_enable"
                @change="toggle_cookie_open"
                id="snd_id_filter"
              />
              <label class="form-check-label c-pointer" for="snd_id_filter">
                ش سند
              </label>
            </li>
            <!--    Factor Forosh    -->
            <li class="list-group-item rtl" v-if="fact_fo != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="fact_fo_enable"
                @change="toggle_cookie_open"
                id="fact_fo_filter"
              />
              <label class="form-check-label c-pointer" for="fact_fo_filter">
                ش فاکتور فروش
              </label>
            </li>
            <!--    Factor Kharid    -->
            <li class="list-group-item rtl" v-if="fact_kha != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="fact_kha_enable"
                @change="toggle_cookie_open"
                id="fact_kha_filter"
              />
              <label class="form-check-label c-pointer" for="fact_kha_filter">
                ش فاکتور خرید
              </label>
            </li>
            <!--    Select2 1   -->
            <li class="list-group-item rtl" v-if="select2_1 != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="select2_1_enable"
                @change="toggle_cookie_open"
                id="select2_1_filter"
              />
              <label class="form-check-label c-pointer" for="select2_1_filter">
                {{ select2_1_label }}
              </label>
            </li>
            <!--    Checkbox 1   -->
            <li class="list-group-item rtl" v-if="checkbox_1 != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="checkbox_1_enable"
                @change="toggle_cookie_open"
                id="checkbox_1_filter"
              />
              <label class="form-check-label c-pointer" for="checkbox_1_filter">
                {{ checkbox_1_label }}
              </label>
            </li>
            <!--    Referred Code    -->
            <li class="list-group-item rtl" v-if="referred_code != undefined">
              <input
                class="form-check-input me-1"
                type="checkbox"
                v-model="referred_code_enable"
                @change="toggle_cookie_open"
                id="referred_code_filter"
              />
              <label
                class="form-check-label c-pointer"
                for="referred_code_filter"
              >
                ش حواله
              </label>
            </li>
          </ul>
        </div>
        <!--   End Switch Filters Showing   -->
        <!--   Plate   -->
        <div
          class="col-12 col-sm-6 col-md-4 mb-3"
          v-if="plate != undefined && plate_enable"
        >
          <p class="mb-1">فیلتر بر اساس پلاک</p>
          <plate v-model:plate="plate_value" :updated_plate="updated_plate" />
        </div>
        <!--   Fiscal Year   -->
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-if="fiscal_year">
          <div class="form-group">
            <label class="form-label">سال مالی</label>
            <select
              class="form-control ltr"
              v-model="$store.state.current_fiscal_year"
            >
              <option value="">انتخاب سال مالی</option>
              <option
                v-for="option in $store.state.fiscal_years_options"
                :key="option"
                :value="option"
                v-text="option"
              ></option>
            </select>
          </div>
        </div>
        <!--    Bed Users,s Name Or Id     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="bed != undefined && bed_enable"
        >
          <input-ayandeh label="بدهکار" v-model:value="bed_value" />
        </div>
        <!--    Bes Users,s Name Or Id     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="bes != undefined && bes_enable"
        >
          <input-ayandeh label="بستانکار" v-model:value="bes_value" />
        </div>
        <!--    Number Bar Filter     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="number_bar != undefined && number_bar_enable"
        >
          <input-ayandeh
            type="number"
            label="شماره بارنامه"
            v-model:value="number_bar_value"
          />
        </div>
        <!--    Order Id     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="order_id != undefined && order_id_enable"
        >
          <input-ayandeh
            type="number"
            ref="order_id_filter"
            label="شماره سفارش"
            v-model:value="order_id_value"
          />
        </div>
        <!--    Card Id     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="card_id != undefined && card_id_enable"
        >
          <input-ayandeh
            type="number"
            ref="card_id_filter"
            label="شماره کارت"
            v-model:value="card_id_value"
          />
        </div>
        <!--    Factory Filter     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="factory_id != undefined && factory_id != null && factory_enable"
        >
          <div class="form-group">
            <label class="mb-2">فیلتر کارخانه</label>
            <v-select
              v-model="factory_value"
              :options="factories_option"
              :reduce="(option) => option.value"
              placeholder="انتخاب کارخانه"
              dir="rtl"
              :loading="$store.state.loading_factory"
            ></v-select>
          </div>
        </div>
        <!--    Product Filter     -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="product_id != undefined && product_id != null && product_enable"
        >
          <div class="form-group">
            <label class="mb-2">فیلتر محصول</label>
            <v-select
              v-model="product_value"
              :options="products_option"
              :reduce="(option) => option.id"
              placeholder="انتخاب محصول"
              dir="rtl"
              :loading="$store.state.loading_factory"
            ></v-select>
          </div>
        </div>
        <!--    Daryaft Id Faramohaseb    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="daryaft_id != undefined && daryaft_id_enable"
        >
          <input-ayandeh
            type="number"
            label="شماره دریافت حسابداری"
            v-model:value="daryaft_id_value"
          />
        </div>
        <!--    Payment Id Faramohaseb    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="payment_id != undefined && payment_id_enable"
        >
          <input-ayandeh
            type="number"
            label="شماره پرداخت حسابداری"
            v-model:value="payment_id_value"
          />
        </div>
        <!--    Amount    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="amount != undefined && amount_enable"
        >
          <input-ayandeh
            :label="amount_label + ' <small>(ریال)<small/>'"
            :placeholder="amount_label + ' (ریال)'"
            :number-format="true"
            dir="ltr"
            v-model:value="amount_value"
          />
        </div>
        <!--    Sanad Id Faramohaseb    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="snd_id != undefined && snd_id_enable"
        >
          <input-ayandeh
            type="number"
            label="شماره سند حسابداری"
            v-model:value="snd_id_value"
          />
        </div>
        <!--    Factor Forosh    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="fact_fo != undefined && fact_fo_enable"
        >
          <input-ayandeh
            type="number"
            label="فاکتور فروش"
            v-model:value="fact_fo_value"
          />
        </div>
        <!--    Factor Forosh    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="fact_fo != undefined && fact_fo_enable"
        >
          <input-ayandeh
            type="number"
            label="فاکتور فروش"
            v-model:value="fact_fo_value"
          />
        </div>
        <!--    Factor kharid    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="fact_kha != undefined && fact_kha_enable"
        >
          <input-ayandeh
            type="number"
            label="فاکتور خرید"
            v-model:value="fact_kha_value"
          />
        </div>
        <!--    Select2 Options   -->
        <div
          class="col-12 col-md-5 mb-3"
          v-if="select2_1 != undefined && select2_1_enable"
        >
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-regular fa-user"
                class="text-icon-light"
              />
              {{ select2_1_label }}
            </label>
            <v-select
              v-model="select2_1_value"
              :options="select2_1_options"
              :reduce="(option) => option.value"
              :placeholder="select2_1_label"
              dir="rtl"
            ></v-select>
          </div>
        </div>
        <!--    Sanad Id Faramohaseb    -->
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-if="referred_code != undefined && referred_code_enable"
        >
          <input-ayandeh
            type="number"
            label="شماره حواله"
            v-model:value="referred_code_value"
          />
        </div>
        <div
          class="col-12 mb-3 border-bottom border-style-bottom-dash"
          v-if="this.$slots.static != undefined && has_basic_filter == true"
        ></div>
        <slot name="static" />
      </div>
    </div>
    <button class="opener-btn" v-if="!open" @click="open = true">
      <font-awesome-icon
        icon="fa-solid fa-angle-down"
        class="me-2"
        :bounce="true"
      />
      فیلتر
    </button>
  </div>
</template>

<script>
import Plate from '@/components/partial/Plate'
import InputAyandeh from '@/components/inputs/InputAyandeh'
export default {
  name: 'FilterCard',
  props: {
    plate: {
      type: Object,
      default: null,
    },
    fiscal_year: {
      type: Boolean,
      default: false,
    },
    number_bar: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
    card_id: {
      type: Number,
      default: null,
    },
    factory_id: {
      type: Number,
      default: null,
    },
    product_id: {
      type: Number,
      default: null,
    },
    daryaft_id: {
      type: Number,
      default: null,
    },
    payment_id: {
      type: Number,
      default: null,
    },
    amount: {
      type: String,
      default: null,
    },
    amount_label: {
      type: String,
      default: 'مبلغ',
    },
    snd_id: {
      type: Number,
      default: null,
    },
    referred_code: {
      type: Number,
      default: null,
    },
    bes: {
      type: String,
      default: null,
    },
    bed: {
      type: String,
      default: null,
    },
    updated_plate: {
      type: Number,
      default: 1,
    },
    fact_kha: {
      type: Number,
      default: null,
    },
    fact_fo: {
      type: Number,
      default: null,
    },
    select2_1: {
      type: String,
      default: null,
    },
    select2_1_label: {
      type: String,
      default: '',
    },
    select2_1_options: {
      type: Object,
    },
    checkbox_1: {
      type: String,
      default: null,
    },
    checkbox_1_label: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:factory_id',
    'update:product_id',
    'update:order_id',
    'update:card_id',
    'update:number_bar',
    'update:daryaft_id',
    'update:payment_id',
    'update:amount',
    'update:snd_id',
    'update:referred_code',
    'update:bes',
    'update:bed',
    'update:plate',
    'refresh',
    'update:fact_fo',
    'update:fact_kha',
    'update:select2_1',
    'update:checkbox_1',
  ],
  data() {
    return {
      height: 0,
      open: true,
      plate_value: null,
      plate_enable: false,
      number_bar_value: '',
      number_bar_enable: false,
      order_id_value: '',
      order_id_enable: false,
      card_id_value: '',
      card_id_enable: false,
      factory_value: '',
      factory_enable: false,
      product_value: '',
      product_enable: false,
      daryaft_id_value: '',
      daryaft_id_enable: false,
      payment_id_value: '',
      payment_id_enable: false,
      amount_value: '',
      amount_enable: false,
      snd_id_value: '',
      snd_id_enable: false,
      referred_code_value: '',
      referred_code_enable: false,
      bes_value: '',
      bes_enable: false,
      bed_value: '',
      bed_enable: false,
      has_basic_filter: false,
      fact_kha_value: '',
      fact_kha_enable: false,
      fact_fo_value: '',
      fact_fo_enable: false,
      select2_1_value: '',
      select2_1_enable: false,
      checkbox_1_value: false,
      checkbox_1_enable: false,
    }
  },
  components: { InputAyandeh, Plate },
  mounted() {
    setTimeout(
      function () {
        this.calc_height()
      }.bind(this),
      2000,
    )

    if (this.plate != undefined) {
      this.plate_enable = this.getCookie('plate_filter')
      this.has_basic_filter = true
    }
    if (this.bed != undefined) {
      this.bed_enable = this.getCookie('bed_filter')
      this.has_basic_filter = true
    }
    if (this.bes != undefined) {
      this.bes_enable = this.getCookie('bes_filter')
      this.has_basic_filter = true
    }
    if (this.number_bar != undefined) {
      this.number_bar_enable = this.getCookie('number_bar_filter')
      this.has_basic_filter = true
    }
    if (this.order_id != undefined) {
      this.order_id_enable = this.getCookie('order_id_filter')
      this.has_basic_filter = true
    }
    if (this.card_id != undefined) {
      this.card_id_enable = this.getCookie('card_id_filter')
      this.has_basic_filter = true
    }
    if (this.factory_id != undefined) {
      this.factory_enable = this.getCookie('factory_filter')
      this.has_basic_filter = true
    }
    if (this.product_id != undefined) {
      this.product_enable = this.getCookie('product_filter')
      this.has_basic_filter = true
    }
    if (this.daryaft_id != undefined) {
      this.daryaft_id_enable = this.getCookie('daryaft_id_filter')
      this.has_basic_filter = true
    }
    if (this.payment_id != undefined) {
      this.payment_id_enable = this.getCookie('payment_id_filter')
      this.has_basic_filter = true
    }
    if (this.amount != undefined) {
      this.amount_enable = this.getCookie('amount_filter')
      this.has_basic_filter = true
    }
    if (this.snd_id != undefined) {
      this.snd_id_enable = this.getCookie('snd_id_filter')
      this.has_basic_filter = true
    }
    if (this.referred_code != undefined) {
      this.referred_code_enable = this.getCookie('referred_code_filter')
      this.has_basic_filter = true
    }
    if (this.fact_kha != undefined) {
      this.fact_kha_enable = this.getCookie('fact_kha_filter')
      this.has_basic_filter = true
    }
    if (this.fact_fo != undefined) {
      this.fact_fo_enable = this.getCookie('fact_fo_filter')
      this.has_basic_filter = true
    }
    if (this.select2_1 != undefined) {
      this.select2_1_enable = this.getCookie('select2_1_filter')
      this.has_basic_filter = true
    }
    if (this.checkbox_1 != undefined) {
      this.checkbox_1_enable = this.getCookie('checkbox_1_filter')
      this.has_basic_filter = true
    }
  },
  methods: {
    calc_height() {
      if(this.$refs.filter_card) {
        setTimeout(
          function () {
            this.height = this.$refs.filter_card.clientHeight
          }.bind(this),
          200,
        )
      }
    },
    toggle_cookie_open(e) {
      var id = e.target.getAttribute('id')
      this.setCookie(id, e.target.checked)
    },
    update_prop(prop, value) {
      this.$emit('update:' + prop, value)
      this.$emit('refresh')
      if (prop === 'plate') {
        if (Object.keys(value).length == 0) {
          this.set_data_to_url({
            two_digits: '',
            number_base: '',
            three_digits: '',
            letter: '',
          })
        } else {
          this.set_data_to_url(value)
        }
      } else {
        this.set_data_to_url({
          [prop]: value,
        })
      }
    },
  },
  watch: {
    plate_enable(newValue) {
      if (newValue) {
        if (this.plate_value != null) {
          this.update_prop('plate', this.plate_value)
        }
      } else {
        this.update_prop('plate', {})
      }
      this.calc_height()
    },
    plate_value(newValue) {
      this.$emit('update:plate', newValue)
      this.$emit('refresh')
      this.update_prop('plate', newValue)
    },
    // Number Bar
    number_bar(newValue) {
      this.number_bar_value = newValue
    },
    number_bar_value(newValue) {
      this.update_prop('number_bar', newValue)
    },
    number_bar_enable(newValue) {
      if (!newValue) this.update_prop('number_bar', '')
      this.calc_height()
    },
    // Orders
    order_id(newValue) {
      this.order_id_value = newValue
    },
    order_id_value(newValue) {
      this.update_prop('order_id', newValue)
    },
    order_id_enable(newValue) {
      if (!newValue) this.update_prop('order_id', '')
      this.calc_height()
    },
    // Card Id
    card_id(newValue) {
      this.card_id_value = newValue
    },
    card_id_value(newValue) {
      this.update_prop('card_id', newValue)
    },
    card_id_enable(newValue) {
      if (!newValue) this.update_prop('card_id', '')
      this.calc_height()
    },
    // Factory
    factory_id(newValue) {
      this.factory_value = newValue || ''
    },
    factory_value(newValue) {
      this.update_prop('factory_id', newValue || '')
    },
    factory_enable(newValue) {
      if (!newValue) this.update_prop('factory_id', '')
      this.calc_height()
    },
    // Product
    product_id(newValue) {
      this.product_value = newValue || ''
    },
    product_value(newValue) {
      this.update_prop('product_id', newValue || '')
    },
    product_enable(newValue) {
      if (!newValue) this.update_prop('product_id', '')
      this.calc_height()
    },
    // Daryaft Id
    daryaft_id(newValue) {
      this.daryaft_id_value = newValue
    },
    daryaft_id_value(newValue) {
      this.update_prop('daryaft_id', newValue)
    },
    daryaft_id_enable(newValue) {
      if (!newValue) this.update_prop('daryaft_id', '')
      this.calc_height()
    },
    // Payment Id
    payment_id(newValue) {
      this.payment_id_value = newValue
    },
    payment_id_value(newValue) {
      this.update_prop('payment_id', newValue)
    },
    payment_id_enable(newValue) {
      if (!newValue) this.update_prop('payment_id', '')
      this.calc_height()
    },
    // Amount
    amount(newValue) {
      this.amount_value = newValue
    },
    amount_value(newValue) {
      this.update_prop('amount', newValue)
    },
    amount_enable(newValue) {
      if (!newValue) this.update_prop('amount', '')
      this.calc_height()
    },
    // Sanad
    snd_id(newValue) {
      this.snd_id_value = newValue
    },
    snd_id_value(newValue) {
      this.update_prop('snd_id', newValue)
    },
    snd_id_enable(newValue) {
      if (!newValue) this.update_prop('snd_id', '')
      this.calc_height()
    },
    // Referred Code
    referred_code(newValue) {
      this.referred_code_value = newValue
    },
    referred_code_value(newValue) {
      this.update_prop('referred_code', newValue)
    },
    referred_code_enable(newValue) {
      if (!newValue) this.update_prop('referred_code', '')
      this.calc_height()
    },
    // Bes
    bes(newValue) {
      this.bes_value = newValue
    },
    bes_value(newValue) {
      this.update_prop('bes', newValue)
    },
    bes_enable(newValue) {
      if (!newValue) this.update_prop('bes', '')
      this.calc_height()
    },
    // Bes
    bed(newValue) {
      this.bed_value = newValue
    },
    bed_value(newValue) {
      this.update_prop('bed', newValue)
    },
    bed_enable(newValue) {
      if (!newValue) this.update_prop('bed', '')
      this.calc_height()
    },
    // Factor Forosh
    fact_fo(newValue) {
      this.fact_fo_value = newValue
    },
    fact_fo_value(newValue) {
      this.update_prop('fact_fo', newValue)
    },
    fact_fo_enable(newValue) {
      if (!newValue) this.update_prop('fact_fo', '')
      this.calc_height()
    },
    // Factor Kharid
    fact_kha(newValue) {
      this.fact_kha_value = newValue
    },
    fact_kha_value(newValue) {
      this.update_prop('fact_kha', newValue)
    },
    fact_kha_enable(newValue) {
      if (!newValue) this.update_prop('fact_kha', '')
      this.calc_height()
    },
    // Select2
    select2_1(newValue) {
      this.select2_1_value = newValue || ''
    },
    select2_1_value(newValue) {
      this.update_prop('select2_1', newValue || '')
    },
    select2_1_enable(newValue) {
      if (!newValue) this.update_prop('select2_1', '')
      this.calc_height()
    },
    // Checkbox
    checkbox_1(newValue) {
      this.checkbox_1_enable = newValue || false
    },
    checkbox_1_enable(newValue) {
      this.update_prop('checkbox_1', newValue)
    },
  },
}
</script>

<style scoped>
.card {
  transition: top 200ms ease-in-out;
}
.opener-btn {
  background-color: white;
  border: 1px solid var(--cui-border-color);
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px 12px;
  position: absolute;
  left: -1px;
  bottom: -31px;
}
.form-check-label {
  margin-bottom: 0;
}
</style>
