<template>
  <div class="col-12 col-sm-6 col-md-3">
    <input-date
      :selected="default_date_pay"
      name="date_pay"
      v-model:value="date_pay_value"
      :disabled="payment_rows.length > 0"
      label="تاریخ پرداخت"
    />
  </div>
  <div class="col-12 col-md-6 mb-3">
    <label class="mb-2">انتخاب شخص بدهکار</label>
    <v-select
      v-model="bed_id_value"
      :filter="userSearch"
      :options="$store.state.users_options"
      :reduce="(option) => option.value"
      :disabled="bed_select_disabled"
      :selectable="
        (option) => bes_ids.filter((x) => x == option.value).length == 0
      "
      placeholder="انتخاب بدهکار"
      dir="rtl"
      :loading="$store.state.users_options_loading"
    >
    </v-select>
    <show-mandeh-new
      :value="codes_mandeh[bed_id_value]"
      :loading="loading_mandeh"
    />
  </div>
  <div class="col-12" v-if="store_in_local">
    <div class="alert alert-info">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group text-dark mb-1">
            <label class="w-100">انتخاب شخص بدهکار در سیستم داخلی</label>
            <v-select
              v-model="bed_id_local_value"
              :filter="localUserSearch"
              :options="$store.state.local_users_options"
              :reduce="(option) => option.value"
              placeholder="جستجو بدهکار"
              dir="rtl"
              :loading="$store.state.local_users_options_loading"
            >
              <template v-slot:no-options> هیچ موردی یافت نشد </template>
              <template v-slot:option="option">
                <div class="d-center">
                  {{ option.label }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div class="selected d-center">
                  {{ option.label }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-5 mb-3">
    <label for="desc" class="form-label">شرح پرداخت</label>
    <textarea
      class="form-control"
      id="desc"
      rows="2"
      placeholder="اختیاری"
      v-model="desc_payment_value"
    ></textarea>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InputDate from '@/components/inputs/InputDate'
import Swal from 'sweetalert2'
import ShowMandehNew from '@/components/partial/ShowMandehNew'

export default {
  name: 'PaymentInputsBedPart',
  components: { ShowMandehNew, InputDate },
  data() {
    return {
      date_pay_value: '',
      bed_id_value: '',
      bed_id_local_value: '',
      desc_payment_value: '',
      store_in_local: false,
    }
  },
  emits: ['update:date_pay'],
  props: {
    date_pay: {
      type: String,
      default: '',
    },
    bed_id: {
      type: String,
      default: '',
    },
    bed_id_local: {
      type: String,
      default: '',
    },
    desc_payment: {
      type: String,
      default: '',
    },
    payment_rows: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    bes_ids: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    payment_selected: {
      type: Object,
      default: null,
    },
    default_date_pay: {
      type: String,
      default: 'today',
    },
    store_in_local_prop: Boolean,
  },
  mounted() {
    this.bed_id_value = this.bed_id.toString()
    this.bed_id_local_value = this.bed_id_local.toString()
    this.desc_payment_value = this.desc_payment
    this.store_in_local = this.store_in_local_prop
    this.get_users()
  },
  methods: {
    ...mapActions(['get_users']),
    // search_users_local(search, loading) {
    //   if (search.length) {
    //     // eslint-disable-next-line no-constant-condition
    //     if (typeof loading == 'function') {
    //       loading(true)
    //     }
    //
    //     this.do_search_in_local_users = this.$store.state.local_users_options
    //     this.get_users({
    //       local_sys: true,
    //       search: search,
    //     })
    //   }
    // },
  },
  computed: {
    bed_select_disabled() {
      if (this.payment_rows.length > 0) return true
      if (
        this.$route.query.bed_id != undefined &&
        !this.$store.state.users_options_loading
      )
        return true
      return false
    },
  },
  watch: {
    bed_id(newValue) {
      this.bed_id_value = newValue
    },
    date_pay_value(newValue) {
      console.log(newValue, 'new value')
      this.$emit('update:date_pay', newValue)
    },
    bed_id_value(newValue) {
      this.$emit('update:bed_id', newValue)

      if (newValue) {
        this.loading_mandeh = true
        this.get_code_mandeh(newValue)
      }

      if (this.payment_selected && this.payment_selected.bed_id == 105010058) {
        this.store_in_local = true
      } else if (newValue == 105010058) {
        Swal.fire({
          icon: 'question',
          html: 'آیا میخواهید که این پرداخت در سیستم داخلی هم ثبت شود؟',
          showDenyButton: true,
          showCloseButton: true,
          confirmButtonText: 'بله، ثبت شود',
          denyButtonText: 'انصراف',
        }).then((result) => {
          if (result.isConfirmed) {
            this.store_in_local = true
          } else {
            this.store_in_local = false
          }
        })
      } else {
        this.store_in_local = false
      }
    },
    bed_id_local_value(newValue) {
      this.$emit('update:bed_id_local', newValue)
    },
    desc_payment_value(newValue) {
      this.$emit('update:desc_payment', newValue)
    },
    store_in_local(newValue) {
      this.$emit('update:store_in_local_prop', newValue)
      if (this.$store.state.local_users_options.length == 0) {
        this.get_users({
          local_sys: true,
        })
      }
    },
  },
}
</script>
