<template>
  <div>
    <div
      class="mt-4"
      :class="{ card: show_in_card_section }"
      v-for="(snd, index) in other_sanads"
      :key="snd.id"
    >
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h3 class="text-ayandeh fs-14 fw-bold d-inline-block mb-0">
            <p v-if="rows_title" v-text="rows_title"></p>
            <template v-else> جزئیات سند حسابداری {{ index + 1 }} </template>
          </h3>
          <btn-ayandeh
            color="outline-primary"
            v-if="deletable"
            class="btn-sm"
            @click="remove_sanad(index)"
          >
            حذف
            <font-awesome-icon icon="fa-solid fa-times" class="ms-1" />
          </btn-ayandeh>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label class="mb-2">بستانکار*</label>
            <v-select
              v-model="snd.bes_id"
              :options="bed_and_bes_options"
              :reduce="(option) => option.value"
              :selectable="(option) => option.value != snd.bed_id"
              placeholder="انتخاب بستانکار"
              dir="rtl"
              :loading="$store.state.users_options_loading"
            ></v-select>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="mb-2">بدهکار*</label>
            <v-select
              v-model="snd.bed_id"
              :options="bed_and_bes_options"
              :reduce="(option) => option.value"
              :selectable="(option) => option.value != snd.bes_id"
              placeholder="انتخاب بدهکار"
              dir="rtl"
              :loading="$store.state.users_options_loading"
            ></v-select>
          </div>
          <div class="col-12 com-sm-6 col-md-3" v-if="snd.bes_id === 'car'">
            <label>نوع سند</label>
            <select
              v-model="snd.type"
              @change="
                function () {
                  if (snd.type === 'from_card_reward') {
                    snd.calc_amount_type = 'by_tonage'
                  }
                }
              "
              class="form-control"
            >
              <option value="from_card_normal">عادی</option>
              <option value="from_card_reward">بابت انعام</option>
            </select>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <p>شیوه محاسبه مبلغ برای سند</p>
            <div class="form-check form-check-inline c-pointer">
              <input
                class="form-check-input"
                type="radio"
                :id="'static_amount_' + index"
                :disabled="snd.type === 'from_card_reward'"
                v-model="snd.calc_amount_type"
                value="static"
              />
              <label class="form-check-label" :for="'static_amount_' + index"
                >مبلغ ثابت</label
              >
            </div>
            <div class="form-check form-check-inline c-pointer">
              <input
                class="form-check-input"
                type="radio"
                :id="'calc_by_tonage' + index"
                v-model="snd.calc_amount_type"
                value="by_tonage"
              />
              <label class="form-check-label" :for="'calc_by_tonage' + index"
                >بر اساس تناژ</label
              >
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              :number-format="true"
              placeholder="مبلغ"
              v-model:value="snd.amount"
              ref="amount"
              dir="ltr"
            >
              <div dir="rtl">
                مبلغ
                <currency :parentheses="true" />
              </div>
            </input-ayandeh>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <CFormLabel>شرح سند حسابداری*</CFormLabel>
            <CFormTextarea
              v-model="snd.description"
              rows="3"
              placeholder="شرح سند"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import collect from 'collect.js'

export default {
  name: 'CardManageOtherSnd',
  data() {
    return {
      other_sanads: [],
    }
  },
  props: {
    other_sanads_prop: {
      default: [],
      type: Array,
    },
    validate: {
      type: Boolean,
    },
    show_in_card_section: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    rows_title: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.other_sanads = this.other_sanads_prop
  },
  computed: {
    bed_and_bes_options() {
      var options = collect(this.$store.state.users_options)
      if (options.where('value', 'car').count() === 0) {
        options.prepend({
          label: 'باربری (صادر کننده بارنامه)',
          value: 'freightage',
        })
        options.prepend({
          label: 'کد حسابداری پلاک',
          value: 'car',
        })
      }
      return options.toArray()
    },
  },
  methods: {
    ...mapActions(['get_users']),
    add_sanad(snd = null) {
      if (this.$store.state.users_options.length <= 2) {
        this.get_users({ is_in_fm: 1 })
      }

      if (snd) {
        this.other_sanads.push(snd)
      } else {
        this.other_sanads.push({
          bed_id: null,
          bes_id: null,
          amount: '',
          calc_amount_type: 'static',
          description: '',
          type: 'from_card_normal',
        })
      }
    },
    remove_sanad(index) {
      this.other_sanads.splice(index, 1)
    },
    reset_all_data() {
      this.other_sanads = []
    },
    chack_validate() {
      var collection = collect(this.other_sanads)

      if (collection.where('bes_id', null).count()) return false

      if (collection.where('bed_id', null).count()) return false

      if (collection.where('amount', '=', '').count()) return false

      if (collection.where('description', '=', '').count()) return false

      return true
    },
  },
  emits: ['update:validate', 'update:other_sanads_prop'],
  watch: {
    other_sanads: {
      handler(newValue) {
        console.log(newValue, this.chack_validate(), 'hiiiii')

        this.$emit('update:validate', this.chack_validate())
        this.$emit('update:other_sanads_prop', newValue)
      },
      deep: true,
    },
  },
}
</script>
