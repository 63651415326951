<template>
  <div
    class="input-group mb-3"
    v-for="(data, index) in datas_input"
    :key="index"
  >
    <div class="w-100">
      <label class="form-label mb-1" :for="'input' + index">
        {{ label + ' ' + (parseInt(index) + 1) }}
      </label>
    </div>
    <input
      class="form-control rounded-start"
      :type="type"
      :id="'input' + index"
      :ref="'input' + index"
      :index="index"
      v-model="datas_input[index]"
      :placeholder="label + ' ' + (parseInt(index) + 1) + ' را وارد کنید'"
      @paste="change_attach"
      @keyup="change_attach"
    />
    <span
      class="input-group-text c-pointer text-danger-light"
      v-if="Object.keys(datas_input).length > 1"
      @click="delete datas_input[index]"
    >
      <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger" />
    </span>
  </div>
  <btn-ayandeh color="secondary" class="btn-sm" @click="add_another_input">
    <font-awesome-icon icon="fa-solid fa-plus" class="text-white" />
  </btn-ayandeh>
</template>

<script>
export default {
  name: 'MultipleInput',
  data() {
    return {
      datas_input: { 0: '' },
    }
  },
  props: {
    value: {
      type: [Object, String],
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.value != undefined) {
      this.datas_input = this.value
    }
  },
  computed: {
    next_index() {
      var length = Object.keys(this.datas_input).length
      var i = 1
      while (i) {
        if (this.datas_input[length] == undefined) {
          i = 0
        } else {
          length++
        }
      }
      return length
    },
  },
  methods: {
    add_another_input() {
      var next_index = this.next_index
      this.datas_input[next_index] = ''
      setTimeout(
        function () {
          this.$refs['input' + next_index][0].focus()
        }.bind(this),
        100,
      )
    },
    change_attach() {
      this.$emit('update:value', this.datas_input)
    },
  },
  watch: {
    datas_input() {},
  },
}
</script>

<style scoped></style>
