<template>
  <div class="show_plaque mt-2 mb-3" :class="size">
    <div class="p-right">
      <div class="fs-14 mt-1">ایران</div>
      <span>{{ car.car_number_base }}</span>
    </div>
    <div class="p-center">
      <span>{{ car.car_number_three_digits }}</span>
      <span class="p-letter">{{ car.car_number_letter }}</span>
      <span>{{ car.car_number_two_digits }}</span>
    </div>
    <div class="p-left">
      <img src="/img/icons/iran_flag.jpg" />
      <span class="fs-10 d-block text-left pe-1 ltr" style="height: 13px"
        >I.R.</span
      >
      <span class="fs-8 d-block text-left pe-1 ltr" style="height: 5px"
        >IRAN</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicensePlate',
  props: ['car', 'size'],
}
</script>
