<template>
  <h1 class="title_card">بخش انعام یا کرایه</h1>
  <div class="row">
    <div class="col-6 col-sm-4 col-md-3 mb-3">
      <router-link
        class="btn btn-app px-5"
        :to="{
          name: 'PaymentAbleList',
          query: { status: 'waiting_for_pay' },
        }"
      >
        <img src="/img/icons/waiting_for_pay_128.png" class="menu-icon-item" />
        درانتظار پرداخت
      </router-link>
    </div>
    <div class="col-6 col-sm-4 col-md-3 mb-3">
      <router-link
        class="btn btn-app px-5"
        :to="{
          name: 'PaymentAbleList',
          query: { status: 'payed' },
        }"
      >
        <img src="/img/icons/payment-method.png" class="menu-icon-item" />
        پرداخت شده
      </router-link>
    </div>
    <div class="col-6 col-sm-4 col-md-3 mb-3">
      <router-link
        class="btn btn-app px-5"
        :to="{
          name: 'PaymentAbleList',
          query: { status: 'canceled' },
        }"
      >
        <img src="/img/icons/close_circle.png" class="menu-icon-item" />
        لغو شده ها
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentAbleMenu',
  data: () => {
    return {
      loading: false,
    }
  },
  mounted() {
    this.get_orders()
  },
  methods: {
    get_orders() {
      this.loading = true
      // eslint-disable-next-line no-undef
      axios.post('/orders/get_orders', { status: 2 }).then((resource) => {
        if (resource.data.status == 'success') {
          this.orders = resource.data.orders
          this.loading = false
        }
      })
    },
  },
}
</script>
<style scoped></style>
