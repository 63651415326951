import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import routes from '@/router/routes'
// import routes from '@/router/routes_example'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return to.hash
    }
  },
})

router.beforeEach((to, from, next) => {
  store.state.NProcess = true
  if (to.matched.length) {
    var title = to.matched[to.matched.length - 1].props.default.label
    document.title = 'صادرات | ' + title
  } else {
    document.title = 'صادرات'
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // eslint-disable-next-line no-undef
    if (!localStorage.getItem('token')) {
      next({
        path: router.resolve({ name: 'AdminLogin' }).path,
        query: { redirect: to.fullPath },
      })
    } else {
      store.dispatch('setAdminInfo')
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  store.state.NProcess = false

  document.body.classList.remove('modal-open');
  document.body.style.overflow = 'auto';
})
export default router
