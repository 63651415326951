import mixin from '../mixin'

export default {
  get_users({ commit, state }, params = {}) {
    var config = {
      params: params,
    }
    if (params.local_sys !== undefined) {
      state.local_users_options_loading = true

      if (state.source_axios) {
        state.source_axios.cancel()
      }

      // eslint-disable-next-line no-undef
      state.source_axios = axios.CancelToken.source()
      Object.assign(config, { cancelToken: state.source_axios.token })
    } else {
      state.users_options_loading = true
    }

    // eslint-disable-next-line no-undef
    axios.get('/users/get_users', config)
      .then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        if (params.local_sys !== undefined) {
          state.local_users_options_loading = false
          commit('storeUsersLocal', response.data.users)
        } else {
          state.users_options_loading = false
          commit('storeUsers', response.data.users)
        }
      }
      state.users_options_loading = false
    })
  },
  get_site_users({ commit, state }, type = 1) {
    state.site_users_options_loading = true
    // eslint-disable-next-line no-undef
    axios.get('/users/get_site_users?type=' + type).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeSiteUsers', response.data.users)
      }
      state.site_users_options_loading = false
    })
  },
  get_payment_types({ commit, state }, local_sys = false) {
    state.payment_types_loading = true
    if (local_sys) {
      state.payment_types_local_loading = true
    }
    // eslint-disable-next-line no-undef
    axios.get('/get_payment_types').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        if (local_sys) {
          commit('storePaymentTypesLocal', response.data.payment_types)
        } else {
          commit('storePaymentTypes', response.data.payment_types)
        }
      }
      state.payment_types_local_loading = state.payment_types_loading = false
    })
  },
  get_type_pay_options({ commit, state }) {
    state.type_pay_options_loading = true
    // eslint-disable-next-line no-undef
    axios.get('/get_type_pay_options').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeTypePayOptions', response.data.type_pay_options)
      }
      state.type_pay_options_loading = false
    })
  },
  get_factories({ commit, state }, query_str_data = null) {
    state.loading_factory = true
    let query = ''
    if (query_str_data) {
      query = mixin.methods.gen_query_string(query_str_data)
    }
    // eslint-disable-next-line no-undef
    axios.get('/factory/get_factories?' + query).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeFactories', response.data.factories)
        state.loading_factory = false
      }
    })
  },
  get_products({ commit, state }) {
    state.loading_products = true
    // eslint-disable-next-line no-undef
    axios.get('/product/get_products')
      .then((response) => {
        if (
          response != undefined &&
          response.data != undefined &&
          response.data.status == 'success'
        ) {
          commit('storeProducts', response.data.products)
          state.loading_products = false
        }
      })
  },
  get_cities({ commit, state }, params = {}) {
    state.loading_cities = true
    // eslint-disable-next-line no-undef
    axios.get('/get_cities', { params: params }).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeCities', response.data.cities)
        state.loading_cities = false
      }
    })
  },
  get_provinces({ commit, state }) {
    state.loading_provinces = true
    // eslint-disable-next-line no-undef
    axios.get('/get_provinces').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeProvinces', response.data.provinces)
        state.loading_provinces = false
      }
    })
  },
  get_anbars({ commit, state }) {
    state.loading_anbars = true
    // eslint-disable-next-line no-undef
    axios.get('/get_anbars').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeAnbars', response.data.anbars)
        state.loading_anbars = false
      }
    })
  },
  get_referreds({ commit, state }, query = {}) {
    state.loading_referreds = true
    // eslint-disable-next-line no-undef
    axios.get('/referred/get_referreds?' + query).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeReferreds', response.data.referreds)
        state.loading_referreds = false
      }
    })
  },
  get_fiscal_years({ commit, state }, type) {
    state.loading_fiscal_year = true
    // eslint-disable-next-line no-undef
    axios.get('/get_fiscal_years/' + type).then((response) => {
      if (response != undefined && response.data != undefined) {
        commit('storeFiscalYearsOptions', response.data.fiscal_years)
        commit('storeCurrentFiscalYears', response.data.current_fiscal_year)
      }
      state.loading_fiscal_year = false
    })
  },
  get_current_fiscal_years({ commit, state }) {
    state.loading_fiscal_year = true
    // eslint-disable-next-line no-undef
    axios.get('/get_current_fiscal_years').then((response) => {
      if (response != undefined && response.data != undefined) {
        commit('storeCurrentFiscalYears', response.data.current_fiscal_year)
      }
      state.loading_fiscal_year = false
    })
  },
  get_all_fiscal_years({ commit, state }) {
    state.loading_all_fiscal_year = true
    // eslint-disable-next-line no-undef
    axios.get('/get_all_fiscal_years?year_grouped_by=1').then((response) => {
      if (response != undefined && response.data != undefined) {
        commit('storeAllFiscalYearsGroupedOptions', response.data.fiscal_years)
      }
      state.loading_all_fiscal_year = false
    })
  },
  get_all_fiscal_years_site({ commit, state }) {
    state.loading_all_fiscal_year = true
    // eslint-disable-next-line no-undef
    axios.get('/fiscal_year/get_all_fiscal_years_site').then((response) => {
      if (response != undefined && response.data != undefined) {
        commit('storeAllFiscalYearsSite', response.data.fiscal_years)
      }
      state.loading_all_fiscal_year = false
    })
  },
  get_sanad_data({ state }, fiscal_year) {
    state.loading_get_sanad_data = true

    // eslint-disable-next-line no-undef
    axios
      .get('/sanad/get_sanad_data?fiscal_year=' + fiscal_year)
      .then((response) => {
        if (response != undefined && response.data != undefined) {
          state.count_verified_sanad = response.data.count_verified_sanad
          state.count_not_verify_sanad = response.data.count_not_verify_sanad
          state.count_all_sanads = response.data.count_all_sanads
          state.loading_get_sanad_data = false
        }
      })
  },
  change_tr_selected({ commit }, data) {
    commit('changeTrSelected', data)
  },
  get_types_arz({ commit, state }) {
    state.type_arz_loading = true
    // eslint-disable-next-line no-undef
    axios.get('/get_type_arz_array').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeTypesArz', response.data.type_arz_arr)
      }
      state.type_arz_loading = false
    })
  },
  get_order({ commit, state }, order_id) {
    state.loading_get_order = true
    // eslint-disable-next-line no-undef
    axios.get('/orders/get_order/' + order_id).then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeOrder', response.data.order)
      }
      state.loading_get_order = false
    })
  },
  updateAdminInfo({ commit }, data) {
    commit('storeAdmin', data)
  },
  setAdminInfo({ commit }) {
    // eslint-disable-next-line no-undef
    axios.get('/admin/get_admin_info').then((response) => {
      if (response != undefined && response.data != undefined) {
        commit('storeAdmin', response.data.admin)
        commit('storeFiscalYearData', response.data.current_fiscal_year)
      }
    })
  },
  get_groups_fm({ commit, state }) {
    state.get_groups_fm_loading = true
    // eslint-disable-next-line no-undef
    axios.get('/get_groups_fm').then((response) => {
      if (
        response != undefined &&
        response.data != undefined &&
        response.data.status == 'success'
      ) {
        commit('storeGroupsFm', response.data.groups)
      }
      state.get_groups_fm_loading = false
    })
  },
  get_default_anbar_fm({ commit }) {
    // eslint-disable-next-line no-undef
    axios.get('/get_default_anbar_fm?site=export').then((response) => {
      if (
        response !== undefined &&
        response.data.status === 'success' &&
        response.data.anbar != null
      ) {
        commit('storeDefAnbarFm', response.data.anbar.Cod)
      }
    })
  },
  get_anbars_fm({ commit }) {
    // eslint-disable-next-line no-undef
    axios.get('/get_anbars_fm?site=export').then((response) => {
      if (response !== undefined && response.data.status === 'success') {
        commit('storeAnbarsFm', response.data.anbars)
      }
    })
  },
  toggle_modal_other_sanads({ state }, status) {
    state.visiable_modal_other_sanad = status
  },
  get_other_sanads({ commit, state }, params = {}) {
    if (state.source_axios) {
      state.source_axios.cancel()
    }

    // eslint-disable-next-line no-undef
    state.source_axios = axios.CancelToken.source()

    state.loading_get_other_sanads = true
    // eslint-disable-next-line no-undef
    params.load_deleted = state.get_deleted_others_sanad ? 1 : ''
    axios
      .get('/others_sanad/get', {
        params: params,
        cancelToken: state.source_axios.token,
      })
      .then((response) => {
        if (response !== undefined && response.data.status === 'success') {
          commit('storeOtherSanads', response.data.data)
          state.loading_get_other_sanads = false
        }
      })
      .catch(() => {
        state.loading_get_other_sanads = false
      })
  },
}
