<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <div class="sidebar-brand">
      <div class="sidebar-brand-full text-right py-2">
        <img
          src="/img/logo/ayandeh_256.png"
          class="d-inline-block"
          width="50"
        />
        <h2 class="d-inline-block fs-4 ms-2">سیمان آینده</h2>
      </div>
      <div class="sidebar-brand-narrow">
        <img
          src="/img/logo/ayandeh_256.png"
          class="d-inline-block"
          width="40"
        />
      </div>
    </div>
    <!--    <AppSidebarNav />-->
    <ul class="sidebar-nav">
      <!--    Dashboard     -->
      <li class="nav-item">
        <router-link :to="{ name: 'Dashboard' }" class="nav-link">
          <font-awesome-icon icon="fa-solid fa-gauge-high" class="nav-icon" />
          داشبورد
        </router-link>
      </li>
      <!--    Orders     -->
      <li
        v-if="has_permission([1, 2, 3, 4, 5])"
        class="nav-group"
        :class="{ show: in_array(['Orders', 'OrderList'], current_open) }"
        @click="toggleNav('Orders')"
      >
        <div class="nav-link nav-group-toggle">
          <font-awesome-icon
            icon="fa-solid fa-table-columns"
            class="nav-icon"
          />
          سفارشات
        </div>
        <ul class="nav-group-items">
          <li class="nav-item">
            <router-link :to="{ name: 'OrderList' }" class="nav-link">
              لیست سفارشات
            </router-link>
          </li>
        </ul>
      </li>
      <!--    Paymentable     -->
      <li class="nav-item" v-if="has_permission([1, 2, 3])">
        <router-link :to="{ name: 'PaymentAbleMenu' }" class="nav-link">
          <font-awesome-icon icon="fa-solid fa-receipt" class="nav-icon" />
          پرداختنی ها
        </router-link>
      </li>
      <!--    Payments     -->
      <li
        v-if="has_permission([1, 2, 3, 6])"
        class="nav-group"
        :class="{
          show: in_array(
            ['Payment', 'NewPayment', 'PaymentList'],
            current_open,
          ),
        }"
        @click="toggleNav('Payment')"
      >
        <div class="nav-link nav-group-toggle">
          <font-awesome-icon
            icon="fa-solid fa-hand-holding-dollar"
            class="nav-icon"
          />
          پرداخت
        </div>
        <ul class="nav-group-items">
          <li class="nav-item">
            <router-link :to="{ name: 'NewPayment' }" class="nav-link">
              ثبت پرداخت
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'PaymentList' }" class="nav-link">
              لیست پرداخت ها
            </router-link>
          </li>
        </ul>
      </li>
      <!--    Sanads     -->
      <li class="nav-item" v-if="has_permission([1, 2, 3])">
        <router-link :to="{ name: 'SanadMenu' }" class="nav-link">
          <font-awesome-icon
            icon="fa-solid fa-file-invoice-dollar"
            class="nav-icon"
          />
          اسناد حسابداری
          <span
            class="badge bg-danger ms-auto"
            v-text="$store.state.count_not_verify_sanad"
            v-if="
              $store.state.count_not_verify_sanad > 0 &&
              $store.state.loading_get_sanad_data == false
            "
          ></span>
        </router-link>
      </li>
      <!--    Cards     -->
      <li
        v-if="has_permission([1, 2, 3])"
        class="nav-group"
        :class="{
          show: in_array(
            ['Cards', 'NewCard', 'CardMenu', 'CardList'],
            current_open,
          ),
        }"
        @click="toggleNav('Cards')"
      >
        <div class="nav-link nav-group-toggle">
          <font-awesome-icon
            icon="fa-solid fa-boxes-packing"
            class="nav-icon"
          />
          بخش کارت ها
        </div>
        <ul class="nav-group-items">
          <li class="nav-item">
            <router-link :to="{ name: 'NewCard' }" class="nav-link">
              کارت جدید
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'CardMenu' }" class="nav-link">
              لیست کارت ها
            </router-link>
          </li>
        </ul>
      </li>
      <!--    Turnover / گردش حساب     -->
      <li class="nav-item" v-if="has_permission([1, 2, 3, 4, 6])">
        <router-link :to="{ name: 'Gardesh' }" class="nav-link">
          <font-awesome-icon icon="dollar-sign" class="nav-icon" />
          گردش حساب
        </router-link>
      </li>
      <!--    Facroty And Products / کارخانه و محصولات     -->
      <li class="nav-item" v-if="has_permission([1, 2, 3])">
        <router-link :to="{ name: 'FactoryList' }" class="nav-link">
          <font-awesome-icon icon="industry" class="nav-icon" />
          کارخانه و محصولات
        </router-link>
      </li>
      <!-- finanical year management / مدیریت سال مالی -->
      <li class="nav-item" v-if="has_permission([1, 2, 3])">
        <router-link :to="{ name: 'FiscalYear' }" class="nav-link">
          <font-awesome-icon
            icon="fas fa-file-invoice-dollar"
            class="nav-icon"
          />
          مدیریت سال مالی
        </router-link>
      </li>
    </ul>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
// import { AppSidebarNav } from '@/components/AppSidebarNav'
export default {
  name: 'AppSidebar',
  data() {
    return {
      current_open: '',
    }
  },
  mounted() {
    if (
      this.getCookie('snd_fiscal_year') &&
      this.getCookie('snd_fiscal_year')?.length > 10
    ) {
      this.set_current_or_cookie_or_query_fiscal_year()
    }
    this.current_open = this.$route.name
  },
  // components: { AppSidebarNav },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  methods: {
    ...mapActions(['get_sanad_data']),
    toggleNav(route_name) {
      if (this.current_open == route_name) {
        this.current_open = ''
      } else {
        this.current_open = route_name
      }
    },
    in_array(array, niddle) {
      var is = array.filter((x) => x == niddle).length
      if (is > 0) return true
      return false
    },
  },
  watch: {
    fiscal_year_snd(newValue) {
      this.get_sanad_data(newValue)
    },
  },
}
</script>
