import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      desc_payment: '',
      bed_id: '',
      bed_id_local: '',
      bes_id: '',
      bes_id_local: '',
      hesabs: [],
      hesabs_local: [],
      loading_hesab: false,
      loading_hesab_local: false,
      store_payment_row_in_local_system: false,
      date_check: '',
      date_check_local: '',
      fee: '',
      fee_local: '',
      date_pay: '',
      type_payment_selected_local: '',
      desc_row_payment: '',
      editing_payment_detail: null,
      attachs: null,
      time_pay: '00:00',
      amount: '',
      tracking_code: '',
      type_payment_selected: '',
      type_pay_selected: '',
    }
  },
  computed: {
    form_naghdi_complete() {
      var res = true
      if (
        this.amount.length == 0 ||
        this.tracking_code.length == 0 ||
        this.date_pay.length == 0 ||
        this.bes_id == null ||
        this.bes_id.length == 0 ||
        this.bed_id.length == 0 ||
        this.$store.state.jalali_validate == 0
      ) {
        res = false
      }
      if (this.type_payment_selected == 2) {
        if (this.date_check && !this.date_check.length) {
          res = false
        }
      } else if (!this.type_pay_selected.length) {
        res = false
      }

      if (this.bes_id == 105010058 && this.store_payment_row_in_local_system) {
        if (this.bes_id_local.length == 0) {
          res = false
        } else if (this.type_payment_selected_local == 2) {
          if (this.date_check_local && !this.date_check_local.length) {
            res = false
          }
        } else if (!this.type_pay_selected.length) {
          res = false
        }
      }
      return res
    },
    hesab_option_select() {
      if (
        this.hesabs.length &&
        this.hesabs[this.type_payment_selected] != undefined
      ) {
        return Object.values(this.hesabs[this.type_payment_selected]).map(
          (item) => {
            return {
              label:
                (item.IdTafsil || item.Id) + ' - ' + (item.Nam || item.Com),
              value: item.IdTafsil || item.Id,
            }
          },
        )
      } else {
        return []
      }
    },
    hesab_option_select_local() {
      if (
        this.hesabs_local.length &&
        this.hesabs_local[this.type_payment_selected_local] != undefined
      ) {
        return Object.values(
          this.hesabs_local[this.type_payment_selected_local],
        ).map((item) => {
          return {
            label: (item.IdTafsil || item.Id) + ' - ' + (item.Nam || item.Com),
            value: item.IdTafsil || item.Id,
          }
        })
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions(['get_payment_types', 'get_type_pay_options']),
    changed_time(event) {
      var value = event.detail.value
      this.time_pay = value.getHours() + ':' + value.getMinutes()
    },
    get_sum_amount_payment(payment) {
      var sum_amount = 0
      if (payment.details.length) {
        payment.details.forEach((item) => {
          sum_amount += item.amount
        })
      }
      return sum_amount
    },
  },
  watch: {
    type_payment_selected(newValue) {
      if (newValue != '' && !this.hesabs[newValue]) {
        this.loading_hesab = true
        // eslint-disable-next-line no-undef
        axios
          .get('/get_hesab_by_type_pay?type_pay=' + newValue)
          .then((response) => {
            if (response != undefined) {
              this.hesabs[newValue] = response.data.data
              this.loading_hesab = false
            }
          })
      }
      this.bes_id = ''
    },
    type_payment_selected_local(newValue) {
      if (newValue != '' && !this.hesabs_local[newValue]) {
        this.loading_hesab_local = true
        var data = this.gen_query_string({
          type_pay: newValue,
          local_sys: true,
        })
        // eslint-disable-next-line no-undef
        axios.get('/get_hesab_by_type_pay?' + data).then((response) => {
          if(response != undefined && response.data != undefined) {
            this.hesabs_local[newValue] = response.data.data
            this.loading_hesab_local = false
          }
        })
      }
      this.bes_id_local = ''
    },
    bes_id(newValue) {
      if (newValue && this.type_payment_selected != 2) {
        this.loading_mandeh_bes = true
        this.get_code_mandeh(newValue)
      }
      if (
        this.editing_payment_detail &&
        this.editing_payment_detail.bes_id == 105010058 &&
        newValue == 105010058 &&
        newValue != null
      ) {
        this.store_payment_row_in_local_system = true
      } else if (newValue == 105010058) {
        Swal.fire({
          icon: 'question',
          html: 'آیا میخواهید که این پرداخت را در سیستم داخلی هم ثبت کنید؟',
          showDenyButton: true,
          showCloseButton: true,
          confirmButtonText: 'بله',
          denyButtonText: 'خیر',
        }).then((result) => {
          if (result.isConfirmed) {
            this.get_payment_types(true)
            this.store_payment_row_in_local_system = true
          }
        })
      } else {
        this.store_payment_row_in_local_system = false
      }
    },
  },
}
