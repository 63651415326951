<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.stop.prevent="submit_login">
                  <h1>ورود</h1>
                  <p class="text-medium-emphasis">ورود به پنل مدیریت صادرات</p>
                  <CInputGroup class="mb-3">
                    <CInputGroupText>
                      <CIcon icon="cil-mobile" />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="موبایل"
                      autocomplete="off"
                      v-model="mobile"
                      dir="ltr"
                    />
                  </CInputGroup>
                  <CInputGroup class="mb-4">
                    <CInputGroupText>
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="رمز عبور"
                      autocomplete="current-password"
                      v-model="password"
                      dir="ltr"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol :xs="6">
                      <CButton
                        color="primary"
                        class="px-4"
                        type="submit"
                        :disabled="loading"
                      >
                        ورود
                        <loading-spinner
                          :show="loading"
                          size="sm"
                          class="ms-2"
                        />
                      </CButton>
                    </CCol>
                    <!--                    <CCol :xs="6" class="text-right">-->
                    <!--                      <CButton color="link" class="px-0">فراموشی رمز!</CButton>-->
                    <!--                    </CCol>-->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              class="d-none d-md-block text-white bg-ayandeh py-5"
              style="width: 44%"
            >
              <CCardBody class="text-center">
                <div>
                  <!--                  <h2>Sign up</h2>-->
                  <!--                  <p>-->
                  <!--                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,-->
                  <!--                    sed do eiusmod tempor incididunt ut labore et dolore magna-->
                  <!--                    aliqua.-->
                  <!--                  </p>-->
                  <!--                  <CButton color="light" variant="outline" class="mt-3">-->
                  <!--                    Register Now!-->
                  <!--                  </CButton>-->
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      mobile: '',
      password: '',
      loading: false,
    }
  },
  methods: {
    ...mapActions(['updateAdminInfo']),
    submit_login() {
      this.loading = true
      // eslint-disable-next-line no-undef
      axios
        .post('/login', {
          mobile: this.mobile,
          password: this.password,
        })
        .then(({ data }) => {
          this.loading = false
          if (data.status === 'success') {
            var api_token = data.admin.api_token
            localStorage.setItem('token', api_token)
            window.axios.defaults.headers.common['Authorization'] =
              'Bearer ' + api_token
            this.updateAdminInfo(data.admin)
            // this.$store.commit('storeAdmin', data.admin)
            var url = this.$route.query.redirect
            if (url == undefined) {
              url = this.$router.resolve({
                name: 'Dashboard',
              }).fullPath
            }
            this.$router.replace(url)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
