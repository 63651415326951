<template>
  <filter-card>
    <template #static>
      <div class="col-12 col-sm-6 col-md-4 position-relative">
        <layout-loading v-if="$store.state.loading_all_fiscal_year" />
        <div class="form-group">
          <label>سال مالی</label>
          <select
            class="form-control ltr"
            v-model="fiscal_year_snd"
            :loading="$store.state.loading_all_fiscal_year"
          >
            <optgroup
              v-for="(fiscal_years, index) in $store.state
                .all_fiscal_year_site"
              :key="index"
              :label="index"
            >
              <option
                v-for="item in fiscal_years"
                :key="item.id"
                :value="item.name"
                v-text="item.label"
              ></option>
            </optgroup>
          </select>
        </div>
      </div>
    </template>
  </filter-card>

  <div class="card">
    <layout-loading v-if="!fiscal_year_snd || fiscal_year_snd == ''" />
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="form-group mb-1" id="type_payment_selected">
            <label class="w-100">افزودن کد حسابداری کامل</label>
            <v-select
              v-model="code_ids"
              @search="doSearch"
              :options="code_options"
              :reduce="(option) => option.IdTafsil"
              :selectable="
                (option) =>
                  code_ids.filter((x) => x == option.IdTafsil).length == 0
              "
              :multiple="true"
              :clearSearchOnSelect="false"
              placeholder="جستجو کد حسابداری"
              dir="rtl"
            >
              <template #no-options>هیچ موردی برای نمایش وجود ندارد</template>
            </v-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <btn-ayandeh
            color="info"
            class="mt-md-3"
            :disabled="code_ids.length == 0 || loading_store_part_code"
            @click="store_code_to_active_list"
            :loading="loading_store_code"
            >افزودن به لیست</btn-ayandeh
          >
        </div>
        <div class="col-12 my-3 border-bottom border-style-bottom-dash"></div>
        <div class="col-12 col-md-4">
          <div class="form-group mb-1">
            <input-ayandeh
              v-model:value="first_part_tafsil"
              dir="ltr"
              label="افزودن پیشوند کد های تفصیل"
              placeholder="قسمت ابتدایی از کل ، معین یا تفصیل"
            ></input-ayandeh>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <btn-ayandeh
            color="info"
            class="mt-md-4"
            :disabled="first_part_tafsil.length == 0 || loading_store_code"
            @click="store_part_code_to_active_list"
            :loading="loading_store_part_code"
            >افزودن به لیست</btn-ayandeh
          >
        </div>
        <div class="col-12 my-3 border-bottom border-style-bottom-dash"></div>
        <div class="col-12">
          <p class="fw-bold text-ayandeh">لیست کد های حسابداری فعال</p>
          <div class="table-ay1">
            <table>
              <thead>
                <tr>
                  <th>ردیف</th>
                  <th>کد حسابداری</th>
                  <th>نام کد حساب</th>
                  <th>موبایل</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <loading-tr v-if="loading_get_active_codes" :colspan="5" />
                <empty-tr
                  v-else-if="active_codes_filtered.length == 0"
                  :colspan="5"
                />
                <tr
                  class="position-relative right-transition"
                  v-for="(code, index) in active_codes_filtered"
                  :key="index"
                  :ref="'tr_' + index"
                >
                  <td>
                    <div class="mobile_title">ردیف :</div>
                    {{ index + 1 }}
                  </td>
                  <td v-text="code.code_id"></td>
                  <td v-text="code.code_name"></td>
                  <td>
                    <div
                      v-if="code.mobiles !== undefined && code.mobiles.length"
                      v-c-tooltip="{
                        content: code.mobiles
                          .map((item) => {
                            return item.mobile
                          })
                          .join(' - '),
                        palcement: 'top',
                      }"
                    >
                      {{ code.mobiles[0].mobile }}
                      <span v-if="code.mobiles.length > 1" class="text-muted">
                        + {{ code.mobiles.length - 1 }}
                      </span>
                    </div>
                    <span v-else>-/-</span>
                  </td>
                  <td>
                    <div
                      class="d-inline-block position-relative c-pointer p-2 text-info"
                      @click="openModalMobiles(code)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-mobile-alt"
                        size="xl"
                        class=""
                      />
                      <font-awesome-icon
                        icon="fa-solid fa-pen"
                        size="2xs"
                        class="position-absolute"
                        style="right: 11px; top: 12px"
                      />
                    </div>
                    <font-awesome-icon
                      @click="delete_code(code, index)"
                      icon="fa-solid fa-trash-can"
                      size="lg"
                      class="text-danger ms-2 c-pointer"
                    />
                    <font-awesome-icon
                      @click="open_modal_sms_setting(code)"
                      icon="fa-solid fa-gear"
                      size="lg"
                      class="text-secondary ms-2 c-pointer"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  Edit Main Payment -->
  <CModal :visible="modal_mobiles" @close="modal_mobiles = false">
    <CModalHeader dismiss @close="modal_mobiles = false">
      <CModalTitle class="fs-14" v-if="code_selected">
        <font-awesome-icon icon="fa-solid fa-pen" size="lg" class="me-2" />
        ویرایش یا افزودن موبایل برای کد ارسال پیامک
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <multiple-input
        type="tel"
        label="موبایل"
        v-model:value="mobiles"
      ></multiple-input>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh
        color="info"
        class="me-2"
        label="ذخیره"
        :disabled="loading_modify_code_mobiles"
        :loading="loading_modify_code_mobiles"
        @click="modify_code_mobiles"
      />
      <btn-ayandeh
        color="light"
        label="برگشت"
        :disabled="loading_modify_code_mobiles"
        @click="modal_mobiles = false"
      />
    </CModalFooter>
  </CModal>

  <!--  Sms Setting -->
  <CModal :visible="modal_sms_set" @close="modal_sms_set = false">
    <CModalHeader dismiss @close="modal_sms_set = false">
      <CModalTitle class="fs-14" v-if="code_selected">
        <font-awesome-icon icon="fa-solid fa-gears" size="lg" class="me-2" />
        تنظیمات پیامک
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p class="text-ayandeh bold">تغییر عنوان بدهکار در پیامک</p>
      <CFormSwitch
        :disabled="custom_title_bed.length"
        id="bedSwitch"
        v-model="use_other_title_for_bed"
        label='استفاده از کلمه "هزینه" به جای بدهکار'
      />
      <input-ayandeh
        label="یا کلمه دلخواد خود را وارد کنید"
        placeholder="حداکثر 4 فاصله"
        v-model:value="custom_title_bed"
      />
      <div
        class="border-bottom border-style-bottom-dash border-bottom-light mt-3"
      ></div>
      <p class="text-ayandeh mt-4 bold">تغییر عنوان بستانکار در پیامک</p>
      <CFormSwitch
        :disabled="custom_title_bes.length"
        id="besSwitch"
        v-model="use_other_title_for_bes"
        label='استفاده از کلمه "درآمد" به جای بستانکار'
      />
      <input-ayandeh
        label="یا کلمه دلخواد خود را وارد کنید"
        placeholder="حداکثر 4 فاصله"
        v-model:value="custom_title_bes"
      />
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh
        color="info"
        class="me-2"
        label="ذخیره"
        :disabled="loading_modify_code"
        :loading="loading_modify_code"
        @click="modify_code_verification"
      />
      <btn-ayandeh
        color="light"
        label="برگشت"
        :disabled="loading_modify_code"
        @click="modal_sms_set = false"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex'
import FilterCard from '@/components/partial/FilterCard'
import LoadingTr from '@/components/loading/LoadingTr'
import EmptyTr from '@/components/partial/EmptyTr'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MultipleInput from '@/components/partial/MultipleInput'
import LayoutLoading from '@/components/loading/LayoutLoading'
import Swal from 'sweetalert2'
import InputAyandeh from '@/components/inputs/InputAyandeh'
import SndMix from '@/mixin/SndMix'

export default {
  name: 'ActiveCodes',
  mixins: [SndMix],
  components: {
    InputAyandeh,
    LayoutLoading,
    MultipleInput,
    FontAwesomeIcon,
    EmptyTr,
    LoadingTr,
    FilterCard,
  },
  data() {
    return {
      source: null,
      first_part_tafsil: '',
      code_ids: [],
      code_options: [],
      loading_store_code: false,
      loading_store_part_code: false,
      modal_mobiles: false,
      code_selected: null,
      mobiles: {
        0: '',
      },
      loading_modify_code_mobiles: false,
      loading_delete_code: false,
      loading_modify_code: false,
      modal_sms_set: false,
      use_other_title_for_bed: false,
      use_other_title_for_bes: false,
      custom_title_bed: '',
      custom_title_bes: '',
    }
  },
  mounted() {
    this.$store.watch(
      () => {
        return this.$store.state.fiscal_year_data
      },
      () => {
        this.fiscal_year_snd = this.$store.state.fiscal_year_data.name
      },
      {
        deep: true,
      },
    )

    this.set_current_or_cookie_or_query_fiscal_year()
    if (this.$store.state.all_fiscal_year_site.length == 0) {
      this.get_all_fiscal_years_site()
    }
    this.get_active_codes()
  },
  methods: {
    ...mapActions([
      'get_current_fiscal_years',
      'get_all_fiscal_years_site'
    ]),
    doSearch: function (search, loading) {
      this.code_options = []
      if (this.source) {
        this.source.cancel()
      }

      if (search.length) {
        // eslint-disable-next-line no-undef
        this.source = axios.CancelToken.source()
        loading(true)
        var query = this.gen_query_string({
          search: search,
          fiscal_year: this.fiscal_year_snd,
        })

        // eslint-disable-next-line no-undef
        axios
          .get('/codes/search_code?' + query, {
            cancelToken: this.source.token,
          })
          .then((response) => {
            if (response !== undefined && response.data.status === 'success') {
              this.code_options = response.data.codes
            }
            loading(false)
          })
      }
    },
    store_code_to_active_list() {
      this.loading_store_code = true
      // eslint-disable-next-line no-undef
      axios
        .post('/codes/store_code_to_active_list', {
          code_ids: this.code_ids,
          fiscal_year: this.fiscal_year_snd,
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            this.code_options = this.code_ids = []
            this.get_active_codes()
          }
          this.loading_store_code = false
          this.my_alert(data.msg, data.status)
        })
    },
    store_part_code_to_active_list() {
      this.loading_store_part_code = true
      // eslint-disable-next-line no-undef
      axios
        .post('/codes/store_part_code_to_active_list', {
          first_part_tafsil: this.first_part_tafsil,
          fiscal_year: this.fiscal_year_snd,
        })
        .then((response) => {
          if (response.data !== undefined && response.data.status === 'success') {
            this.code_options = this.code_ids = []
            this.get_active_codes()
          }
          this.loading_store_part_code = false
          this.my_alert(response.data.msg, response.data.status)
        })
    },
    modify_code_mobiles() {
      this.loading_modify_code_mobiles = true
      // eslint-disable-next-line no-undef
      axios
        .post('/codes/modify_code_mobiles', {
          code_verifiable_id: this.code_selected.id,
          mobiles: this.mobiles,
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            var modified = this.active_codes.filter(
              (x) => x.id == this.code_selected.id,
            )[0]
            modified.mobiles = []
            setTimeout(function () {
              modified.mobiles = data.mobiles
            }, 20)
          }
          this.modal_mobiles = this.loading_modify_code_mobiles = false
          this.toast(data.msg, data.status)
          this.code_selected = null
        })
    },
    delete_code(code, index) {
      Swal.fire({
        confirmButtonText: 'حذف شود',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'حذف کد',
        html: 'آیا میخواهید کد <b>' + code.code_id + '</b> از لیست حذف شود؟',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_delete_code = true
          var index_in_list = this.active_codes
            .map((x) => x.id)
            .indexOf(code.id)
          // eslint-disable-next-line no-undef
          axios
            .get('/codes/delete_code?code_verifiable_id=' + code.id)
            .then(({ data }) => {
              if (data.status == 'success') {
                this.$refs['tr_' + index][0].style.right = '100%'
                setTimeout(
                  function () {
                    delete this.active_codes[index_in_list]
                    this.$refs['tr_' + index][0].style.right = '0'
                  }.bind(this),
                  200,
                )
              }
              this.toast(data.msg, data.status)
              this.loading_delete_code = false
            })
        }
      })
    },
    openModalMobiles(code) {
      this.modal_mobiles = true
      this.code_selected = code

      var mobiles = {}
      if(this.code_selected.mobiles !== undefined) {
        mobiles = this.code_selected.mobiles.map((x) => x.mobile)
      }
      if (mobiles.length) {
        this.mobiles = mobiles
      } else {
        this.mobiles = { 0: '' }
      }
    },
    open_modal_sms_setting(code) {
      this.modal_sms_set = true
      this.code_selected = code
      this.set_code_selected_for_data()
    },
    set_code_selected_for_data() {
      this.use_other_title_for_bed = this.code_selected.use_other_title_for_bed
      this.use_other_title_for_bes = this.code_selected.use_other_title_for_bes
      this.custom_title_bed = this.code_selected.custom_title_bed || ''
      this.custom_title_bes = this.code_selected.custom_title_bes || ''
    },
    modify_code_verification() {
      this.loading_modify_code = true
      // eslint-disable-next-line no-undef
      axios
        .post('/codes/modify_code', {
          use_other_title_for_bed: this.use_other_title_for_bed,
          use_other_title_for_bes: this.use_other_title_for_bes,
          custom_title_bed: this.custom_title_bed,
          custom_title_bes: this.custom_title_bes,
          code_verifiable_id: this.code_selected.id,
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            var modified = this.active_codes.filter(
              (x) => x.id == this.code_selected.id,
            )[0]

            modified.custom_title_bed = data.custom_title_bed
            modified.custom_title_bes = data.custom_title_bes
            modified.use_other_title_for_bed = data.use_other_title_for_bed
            modified.use_other_title_for_bes = data.use_other_title_for_bes
            this.modal_sms_set = false
            this.code_selected = null
          }
          this.loading_modify_code = false
          this.toast(data.msg, data.status)
        })
    },
  },
  computed: {
    active_codes_filtered() {
      return this.active_codes.filter((x) => x != undefined)
    },
  },
  watch: {
    fiscal_year_snd() {
      this.code_ids = this.code_options = []
      this.get_active_codes()
    },
    custom_title_bes(newValue) {
      if (newValue.length) {
        this.use_other_title_for_bes = false
      } else {
        this.use_other_title_for_bes =
          this.code_selected.use_other_title_for_bes
      }
    },
    custom_title_bed(newValue) {
      if (newValue.length) {
        this.use_other_title_for_bed = false
      } else {
        this.use_other_title_for_bed =
          this.code_selected.use_other_title_for_bed
      }
    },
  },
}
</script>

<style scoped></style>
