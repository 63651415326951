<template>
  <filter-card>
    <template #static>
      <div class="col-12 col-sm-6 col-md-4 position-relative">
        <layout-loading v-if="$store.state.loading_all_fiscal_year" />
        <div class="form-group">
          <label>سال مالی</label>
          <select
            class="form-control ltr"
            v-model="fiscal_year_snd"
            :loading="$store.state.loading_all_fiscal_year"
          >
            <optgroup
              v-for="(fiscal_years, index) in $store.state.all_fiscal_year_site"
              :key="index"
              :label="index"
            >
              <option
                v-for="item in fiscal_years"
                :key="item.id"
                :value="item.name"
                v-text="item.label"
              ></option>
            </optgroup>
          </select>
        </div>
      </div>
    </template>
  </filter-card>
  <div class="row">
    <div class="col-12 mb-5">
      <router-link
        :to="{
          name: 'SanadActiceCodes',
          query: { fiscal_year: fiscal_year_snd },
        }"
      >
        <btn-ayandeh>
          <font-awesome-icon icon="fa-solid fa-bars-progress" class="me-2" />
          مدیریت کد های حسابداری فعال
        </btn-ayandeh>
      </router-link>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <router-link
        :to="{
          name: 'SanadList',
          query: { status: 'not_verified', fiscal_year: fiscal_year_snd },
        }"
      >
        <CWidgetStatsF color="danger" title="اسناد تایید نشده">
          <template #icon>
            <font-awesome-icon icon="circle-xmark" size="2xl" />
          </template>
          <template #value v-if="$store.state.loading_get_sanad_data">
            <loading-spinner :show="true" size="sm" class="fs-10" />
          </template>
          <template #value v-else>{{
            $store.state.count_not_verify_sanad
          }}</template>
        </CWidgetStatsF>
      </router-link>
    </div>
    <div class="col-12 col-sm-6 col-md-4">
      <router-link
        :to="{
          name: 'SanadList',
          query: { status: 'verified', fiscal_year: fiscal_year_snd },
        }"
      >
        <CWidgetStatsF color="success" title="اسناد تایید شده">
          <template #icon>
            <font-awesome-icon icon="circle-check" size="2xl" />
          </template>
          <template #value v-if="$store.state.loading_get_sanad_data">
            <loading-spinner :show="true" size="sm" class="fs-10" />
          </template>
          <template #value v-else>{{
            $store.state.count_verified_sanad
          }}</template>
        </CWidgetStatsF>
      </router-link>
    </div>
    <!--    همه اسناد تایید حسابداری-->
    <div class="col-12 col-sm-6 col-md-4">
      <router-link
        :to="{
          name: 'SanadList',
          query: { status: 'all_snd', fiscal_year: fiscal_year_snd },
        }"
      >
        <CWidgetStatsF color="primary" title="همه اسناد حسابداری">
          <template #icon>
            <font-awesome-icon icon="stream" size="2xl" />
          </template>
          <template #value v-if="$store.state.loading_get_sanad_data">
            <loading-spinner :show="true" size="sm" class="fs-10" />
          </template>
          <template #value v-else>{{ $store.state.count_all_sanads }}</template>
        </CWidgetStatsF>
      </router-link>
    </div>
  </div>
</template>

<script>
import FilterCard from '@/components/partial/FilterCard'
import { mapActions } from 'vuex'
import LayoutLoading from '@/components/loading/LayoutLoading'
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import SndMix from '@/mixin/SndMix'
export default {
  name: 'SanadMenu',
  mixins: [SndMix],
  components: { LoadingSpinner, LayoutLoading, FilterCard },
  mounted() {
    this.set_current_or_cookie_or_query_fiscal_year()
    if (this.$store.state.all_fiscal_year_site.length == 0) {
      this.get_all_fiscal_years_site()
    }
  },
  methods: {
    ...mapActions([
      'get_all_fiscal_years',
      'get_all_fiscal_years_site',
      'get_current_fiscal_years',
      'get_sanad_data',
    ]),
  },
  watch: {
    fiscal_year_snd(newValue) {
      this.get_sanad_data(newValue)
    },
  },
}
</script>

<style scoped></style>
