<template>
  <filter-card>
    <template #static>
      <div class="row bg-white m-0" id="gardesh">
        <div class="col-12 mb-3" :class="{ 'col-sm-6': user_id.length == 0 }">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-regular fa-user"
                class="text-icon-light"
              />
              انتخاب کاربران
            </label>
            <v-select
              v-model="user_id"
              :multiple="true"
              :filter="userSearch"
              :options="$store.state.users_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب کاربران"
              dir="rtl"
              :loading="$store.state.users_options_loading"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-show="user_id.length == 0">
          <div class="form-group">
            <label>انتخاب گروه</label>
            <select class="form-control" v-model="group_id">
              <option
                v-for="(group, index) in $store.state.groups_fm"
                :key="index"
                :value="group.Id"
                v-text="group.Com"
              ></option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-show="user_id.length == 0">
          <div class="form-group dd">
            <label>وضعیت مانده</label>
            <select class="form-control" v-model="status_mandeh">
              <option value="all">همه</option>
              <option value="beds_and_besed">بدهکاران و بستانکاران</option>
              <option value="beds">فقط بدهکاران</option>
              <option value="beses">فقط بستانکاران</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="d-flex">
            <input-date
              class="mb-3 mb-md-0 me-1"
              v-model:value="from_date"
              selected="start_year"
              :radius_right="false"
              :radius_left="false"
              label="از تاریخ"
              update_url_on_change="from_date"
            />
            <input-date
              :radius_right="false"
              selected="today"
              v-model:value="to_date"
              label="تا تاریخ"
              update_url_on_change="to_date"
            />
          </div>
        </div>
        <div class="col-12">
          <ayandeh-button
            color="info"
            size="sm"
            @click="get_user_data()"
            :disabled="disabled_filter_btn"
            :loading="loading_get"
          >
            <font-awesome-icon icon="fa-solid fa-filter" />
            اعمال فیلتر
          </ayandeh-button>
        </div>
      </div>
    </template>
  </filter-card>
  <!--    Gardesh List    -->
  <div class="container-fluid p-0">
    <div class="card">
      <div class="card-header">
        <h2 class="fs-14 bold">گردش حساب مشتریان</h2>
      </div>
      <div class="card-body">
        <div class="d-block d-md-flex justify-content-between mb-2">
          <h2 class="text-ayabdeh fs-16 bold">کاربران یافت شده</h2>
          <p>
            تعداد :
            <b v-text="user_data.length"></b>
          </p>
          <div>
            <ayandeh-button
              color="secondary"
              v-if="
                $store.state.tr_selected.length && $store.state.jalali_validate
              "
              :disabled="loading_download_list"
              :loading="loading_download_list"
              @click="donwload_list"
            >
              دانلود لیست
              <font-awesome-icon icon="fa-download" />
            </ayandeh-button>
            <ayandeh-button
              color="secondary"
              class="mx-1"
              v-if="
                $store.state.tr_selected.length && $store.state.jalali_validate
              "
              :disabled="loading_download.length != 0"
              :loading="loading_download.length != 0"
              @click="show_modal_download_multiple_gardesh"
            >
              دانلود موارد انتخاب شده
              <font-awesome-icon icon="fa-download" />
            </ayandeh-button>
            <ayandeh-button
              color="danger"
              :disabled="$store.state.tr_selected.length == 0 || loading_sms"
              :loading="loading_sms"
              @click="show_modal_send_sms"
            >
              ارسال پیامک به کاربران
              <font-awesome-icon icon="fa-sms" />
            </ayandeh-button>
          </div>
        </div>
        <div class="table-ay1">
          <table class="mb-0">
            <thead>
              <tr class="bg-ayandeh text-white">
                <th>ردیف</th>
                <th>مشتری</th>
                <th class="fs-13">رزروی در انتظار بارنامه</th>
                <th class="fs-13">رزروی در انتظار بارگیری</th>
                <th class="fs-13">رزروی در انتظار تخلیه</th>
                <th>جمع رزروی</th>
                <th class="fs-13">
                  مانده کیف پول
                  <small v-text="'(' + currency + ')'"></small>
                </th>
                <th>مانده نهایی</th>
                <th>وضعیت</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <loading-tr v-if="loading_get" :colspan="10" :count="15" />
              <empty-tr v-else-if="user_data.length == 0" :colspan="10" />
              <tr
                @click.ctrl="toggle_select_row_table(u.Id)"
                @dblclick="toggle_select_row_table(u.Id)"
                :class="{
                  selected: $store.state.tr_selected.indexOf(u.Id) != -1,
                }"
                v-else
                v-for="(u, index) in user_data"
                :key="index"
              >
                <td>
                  <span class="mobile_title">ردیف : {{ index + 1 }}</span>
                  <span class="d-none d-md-block">{{ index + 1 }}</span>
                </td>
                <td class="d-table-cell">
                  <span class="mobile_title">مشتری : </span>
                  <div>
                    <p class="mb-1" v-text="u.Com"></p>
                    <p class="mb-0" v-text="u.Id"></p>
                    <a
                      :href="'tel:' + u.Mob"
                      class="mb-0"
                      v-if="u.Mob && u.Mob != ''"
                    >
                      <i class="fa fa-mobile-alt"></i>
                      {{ u.Mob }}
                    </a>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">رزروی در انتظار بارنامه</div>
                  <div>
                    <p class="mb-1">
                      {{ u.data_reserve.waiting_bar_num.ton }}
                      <template v-if="u.data_reserve.waiting_bar_num.ton != 0">
                        تن
                        <font-awesome-icon
                          icon="question-circle"
                          class="text-muted align-middle c-pointer p-1"
                          @click="show_modal_cards_reserve_detail(u.Id)"
                        />
                      </template>
                    </p>
                    <p class="mb-0">
                      {{ toNumberFormat(u.data_reserve.waiting_bar_num.price) }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">رزروی در انتظار بارگیری</div>
                  <div>
                    <p class="mb-1">
                      {{ u.data_reserve.waiting_load.ton }}
                      <template v-if="u.data_reserve.waiting_load.ton != 0">
                        تن
                        <font-awesome-icon
                          icon="question-circle"
                          class="text-muted align-middle c-pointer p-1"
                          @click="
                            show_modal_orders_waiting_load_reserve_detail(
                              u.Id,
                              'waiting_load',
                            )
                          "
                        />
                      </template>
                    </p>
                    <p class="mb-0">
                      {{ toNumberFormat(u.data_reserve.waiting_load.price) }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">رزروی در انتظار تخلیه</div>
                  <div>
                    <p class="mb-1">
                      {{ u.data_reserve.waiting_dis_charge.ton }}
                      <template
                        v-if="u.data_reserve.waiting_dis_charge.ton != 0"
                      >
                        تن
                        <font-awesome-icon
                          icon="question-circle"
                          class="text-muted align-middle c-pointer p-1"
                          @click="
                            show_modal_orders_waiting_dis_charge_reserve_detail(
                              u.Id,
                              'waiting_dis_charge',
                            )
                          "
                        />
                      </template>
                    </p>
                    <p class="mb-0">
                      {{
                        toNumberFormat(u.data_reserve.waiting_dis_charge.price)
                      }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">جمع رزروی</div>
                  <div>
                    <p class="mb-1">
                      {{
                        (
                          u.data_reserve.waiting_bar_num.ton +
                          u.data_reserve.waiting_load.ton +
                          u.data_reserve.waiting_dis_charge.ton
                        ).toFixed(2)
                      }}
                      {{
                        u.data_reserve.waiting_bar_num.ton +
                          u.data_reserve.waiting_load.ton +
                          u.data_reserve.waiting_dis_charge.ton !=
                        0
                          ? 'تن'
                          : ''
                      }}
                    </p>
                    <p class="mb-0">
                      {{
                        toNumberFormat(
                          u.data_reserve.waiting_bar_num.price +
                            u.data_reserve.waiting_load.price +
                            u.data_reserve.waiting_dis_charge.price,
                        )
                      }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">مانده کیف پول :</div>
                  <show-mandeh :value="u.mandeh" :min_label="true" />
                </td>
                <td>
                  <div class="mobile_title">مانده نهایی :</div>
                  <span
                    :class="{
                      'text-danger': mandeh_total(u) > 0,
                      'text-success': mandeh_total(u) < 0,
                    }"
                    >{{ toNumberFormat(mandeh_total(u)) }}</span
                  >
                </td>
                <td>
                  <div class="mobile_title">وضعیت :</div>
                  <span v-if="mandeh_total(u) > 0" class="text-danger"
                    >بدهکار</span
                  >
                  <span v-else-if="mandeh_total(u) < 0" class="text-success"
                    >بستانکار</span
                  >
                  <span v-else>بی حساب</span>
                </td>
                <td class="ltr d-flex">
                  <div
                    class="form-check"
                    v-if="$store.state.tr_selected.length"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="u.Id"
                      v-model="$store.state.tr_selected"
                    />
                  </div>
                  <ayandeh-button
                    class="d-flex text-white"
                    color="secondary"
                    :disabled="loading_download.find((x) => x == u.Id)"
                    :loading="loading_download.find((x) => x == u.Id)"
                    @click="show_modal_download_gardesh(u)"
                  >
                    <font-awesome-icon
                      icon="fa-download"
                      class="align-middle"
                    />
                  </ayandeh-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-end" v-if="$store.state.tr_selected.length">
            <select-all :values="all_tr_values" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <CModal
    :visible="visiable_modal_download_gardesh"
    @close="visiable_modal_download_gardesh = false"
  >
    <CModalHeader @close="visiable_modal_download_gardesh = false">
      <CModalTitle class="fs-14">
        دانلود گردش حساب
        {{
          shakhs_selected_for_download ? shakhs_selected_for_download.Com : ''
        }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CFormSwitch
        id="show_first_page"
        label="نمایش صفحه اول در فایل pdf"
        v-model="show_first_page_gardesh"
      />
      <div class="mt-4">
        <btn-ayandeh
          @click="visiable_modal_download_gardesh = false"
          color="light"
          class="me-2"
          label="انصراف"
        />
        <btn-ayandeh @click="download_gardesh()">
          دانلود فایل
          <font-awesome-icon icon="fa-download" class="align-middle ms-1" />
        </btn-ayandeh>
      </div>
    </CModalBody>
  </CModal>

  <CModal
    :visible="visiable_modal_download_multiple_gardesh"
    @close="visiable_modal_download_multiple_gardesh = false"
  >
    <CModalHeader @close="visiable_modal_download_multiple_gardesh = false">
      <CModalTitle class="fs-14">
        دانلود گردش حساب
        {{ $store.state.tr_selected.length }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CFormSwitch
        id="show_first_page"
        label="نمایش صفحه اول در فایل pdf"
        v-model="show_first_page_gardesh"
      />
      <div class="mt-4">
        <btn-ayandeh
          @click="visiable_modal_download_multiple_gardesh = false"
          color="light"
          class="me-2"
          label="انصراف"
        />
        <btn-ayandeh @click="download_selected">
          دانلود موارد انتخاب شده
          <font-awesome-icon icon="fa-download" class="align-middle ms-1" />
        </btn-ayandeh>
      </div>
    </CModalBody>
  </CModal>

  <CModal :visible="visiable_modal_sms" @close="visiable_modal_sms = false">
    <CModalHeader @close="visiable_modal_sms = false">
      <CModalTitle class="fs-14">
        ارسال پیامک به مشتریان انتخاب شده
        {{ $store.state.tr_selected.length }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <input-ayandeh
        placeholder="جمله دلخواه تان را وارد کنید"
        v-model:value="token20"
        dir="rtl"
        :class="{ 'is-invalid': error_token20 && token20.length, 'mb-3': true }"
      >
        <font-awesome-icon
          icon="fa-solid fa-weight-hanging"
          class="text-icon-light"
        />
        جمله دلخواه
        <currency :parentheses="true" />
      </input-ayandeh>

      <CFormSwitch
        id="show_first_page"
        label="نمایش صفحه اول در فایل pdf"
        v-model="show_first_page_gardesh"
      />

      <div class="mb-3" v-if="$store.state.tr_selected.length == 1">
        <p class="mb-1 text-info">
          موبایل های دیگری که میخواهید پیامک به آنها هم ارسال شود را وارد کنید
        </p>
        <multiple-input
          type="tel"
          label="موبایل"
          v-model:value="mobiles"
        ></multiple-input>
      </div>
      <div class="card mb-3">
        <div class="card-header">پیش نمایش پیامک ارسالی</div>
        <div class="card-body">
          مشتری گرامی "نام مشتری"
          <br />
          با سلام و احترام
          <br />
          <span class="text-info">{{ token20 || 'جمله دلخواه' }}</span>
          <br />
          برای دریافت صورت حساب تان از تاریخ {{ from_date }} تا {{ to_date }}
          از لینک زیر استفاده کنید.
          <br />
          ayandehcement.ir/gx/njskd<br />
          بازرگانی سیمان آینده
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh
        @click="visiable_modal_download_multiple_gardesh = false"
        color="light"
        class="me-2"
        label="انصراف"
      />
      <btn-ayandeh
        @click="send_sms"
        :disabled="error_token20 || loading_sms"
        :loading="loading_sms"
      >
        ارسال پیامک
        <font-awesome-icon icon="fa-download" class="align-middle ms-1" />
      </btn-ayandeh>
    </CModalFooter>
  </CModal>

  <CModal
    size="xl"
    :visible="visiable_modal_cards_detail_reserve"
    @close="visiable_modal_cards_detail_reserve = false"
  >
    <CModalHeader @close="visiable_modal_cards_detail_reserve = false">
      <CModalTitle class="fs-14">
        نمایش کارت های <b>رزروی در انتظار بارنامه</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="table-ay1">
        <table class="mb-0">
          <thead>
            <tr class="bg-ayandeh text-white">
              <th>ردیف</th>
              <th>شماره کارت</th>
              <th>نام محصول</th>
              <th>تناژ باقی مانده</th>
              <th>
                فی فروش
                <currency />
              </th>
              <th>
                جمع مبلغ
                <currency />
              </th>
            </tr>
          </thead>
          <tbody>
            <loading-tr v-if="loading_reserve_det" :colspan="6" :count="5" />
            <tr v-else v-for="(row, index) in cards_reserve" :key="index">
              <td v-text="index + 1"></td>
              <td v-text="row.id"></td>
              <td v-text="row.product.title"></td>
              <td>
                <span
                  class="d-inline-block ltr"
                  v-text="row.remaining_tonage"
                ></span>
                تن
              </td>
              <td>{{ toNumberFormat(row.price_per_ton) }}</td>
              <td>
                {{ toNumberFormat(row.remaining_tonage * row.price_per_ton) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="mt-4">
        <btn-ayandeh
          @click="visiable_modal_cards_detail_reserve = false"
          color="light"
          class="me-2"
          label="انصراف"
        />
      </div>
    </CModalFooter>
  </CModal>

  <CModal
    size="xl"
    :visible="visiable_modal_orders_waiting_load_reserve"
    @close="visiable_modal_orders_waiting_load_reserve = false"
  >
    <CModalHeader @close="visiable_modal_orders_waiting_load_reserve = false">
      <CModalTitle class="fs-14">
        نمایش سفارشات <b>رزروی در انتظار بارگیری</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="table-ay1">
        <table class="table table_responsive mb-0">
          <thead>
            <tr class="bg-ayandeh text-white">
              <th>ردیف</th>
              <th>شماره سفارش</th>
              <th>نام محصول</th>
              <th>تناژ</th>
              <th>
                فی فروش
                <currency />
              </th>
              <th>
                جمع مبلغ
                <currency />
              </th>
            </tr>
          </thead>
          <tbody>
            <loading-tr v-if="loading_reserve_det" :colspan="6" :count="5" />
            <tr v-else v-for="(row, index) in orders_waiting_load" :key="index">
              <td v-text="index + 1"></td>
              <td v-text="row.order_id"></td>
              <td v-text="row.product.title"></td>
              <td>
                <span
                  class="d-inline-block ltr"
                  v-text="row.max_ton_loaded"
                ></span>
                تن
              </td>
              <td>{{ toNumberFormat(row.price_per_ton) }}</td>
              <td>
                {{ toNumberFormat(row.price_per_ton * row.max_ton_loaded) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="mt-4">
        <btn-ayandeh
          @click="visiable_modal_orders_waiting_load_reserve = false"
          color="light"
          class="me-2"
          label="انصراف"
        />
      </div>
    </CModalFooter>
  </CModal>

  <CModal
    size="xl"
    :visible="visiable_modal_orders_waiting_dis_charge_reserve"
    @close="visiable_modal_orders_waiting_dis_charge_reserve = false"
  >
    <CModalHeader
      @close="visiable_modal_orders_waiting_dis_charge_reserve = false"
    >
      <CModalTitle class="fs-14">
        نمایش سفارشات <b>رزروی در انتظار تخلیه</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="table-ay1">
        <table class="table table_responsive mb-0">
          <thead>
            <tr class="bg-ayandeh text-white">
              <th>ردیف</th>
              <th>شماره سفارش</th>
              <th>نام محصول</th>
              <th>تناژ</th>
              <th>
                فی فروش
                <currency />
              </th>
              <th>
                جمع مبلغ
                <currency />
              </th>
            </tr>
          </thead>
          <tbody>
            <loading-tr v-if="loading_reserve_det" :colspan="6" :count="5" />
            <tr
              v-else
              v-for="(row, index) in orders_waiting_dis_charge"
              :key="index"
            >
              <td v-text="index + 1"></td>
              <td v-text="row.order_id"></td>
              <td v-text="row.product.title"></td>
              <td>
                <span class="d-inline-block ltr" v-text="row.ton_loaded"></span>
                تن
              </td>
              <td>{{ toNumberFormat(row.price_per_ton) }}</td>
              <td>
                {{ toNumberFormat(row.price_per_ton * row.ton_loaded) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="mt-4">
        <btn-ayandeh
          @click="visiable_modal_orders_waiting_load_reserve = false"
          color="light"
          class="me-2"
          label="انصراف"
        />
      </div>
    </CModalFooter>
  </CModal>
</template>

<script>
import FilterCard from '@/components/partial/FilterCard'
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AyandehButton from '@/components/partial/AyandehButton'
import InputDate from '@/components/inputs/InputDate'
import ShowMandeh from '@/components/partial/ShowMandeh'
import EmptyTr from '@/components/partial/EmptyTr'
import LoadingTr from '@/components/loading/LoadingTr'
import SelectAll from '@/components/inputs/SelectAll'
import InputAyandeh from '@/components/inputs/InputAyandeh'
import MultipleInput from '@/components/partial/MultipleInput'
import Swal from 'sweetalert2'

export default {
  name: 'GardeshList',
  components: {
    MultipleInput,
    InputAyandeh,
    SelectAll,
    LoadingTr,
    EmptyTr,
    ShowMandeh,
    InputDate,
    AyandehButton,
    FontAwesomeIcon,
    FilterCard,
  },
  data() {
    return {
      user_data: [],
      total: 0,
      shakhs: [],
      user_id: [],
      groups: [],
      group_id: '0',
      from_date: '',
      to_date: '',
      status_mandeh: 'all',
      loading_get: false,
      user_selected: [],
      loading_sms: false,
      cards_reserve: null,
      orders_waiting_load: null,
      orders_waiting_dis_charge: null,
      loading_reserve_det: false,
      loading_download: [],
      mobiles: {
        0: '',
      },
      loading_download_list: false,
      visiable_modal_download_gardesh: false,
      visiable_modal_download_multiple_gardesh: false,
      show_first_page_gardesh: false,
      shakhs_selected_for_download: null,
      visiable_modal_sms: false,
      visiable_modal_cards_detail_reserve: false,
      visiable_modal_orders_waiting_load_reserve: false,
      visiable_modal_orders_waiting_dis_charge_reserve: false,
    }
  },
  beforeMount() {
    this.set_url_query_to_data()
  },
  mounted() {
    this.reset_data_page()
    this.get_users({ is_in_fm: 1 })
    this.get_groups_fm()
  },
  methods: {
    ...mapActions(['get_users', 'get_groups_fm', 'change_tr_selected']),
    show_modal_send_sms() {
      this.visiable_modal_sms = true
      this.show_first_page_gardesh = false
    },
    get_data_reserve_shakhs(shakhs_id, type) {
      this.cards_reserve =
        this.orders_waiting_load =
        this.orders_waiting_dis_charge =
          null
      this.loading_reserve_det = true
      // eslint-disable-next-line no-undef
      axios
        .get(
          '/gardesh/data_reserve_shakhs?shakhs_id=' +
            shakhs_id +
            '&type=' +
            type,
        )
        .then(
          function (response) {
            if (response.data && response.data.status == 'success') {
              if (response.data.cards_reserve != undefined) {
                this.cards_reserve = response.data.cards_reserve
              }
              if (response.data.orders_waiting_load != undefined) {
                this.orders_waiting_load = response.data.orders_waiting_load
              }
              if (response.data.orders_waiting_dis_charge != undefined) {
                this.orders_waiting_dis_charge =
                  response.data.orders_waiting_dis_charge
              }
            }
            this.loading_reserve_det = false
          }.bind(this),
        )
    },
    show_modal_cards_reserve_detail(shakhs_id) {
      this.visiable_modal_cards_detail_reserve = true
      this.get_data_reserve_shakhs(shakhs_id, 'waiting_number_bar')
    },
    show_modal_orders_waiting_load_reserve_detail(shakhs_id) {
      this.visiable_modal_orders_waiting_load_reserve = true
      this.get_data_reserve_shakhs(shakhs_id, 'waiting_load')
    },
    show_modal_orders_waiting_dis_charge_reserve_detail(shakhs_id) {
      this.visiable_modal_orders_waiting_dis_charge_reserve = true
      this.get_data_reserve_shakhs(shakhs_id, 'waiting_dis_charge')
    },
    reset_data_page() {
      this.change_tr_selected([])
      this.loading_download_list = this.loading_download = false
    },
    get_user_data() {
      if (this.disabled_filter_btn) {
        return false
      }
      this.loading_download = this.user_selected = []
      this.loading_get = true

      var formData = {
        user_id: this.user_id,
        group_id: this.group_id,
        status_mandeh: this.status_mandeh,
        from_date: this.from_date,
        to_date: this.to_date,
      }
      // eslint-disable-next-line no-undef
      axios
        .post('/gardesh/get_gardeshs', formData)
        .then((response) => {
          if (response.data.status == 'success') {
            this.change_tr_selected([])
            this.user_data = response.data.user_data
          } else {
            this.my_alert(response.data.status, response.data.msg)
          }
          this.loading_get = false
        })
        .catch(() => {
          this.loading_get = false
        })
    },
    mandeh_total(u) {
      return (
        u.mandeh +
        u.data_reserve.waiting_bar_num.price +
        u.data_reserve.waiting_load.price +
        u.data_reserve.waiting_dis_charge.price
      )
    },
    select_all() {
      this.user_selected = []
      this.user_selected = this.user_selected.concat(
        this.user_data.map((x) => x.Id),
      )
    },
    send_sms() {
      this.loading_sms = true
      // eslint-disable-next-line no-undef
      axios
        .post('/gardesh/send_sms', {
          shakhs_ids: this.$store.state.tr_selected,
          from_date: this.from_date,
          to_date: this.to_date,
          token20: this.token20,
          mobiles: this.mobiles,
          show_first_page_gardesh: this.show_first_page_gardesh || '',
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.visiable_modal_sms = false
            this.token20 = ''
          }
          this.my_alert(response.data.msg, response.data.status)
          this.loading_sms = false
        })
        .catch(function () {
          this.loading_sms = false
        })
    },
    show_modal_download_gardesh(shakhs) {
      this.visiable_modal_download_gardesh = true
      this.shakhs_selected_for_download = shakhs
    },
    show_modal_download_multiple_gardesh() {
      this.visiable_modal_download_multiple_gardesh = true
    },
    download_gardesh(shakhs_id = null) {
      if (shakhs_id == null) {
        shakhs_id = this.shakhs_selected_for_download.Id
      }
      this.visiable_modal_download_gardesh = false
      this.loading_download.push(shakhs_id)
      var query = this.gen_query_string({
        shakhs_id: shakhs_id,
        from_date: this.from_date,
        to_date: this.to_date,
        show_first_page_gardesh: this.show_first_page_gardesh || '',
      })

      // eslint-disable-next-line no-undef
      axios.get('/gardesh/download_gardesh?' + query).then((response) => {
        if (response.data.status == 'success') {
          Swal.fire({
            confirmButtonText: 'تایید',
            icon: response.data.status,
            text: response.data.msg,
            footer: '<a class="text-info" target="_blank" href="'+ response.data.path +'">دانلود دستی فایل</a>'
          });
          this.ajax_download(response.data.path, response.data.origin_name)
        }
        this.loading_download.splice(
          this.loading_download.indexOf(shakhs_id),
          1,
        )
      })
    },
    download_selected() {
      this.visiable_modal_download_multiple_gardesh = false
      this.$store.state.tr_selected.forEach(
        function (shakhs_id) {
          this.download_gardesh(shakhs_id)
        }.bind(this),
      )
    },
    donwload_list() {
      this.loading_download_list = true

      // eslint-disable-next-line no-undef
      axios
        .post('/gardesh/download_gardesh_list', {
          shakhs: this.$store.state.tr_selected,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.toast(response.data.msg)
            this.ajax_download(response.data.path, response.data.origin_name)
          }
          this.loading_download_list = false
        })
        .catch(() => {
          this.loading_download_list = false
        })
    },
  },
  computed: {
    disabled_filter_btn() {
      if (this.$store.state.jalali_validate == false || this.loading_get) {
        return true
      } else {
        return false
      }
    },
    shakhs_computed() {
      return this.shakhs.map(function (user) {
        return user.Com + ' - ' + user.Id
      })
    },
    all_tr_values() {
      return this.user_data.map((x) => x.Id)
    },
  },
  watch: {
    user_id(newValue) {
      this.set_data_to_url({
        user_id: newValue.join(','),
      })
    },
    token20(newValue, oldValue) {
      if ((newValue.match(/ /g) || []).length > 7) {
        if ((oldValue.match(/ /g) || []).length <= 7) {
          this.token20.rtrim()
          this.my_alert(
            'error',
            'مقدار token20 نباید بیشتر از 7 فاصله خالی داشته باشد!',
          )
        }
        this.error_token20 = true
      } else {
        this.error_token20 = false
      }
    },
  },
}
</script>
