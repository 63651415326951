<template>
  <tr>
    <td :colspan="colspan" class="text-center py-4">
      هیچ {{ label }} برای نمایش وجود ندارد!
    </td>
  </tr>
</template>

<script>
export default {
  name: 'EmptyTr',
  props: {
    label: {
      type: String,
      default: 'موردی',
    },
    colspan: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped></style>
