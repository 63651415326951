<template>
  <CModal
    :transition="true"
    size="md"
    :visible="localModelValue"
    @close="closeModal"
  >
    <CModalHeader dismiss @close="closeModal">
      <CModalTitle class="fs-20">انتقال سال مالی</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="mb-4 d-flex flex-column gap-2">
        <div class="form-group">
          <label class="form-label"
            >سال مالی مبدأ <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            v-model="originFiscalYear"
            @change="moveFiscalYearValidateHandler('origin')"
          >
            <option value="" disabled selected>انتخاب سال مالی مبدأ</option>
            <option
              v-for="option in originFiscalYears"
              :key="option.id"
              :value="option.id"
              v-text="option.label"
            ></option>
          </select>
          <span class="fs-12 text-danger mt-1">{{
            originFiscalYearError
          }}</span>
        </div>
        <div class="form-group">
          <label class="form-label"
            >سال مالی مقصد <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            v-model="destFiscalYear"
            @change="moveFiscalYearValidateHandler('dest')"
          >
            <option value="" disabled selected>انتخاب سال مالی مقصد</option>
            <option
              v-for="option in destFiscalYears"
              :key="option.id"
              :value="option.id"
              v-text="option.label"
            ></option>
          </select>
          <span class="fs-12 text-danger mt-1">{{ destFiscalYearError }}</span>
        </div>
        <div class="container-fluid mt-3">
          <div class="row">
            <div
              v-for="(item, index) in checkboxItems"
              :key="index"
              class="col-6 d-flex align-items-center gap-2 p-0 mb-2"
            >
              <input
                v-model="item.value"
                class="form-check-input m-0"
                type="checkbox"
                :id="`checkbox-${index}`"
                @change="moveFiscalYearValidateHandler('checkbox')"
              />
              <label
                class="form-check-label mb-0 c-pointer"
                :for="`checkbox-${index}`"
              >
                {{ item.label }}
              </label>
            </div>
            <div
              v-if="checkboxItems[1].value"
              class="col-6 d-flex align-items-center gap-2 p-0 mb-2"
            >
              <input
                v-model="orderCheckbox.value"
                class="form-check-input m-0"
                type="checkbox"
                id="orders-checkbox"
              />
              <label
                class="form-check-label mb-0 c-pointer"
                for="orders-checkbox"
              >
                {{ orderCheckbox.label }}
              </label>
            </div>
          </div>
          <span class="fs-12 text-danger mt-1">{{ checkboxError }}</span>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="flex-row-reverse justify-content-start">
      <btn-ayandeh
        label="ذخیره"
        :loading="loading"
        :disabled="!moveFiscalYearValidate"
        @click="moveFiscalYearHandler"
      />
      <btn-ayandeh
        @click="closeModal"
        color="light"
        class="me-2"
        label="انصراف"
      />
    </CModalFooter>
  </CModal>
</template>

<script setup>
import Swal from 'sweetalert2'
import axios from 'axios'
import { computed, defineProps, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  fiscalYearList: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const localModelValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const originFiscalYears = ref([])
const destFiscalYears = ref([])
const originFiscalYear = ref(null)
const destFiscalYear = ref(null)
const checkboxItems = ref([
  {
    label: 'اطلاعات پایه',
    value: false,
  },
  {
    label: 'کارت ها',
    value: false,
  },
  {
    label: 'حواله ها',
    value: false,
  },
  {
    label: 'اسناد پرداختنی',
    value: false,
  },
])

const orderCheckbox = ref({
  label: 'سفارشات',
  value: false,
})

const moveFiscalYearValidate = ref(true)
const originFiscalYearError = ref('')
const destFiscalYearError = ref('')
const checkboxError = ref('')
const loading = ref(false)

watch(
  () => props.fiscalYearList,
  (val) => {
    originFiscalYears.value = val
    destFiscalYears.value = val
  },
)

watch(
  () => destFiscalYear.value,
  (val) => {
    originFiscalYears.value = props.fiscalYearList.filter(
      (item) => item.id !== val,
    )
  },
)

watch(
  () => originFiscalYear.value,
  (val) => {
    destFiscalYears.value = props.fiscalYearList.filter(
      (item) => item.id !== val,
    )
  },
)

const moveFiscalYearHandler = async () => {
  moveFiscalYearValidateHandler()

  if (moveFiscalYearValidate.value) {
    loading.value = true
    try {
      const body = {
        fiscal_year_origin: originFiscalYear.value,
        fiscal_year_dest: destFiscalYear.value,
        base_data: checkboxItems.value[0].value ? 1 : 0,
        cards_data: checkboxItems.value[1].value ? 1 : 0,
        orders_data: orderCheckbox.value ? 1 : 0,
        referreds_data: checkboxItems.value[2].value ? 1 : 0,
        sanad_data: checkboxItems.value[3].value ? 1 : 0,
      }
      const data = await axios.post('/fiscal_year/transfer', body)

      if (data.data.status === 'error') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
        })
        Toast.fire({
          icon: data.data.status,
          html: data.data.msg,
        })
      } else {
        loading.value = false
        localModelValue.value = false
      }
    } catch (err) {
      loading.value = false
      console.error(err)
    }
  }
}

const moveFiscalYearValidateHandler = (type = 'all') => {
  if (type === 'all') {
    if (!originFiscalYear.value)
      originFiscalYearError.value = 'انتخاب سال مبدا الزامی است.'
    else originFiscalYearError.value = ''
    if (!destFiscalYear.value)
      destFiscalYearError.value = 'انتخاب سال مقصد الزامی است.'
    else destFiscalYearError.value = ''
    if (checkboxItems.value.every((item) => !item.value))
      checkboxError.value = 'انتخاب حداقل یکی از موارد بالا الزامی است.'
    else checkboxError.value = ''
  } else if (type === 'origin') {
    if (!originFiscalYear.value)
      originFiscalYearError.value = 'انتخاب سال مبدا الزامی است.'
    else originFiscalYearError.value = ''
  } else if (type === 'dest') {
    if (!destFiscalYear.value)
      destFiscalYearError.value = 'انتخاب سال مقصد الزامی است.'
    else destFiscalYearError.value = ''
  } else if (type === 'checkbox') {
    if (checkboxItems.value.every((item) => !item.value))
      checkboxError.value = 'انتخاب حداقل یکی از موارد بالا الزامی است.'
    else checkboxError.value = ''
  }
  if (
    originFiscalYearError.value ||
    destFiscalYearError.value ||
    checkboxError.value
  ) {
    moveFiscalYearValidate.value = false
  } else moveFiscalYearValidate.value = true
}

const closeModal = () => {
  localModelValue.value = false
}

watch(
  () => localModelValue.value,
  (val) => {
    if (!val) {
      originFiscalYearError.value = ''
      destFiscalYearError.value = ''
      checkboxError.value = ''
      originFiscalYear.value = null
      destFiscalYear.value = null
      checkboxItems.value = checkboxItems.value.map((item) => ({
        ...item,
        value: false,
      }))
      moveFiscalYearValidate.value = true
    }
  },
)
</script>

<style lang="scss" scoped></style>
