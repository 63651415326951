<template>
  <div class="has-validation">
    <CFormLabel class="w-100" dir="rtl">
      <font-awesome-icon
        v-if="iconFontAwesome"
        :icon="iconFontAwesome"
        class="me-1"
      />
      <span v-html="label"></span>
      <slot />
      <sup class="text-danger fs-13" v-if="required">*</sup>
    </CFormLabel>
    <input
      :class="class_computed"
      :value="showValue"
      @input="updateValue"
      ref="input"
      @paste="updateValue"
      @blur="
        (e) => {
          $emit('blur', e)
        }
      "
      :placeholder="placeholder || label"
      autocomplete="off"
      v-bind="$attrs"
    />
    <div class="invalid-feedback" v-if="text_error" v-text="text_error"></div>
  </div>
</template>

<script>
import { mask } from '@bardoui/vmask'
export default {
  name: 'InputAyandeh',
  data() {
    return {
      val: null,
    }
  },
  emits: ['update:value'],
  mounted() {
    this.val = this.value
  },
  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      default: '',
    },
    iconFontAwesome: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    numberFormat: {
      type: Boolean,
      default: false,
    },
    just: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateValue(e) {
      if (e.target === undefined) return false

      var value = this.to_en(e.target.value)
      if (this.just === 'number') {
        value = value.toString().replaceAll(/[^0-9]/g, '')
      }
      if (this.mask != '') {
        value = mask(value, this.mask)
      }

      this.$emit(
        'update:value',
        this.numberFormat ? value.toString().replace(/\D/g, '') : value,
      )

      this.$emit('keyup')
      this.$emit('paste')
      if (this.$store.state.v$ != undefined) {
        this.v$.$validate()
      }
    },
  },
  computed: {
    showValue() {
      let price = this.value + ''
      return this.numberFormat
        ? price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.value
    },
    class_computed() {
      var my_class = 'form-control '
      if (this.text_error) {
        my_class += 'is-invalid '
      }
      return (my_class += this.class)
    },
    text_error() {
      if (this.errorMessage) {
        return this.errorMessage
      } else if (
        this.$store.state.v$ != undefined &&
        this.$store.state.v$.$invalid &&
        this.$attrs.name != undefined
      ) {
        var errors = this.$store.state.v$.$silentErrors.filter(
          (x) => x.$property == this.$attrs.name,
        )
        if (errors.length) {
          return errors[0].$message
        }
      }
      return false
    },
  },
}
</script>

<style scoped></style>
