<template>
  <div class="card mb-5" v-if="order">
    <div class="card-header">
      <p class="d-inline-block mb-0">بازگردانی سفارش به مراحل قبل</p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="form-group">
            <label for="level">نام مرحله</label>
            <select id="level" class="form-control" v-model="back_to_level">
              <option value="">انتخاب</option>
              <option
                v-for="(level, index) in levels_order.filter(
                  (x) => order.status > x.value || order.status === -1 && x.value == 1 ,
                )"
                :key="index"
                :value="level.value"
                v-text="level.label"
              ></option>
            </select>
          </div>
          <div class="col-12 mt-5">
            <btn-ayandeh
              @click="go_to_back_level"
              label="بازگردانی"
              :disabled="back_to_level == ''"
              :loading="loading_back_to_level"
            />
            <btn-ayandeh
              @click="$router.go(-1)"
              label="بازگشت"
              color="light"
              class="ms-2"
              :disabled="loading_update"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-if="order">
    <div class="card-header">
      <p class="d-inline-block mb-0" v-c-tooltip="'id: ' + $route.params.id">
        ویرایش سفارش شماره
        {{ order.order_id }}
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <p class="text-ayandeh bold">
            <font-awesome-icon
              icon="fa-solid fa-info"
              size="xl"
              class="me-1 text-icon-light"
            />
            اطلاعات کلی
          </p>
        </div>
        <template v-if="order.type_arz">
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              :number-format="true"
              v-model:value="price_arz"
              :placeholder="'نرخ ' + order.type_arz_title"
              dir="ltr"
            >
              نرخ
              {{ order.type_arz_title }}
              <currency :parentheses="true" />
            </input-ayandeh>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              v-model:value="price_per_ton_arz"
              :placeholder="'فی فروش به ' + order.type_arz_title"
              dir="ltr"
            >
              فی فروش به
              <small>({{ order.type_arz_title }})</small>
            </input-ayandeh>
          </div>
        </template>
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-else>
          <input-ayandeh
            :number-format="true"
            v-model:value="price_per_ton"
            placeholder="فی فروش"
            dir="ltr"
          >
            فی فروش
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="کرایه را وارد کنید"
            dir="ltr"
            v-model:value="price_rent"
          >
            کرایه
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="انعام را وارد کنید"
            v-model:value="price_reward"
            dir="ltr"
          >
            انعام
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 mt-5">
          <p class="text-ayandeh bold">
            <font-awesome-icon
              icon="fa-solid fa-truck-fast"
              size="xl"
              class="me-1 text-icon-light"
            />
            اطلاعات اعلام بار
          </p>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-if="order.status == 1">
          <input-ayandeh
            just="number"
            label="حداکثر تناژ بارگیری"
            v-model:value="max_ton_loaded"
            dir="ltr"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            just="number"
            label="شماره بارنامه"
            v-model:value="number_bar"
            dir="ltr"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-date
            v-if="show_dates"
            :selected="date_number_bar"
            v-model:value="date_number_bar"
            label="تاریخ بارنامه"
          />
        </div>
        <!--        <div class="col-12 col-sm-6 col-md-6 mb-3">-->
        <!--          <p class="mb-1">پلاک خودرو</p>-->
        <!--          <plate-->
        <!--            v-if="order.car"-->
        <!--            :car="order.car"-->
        <!--            v-model:plate="plate"-->
        <!--            v-model:complete="plate_complete"-->
        <!--          />-->
        <!--        </div>-->
        <template v-if="order.status >= 2">
          <div class="col-12 mt-5">
            <p class="text-ayandeh bold">
              <font-awesome-icon
                icon="fa-solid fa-dolly"
                size="xl"
                class="me-1 text-icon-light"
              />
              اطلاعات بارگیری
            </p>
          </div>
          <template v-if="order.status >= 2">
            <div
              class="col-12 col-sm-6 col-md-3 mb-3"
              v-for="(referred, index) in order.referreds"
              :key="index"
            >
              <div class="form-group">
                <label for="price_per_ton_arz" class="form-label">
                  تناژ بار شده از حواله
                  {{ referred.referred_code }}
                </label>
                <input
                  type="text"
                  v-model="referred.pivot.ton_loaded"
                  class="form-control ltr"
                />
              </div>
            </div>
          </template>
          <div class="col-12 col-sm-6 col-md-3 mb-3" v-if="order.status >= 2">
            <input-date
              v-if="show_dates"
              :selected="date_loaded"
              v-model:value="date_loaded"
              label="تاریخ بارگیری"
            />
          </div>
        </template>
        <template v-if="order.status >= 3">
          <div class="col-12 mt-5">
            <p class="text-ayandeh bold position-relative">
              <font-awesome-icon
                icon="fa-solid fa-truck-ramp-box"
                size="xl"
                class="me-2 text-icon-light"
              />
              <font-awesome-icon
                size="lg"
                icon="fa-solid fa-people-carry-box"
                class="text-icon-light position-absolute"
                style="right: 15px; top: 2px"
              />
              اطلاعات تخلیه
            </p>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              v-model:value="bag_shortage"
              @keyup="CalcTonage"
              @paste="CalcTonage"
              dir="ltr"
              just="number"
              icon-font-awesome="fa-solid fa-sack-xmark"
              label="مقدار کسری به کیسه"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              v-model:value="tonage_shortage"
              @keyup="CalcBag"
              @paste="CalcBag"
              dir="ltr"
              just="number"
              icon-font-awesome="fa-solid fa-weight-scale"
              label="مقدار به تناژ"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-date
              v-if="show_dates"
              :selected="date_disCharge"
              v-model:value="date_disCharge"
              label="تاریخ تخلیه"
            />
          </div>
          <div class="col-12 mb-3">
            <p class="mb-1">آپلود فایل پیوست مربوط به تخلیه</p>
            <multiple-attach v-model:attach="attachs" />
          </div>
        </template>
        <div class="col-12 mt-5">
          <btn-ayandeh
            @click="update_order"
            label="ذخیره"
            :disabled="disabled_btn_update"
            :loading="loading_update"
          />
          <btn-ayandeh
            @click="$router.go(-1)"
            label="بازگشت"
            color="light"
            class="ms-2"
            :disabled="loading_update"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="text-center pt-5" v-else>
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>
</template>

<script>
import InputAyandeh from '@/components/inputs/InputAyandeh'
import InputDate from '@/components/inputs/InputDate'
// import Plate from '@/components/partial/Plate'
import DisCharge from '@/mixin/DisCharge'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Order from '@/mixin/Order'
import Currency from '@/components/partial/Currency'
import Swal from 'sweetalert2'
import MultipleAttach from '@/components/partial/MultipleAttach'
export default {
  name: 'EditOrder',
  components: {
    MultipleAttach,
    Currency,
    FontAwesomeIcon,
    InputDate,
    InputAyandeh,
  },
  mixins: [DisCharge, Order],
  data() {
    return {
      max_ton_loaded: '',
      number_bar: '',
      date_number_bar: '',
      ton_loaded: '',
      date_loaded: '',
      date_disCharge: '',
      plate: '',
      price_arz: '',
      price_per_ton_arz: '',
      price_per_ton: '',
      price_rent: '',
      price_reward: '',
      loading_update: false,
      show_dates: false,
      plate_complete: true,
      attachs: null,
      back_to_level: '',
      loading_back_to_level: false,
      levels_order: [
        {
          value: 1,
          label: 'در انتظار بارگیری',
        },
        {
          value: 2,
          label: 'در انتظار تخلیه',
        },
        {
          value: 3,
          label: 'تخلیه شده',
        },
      ],
    }
  },
  mounted() {
    this.get_order(this.$route.params.id)
  },
  methods: {
    go_to_back_level() {
      var label = this.levels_order.filter(
        (x) => x.value == this.back_to_level,
      )[0].label
      Swal.fire({
        confirmButtonText: 'بازگردانی',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'بازگردانی',
        html:
          'آیا میخواهید سفارش مورد نظر به مرحله <b>' +
          label +
          '</b> بازگردانی شود؟',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_back_to_level = true
          var query = this.gen_query_string({
            order_id: this.order.id,
            go_to_status: this.back_to_level,
          })
          // eslint-disable-next-line no-undef
          axios.get('/orders/back_to_level?' + query)
            .then(({ data }) => {
              if (data.status == 'success') {
                if (this.$route.query.redirect != undefined) {
                  this.$router.replace(this.$route.query.redirect)
                } else {
                  this.$router.replace({ name: 'OrderList' })
                }
              }
              this.my_alert(data.msg, data.status)
              this.loading_back_to_level = false
            })
            .catch((e) => {
              this.loading_back_to_level = false
            })

        }
      })
    },
    update_order() {
      this.loading_update = true
      var data = {
        order_id: this.order.id,
        max_ton_loaded: this.max_ton_loaded,
        number_bar: this.number_bar,
        date_number_bar: this.date_number_bar,
        plate: this.plate,
        date_loaded: this.date_loaded,
        bag_shortage: this.bag_shortage,
        ton_shortage: this.tonage_shortage,
        date_disCharge: this.date_disCharge,
        price_per_ton_arz: this.price_per_ton_arz,
        price_arz: this.price_arz,
        price_per_ton: this.price_per_ton,
        price_rent: this.price_rent,
        // price_reward: this.price_reward,
        attachs: this.attachs,
        referred_ton_loaded: this.ton_loaded_arr,
      }
      var config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      // eslint-disable-next-line no-undef
      axios.post('/orders/update_order', data, config).then(({ data }) => {
        if (data.status == 'success') {
          if (this.$route.query.redirect != undefined) {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.replace({ name: 'OrderList' })
          }
        }
        this.my_alert(data.msg, data.status)
        this.loading_update = false
      })
    },
    set_order_data(order) {
      this.tonage_shortage = order.ton_shortage
      if (order.status >= 3) {
        this.CalcBag()
      }

      this.price_per_ton_arz = order.price_per_ton_arz
      this.price_per_ton = this.toNumberFormat(order.price_per_ton, true)
      this.price_rent = this.toNumberFormat(order.price_rent)
      this.price_reward = this.toNumberFormat(order.price_reward)
      this.price_arz = this.toNumberFormat(order.price_arz)
      this.max_ton_loaded = order.max_ton_loaded
      this.number_bar = order.number_bar
      this.date_number_bar = this.to_jalali(order.date_number_bar)
      this.ton_loaded = order.ton_loaded
      if (order.date_loaded) {
        this.date_loaded = this.to_jalali(order.date_loaded)
      }
      if (order.date_disCharge) {
        this.date_disCharge = this.to_jalali(order.date_disCharge)
      }
      this.show_dates = true
    },
  },
  computed: {
    ton_loaded_arr() {
      var res = {}
      this.order.referreds.forEach(function (item) {
        res[item.referred_code] = item.pivot.ton_loaded
      })
      return res
    },
    disabled_btn_update() {
      if (
        this.loading_update ||
        !this.$store.state.jalali_validate ||
        !this.plate_complete ||
        this.number_bar.length == 0
      )
        return true

      if (this.order.status >= 2) {
        if (this.ton_loaded == '' || !this.ton_loaded) return true
      }
      return false
    },
  },
  watch: {
    tonage_shortage(newValue, oldValue) {
      if (newValue > parseFloat(this.ton_loaded)) {
        this.$nextTick(() => {
          this.tonage_shortage = oldValue
        })
        this.CalcBag()
        this.toast('تناژ کسری نمیتواند بیشتر از تناژ بارگیری شده باشد', 'info')
      }
    },
  },
}
</script>
