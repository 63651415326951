<template>
  <tr>
    <td :colspan="colspan" class="py-4 text-center">
      <div class="spinner-border"></div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'LoadingTrSpin',
  props: {
    colspan: {
      type: Number,
      required: true,
    },
  },
}
</script>
