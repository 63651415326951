<template>
  <CModal
    alignment="center"
    :transition="true"
    size="md"
    :visible="localModelValue"
    @close="closeModal"
  >
    <CModalHeader dismiss @close="closeModal">
      <CModalTitle class="fs-20">حذف سال مالی</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="d-flex flex-column align-items-center mb-4">
        <font-awesome-icon icon="fa-question-circle" class="nav-icon fa-5x" />
        <h2 class="my-2">حذف سال مالی</h2>
        <div
          v-if="removeStep === 'ONE'"
          class="d-flex flex-column align-items-center gap-2"
        >
          <div>
            با حذف این سال مالی تمام اطلاعات شما در این سال از بین خواهد رفت.
          </div>
          <div>آیا از حذف آن اطمینان دارید؟</div>
        </div>
        <div
          v-else-if="removeStep === 'TWO'"
          class="d-flex flex-column align-items-center gap-2"
        >
          <div>کد تایید به ایجاد کننده سال مالی ارسال شد.</div>
          <div class="d-flex align-items-center gap-1">
            <span>نام:</span>
            <span> {{ userInfo.fullName }} </span>
          </div>
          <div class="d-flex align-items-center gap-1">
            <span>شماره موبایل:</span>
            <span> {{ userInfo.mobile }} </span>
          </div>
          <input-ayandeh
            type="number"
            label="کد تایید:"
            placeholder="کد تایید ارسالی را وارد کنید."
            :error-message="verifyCodeError"
            v-model:value="verifyCode"
            @input="verifyCodeValidate"
          >
          </input-ayandeh>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="flex-row-reverse justify-content-start">
      <btn-ayandeh
        v-if="removeStep === 'ONE'"
        label="بله حذف شود"
        :loading="loading"
        :disabled="!!verifyCodeError"
        @click="sendVerifyCodeHandler"
      />
      <btn-ayandeh
        v-else
        label="حذف"
        :loading="loading"
        :disabled="!!verifyCodeError"
        @click="removeFiscalYearHandler"
      />
      <btn-ayandeh
        @click="closeModal"
        color="light"
        class="me-2"
        label="انصراف"
      />
    </CModalFooter>
  </CModal>
</template>

<script setup>
import axios from 'axios'
import Swal from 'sweetalert2'
import { computed, reactive, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  fiscalId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'updateList'])

const localModelValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const removeStep = ref('ONE')
const loading = ref(false)
const verifyCode = ref(null)
const userInfo = reactive({
  fullName: '',
  mobile: '',
})
const verifyCodeError = ref('')

const verifyCodeValidate = () => {
  if (!verifyCode.value)
    verifyCodeError.value = 'وارد کردن کد تایید الزامی است.'
  else verifyCodeError.value = ''
}

const closeModal = () => {
  localModelValue.value = false
}

const sendVerifyCodeHandler = async () => {
  loading.value = true
  try {
    const data = await axios.delete('/fiscal_year/delete', {
      params: {
        fiscal_year_id: props.fiscalId,
        action: 'send_code',
      },
    })
    loading.value = false
    userInfo.fullName = data.data.fullname
    userInfo.mobile = data.data.mobile
    removeStep.value = 'TWO'
  } catch (err) {
    loading.value = false
    console.error(err)
  }
}

const removeFiscalYearHandler = async () => {
  verifyCodeValidate()
  if (!!verifyCodeError.value) return
  loading.value = true
  try {
    const response = await axios.delete('/fiscal_year/delete', {
      params: {
        fiscal_year_id: props.fiscalId,
        action: 'verify_code',
        verify_code: verifyCode.value,
      },
    })
    if (response.data.status === 'error') {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: response.data.status,
        html: response.data.msg,
      })
    } else {
      localModelValue.value = false
      emit('updateList')
    }
    loading.value = false
  } catch (err) {
    loading.value = false
    console.error(err)
  }
}

watch(
  () => localModelValue.value,
  (val) => {
    if (!val) {
      removeStep.value = 'ONE'
      verifyCodeError.value = ''
    }
  },
)
</script>

<style lang="scss" scoped></style>
