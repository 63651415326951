<template>
  <!--  Loading Get Data  -->
  <div class="text-center pt-5" v-if="loading_get_order">
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>
  <div class="text-center pt-5" v-else-if="order == null">
    هیچ موردی برای نمایش وجود ندارد
  </div>
  <template v-else>
    <div class="card">
      <div class="card-body p-0">
        <div class="row m-0">
          <div class="col-12 col-md-4 border-end border-xs-left-0 p-3">
            <div class="mb-3">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                class="me-1 text-ayandeh"
                size="lg"
              />
              {{ get_fullname(order.card_export.user) }}
            </div>
            <div class="mb-3">
              <span class="text-muted">وضعیت</span> :
              <b
                v-text="order.status_title"
                :class="{
                  'text-danger': order.status == -1,
                  'text-info': order.status == 1,
                  'text-success': order.status > 1,
                }"
              ></b>
            </div>
            <div class="mb-3">
              <span class="text-muted">شماره سفارش</span> :
              <span class="fw-bold">{{ order.order_id }}</span>
            </div>
            <div class="mb-3">
              <span class="text-muted">تاریخ بارنامه</span> :
              {{
                order.date_number_bar ? to_jalali(order.date_number_bar) : '-/-'
              }}
            </div>
            <div class="mb-3">
              <span class="text-muted">تاریخ بارگیری</span> :
              {{ order.date_loaded ? to_jalali(order.date_loaded) : '-/-' }}
            </div>
            <div class="mb-3">
              <span class="text-muted">تاریخ تخلیه</span> :
              {{
                order.date_disCharge ? to_jalali(order.date_disCharge) : '-/-'
              }}
            </div>
          </div>
          <div class="col-12 col-md-8 p-3">
            <!--   اطلاعات سفارش   -->
            <div class="row mb-3 border-bottom border-style-bottom-dash">
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">شماره بارنامه</span> :
                {{ order.number_bar }}
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                v-if="order.status > 1"
              >
                <span class="text-muted">تناژ بارگیری</span> :
                <b>{{ parseFloat(order.ton_loaded) }} </b>
                <small>تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3" v-else>
                <span class="text-muted">حداکثر تناژ برای بارگیری</span> :
                {{ parseFloat(order.max_ton_loaded) }}
                <small>تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">پرداخت کرایه توسط</span> :
                {{ order.card_export.rent_by_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">ترخیص توسط</span> :
                {{ order.card_export.clearance_by_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">محل تخلیه</span> :
                {{ order.card_export.city.title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">نوع ارز فروش</span> :
                {{ order.type_arz_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">
                  فی فروش به
                  {{ order.type_arz_title }}
                </span>
                :
                <span
                  v-if="order.price_per_ton"
                  v-text="toNumberFormat(order.price_per_ton)"
                ></span>
                <span
                  v-if="order.type_arz"
                  v-text="parseFloat(order.price_per_ton_arz)"
                ></span>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">فی کرایه</span> :
                {{ toNumberFormat(order.price_rent) }}
                <currency />
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">فی انعام</span> :
                <template v-if="order.price_reward">
                  {{ toNumberFormat(order.price_reward) }}
                  <currency />
                </template>
                <template v-else>ندارد</template>
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                :class="{ 'text-danger': order.ton_shortage }"
              >
                <span class="text-muted">کسری / خراب</span> :
                {{ order.ton_shortage }}
                <currency />
              </div>
            </div>
            <!--   اطلاعات خودرو   -->
            <div class="row mb-3">
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">نوع خودرو</span> :
                {{ order.car.car_type.title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">نام راننده</span> :
                {{ get_fullname(order.driver) }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <span class="text-muted">موبایل راننده</span> :
                {{ order.driver.mobile }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <license-plate class="mb-0" :car="order.car" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <show-attachments type="b" :attachments="order.attachments" />
  </template>
</template>

<script>
import LicensePlate from '@/components/partial/LicensePlate'
import ShowAttachments from '@/components/partial/ShowAttachments'
export default {
  name: 'OrderDetail',
  components: { ShowAttachments, LicensePlate },
  data() {
    return {
      order: null,
      loading_get_order: false,
    }
  },
  mounted() {
    this.get_order()
  },
  methods: {
    get_order() {
      this.loading_get_order = true

      const hash_code = this.$route.params.hash_code
      if (hash_code != undefined) {
        // eslint-disable-next-line no-undef
        axios.get('/orders/get_order_by_hash/' + hash_code).then(({ data }) => {
          if (data.status == 'success') {
            if (data.order == null) {
              this.my_alert('هیچ موردی برای نمایش یافت نشد', 'warning')
            }
            this.order = data.order
          }
          this.loading_get_order = false
        })
      }
    },
  },
}
</script>

<style scoped></style>
