export default {
  data() {
    return {
      bag_shortage: null,
      tonage_shortage: null,
    }
  },
  methods: {
    CalcTonage() {
      var tonage = (this.bag_shortage * 50) / 1000
      this.tonage_shortage = tonage == 0 ? null : tonage
    },
    CalcBag() {
      var bag =
        (this.tonage_shortage.toString().replaceAll(/[^0-9.]/g, '') * 1000) / 50
      this.bag_shortage = bag == 0 ? null : bag
    },
  },
}
