<template>
  <template v-if="payment_able">
    <div class="card mb-4">
      <div class="card-body p-0">
        <div class="row g-0">
          <div
            class="col-12 col-md-4 border-end border-style-left-dash p-3 invoice-style d-grid"
          >
            <div class="align-self-sm-start">
              <div class="d-flex justify-content-between mb-2">
                <span>وضعیت پرداخت</span>
                <span v-if="payment_able.cancel" class="text-end">
                  <span class="text-danger">لغو شده</span>
                  <small class="text-muted fs-10 d-block">
                    وضعیت قبلی :
                    {{ payment_able.status_title }}
                  </small>
                </span>
                <span
                  v-else
                  :class="{
                    'text-info': !payment_able.payed,
                    'text-success': payment_able.payed,
                    'text-danger': payment_able.cancel,
                  }"
                  v-text="payment_able.status_title"
                ></span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span>
                  <font-awesome-icon
                    icon="fa-solid fa-coins"
                    class="text-icon-light"
                  />
                  مبلغ
                </span>
                <span>
                  <span
                    class="me-1"
                    v-text="toNumberFormat(payment_able.amount)"
                  ></span>
                  <currency />
                </span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-calendar-plus"
                    class="text-icon-light"
                  />
                  تاریخ
                </div>
                <span class="date">
                  {{ to_jalali(payment_able.created_at, 'long') }}
                </span>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                v-if="payment_able.date_pay"
              >
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-calendar-check"
                    class="text-icon-light"
                  />
                  تاریخ پرداخت
                </div>
                <span class="date">
                  {{ to_jalali(payment_able.date_pay, 'long') }}
                </span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-person-chalkboard"
                    class="text-icon-light"
                  />نام بستانکار
                </div>
                <span
                  v-if="payment_able.creditorable.car_number_base != undefined"
                >
                  {{ payment_able.creditorable.plaque }}
                </span>
                <span v-if="payment_able.creditorable.first_name != undefined">
                  {{ get_fullname(payment_able.creditorable) }}
                </span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-person-chalkboard"
                    class="text-icon-light"
                  />کد حسابداری بستانکار
                </div>
                <span>
                  {{ payment_able.creditorable.faramohaseb_id }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 p-3">
            <div
              class="d-flex justify-content-between titr_line_dott titr_white"
            >
              <span>بابت 3e2</span>
              <span>
                {{ payment_able.pay_for_title }}
                <template v-if="payment_able.order_id">
                  <span class="px-1">{{ payment_able.order.ton_loaded }}</span>
                  تن بار
                </template>
              </span>
            </div>
            <div
              class="d-flex justify-content-between titr_line_dott titr_white"
              v-if="payment_able.order_id && payment_able.order.ton_shortage"
            >
              <span>کسری / خراب</span>
              <span>
                تعداد
                {{ payment_able.order.ton_shortage * 20 }}
                کیسه به مبلغ
                {{
                  toNumberFormat(
                    (get_price_per_ton_rial(payment_able.order) +
                      payment_able.order.price_rent +
                      payment_able.order.price_reward || 0) *
                      payment_able.order.ton_shortage,
                  )
                }}
                <currency />
              </span>
            </div>
            <div
              class="d-flex justify-content-between titr_line_dott titr_white"
              v-if="payment_able.order_id"
            >
              <span>شرح مالی</span>
              <div>
                مبلغ
                {{ payment_able.pay_for_title }}
                برای هر تن
                <span v-if="payment_able.pay_for == 'rent'">
                  {{ toNumberFormat(payment_able.order.price_rent) }}
                </span>
                <span v-else-if="payment_able.pay_for == 'reward'">
                  {{ toNumberFormat(payment_able.order.price_reward) }}
                </span>
                <span v-else-if="payment_able.pay_for == 'rent_and_reward'">
                  {{
                    toNumberFormat(
                      payment_able.order.price_rent +
                        payment_able.order.price_reward,
                    )
                  }}
                </span>
                <currency class="ms-1" />
              </div>
            </div>
            <div v-if="payment_able.description">
              <span class="text-muted">توضیحات : </span>
              {{ payment_able.description }}
            </div>
            <div class="row mt-4" v-if="payment_able.order_id">
              <div class="col-12 col-md-6 border-end">
                <license-plate
                  :car="payment_able.order.car"
                  size="sm"
                  class="mx-auto"
                />
                <div class="d-flex justify-content-between mb-2">
                  <span>شماره بارنامه</span>
                  <span>{{ payment_able.order.number_bar }}</span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <span>تاریخ بارگیری</span>
                  <span class="date">
                    {{ to_jalali(payment_able.order.date_loaded) }}
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <span>تاریخ تخلیه</span>
                  <span class="date">
                    {{ to_jalali(payment_able.order.date_disCharge) }}
                  </span>
                </div>
              </div>
              <div
                class="col-12"
                :class="{ 'col-md-6': payment_able.order_id }"
                v-if="payment_able.bank_information_id"
              >
                <show-credit-card
                  v-if="payment_able.bank_information_id"
                  :account_bank="payment_able.order.credit_card_rent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payment_able.payments.length" class="mb-5">
      <p>رسید پرداخت های انجام شده</p>
      <div class="row">
        <template v-for="payment in payment_able.payments" :key="payment.id">
          <div
            class="col-12 col-sm-6 col-md-3"
            v-for="det in payment.details"
            :key="det.id"
          >
            <show-payment-small :payment="payment" :payment-det="det" />
          </div>
        </template>
      </div>
    </div>
  </template>
  <div class="text-center pt-5" v-else>
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>
</template>
<script>
import DotsLoading from '@/components/loading/DotsLoading'
import ShowPaymentSmall from '@/components/payment/ShowPaymentSmall'
import ShowCreditCard from '@/components/partial/ShowCreditCard'
import LicensePlate from '@/components/partial/LicensePlate'
import Currency from '@/components/partial/Currency'

export default {
  name: 'ShowPaymentAble',
  components: {
    DotsLoading,
    ShowPaymentSmall,
    ShowCreditCard,
    LicensePlate,
    Currency,
  },
  data() {
    return {
      payment_able: null,
      loading: false,
    }
  },
  mounted() {
    this.get_payment_able()
  },
  methods: {
    get_payment_able() {
      var data = this.gen_query_string({
        hash_code: this.$route.params.hash_code,
      })
      this.loading = true
      // eslint-disable-next-line no-undef
      axios.get('/paymentable/get?' + data).then(({ data }) => {
        if (data.status == 'success') {
          this.payment_able = data.payment_able
          this.loading = false
          if (data.payment_able == null) {
            this.my_alert('هیچ اطلاعاتی برای نمایش یافت نشد', 'info')
          }
        }
      })
    },
  },
  computed: {
    sum_amount_payed() {
      return this.calc_sum_amount_payed(this.payment_able)
    },
  },
}
</script>
