<template>
  <div>
    <div class="d-sm-flex justify-content-between mb-1">
      <h1 class="title_card mb-3 mb-sm-0">لیست کارخانه ها</h1>
      <div class="d-flex d-sm-block justify-content-between">
        <div class="d-inline-block me-3">
          تعداد :
          <loading-spinner
            :show="true"
            size="sm"
            color="muted"
            v-if="$store.state.loading_factory"
          />
          <span v-else v-text="$store.state.factories.length"></span>
        </div>
        <btn-ayandeh
          class="btn-sm"
          color="outline-dark"
          @click="open_modal_create_factory"
          :loading="loading_create"
          :disabled="loading_create"
        >
          <font-awesome-icon icon="plus" class="me-1" />
          کارخانه جدید
        </btn-ayandeh>
      </div>
    </div>
    <div class="table-ay1">
      <table>
        <thead>
          <tr>
            <th>ردیف</th>
            <th>نام کارخانه</th>
            <th>تعداد محصولات</th>
            <th>موبایل</th>
            <th>تصویر</th>
            <th>وضعیت</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="$store.state.loading_factory">
            <tr v-for="x in 6" :key="x">
              <td v-for="i in 7" :key="i">
                <shine-loading />
              </td>
            </tr>
          </template>
          <tr v-else-if="$store.state.factories.length == 0">
            <td colspan="7" class="text-center py-4">
              هیچ سفارشی برای نمایش وجود ندارد!
            </td>
          </tr>
          <tr
            v-else
            v-for="(factory, index) in $store.state.factories"
            @click.right.prevent="show_more_menu(factory, index)"
            :key="index"
          >
            <td>
              <div class="mobile_title">ردیف :</div>
              {{ index + 1 }}
            </td>
            <td>
              <div class="mobile_title">نام کارخانه :</div>
              {{ factory.name }}
            </td>
            <td>
              <div class="mobile_title">تعداد محصولات :</div>
              <div>
                {{ factory.active_products_count }}
                <small>کالا</small>
              </div>
            </td>
            <td>
              <div class="mobile_title">موبایل :</div>
              {{ factory.mobile || '-/-' }}
            </td>
            <td>
              <div class="mobile_title">تصویر :</div>
              <img
                v-if="factory.logo_id"
                src="/img/icons/icons8-image-100.png"
                width="30"
                class="c-pointer"
                alt="تصویر کارخانه"
                @click="show_image(factory.logo)"
              />
              <span v-else>-/-</span>
            </td>
            <td :class="factory.color_status">
              <div class="mobile_title">موبایل :</div>
              {{ factory.title_status }}
            </td>
            <td class="ltr">
              <button
                v-c-tooltip="{
                  content: 'کلیک راست',
                  placement: 'right',
                }"
                @click="show_more_menu(factory, index)"
                class="btn btn-light bg-transparent py-1 px-2 border-0 c-pointer"
              >
                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <CModal size="sm" :visible="visible_modal_more" @close="reset_modal_more">
    <CModalBody v-if="factory_selected">
      <p class="fw-bold">{{ factory_selected.name }}</p>
      <div class="d-flex justify-content-between mt-4">
        <CFormSwitch
          id="factory_status_switch"
          @change="change_status_factory"
          label="وضعیت کارخانه"
          :disabled="loading_change_status"
          v-model="factory_selected.status"
        />
        <loading-spinner
          size="sm"
          :show="loading_change_status"
          color="secondary"
        />
      </div>
      <btn-ayandeh
        color="primary"
        class="w-100 mb-2 d-flex justify-content-between"
      >
        مدیریت بازاریاب ها
        <small> ({{ factory_selected.visitors.length }}) </small>
      </btn-ayandeh>
      <btn-ayandeh class="w-100 mb-2 d-flex justify-content-between">
        مشاهده محصولات
        <small> ({{ factory_selected.active_products_count }}) </small>
      </btn-ayandeh>
    </CModalBody>
  </CModal>
  <modal-create-factory
    v-model:visible="visible_modal_create_factory"
    @created_factory="get_facts"
  />
</template>

<script>
import { mapActions } from 'vuex'
import ModalCreateFactory from '@/views/factory/ModalCreateFactory'
import Swal from 'sweetalert2'

export default {
  name: 'FactoryList',
  components: { ModalCreateFactory },
  data() {
    return {
      visible_modal_more: false,
      factory_selected: null,
      factory_selected_index: null,
      loading_change_status: false,
      loading_create: false,
      visible_modal_create_factory: false,
    }
  },
  mounted() {
    this.get_facts()
  },
  methods: {
    ...mapActions(['get_factories']),
    show_image(logo_file) {
      Swal.fire({
        title: '',
        cancelButtonText: 'بستن',
        showCancelButton: true,
        showConfirmButton: false,
        html:
          "<p class='fs-14 mb-1'>تصویر لوگو کارخانه</p><span>" +
          logo_file.original_name +
          "</span><br><small dir='ltr'>" +
          this.calc_file_size(logo_file.size) +
          '</small>',
        imageUrl: logo_file.download_url,
        imageWidth: 300,
        imageAlt: 'لوگو کارخانه',
      }).then(() => {})
    },
    get_facts() {
      this.get_factories({
        with_count: 'active_products',
        with: 'visitors,logo',
      })
    },
    show_more_menu(factory, index) {
      this.factory_selected = factory
      this.factory_selected_index = index
      this.visible_modal_more = true
    },
    reset_modal_more() {
      this.visible_modal_more = false
      this.factory_selected = null
      this.factory_selected_index = null
    },
    change_status_factory() {
      if (this.factory_selected) {
        this.loading_change_status = true
        // eslint-disable-next-line no-undef
        axios
          .get('/factory/change_status/' + this.factory_selected.id)
          .then(({ data }) => {
            if (data.status == 'success') {
              this.$store.state.factories[this.factory_selected_index] =
                this.factory_selected = data.factory
            }
            this.toast(data.msg, data.status, 3000)
            this.loading_change_status = false
          })
      }
    },
    open_modal_create_factory() {
      this.visible_modal_create_factory = true
    },
  },
}
</script>
