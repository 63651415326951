<template>
  <CModal
    fullscreen="xs"
    :visible="visiable_var"
    backdrop="static"
    scrollable
    @close="closed"
  >
    <CModalHeader dismiss @close="closed">
      <CModalTitle class="fs-14">تایید سند شماره {{ snd.IdSnd }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="table-ay1">
        <table>
          <thead>
            <tr>
              <th>کد حسابداری</th>
              <th>موبایل</th>
            </tr>
          </thead>
          <tbody>
            <loading-tr v-if="loading_get_mobiles" :count="4" :colspan="2" />
            <tr v-for="code in codes" :key="code" v-else>
              <td v-text="code"></td>
              <td>{{ code_mobiles[code] || '-/-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="mb-2 mt-3">
          کد های حسابداری فوق و موبایل های مقابل شان در سند مربوطه دخیل هستند
        </p>
        <p class="text-info">
          <b>توجه :</b>
          در صورتی که این سند را تایید کنید، لینک مربوط به این سند به موبایل کد
          های حسابداری پیامک خواهد شد!
        </p>
      </div>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh @click="closed" color="light" class="me-2" label="بازگشت" />
      <btn-ayandeh
        @click="do_verify_snd"
        :loading="loading_verify_snd"
        :disabled="loading_verify_snd"
        label="تایید سند"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import LoadingTr from '@/components/loading/LoadingTr'
export default {
  name: 'ModalVerifySnd',
  components: { LoadingTr },
  data() {
    return {
      visiable_var: false,
      loading_verify_snd: false,
      loading_get_mobiles: false,
      codes: {},
      code_mobiles: {},
    }
  },
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    snd: {
      type: Object,
    },
    fiscal_year_prop: {
      type: String,
    },
  },
  watch: {
    visiable(newValue) {
      this.visiable_var = newValue
      this.get_code_mobiles()
    },
  },
  methods: {
    closed() {
      this.visiable_var = false
      this.$emit('update:visiable', this.visiable_var)
    },
    do_verify_snd() {
      this.loading_verify_snd = true

      var query = this.gen_query_string({
        fiscal_year: this.fiscal_year_prop,
        snd_id: this.snd.id,
      })
      // eslint-disable-next-line no-undef
      axios.get('/sanad/verify_snd?' + query).then(({ data }) => {
        if (data.status == 'success') {
          this.$emit('verified', this.snd.id)
        }
        this.my_alert(data.msg, data.status)
        this.loading_verify_snd = false
        this.closed()
      })
    },
    get_code_mobiles() {
      this.loading_get_mobiles = true

      var codes = []
      this.snd.details.forEach(function (item) {
        codes.push(item.IdTafsil)
      })
      codes = codes.filter(function (item, pos, self) {
        return self.indexOf(item) == pos
      })
      this.codes = codes
      var data = {
        fiscal_year: this.fiscal_year_prop,
        code_ids: codes,
      }
      // eslint-disable-next-line no-undef
      axios.post('/codes/get_code_mobiles', data).then(({ data }) => {
        if (data.status == 'success') {
          this.code_mobiles = data.code_mobiles
        }
        this.loading_get_mobiles = false
      })
    },
  },
}
</script>
