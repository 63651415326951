import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import moment from 'moment-jalaali'

export default {
  data: function () {
    return {
      error_token20: false,
      token20: '',
      currency: 'ریال',
      jalali_validate: false,
      alefba: [
        'ع',
        'الف',
        'ب',
        'پ',
        'ت',
        'ث',
        'ج',
        'چ',
        'ح',
        'خ',
        'د',
        'ذ',
        'ر',
        'ز',
        'ژ',
        'س',
        'ش',
        'ص',
        'ض',
        'ط',
        'ظ',
        'غ',
        'ف',
        'ق',
        'ک',
        'گ',
        'ل',
        'م',
        'ن',
        'و',
        'ه',
        'ی',
      ],
      codes_mandeh: {},
      loading_mandeh: false,
      loading_mandeh_bes: false,
      NProcess: false,
      paginate_datas: {
        total: 0,
        data: [],
      },
      page: 1,
      fiscal_year: null,
      fiscal_year_snd: null,
      active_codes: [],
      loading_get_active_codes: false,
      backdrop_modal: '',
    }
  },
  methods: {
    ...mapActions(['get_current_fiscal_years', 'updateAdminInfo']),
    userSearch(options, search) {
      if (search.length) {
        var regex_ar = new RegExp(this.to_ar(search), 'i')
        var regex_fa = new RegExp(this.to_fa(search), 'i')
        return this.$store.state.users_options.filter(
          (x) => x.label.search(regex_ar) >= 0 || x.label.search(regex_fa) >= 0,
        )
      }
      return this.$store.state.users_options
    },
    localUserSearch(options, search) {
      if (search.length) {
        var regex_ar = new RegExp(this.to_ar(search), 'i')
        var regex_fa = new RegExp(this.to_fa(search), 'i')
        return this.$store.state.local_users_options.filter(
          (x) => x.label.search(regex_ar) >= 0 || x.label.search(regex_fa) >= 0,
        )
      }
      return this.$store.state.users_options
    },
    get_num(string) {
      return string.toString().replaceAll(/[^0-9]/g, '')
    },
    userSearchVueSelect(options, search) {
      if (search.length) {
        var regex_ar = new RegExp(this.to_ar(search), 'i')
        var regex_fa = new RegExp(this.to_fa(search), 'i')
        return this.$store.state.site_users_options.filter(
          (x) => x.label.search(regex_ar) >= 0 || x.label.search(regex_fa) >= 0,
        )
      }
      return this.$store.state.site_users_options
    },
    get_active_codes() {
      this.loading_get_active_codes = true
      this.active_codes = []
      // eslint-disable-next-line no-undef
      axios
        .get('/codes/get_active_codes?fiscal_year=' + this.fiscal_year_snd)
        .then((resource) => {
          if (resource !== undefined && resource.data.status === 'success') {
            this.active_codes = resource.data.codes
          }
          this.loading_get_active_codes = false
        })
    },
    compare_arrays(a, b) {
      return (
        a.length === b.length && a.every((element) => b.indexOf(element) != -1)
      )
    },
    has_permission(admin_ids) {
      if (
        this.$store.state.admin != null &&
        admin_ids.indexOf(this.$store.state.admin.id) >= 0
      ) {
        return true
      }
      return false
    },
    select_all_row_table() {
      // this.tr_selected = values
    },
    toggle_select_row_table(value) {
      var index = this.$store.state.tr_selected.indexOf(value)
      if (index == -1) {
        this.$store.state.tr_selected.push(value)
      } else {
        this.$store.state.tr_selected.splice(index, 1)
      }
    },
    reset_paginate_data() {
      this.paginate_datas = {
        total: 0,
        data: [],
      }
    },
    sum(item, object) {
      var sum = 0
      object.forEach(function (row) {
        sum += parseFloat(row[item])
      })
      return isNaN(sum) ? 0 : sum
    },
    setCookie(cname, cvalue = true, exdays = 180) {
      var d = new Date()
      if (cvalue) {
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      } else {
        d.setTime(d.getTime() + 1000)
      }
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    },
    getCookie(cname) {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    set_url_query_to_data() {
      if (this.$route.query.page != undefined) {
        this.page = this.$route.query.page
      }
      if (this.$route.query.status != undefined) {
        this.status = this.$route.query.status
      }
      // Floating Filter
      if (this.$route.query.factory != undefined && this.factory != undefined) {
        this.factory = this.$route.query.factory
      }
      if (this.$route.query.order_id != undefined) {
        this.order_id = this.$route.query.order_id
      }
      if (this.$route.query.user_id != undefined) {
        if (this.$route.query.user_id.indexOf(',') != -1) {
          this.user_id = this.$route.query.user_id.split(',')
        } else {
          this.user_id = parseInt(this.$route.query.user_id)
        }
      }
      if (this.$route.query.number_bar != undefined) {
        this.number_bar = this.$route.query.number_bar
      }
      if (this.$route.query.daryaft_id != undefined) {
        this.daryaft_id = this.$route.query.daryaft_id
      }
      if (this.$route.query.payment_id != undefined) {
        this.payment_id = this.$route.query.payment_id
      }
      if (this.$route.query.amount != undefined) {
        this.amount = this.$route.query.amount
      }
      if (this.$route.query.snd_id != undefined) {
        this.snd_id = this.$route.query.snd_id
      }
      if (this.$route.query.bes != undefined) {
        this.bes = this.$route.query.bes
      }
      if (this.$route.query.bed != undefined) {
        this.bed = this.$route.query.bed
      }
      if (this.$route.query.from_date != undefined) {
        this.from_date = this.$route.query.from_date
      }
      if (this.$route.query.to_date != undefined) {
        this.to_date = this.$route.query.to_date
      }
      if (this.$route.query.number_base != undefined) {
        this.$store.state.number_base = this.$route.query.number_base
      }
      if (this.$route.query.three_digits != undefined) {
        this.$store.state.three_digits = this.$route.query.three_digits
      }
      if (this.$route.query.two_digits != undefined) {
        this.$store.state.two_digits = this.$route.query.two_digits
      }
      if (this.$route.query.letter != undefined) {
        this.$store.state.plate_letter = this.$route.query.letter
      }
      if (this.$route.query.code_name_bes != undefined) {
        this.code_name_bes = this.$route.query.code_name_bes
      }
      if (this.$route.query.code_name_bed != undefined) {
        this.code_name_bed = this.$route.query.code_name_bed
      }
    },
    set_current_or_cookie_or_query_fiscal_year() {
      if (this.$route.query.fiscal_year != undefined) {
        this.fiscal_year_snd = this.$route.query.fiscal_year
      } else if (this.getCookie('snd_fiscal_year')) {
        this.fiscal_year_snd = this.getCookie('snd_fiscal_year')
      } else {
        this.get_current_fiscal_years()
        this.$store.watch(
          () => {
            return this.$store.state.current_fiscal_year
          },
          (newValue) => {
            this.fiscal_year_snd = newValue
          },
          {
            deep: true,
          },
        )
      }
    },
    set_data_to_url($query_object) {
      var all_queryery = {}
      Object.assign(all_queryery, Object(this.$route.query))
      Object.assign(all_queryery, $query_object)
      this.$router.push({
        path: this.$route.path,
        query: all_queryery,
      })
    },
    get_price_per_ton_rial(order) {
      if (order.type_arz) {
        return order.price_per_ton_arz * order.price_arz
      } else {
        return order.price_per_ton
      }
    },
    gen_query_string(data) {
      const ret = []
      for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
      return ret.join('&')
    },
    get_fullname(user) {
      if (user) return (user.first_name || '') + ' ' + (user.last_name || '')
    },
    toNumberFormat(data, if_null_return_null = false) {
      if (data) {
        data = this.to_en(data)
        return parseInt(
          data.toString().replaceAll(/[^0-9.]/g, ''),
        ).toLocaleString()
      }
      if (if_null_return_null) {
        return ''
      } else {
        return 0
      }
    },
    showCreditCard(credit_card) {
      return credit_card.match(/.{1,4}/g).join(' ')
    },
    to_en(strNum) {
      var fa = [
        '۰',
        '۱',
        '۲',
        '۳',
        '۴',
        '۵',
        '۶',
        '۷',
        '۸',
        '۹',
        '٠',
        '١',
        '٢',
        '٣',
        '٤',
        '٥',
        '٦',
        '٧',
        '٨',
        '٩',
      ]
      var en = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
      ]
      if (strNum.length) {
        for (var i = 0; i < 10; i++) {
          var regex_fa = new RegExp(fa[i], 'g')
          strNum = strNum.replaceAll(regex_fa, en[i])
        }
      }
      return strNum
    },
    to_ar(str) {
      const persianNumbers = [
        '۰',
        '۱',
        '۲',
        '۳',
        '۴',
        '۵',
        '۶',
        '۷',
        '۸',
        '۹',
        'ی',
        'ک',
      ]
      const arabicNumbers = [
        '٠',
        '١',
        '٢',
        '٣',
        '٤',
        '٥',
        '٦',
        '٧',
        '٨',
        '٩',
        'ي',
        'ك',
      ]

      return str
        .split('')
        .map((c) => arabicNumbers[persianNumbers.indexOf(c)] || c)
        .join('')
    },
    to_fa(str) {
      const persianNumbers = [
        '۰',
        '۱',
        '۲',
        '۳',
        '۴',
        '۵',
        '۶',
        '۷',
        '۸',
        '۹',
        'ی',
        'ک',
      ]
      const arabicNumbers = [
        '٠',
        '١',
        '٢',
        '٣',
        '٤',
        '٥',
        '٦',
        '٧',
        '٨',
        '٩',
        'ي',
        'ك',
      ]

      return str
        .split('')
        .map((c) => persianNumbers[arabicNumbers.indexOf(c)] || c)
        .join('')
    },
    to_jalali(date, format = '') {
      if (date) {
        if (format == 'long') {
          format = 'jYYYY/jMM/jDD HH:mm'
        } else if (format == '') {
          format = 'jYYYY/jMM/jDD'
        }
        var jdate = moment(date).format(format)
        return jdate
      } else {
        return '-'
      }
    },
    toast(msg, type = 'success', time = 5000) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: time,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: type,
        html: msg,
      })
    },
    my_alert(msg, type = 'success', title = null) {
      var title_text = 'موفق'
      if (type == 'error') {
        title_text = 'خطا'
      } else if (type == 'info') {
        title_text = 'توجه'
      } else if (type == 'warning') {
        title_text = 'هشدار'
      }

      if (title) {
        title_text = title
      }

      Swal.fire({
        confirmButtonText: 'خب',
        icon: type,
        title: title_text,
        html: msg,
      }).then((result) => {
        this.backdrop_modal = ''
      })
    },
    log_out() {
      localStorage.removeItem('token')
      window.axios.defaults.headers.common['Authorization'] = null
      this.updateAdminInfo(null)
      this.$router.push({ name: 'AdminLogin' })
    },
    get_card_bank_name(credit_number) {
      var bankName = [
        'بانک اقتصاد نوین',
        'بانک انصار',
        'بانک ایران زمین',
        'بانک پارسیان',
        'بانک پارسیان',
        'بانک پارسیان',
        'بانک پاسارگاد',
        'بانک پاسارگاد',
        'بانک آینده',
        'بانک تجارت',
        'بانک تجارت',
        'بانک توسعه تعاون',
        'بانک توسعه صادرات ایران',
        'بانک توسعه صادرات ایران',
        'بانک حکمت ایرانیان',
        'بانک دی',
        'بانک رفاه کارگران',
        'بانک سامان',
        'بانک سپه',
        'بانک سرمایه',
        'بانک سینا',
        'بانک شهر',
        'بانک شهر',
        'بانک صادرات ایران',
        'بانک صنعت و معدن',
        'بانک قرض الحسنه مهر ایران',
        'بانک قوامین',
        'بانک کارآفرین',
        'بانک کارآفرین',
        'بانک کشاورزی',
        'بانک کشاورزی',
        'بانک گردشگری',
        'بانک مرکزی',
        'بانک مسکن',
        'بانک ملت',
        'بانک ملت',
        'بانک ملی',
        'بانک مهر اقتصاد',
        'پست بانک ایران',
        'موسسه اعتباری توسعه',
        'موسسه اعتباری کوثر',
        'مؤسسه اعتباری ملل (عسکریه سابق)',
        'بانک قرض الحسنه رسالت',
        'بانک خاورمیانه',
      ]
      var dataNumber = [
        627412, 627381, 505785, 622106, 639194, 627884, 639347, 502229, 636214,
        627353, 585983, 502908, 627648, 207177, 636949, 502938, 589463, 621986,
        589210, 639607, 639346, 502806, 504706, 603769, 627961, 606373, 639599,
        627488, 502910, 603770, 639217, 505416, 636795, 628023, 610433, 991975,
        603799, 639370, 627760, 628157, 505801, 606256, 504172, 505809,
      ]
      var six_char_card = credit_number
        .toString()
        .replace(/[^0-9]/g, '')
        .substring(0, 6)
      var index = dataNumber.indexOf(parseInt(six_char_card))
      if (index >= 0) return [six_char_card, bankName[index]]
      return ['f00', '--']
    },
    get_code_mandeh(code_id) {
      // eslint-disable-next-line no-undef
      axios.get('/get_code_mandeh/' + code_id).then(({ data }) => {
        this.codes_mandeh[code_id] = data.mandeh
        this.loading_mandeh = false
        this.loading_mandeh_bes = false
      })
    },
    ajax_download(url, file_name) {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', file_name)
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
    },
    calc_sum_amount_payed(payment_able) {
      var sum_amount_payed = 0
      if (payment_able.payments.length) {
        payment_able.payments.forEach(function (payment) {
          payment.details.forEach(function (det) {
            sum_amount_payed += det.amount
          })
        })
      }
      return sum_amount_payed
    },
  },
  computed: {
    factories_option() {
      return this.$store.state.factories.map((factory) => {
        return {
          label: factory.name,
          value: factory.id,
        }
      })
    },
    products_option() {
      var products = this.$store.state.products
      if (this.factory_id) {
        return products.filter((x) => x.factory_id == this.factory_id)
      } else {
        return products
      }
    },
    province_options() {
      return this.$store.state.provinces.map((province) => {
        return {
          label: province.title,
          value: province.id,
        }
      })
    },
    city_options() {
      return this.$store.state.cities.map((city) => {
        return {
          label: city.title,
          value: city.id,
        }
      })
    },
    referred_options() {
      return this.$store.state.referreds.map((city) => {
        return {
          label: city.referred_code,
          value: city.id,
        }
      })
    },
    anbar_options() {
      var res = [
        {
          label: 'تعریف انبار جدید',
          value: 'new',
        },
      ]
      var anbars = this.$store.state.anbars.map((city) => {
        return {
          label: city.title,
          value: city.id,
        }
      })
      Object.assign(anbars, res)
      return anbars
    },
  },
  watch: {
    fiscal_year(newValue) {
      this.set_data_to_url({
        fiscal_year: newValue,
      })
    },
    token20(newValue, oldValue) {
      if ((newValue.match(/ /g) || []).length > 7) {
        if ((oldValue.match(/ /g) || []).length <= 7) {
          var str = new String(this.token20)
          str.trimEnd()
          this.toast(
            'مقدار token20 نباید بیشتر از 7 فاصله خالی داشته باشد!',
            'error',
          )
        }
        this.error_token20 = true
      } else {
        this.error_token20 = false
      }
    },
  },
}
