export default {
  data() {
    return {
      order: {
        id: null,
      },
      loading_get_order: false,
      loading_cancel_order: false,
    }
  },
  methods: {
    get_order(order_id) {
      this.loading_get_order = true
      // eslint-disable-next-line no-undef
      axios.get('/orders/get_order/' + order_id).then(({ data }) => {
        if (data.status == 'success') {
          this.set_order_data(data.order)
          setTimeout(
            function () {
              this.order = data.order
            }.bind(this),
            1000,
          )
        }
        this.loading_get_order = false
      })
    },
    cancel_order(order_id, desc = '') {
      this.loading_cancel_order = true
      // eslint-disable-next-line no-undef
      axios.get('/orders/cancel/' + order_id, { params: { desc: desc }}).then(({ data }) => {
        if (data.status == 'success') {
          if (this.$route.name == 'OrderList') {
            var index = this.orders.data.map((x) => x.id).indexOf(order_id)
            this.$refs['order' + index][0].style.right = '100%'
            setTimeout(
              function () {
                delete this.orders.data[index]
              }.bind(this),
              200,
            )
            // delete this.orders.data[index]
          }
        }
        this.loading_cancel_order = false
      })
    },
  },
}
