export default {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
  storeAdmin(store, payload) {
    store.admin = payload
  },
  storeFiscalYearData(store, payload) {
    store.fiscal_year_data = payload
  },
  storeJalaliValidate(store, payload) {
    store.jalali_validate = payload
  },
  storeUsers(store, payload) {
    store.users_options = payload
  },
  storeSiteUsers(store, payload) {
    store.site_users_options = payload
  },
  storeUsersLocal(store, payload) {
    if (payload.length) {
      store.local_users_options = payload
    }
  },
  storePaymentTypes(store, payload) {
    store.payment_types = payload
  },
  storePaymentTypesLocal(store, payload) {
    store.payment_types_local = payload
  },
  storeTypePayOptions(store, payload) {
    store.type_pay_options = payload
  },
  storeOrder(store, payload) {
    store.order = payload
  },
  storeFactories(store, payload) {
    store.factories = payload
  },
  storeProducts(store, payload) {
    store.products = payload
  },
  storeFiscalYearsOptions(store, payload) {
    store.fiscal_years_options = payload
  },
  storeAllFiscalYearsGroupedOptions(store, payload) {
    store.all_fiscal_year_grouped = payload
  },
  storeAllFiscalYearsSite(store, payload) {
    store.all_fiscal_year_site = payload
  },
  storeCurrentFiscalYears(store, payload) {
    store.current_fiscal_year = payload
  },
  storeProvinces(store, payload) {
    store.provinces = payload
  },
  storeCities(store, payload) {
    store.cities = payload
  },
  storeAnbars(store, payload) {
    store.anbars = payload
  },
  storeReferreds(store, payload) {
    store.referreds = payload
  },
  changeTrSelected(store, payload) {
    store.tr_selected = payload
  },
  storeTypesArz(store, payload) {
    store.type_arz_arr = payload
  },
  storeGroupsFm(store, payload) {
    store.groups_fm = payload
  },
  storeDefAnbarFm(store, payload) {
    store.default_anbar_fm = payload
  },
  storeAnbarsFm(store, payload) {
    store.anbars_fm = payload
  },
  storeOtherSanads(store, payload) {
    store.other_sanads = payload
  },
}
