<template>
  <CModal
    fullscreen="xs"
    :visible="visiable_var"
    backdrop="static"
    scrollable
    @close="closed"
  >
    <CModalHeader dismiss @close="closed">
      <CModalTitle class="fs-14"
        >مدیریت ارسال پیامک سند {{ snd.Id }}</CModalTitle
      >
    </CModalHeader>
    <CModalBody>
      <template v-if="loading_get_mobiles">
        <div v-for="i in 3" :key="i" class="mb-3">
          <shine-loading width="70" />
          <shine-loading width="20" size="sm" />
        </div>
      </template>
      <div
        v-else
        @click="toggleSelect(mobile)"
        :class="{
          'border-warning': is_selected(mobile.id),
        }"
        class="bg-white d-flex justify-content-between rounded border user-select-none py-2 ps-3 pe-2 mb-2"
        v-for="(mobile, index) in mobiles"
        :key="index"
      >
        <p class="mb-0 fs-16" v-text="mobile.mobile"></p>
        <div>
          <div class="text-end">
            <font-awesome-icon
              icon="fa-solid fa-circle-check"
              class="text-warning"
              v-if="is_selected(mobile.id)"
            />
            <font-awesome-icon
              icon="fa-regular fa-circle"
              class="text-muted"
              v-else
            />
          </div>
          <div
            v-if="
              mobile.seen_status != undefined &&
              mobile.seen_status.seen_date != null
            "
          >
            <div>
              <span class="fs-11 d-inline-block ltr">
                {{ to_jalali(mobile.seen_status.created_at, 'long') }}
              </span>
              <font-awesome-icon
                v-c-tooltip="{
                  content:
                    'زمان مشاهد لینک : ' +
                    to_jalali(mobile.seen_status.seen_date, 'long'),
                  placement: 'right',
                }"
                size="xs"
                icon="fa-solid fa-check-double"
                class="text-info align-middle ms-2 p-1 c-pointer"
              />
            </div>
          </div>
          <div v-else-if="mobile.seen_status != undefined">
            <div>
              <span class="fs-11 d-inline-block ltr">
                {{ to_jalali(mobile.seen_status.created_at, 'long') }}
              </span>
              <font-awesome-icon
                size="xs"
                icon="fa-solid fa-check"
                class="align-middle ms-2"
              />
            </div>
          </div>
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh @click="closed" color="light" class="me-2" label="بازگشت" />
      <btn-ayandeh
        :loading="loading_send_sms"
        :disabled="mobiles_selected.length == 0 || loading_send_sms"
        @click="send_again_smd"
        label="ارسال مجدد پیامک"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import ShineLoading from '@/components/loading/ShineLoading'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  name: 'ModalManageSms',
  components: { FontAwesomeIcon, ShineLoading },

  data() {
    return {
      visiable_var: false,
      loading_get_mobiles: false,
      loading_send_sms: false,
      mobiles: {},
      mobiles_selected: [],
    }
  },
  props: {
    visiable: {
      type: Boolean,
      default: false,
    },
    snd: {
      type: Object,
    },
    fiscal_year_prop: {
      type: String,
    },
  },
  watch: {
    visiable(newValue) {
      this.visiable_var = newValue
      if (newValue) {
        this.get_snd_mobiles()
      }
    },
  },
  methods: {
    toggleSelect(mobile) {
      if (this.is_selected(mobile.id)) {
        var index = this.mobiles_selected.indexOf(mobile.id)
        this.mobiles_selected.splice(index, 1)
      } else {
        this.mobiles_selected.push(mobile.id)
      }
    },
    is_selected(mobile_id) {
      if (this.mobiles_selected.filter((x) => x == mobile_id).length == 0) {
        return false
      } else {
        return true
      }
    },
    closed() {
      this.visiable_var = false
      this.$emit('update:visiable', this.visiable_var)
    },
    get_snd_mobiles() {
      this.loading_get_mobiles = true
      var query = this.gen_query_string({
        fiscal_year: this.fiscal_year_prop,
        snd_id: this.snd.Id,
      })
      // eslint-disable-next-line no-undef
      axios.get('/sanad/get_snd_mobiles?' + query).then(({ data }) => {
        if (data.status == 'success') {
          this.mobiles = data.mobiles
        }
        this.loading_get_mobiles = false
      })
    },
    send_again_smd() {
      this.loading_send_sms = true
      var data = {
        mobile_ids: this.mobiles_selected,
        fiscal_year: this.fiscal_year_prop,
        snd_id: this.snd.Id,
      }
      // eslint-disable-next-line no-undef
      axios.post('/sanad/send_sms_snd', data).then(({ data }) => {
        if (data.status == 'success') {
          this.mobiles = data.mobiles
          this.mobiles_selected = []
          this.closed()
        }
        this.loading_send_sms = false
      })
    },
  },
}
</script>
