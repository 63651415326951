<template>
  <div>
    <template v-if="isNaN(value)">{{ value }}</template>
    <template v-else>
      {{ toNumberFormat(value) }}
      <currency class="me-1" />
      <small v-if="value > 0" class="text-danger">
        {{ min_label ? '(بد)' : '(بدهکار)' }}
      </small>
      <small v-else-if="value < 0" class="text-success">
        {{ min_label ? '(بس)' : '(بستانکار)' }}
      </small>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ShowMandeh',
  props: {
    value: Number,
    min_label: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
