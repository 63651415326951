<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="row border-bottom">
        <payment-input-bed-part
          v-model:date_pay="date_pay"
          v-model:bed_id="bed_id"
          v-model:bed_id_local="bed_id_local"
          v-model:desc_payment="desc_payment"
          v-model:store_in_local_prop="store_payment_in_local_system"
          :payment_rows="payment_rows"
          :bes_ids="bes_ids"
        />
        <div class="col-12 overflow-x-hidden" v-if="payment_rows.length">
          <div class="text-left">
            <font-awesome-icon
              icon="fa-solid fa-plus"
              class="fs-21 px-2 text-ayandeh c-pointer"
              v-if="!add_new_row"
              @click="add_new_row_payment"
            />
          </div>
          <div class="table-ay1 mb-3">
            <table>
              <thead>
                <tr>
                  <th>ردیف</th>
                  <th>نوع</th>
                  <th>مبلغ</th>
                  <th>حساب</th>
                  <th>ساعت</th>
                  <th v-if="have_check">تاریخ سررسید چک</th>
                  <th>کد پیگیری</th>
                  <th>شرح</th>
                  <th>شیوه پرداخت</th>
                  <th>پیوست</th>
                  <th>کارمزد</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in payment_rows"
                  :key="index"
                  class="animation_hide_left"
                  :ref="'tr_' + index"
                >
                  <td>
                    <span class="mobile_title">ردیف :</span>
                    {{ index + 1 }}
                  </td>
                  <td>
                    <span class="mobile_title">نوع :</span>
                    {{
                      $store.state.payment_types.filter(
                        (x) => x.value == row.type_payment,
                      )[0].label
                    }}
                  </td>
                  <td>
                    <span class="mobile_title">مبلغ :</span>
                    {{ toNumberFormat(row.amount) }}
                  </td>
                  <td>
                    <span class="mobile_title">حساب :</span>
                    {{ row.bes_name }}
                  </td>
                  <td>
                    <span class="mobile_title">ساعت :</span>
                    {{ row.time_pay }}
                  </td>
                  <td v-if="have_check">
                    <span class="mobile_title">تاریخ سررسید چک :</span>
                    {{ row.date_check || '-' }}
                  </td>
                  <td>
                    <span class="mobile_title">کد پیگیری :</span>
                    {{ row.tracking_code }}
                  </td>
                  <td>
                    <span class="mobile_title">شرح :</span>
                    {{ row.description }}
                  </td>
                  <td>
                    <span class="mobile_title">شیوه پرداخت :</span>
                    {{ $store.state.type_pay_options[row.type_pay] || '-' }}
                  </td>
                  <td>
                    <span class="mobile_title">پیوست :</span>
                    {{ Object.keys(row.attachs).length }}
                  </td>
                  <td>
                    <span class="mobile_title">کارمزد :</span>
                    {{ toNumberFormat(row.fee) }}
                  </td>
                  <td>
                    <i
                      class="fa fa-times text-danger py-1 px-2 c-pointer"
                      @click="removeCacheDaryaftDet(index)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--  Rows-->
      <div class="row border-top pt-3" v-show="add_new_row">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label>نوع</label>
            <v-select
              v-model="type_payment_selected"
              :options="$store.state.payment_types"
              :reduce="(option) => option.value"
              placeholder="انتخاب نوع"
              dir="rtl"
              :loading="$store.state.payment_types_loading"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-8 mb-3">
          <div
            class="form-group mb-1"
            id="type_payment_selected"
            v-show="type_payment_selected != ''"
          >
            <label class="w-100">انتخاب حساب</label>
            <v-select
              v-model="bes_id"
              :options="hesab_option_select"
              :reduce="(option) => option.value"
              :selectable="(option) => option.value != bed_id"
              placeholder="انتخاب حساب"
              dir="rtl"
              :loading="loading_hesab"
            ></v-select>
          </div>
          <div v-if="bes_id != '' && type_payment_selected != 2">
            مانده :
            <loading-spinner
              v-if="loading_mandeh_bes"
              size="sm"
              color="muted"
              :show="loading_mandeh_bes"
            />
            <show-mandeh
              class="d-inline-block"
              v-else-if="codes_mandeh[bes_id]"
              :value="codes_mandeh[bes_id]"
            />
            <span v-else>-</span>
          </div>
        </div>
        <!--    Store In Local System     -->
        <template v-if="store_payment_row_in_local_system">
          <div class="alert alert-info">
            <p>ثبت پرداخت در سیستم داخلی</p>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <div class="form-group">
                  <label class="mb-2">نوع</label>
                  <v-select
                    v-model="type_payment_selected_local"
                    :options="$store.state.payment_types_local"
                    :reduce="(option) => option.value"
                    placeholder="انتخاب نوع"
                    dir="rtl"
                    :loading="$store.state.payment_types_local_loading"
                  ></v-select>
                </div>
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                v-if="type_payment_selected_local == 2"
              >
                <input-date
                  v-model:value="date_check_local"
                  placeholder="روز/ماه/سال"
                  label="تاریخ سررسید چک"
                />
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                v-if="type_payment_selected_local == 3"
              >
                <input-ayandeh
                  :number-format="true"
                  placeholder="کارمزد حواله را وارد کنید"
                  v-model:value="fee_local"
                  dir="ltr"
                >
                  مبلغ کارمزد
                  <currency :parentheses="true" />
                </input-ayandeh>
              </div>
              <div class="col-12 col-sm-6 col-md-8 mb-3">
                <div
                  class="form-group mb-1"
                  v-show="type_payment_selected_local != ''"
                >
                  <label class="w-100 mb-2">انتخاب حساب</label>
                  <v-select
                    v-model="bes_id_local"
                    :options="hesab_option_select_local"
                    :reduce="(option) => option.value"
                    placeholder="انتخاب حساب"
                    dir="rtl"
                    :loading="loading_hesab_local"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="col-12 p-0">
          <div class="row m-0">
            <div class="col-12 col-md-8 p-0">
              <div class="row m-0">
                <div class="col-12 col-sm-6 mb-3" :class="col_md_sync_sidebar">
                  <input-ayandeh
                    :number-format="true"
                    placeholder="مبلغ پرداخت شده را وارد کنید"
                    v-model:value="amount"
                    ref="amount"
                    dir="ltr"
                  >
                    مبلغ پرداخت شده
                    <currency :parentheses="true" />
                  </input-ayandeh>
                </div>
                <div class="col-12 col-sm-6 mb-3" :class="col_md_sync_sidebar">
                  <input-ayandeh
                    type="tel"
                    :label="
                      type_payment_selected == 2 ? 'شماره چک' : 'کد پیگیری'
                    "
                    placeholder="شماره تراکنش/ کد رهگیری/ پیگیری"
                    v-model:value="tracking_code"
                    dir="ltr"
                  />
                </div>
                <div
                  class="col-12 col-sm-6 mb-3"
                  :class="col_md_sync_sidebar"
                  v-if="type_payment_selected == 3"
                >
                  <input-ayandeh
                    :number-format="true"
                    placeholder="کارمزد حواله را وارد کنید"
                    v-model:value="fee"
                    dir="ltr"
                  >
                    مبلغ کارمزد
                    <currency :parentheses="true" />
                  </input-ayandeh>
                </div>
                <div class="col-12 col-sm-6" :class="col_md_sync_sidebar">
                  <input-date
                    v-if="type_payment_selected == 2"
                    v-model:value="date_check"
                    placeholder="روز/ماه/سال"
                    label="تاریخ سررسید چک"
                  />
                  <div class="form-group" v-else>
                    <label class="mb-2">شیوه پرداخت</label>
                    <select
                      class="form-control"
                      name="type_pay"
                      v-model="type_pay_selected"
                    >
                      <option value="">انتخاب شیوه پرداخت</option>
                      <option
                        v-for="(type_pay, index) in $store.state
                          .type_pay_options"
                        :key="index"
                        :value="index"
                        v-text="type_pay"
                      ></option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-sm-6" :class="col_md_sync_sidebar">
                  <div class="form-group mb-2">
                    <label for="desc_row">شرح</label>
                    <textarea
                      class="form-control"
                      id="desc_row"
                      rows="2"
                      v-model="desc_row_payment"
                      placeholder="اختیاری"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <multiple-attach
                    ref="attach_multiple"
                    v-model:attach="attachs"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <p class="mb-2">ساعت پرداخت</p>
              <smart-time-picker @change="changed_time" ref="timepicker" />
            </div>
          </div>
        </div>
        <div class="col-12">
          <btn-ayandeh
            color="secondary"
            type="button"
            class="px-3"
            :loading="loading_store_row_daryaft"
            @click="verify_and_store_row_payment"
            :disabled="
              !form_naghdi_complete ||
              !bes_id ||
              bes_id == '' ||
              loading_store_row_daryaft ||
              (store_payment_in_local_system && !bed_id_local)
            "
          >
            افزودن
            <font-awesome-icon icon="fa-solid fa-plus" class="align-sub ps-1" />
          </btn-ayandeh>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <btn-ayandeh
        color="success"
        class="px-3"
        :loading="loading_store_payment"
        @click="save_payment"
        :disabled="payment_rows.length == 0 || loading_store_payment"
        >ذخیره پرداخت
      </btn-ayandeh>
    </div>
  </div>
</template>

<script>
import InputDate from '@/components/inputs/InputDate'
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import 'smart-webcomponents/source/styles/smart.default.css'
import 'smart-webcomponents/source/modules/smart.timepicker.js'
import MultipleAttach from '@/components/partial/MultipleAttach'
import Currency from '@/components/partial/Currency'
// import Swal from 'sweetalert2'
import ShowMandeh from '@/components/partial/ShowMandeh'
import PaymentInputBedPart from '@/components/payment/PaymentInputsBedPart'
import payment_mixin from '@/mixin/Payment'

export default {
  name: 'NewPayment',
  mixins: [payment_mixin],
  components: {
    PaymentInputBedPart,
    ShowMandeh,
    Currency,
    MultipleAttach,
    LoadingSpinner,
    InputDate,
  },
  data() {
    return {
      loading_store_payment: false,
      payment_rows: [],
      add_new_row: true,
      loading_daryaft: false,
      loading_store_row_daryaft: false,
      store_payment_in_local_system: false,
      payment_able_id: '',
    }
  },
  beforeMount() {
    this.get_type_pay_options()
  },
  mounted() {
    this.get_payment_types()

    var timepicker = this.$refs.timepicker
    timepicker.value = this.time_pay
    timepicker.format = '12-hour'
    timepicker.autoSwitchToMinutes = true

    if (this.$route.query.bed_id != undefined) {
      this.bed_id = this.$route.query.bed_id
    }

    if (this.$route.query.amount != undefined) {
      this.amount = this.toNumberFormat(this.$route.query.amount)
    }

    if (this.$route.query.type_payment_selected != undefined) {
      this.type_payment_selected = parseInt(
        this.$route.query.type_payment_selected
      )
    }

    if (this.$route.query.type_pay_selected != undefined) {
      this.type_pay_selected = this.$route.query.type_pay_selected
    }

    if (this.$route.query.payment_able_id != undefined) {
      this.payment_able_id = this.$route.query.payment_able_id
    }

    if (this.$route.query.bes_id) {
      setTimeout(
        function () {
          this.bes_id = this.$route.query.bes_id
        }.bind(this),
        3000,
      )
    }
  },
  computed: {
    bes_ids() {
      var bes_id = []
      if (this.payment_rows.length) {
        this.payment_rows.forEach((x) => {
          bes_id.push(x.bed_id)
        })
      }

      if (this.bes_id) {
        bes_id.push(this.bes_id)
      }
      return bes_id
    },
    have_check() {
      return this.payment_rows.filter((row) => row.type_payment == 2).length
    },
    col_md_sync_sidebar() {
      if (this.$store.state.sidebarVisible) {
        return 'col-md-6'
      } else {
        return 'col-md-4'
      }
    },
  },
  methods: {
    verify_and_store_row_payment() {
      if (!this.form_naghdi_complete) {
        this.my_alert('لطفا اطلاعات خواسته شده را بصورت صحیح پر کنید!', 'info')
        return false
      }

      this.loading_store_row_daryaft = true
      this.store_row_payment()
      this.loading_store_row_daryaft = false
    },
    store_row_payment() {
      var bes_name, date_check, bes_name_local, date_check_local
      if (this.store_payment_row_in_local_system) {
        if (this.type_payment_selected_local == 2) {
          date_check_local = this.date_check_local
        } else {
          date_check_local = ''
        }
        bes_name_local =
          this.hesabs_local[this.type_payment_selected_local][this.bes_id_local]
            .Nam
      }
      if (this.type_payment_selected == 2) {
        date_check = this.date_check
      } else {
        date_check = ''
      }
      bes_name = this.hesabs[this.type_payment_selected][this.bes_id].Nam
      this.payment_rows.push({
        store_payment_row_in_local_system: this
          .store_payment_row_in_local_system
          ? true
          : '',
        type_payment: this.type_payment_selected,
        type_payment_local: this.type_payment_selected_local,
        amount: this.amount,
        tracking_code: this.tracking_code,
        time_pay: this.time_pay,
        bes_id: this.bes_id,
        bes_id_local: this.bes_id_local,
        bes_name: bes_name,
        bes_name_local: bes_name_local,
        type_pay: this.type_pay_selected,
        description: this.desc_row_payment,
        date_check: date_check,
        date_check_local: date_check_local,
        fee: this.fee,
        fee_local: this.fee_local,
        attachs: Object.assign({}, this.attachs),
      })
      this.add_new_row = false
      setTimeout(
        function () {
          this.reset_row_payment()
        }.bind(this),
        1000,
      )
    },
    reset_row_payment() {
      this.tracking_code = ''
      this.desc_row_payment = ''
      this.type_payment_selected = ''
      this.type_payment_selected_local = ''
      // this.amount = ''
      this.type_pay_selected = ''
      this.date_check = ''
      this.date_check_local = ''
      this.bes_id = ''
      this.bes_id_local = ''
      this.time_pay = '00:00'
      this.fee = ''
      this.$refs.attach_multiple.reset()
      this.$refs.timepicker.value = this.time_pay
    },
    add_new_row_payment() {
      this.add_new_row = true
      setTimeout(
        function () {
          this.$refs.amount.focus()
        }.bind(this),
        200,
      )
    },
    removeCacheDaryaftDet(index) {
      var classList = this.$refs['tr_' + index][0].classList
      classList.add('hide')
      setTimeout(
        function () {
          classList.remove('hide')
          delete this.payment_rows[index]
          if (this.payment_rows.length == 0) {
            this.add_new_row = true
          }
        }.bind(this),
        200,
      )
    },
    save_payment() {
      this.loading_store_payment = true

      var formData = {
        bed_id: this.bed_id,
        bed_id_local: this.bed_id_local ? this.bed_id_local : '',
        date_pay: this.date_pay,
        description: this.desc_payment,
        payment_able_id: this.payment_able_id,
        store_payment_in_local_system: this.store_payment_in_local_system
          ? true
          : '',
        rows: this.payment_rows,
      }

      // eslint-disable-next-line no-undef
      axios
        .post('/payment/store', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            this.reset_adding()
            if (data.status_payment_able == 'payed') {
              this.$router.replace({ name: 'PaymentList' })
            } else {
              this.$router.replace({ name: 'PaymentList' })
            }
          }
          this.loading_store_payment = false
          this.my_alert(data.msg, data.status)
        })
        .catch(() => {
          this.loading_store_payment = false
        })
    },
    reset_adding() {
      this.reset_row_payment()
      this.payment_rows = []
      this.date_check = ''
      this.date_check_local = ''
      this.add_new_row = true
    },
  },
  watch: {},
}
</script>
