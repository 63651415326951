<template>
  <template v-if="snd">
    <div class="text-end mb-2">
      <slot name="btns" />
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <div class="mb-3">
          <h3 class="fs-14 bold text-center">سند حسابداری</h3>
          <p class="text-center mb-4">سیمان آینده</p>
          <div class="d-flex justify-content-between mb-2">
            <div>
              شماره :
              {{ snd.IdSnd }}
            </div>
            <div>
              تاریخ :
              {{ snd.SDate }}
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <div>
              شرح :
              {{ snd.ShSnd }}
            </div>
            <div>
              وضعیت :
              <span class="text-success fw-bold" v-if="snd.verified"
                >تایید شده</span
              >
              <span class="text-danger fw-bold" v-else>تایید نشده</span>
            </div>
          </div>
        </div>
        <div
          class="table-ay1 rounded-bottom-0 border-end border-start border-bottom"
        >
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>کد کل</th>
                <th>شماره فرعی</th>
                <th>حساب</th>
                <th>بدهکار</th>
                <th>بستانکار</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(detail, index) in snd.details" :key="index">
                <td>
                  <div class="mobile_title">ردیف :</div>
                  {{ index + 1 }}
                </td>
                <td>
                  <div class="mobile_title">کد کل :</div>
                  <span>{{ detail.IdCol }}</span>

                </td>
                <td>
                  <div class="mobile_title">شماره فرعی :</div>
                  {{ detail.IdSnd }}
                </td>
                <td v-c-tooltip="{
                  content: detail.IdTafsil,
                  placement: 'right',
                  color: 'white',
                }">
                  <div class="mobile_title">حساب :</div>
                  <p class="mb-0 bold">{{ detail.col.Nam }}</p>
                  <p class="mb-0">
                    {{ detail.moen.Nam }} -
                    {{ detail.tafsil != undefined ? detail.tafsil.Nam : '!' }}
                  </p>
                </td>
                <td>
                  <div class="mobile_title">بدهکار :</div>
                  {{ toNumberFormat(parseFloat(detail.Bed)) }}
                </td>
                <td>
                  <div class="mobile_title">بستانکار :</div>
                  {{ toNumberFormat(parseFloat(detail.Bes)) }}
                </td>
              </tr>
              <tr class="border-top">
                <td
                  colspan="4"
                  class="text-white"
                  style="background-color: #477390"
                >
                  وضعیت تراز
                  <font-awesome-icon
                    icon="fa-solid fa-left-long"
                    class="align-middle ms-3 d-none d-md-inline-block"
                  />
                </td>
                <td>
                  <div class="mobile_title">بدهکار :</div>
                  {{ toNumberFormat(sum('Bed', snd.details)) }}
                </td>
                <td>
                  <div class="mobile_title">بستانکار :</div>
                  {{ toNumberFormat(sum('Bes', snd.details)) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="d-flex justify-content-between border border-top-0 pb-3 rounded-bottom"
        >
          <div
            class="px-3 ps-md-5 me-auto mt-auto fs-13"
            :class="{ 'pt-5': snd.verified == 0, 'pt-2': snd.verified }"
            v-if="snd.creator_name"
          >
            <img
              v-if="snd.verified"
              src="/img/emza/other-signature.png"
              class="signature"
              width="130"
            />
            <p class="mb-0">تنظیم کننده ({{ snd.creator_name }})</p>
          </div>
          <div
            class="px-3 pe-md-5 ms-auto mt-auto fs-13"
            :class="{ 'pt-5': !snd.verified, 'pt-2': snd.verified }"
          >
            <img
              v-if="snd.verified"
              src="/img/emza/sokse-signature.png"
              class="signature"
              width="80"
            />
            <p class="mb-0">مدیر عامل</p>
          </div>
        </div>
      </div>
    </div>
    <show-attachments type="b" :attachments="attachments" />
    <show-attachments
      type="b"
      label="فایل پیوست متناظر"
      :attachments="attachments_counterpart"
    />
  </template>

  <!--  Loading Get Data  -->
  <div class="text-center pt-5" v-else>
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>
</template>

<script>
import ShowAttachments from '@/components/partial/ShowAttachments'
export default {
  name: 'ShowSndDetails',
  components: { ShowAttachments },
  data() {
    return {}
  },
  props: {
    attachments: Object,
    attachments_counterpart: Object,
    snd: Object,
  },
}
</script>

<style scoped></style>
