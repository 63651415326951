export default {
  data() {
    return {}
  },
  watch: {
    fiscal_year_snd(newValue) {
      this.setCookie('snd_fiscal_year', newValue)
    },
  },
}
