<template>
  <nav
    aria-label="Page navigation example"
    class="d-block d-md-flex justify-content-between mb-3 px-3"
    v-if="paginate_data.total / paginate_data.per_page > 1"
  >
    <ul class="pagination mt-md-3">
      <li
        class="page-item"
        :class="{ disabled: current_page == 1 }"
        @click="go_to_page(current_page - 1)"
      >
        <a class="page-link" href="#" aria-label="Previous">
          <font-awesome-icon icon="fa-solid fa-angles-right" />
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li
        class="page-item"
        @click="go_to_page(page)"
        :class="{
          active: page == current_page,
          'c-default': page == '...',
        }"
        v-for="page in p_shown_pages"
        :key="page"
      >
        <a class="page-link text-muted" v-if="page == '...'">...</a>
        <a class="page-link" href="#" v-text="page" v-else></a>
      </li>
      <li
        class="page-item"
        :class="{ disabled: current_page == paginate_data.last_page }"
        @click="go_to_page(current_page + 1)"
      >
        <a class="page-link" href="#" aria-label="Next">
          <font-awesome-icon icon="fa-solid fa-angles-left" />
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
    <div class="form-group d-inline-block mt-2 mt-md-0" style="width: 110px">
      <lebel>صفحه</lebel>
      <div class="d-flex">
        <div style="line-height: 33px" class="me-1">
          {{ paginate_data.last_page }}/
        </div>
        <input type="number" class="form-control" v-model="current_page_var" />
        <font-awesome-icon
          @click="go"
          icon="fa-solid fa-magnifying-glass"
          class="text-ayandeh p-2 c-pointer"
        />
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  data() {
    return {
      current_page_var: 0,
    }
  },
  mounted() {
    this.current_page_var = parseInt(this.current_page)
  },
  props: {
    current_page: {
      type: Number,
    },
    paginate_data: {
      type: Object,
    },
  },
  computed: {
    p_shown_pages() {
      var res = []
      var num_round_current = 4
      if (this.paginate_data.total) {
        var last_page = this.paginate_data.last_page
        var current_page = parseInt(this.current_page)
        for (var i = current_page - num_round_current; i < current_page; i++) {
          if (i >= 1) {
            res.push(i)
          }
        }
        for (
          var j = current_page;
          j <= last_page && j <= current_page + num_round_current;
          j++
        ) {
          res.push(j)
        }
        if (last_page > num_round_current && current_page < last_page) {
          res.push('...')
          res.push(last_page)
        }
      }
      return res
    },
  },
  methods: {
    go_to_page(page_number) {
      if (page_number != '...') {
        this.set_data_to_url({ page: parseInt(page_number) })
        this.$emit('go_to', page_number)
      }
    },
    go() {
      if (this.current_page_var > this.paginate_data.last_page) {
        this.toast('شماره صفحه وارد شده وجود ندارد', 'warning')
        return false
      }
      if (this.current_page_var != '') {
        this.go_to_page(this.current_page_var)
      }
    },
  },
  watch: {
    current_page(newValue) {
      this.current_page_var = newValue
    },
  },
}
</script>
