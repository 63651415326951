import { h, resolveComponent } from 'vue'
import NewPayment from '@/views/payment/NewPayment'
import PaymentAbleShow from '@/views/paymentable/PaymentAbleShow'
import PaymentAbleList from '@/views/paymentable/PaymentAbleList'
import PaymentAbleMenu from '@/views/paymentable/PaymentAbleMenu'
import DisCharge from '@/views/orders/DisCharge'
import Orders from '@/views/orders/Orders'
import Dashboard from '@/views/Dashboard'
import AdminLayout from '@/layouts/AdminLayout'
import PaymentList from '@/views/payment/PaymentList'
import ShowPayment from '@/views/payment/ShowPayment'
import EditOrder from '@/views/orders/EditOrder'
import SanadList from '@/views/sanad/SanadList'
import SanadMenu from '@/views/sanad/SanadMenu'
import ActiveCodes from '@/views/sanad/ActiveCodes'
import SanadDetail from '@/views/sanad/SanadDetail'
import CardList from '@/views/cards/CardList'
import CardMenu from '@/views/cards/CardMenu'
import NewCard from '@/views/cards/NewCard'
import GardeshList from '@/views/gardesh/GardeshList'
import FactoryList from '@/views/factory/FactoryList'
import FiscalYear from '@/views/fiscal_year/FiscalYear'

export default {
  path: '/admin',
  name: 'Home',
  props: {
    label: 'خانه',
  },
  meta: {
    requiresAuth: true,
  },
  component: AdminLayout,
  redirect: '/admin/dashboard',
  children: [
    // Dashboard
    {
      path: 'dashboard',
      name: 'Dashboard',
      props: {
        label: 'داشبورد',
      },
      component: Dashboard,
    },
    // Orders
    {
      path: 'orders',
      name: 'Orders',
      redirect: '/admin/orders/list',
      props: {
        label: 'سفارشات',
      },
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: 'list',
          name: 'OrderList',
          props: {
            label: 'لیست سفارشات',
          },
          component: Orders,
        },
        {
          path: 'disCharge/:id',
          name: 'DisCharge',
          props: {
            label: 'تایید تخلیه',
          },
          component: DisCharge,
        },
        {
          path: 'edit/:id',
          name: 'EditOrder',
          props: {
            label: 'ویرایش سفارش',
          },
          component: EditOrder,
        },
      ],
    },
    // Rent And Reward
    {
      path: 'paymentable',
      name: 'PaymentAble',
      redirect: '/admin/paymentable/menu',
      props: {
        label: 'پرداختنی ها',
      },
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: 'menu',
          name: 'PaymentAbleMenu',
          props: {
            label: 'منو پرداختنی ها',
          },
          component: PaymentAbleMenu,
        },
        {
          path: 'list',
          name: 'PaymentAbleList',
          props: {
            label: 'لیست پرداختنی ها',
          },
          component: PaymentAbleList,
        },
        {
          path: 'show/:id',
          name: 'PaymentAbleShow',
          props: {
            label: 'جزئیات پرداختنی',
          },
          component: PaymentAbleShow,
        },
      ],
    },
    // Payments
    {
      path: 'payment',
      name: 'Payment',
      redirect: '/payment/new',
      props: {
        label: 'پرداخت',
      },
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: 'new',
          name: 'NewPayment',
          props: {
            label: 'پرداخت جدید',
          },
          component: NewPayment,
        },
        {
          path: 'list',
          name: 'PaymentList',
          props: {
            label: 'لیست پرداخت ها',
          },
          component: PaymentList,
        },
        {
          path: 'show/:id',
          name: 'ShowPayment',
          props: {
            label: 'جزئیات پرداخت',
          },
          component: ShowPayment,
        },
      ],
    },
    // Sanad
    {
      path: 'sanad',
      name: 'Sanad',
      props: {
        label: 'اسناد حسابداری',
      },
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      children: [
        {
          path: 'menu',
          name: 'SanadMenu',
          props: {
            label: 'منوی اسناد',
          },
          component: SanadMenu,
        },
        {
          path: 'list',
          name: 'SanadList',
          props: {
            label: 'لیست اسناد حسابداری',
          },
          component: SanadList,
        },
        {
          path: 'show/:fiscal_year/:id',
          name: 'SanadDetail',
          props: {
            label: 'جزئیات سند حسابداری',
          },
          component: SanadDetail,
        },
        {
          path: 'active_codes',
          name: 'SanadActiceCodes',
          props: {
            label: 'کد های حسابداری فعال',
          },
          component: ActiveCodes,
        },
      ],
    },
    // Cards
    {
      path: 'card',
      name: 'Cards',
      props: {
        label: 'کارت ها',
      },
      component: {
        render() {
          return h(resolveComponent('router-view'))
        },
      },
      redirect: '/card/list',
      children: [
        {
          path: 'menu',
          name: 'CardMenu',
          props: {
            label: 'منوی کارت ها',
          },
          component: CardMenu,
        },
        {
          path: 'list',
          name: 'CardList',
          props: {
            label: 'لیست کارت ها',
          },
          component: CardList,
        },
        {
          path: 'create',
          name: 'NewCard',
          props: {
            label: 'ایجاد کارت جدید',
          },
          component: NewCard,
        },
      ],
    },
    // Turnover / گردش حساب
    {
      path: 'gardesh',
      name: 'Gardesh',
      props: {
        label: 'گردش حساب',
      },
      component: GardeshList,
    },
    // Factotry And Products
    {
      path: 'factory-list',
      name: 'FactoryList',
      props: {
        label: 'لیست کارخانه ها',
      },
      component: FactoryList,
    },
    {
      path: 'fiscal-year-management',
      name: 'FiscalYear',
      props: {
        label: 'مدیریت سال مالی',
      },
      component: FiscalYear,
    },
  ],
}
