<template>
  <show-snd-details :snd="snd" :attachments="attachments">
    <template #btns>
      <btn-ayandeh
        @click="download_snd"
        :loading="loading_download"
        :disabled="loading_download"
      >
        <font-awesome-icon icon="fa-solid fa-file-pdf" class="me-1" />
        دانلود سند
      </btn-ayandeh>
    </template>
  </show-snd-details>
</template>

<script>
import ShowSndDetails from '@/components/ShowSndDetails'
export default {
  name: 'SanadDetailByHash',
  data() {
    return {
      loading_get_sanad: false,
      snd: null,
      attachments: [],
      loading_download: false,
      fiscal_year: '',
    }
  },
  components: { ShowSndDetails },
  mounted() {
    this.get_snd_by_hash()
  },
  methods: {
    get_snd_by_hash() {
      this.loading_get_sanad = true
      var query = {
        side: this.$route.params.side,
        fiscal_year_id: this.$route.params.fiscal_year_id,
        snd_verified_hash_code: this.$route.params.snd_verified_hash_code,
        mobile_code_hash_code: this.$route.params.mobile_code_hash_code,
        hash_fiscal_year_and_snd_id:
        this.$route.params.hash_fiscal_year_and_snd_id,
      }
      // eslint-disable-next-line no-undef
      axios.get('/sanad/get_sanad_by_hash?', { params : query }).then(({ data }) => {
        if (data.status == 'success') {
          this.snd = data.snd
          this.fiscal_year = data.fiscal_year
          this.attachments = data.attachments
        }

        this.loading_get_sanad = false
      })
    },
    download_snd() {
      if (this.$route.params.hash_fiscal_year_and_snd_id != undefined) {
        this.download_snd_pdf_for_admin()
      } else {
        this.download_snd_pdf_for_user()
      }
    },
    download_snd_pdf_for_user() {
      this.loading_download = true
      var query = {
        side: this.$route.params.side,
        fiscal_year_id: this.$route.params.fiscal_year_id,
        snd_verified_hash_code: this.snd.hash_code,
        mobile_code_hash_code: this.$route.params.mobile_code_hash_code,
      }
      // eslint-disable-next-line no-undef
      axios.get('/sanad/download_snd?', { params: query }).then(({ data }) => {
        this.loading_download = false
        this.toast(data.msg, data.status)
        this.ajax_download(data.path, data.origin_name)
      })
    },
    download_snd_pdf_for_admin() {
      this.loading_download = true
      var query = this.gen_query_string({
        fiscal_year: this.fiscal_year,
        snd_id: this.snd.Id,
      })
      // eslint-disable-next-line no-undef
      axios.get('/sanad/download_snd?' + query).then(({ data }) => {
        this.loading_download = false
        this.toast(data.msg, data.status)
        this.ajax_download(data.path, data.origin_name)
      })
    },
  },
}
</script>
