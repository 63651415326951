<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-12 col-md-8 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-regular fa-user"
                class="text-icon-light"
              />
              انتخاب مشتری
            </label>
            <v-select
              v-model="user_id"
              :options="$store.state.site_users_options"
              :reduce="(option) => option.user_id"
              placeholder="انتخاب مشتری"
              dir="rtl"
              :loading="$store.state.site_users_options_loading"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            type="number"
            placeholder="تناژ اولیه کارت"
            v-model:value="tonage"
            dir="ltr"
          >
            <font-awesome-icon
              icon="fa-solid fa-weight-hanging"
              class="text-icon-light"
            />
            تناژ کارت
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-industry"
                class="text-icon-light"
              />
              کارخانه
            </label>
            <v-select
              v-model="factory_id"
              :options="factories_option"
              :reduce="(option) => option.value"
              placeholder="انتخاب کارخانه"
              dir="rtl"
              :loading="$store.state.loading_factory"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-boxes-stacked"
                class="text-icon-light"
              />
              محصول
            </label>
            <v-select
              v-model="product_selected"
              :options="products_option"
              placeholder="انتخاب محصول"
              dir="rtl"
              :loading="$store.state.loading_products"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-map-pin"
                class="text-icon-light"
              />
              استان مرزی
            </label>
            <v-select
              v-model="province_id"
              :options="province_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب استان تخلیه"
              dir="rtl"
              :loading="$store.state.loading_provinces"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-map-pin"
                class="text-icon-light"
              />
              شهر تخلیه (نام مرز)
            </label>
            <v-select
              v-model="city_id"
              :options="city_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب مرز تخلیه"
              dir="rtl"
              :loading="$store.state.loading_cities"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-warehouse"
                class="text-icon-light"
              />
              انتخاب انبار
            </label>
            <v-select
              v-model="anbar_id"
              :options="anbar_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب انبار"
              dir="rtl"
              :loading="$store.state.loading_anbars"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3" v-if="anbar_id == 'new'">
          <input-ayandeh
            ref="anbar_input"
            placeholder="نام انبار جدید را وارد کنید"
            v-model:value="new_anbar_title"
          >
            <font-awesome-icon
              icon="fa-solid fa-warehouse"
              class="text-icon-light"
            />
            نام انبار
          </input-ayandeh>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="کرایه هر تن بار"
            v-model:value="price_rent"
            dir="ltr"
          >
            <font-awesome-icon
              icon="fa-solid fa-money-bill"
              class="text-icon-light"
            />
            کرایه
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="انعام هر تن بار"
            v-model:value="price_reward"
            dir="ltr"
          >
            <font-awesome-icon
              :icon="['fas', 'gift']"
              class="text-icon-light"
            />
            انعام
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>واحد ارزی فروش</label>
            <select class="form-control" v-model="type_arz">
              <option value="">انتخاب</option>
              <option value="rial">ریال</option>
              <option value="dollar">دلار</option>
              <option value="rupee">روپیه</option>
            </select>
          </div>
        </div>
        <template v-if="type_arz != '' && type_arz != 'rial'">
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              :number-format="true"
              placeholder="نرخ ارز انتخاب شده"
              v-model:value="price_arz"
              dir="ltr"
            >
              نرخ ارز
              <currency :parentheses="true" />
            </input-ayandeh>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              placeholder="فی فروش ارزی"
              v-model:value="price_per_ton_arz"
              dir="ltr"
            >
              فی فروش ارزی
            </input-ayandeh>
          </div>
        </template>
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-else-if="type_arz == 'rial'"
        >
          <input-ayandeh
            :number-format="true"
            placeholder="فی فروش"
            v-model:value="price_per_ton"
            dir="ltr"
          >
            فی فروش
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>پرداخت کرایه توسط</label>
            <select class="form-control" v-model="rent_by">
              <option value="">انتخاب</option>
              <option value="ayandeh">شرکت آینده</option>
              <option value="user">مشتری</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>ترخیص توسط</label>
            <select class="form-control" v-model="clearance_by">
              <option value="">انتخاب</option>
              <option value="ayandeh">شرکت آینده</option>
              <option value="user">مشتری</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="form-group">
            <label class="mb-2">اتصال حواله به کارت</label>
            <v-select
              v-model="referred_ids"
              :options="referred_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب حواله"
              :disabled="referred_options.length == 0"
              dir="rtl"
              :multiple="true"
              :loading="$store.state.loading_referreds"
            ></v-select>
          </div>
        </div>
        <div class="col-12 pt-4">

        </div>
      </div>
    </div>
    <div class="card-footer">
      <btn-ayandeh
        :disabled="disable_btn_store_card"
        :loading="loading_store_card"
        @click="store_card"
      >
        ذخیره کارت
      </btn-ayandeh>
      <btn-ayandeh
        @click="$refs.card_manager.add_sanad()"
        color="info"
        class="ms-2"
        :disabled="loading_store_card"
      >
        افزودن سند حسابداری
        <font-awesome-icon icon="fa-solid fa-file-circle-plus" />
      </btn-ayandeh>

    </div>
  </div>

  <card-manage-other-snd ref="card_manager"
                         v-model:validate="validate_other_sanads"
                         v-model:other_sanads_prop="other_sanads" />

</template>

<script>
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CardMix from '@/mixin/CardMix'
import BtnAyandeh from "@/components/partial/AyandehButton.vue"
import CardManageOtherSnd from "@/views/sanads_other/CardManageOtherSnd.vue"

export default {
  name: 'NewCard',
  components: { CardManageOtherSnd, BtnAyandeh, FontAwesomeIcon },
  mixins: [CardMix],
  data() {
    return {
      loading_store_card: false,
      validate_other_sanads: false,
      other_sanads: [],
    }
  },
  mounted() {
    this.get_site_users()
    this.get_factories()
    this.get_products()
    this.get_provinces()
    this.get_cities({
      province_id: this.province_id
    })
    this.get_anbars()
  },
  computed: {
    disable_btn_store_card() {
      if (
        !this.user_id ||
        this.tonage === '' ||
        !this.product_selected ||
        !this.city_id ||
        !this.anbar_id ||
        this.price_rent === '' ||
        this.rent_by == '' ||
        this.clearance_by === ''
      ) {
        return true
      }
      if (this.type_arz !== '' && this.type_arz !== 'rial') {
        if (!this.price_arz || !this.price_per_ton_arz) {
          return true
        }
      } else if (!this.price_per_ton) {
        return true
      }
      // Anbar Check
      if (this.anbar_id === 'new' && this.new_anbar_title.length === 0) {
        return true
      }
      // Check Other Snd
      if(this.validate_other_sanads == false)
        return true

      return false
    },
  },
  methods: {
    ...mapActions([
      'get_site_users',
      'get_factories',
      'get_products',
      'get_cities',
      'get_provinces',
      'get_anbars',
      'get_referreds',
    ]),
    store_card() {
      this.loading_store_card = true
      var data = {
        user_id: this.user_id,
        tonage: this.tonage,
        product_id: this.product_selected.id,
        city_id: this.city_id,
        anbar_id: this.anbar_id,
        price_rent: this.price_rent,
        price_reward: this.price_reward,
        rent_by: this.rent_by,
        clearance_by: this.clearance_by,
        referred_ids: this.referred_ids,
        type_arz: this.type_arz,
        price_arz: this.price_arz,
        price_per_ton_arz: this.price_per_ton_arz,
        price_per_ton: this.price_per_ton,
        new_anbar_title: this.new_anbar_title,
        other_sanads: this.other_sanads,
      }
      // eslint-disable-next-line no-undef
      axios.post('/card/store', data).then(({ data }) => {
        if (data.status === 'success') {
          this.$router.replace('/admin/card/list')
        }
        this.loading_store_card = false
        this.my_alert(data.msg, data.status)
      })
    },
  },
  watch: {
    factory_id() {
      this.product_id = ''
    },
    product_selected(newValue) {
      this.referred_ids = []
      if (newValue) {
        this.factory_id = newValue.factory_id
        this.get_referreds(
          this.gen_query_string({
            product_id: newValue.id,
            minimal: 1,
          }),
        )
      } else {
        this.factory_id = ''
      }
    },
    anbar_id(newValue) {
      if (newValue == 'new') {
        setTimeout(
          function () {
            this.$refs.anbar_input.$refs.input.focus()
          }.bind(this),
          100,
        )
      }
    },
    new_anbar_title(newValue) {
      if (newValue.startsWith('انبار')) {
        this.my_alert(
          'لطفا از کلمه ' +
            '<b>"انبار"</b>' +
            ' در ابتدای نام انبار تان استفاده نکنید',
          'warning',
        )
      }
    },
  },
}
</script>
