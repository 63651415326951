<template>
  <filter-card
    :fiscal_year="true"
    v-model:payment_id="payment_id"
    v-model:amount="amount_row_payment"
    v-model:snd_id="snd_id"
    v-model:bes="bes"
    v-model:bed="bed"
    amount_label="مبلغ پرداخت"
    @refresh="get_payments"
  ></filter-card>
  <div class="d-flex justify-content-between">
    <h1 class="title_card">لیست پرداخت ها</h1>
    <span>
      تعداد :
      {{ paginate_datas.total || 0 }}
    </span>
  </div>
  <div class="table-ay1">
    <table>
      <thead>
        <tr>
          <th>ردیف</th>
          <th>
            ش پرداخت
            <small>(فرامحاسب)</small>
          </th>
          <th>
            شخص بدهکار
            <small>(دریافت کننده مبلغ)</small>
          </th>
          <th>
            جمع مبلغ پرداختی
            <currency :parentheses="true" />
          </th>
          <th>تاریخ پرداخت</th>
          <th>شرح</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <loading-tr v-if="payments_loading" :colspan="7"></loading-tr>
        <empty-tr v-else-if="paginate_datas.data.length == 0" colspan="7" />
        <tr
          v-else
          v-for="(payment, index) in paginate_datas.data"
          :key="payment.id"
        >
          <td>
            <div class="mobile_title">ردیف :</div>
            <row-num-table :paginate_datas="paginate_datas" :index="index" />
          </td>
          <td>
            <div class="mobile_title">ش پرداخت <small>(فرامحاسب)</small> :</div>
            {{ payment.fm_pardakht_id || '-' }}
          </td>
          <td>
            <div class="mobile_title">
              شخص بدهکار<small>(دریافت کننده مبلغ)</small> :
            </div>
            <div>
              <p v-text="payment.bed_name || '-/-'" class="mb-0"></p>
              <small v-text="payment.bed_id"></small>
            </div>
          </td>
          <td>
            <div class="mobile_title">
              جمع مبلغ پرداختی <currency :parentheses="true" /> :
            </div>
            {{ toNumberFormat(get_sum_amount_payment(payment)) }}
          </td>
          <td>
            <div class="mobile_title">تاریخ پرداخت :</div>
            {{ to_jalali(payment.date) }}
          </td>
          <td>
            <div class="mobile_title">تاریخ شرح :</div>
            {{ payment.description || '-' }}
          </td>
          <td>
            <router-link
              :to="{ name: 'ShowPayment', params: { id: payment.id } }"
            >
              <btn-ayandeh
                label="جزئیات"
                class="btn-sm"
                :loading="payments_loading"
              />
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!payments_loading"
    :current_page="page"
    :paginate_data="paginate_datas"
    class="mt-3"
    @go_to="get_payments"
  ></Pagination>
</template>

<script>
import Currency from '@/components/partial/Currency'
import LoadingTr from '@/components/loading/LoadingTr'
import EmptyTr from '@/components/partial/EmptyTr'
import payment_mixin from '@/mixin/Payment'
import Pagination from '@/components/partial/Pagination'
import FilterCard from '@/components/partial/FilterCard'
import { mapActions } from 'vuex'
import RowNumTable from '@/components/partial/RowNumTable'
export default {
  name: 'PaymentList',
  mixins: [payment_mixin],
  components: {
    RowNumTable,
    FilterCard,
    Pagination,
    EmptyTr,
    LoadingTr,
    Currency,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fiscal_year: '',
      payment_id: '',
      amount_row_payment: '',
      snd_id: '',
      bes: '',
      bed: '',
      payments_loading: false,
    }
  },
  methods: {
    ...mapActions(['get_fiscal_years']),
    get_payments: function (page = 1) {
      this.page = page
      this.payments_loading = true

      if (this.$store.state.source_axios) {
        this.$store.state.source_axios.cancel()
      }
      // eslint-disable-next-line no-undef
      this.$store.state.source_axios = axios.CancelToken.source()
      var query = this.gen_query_string({
        page: this.page,
        fiscal_year: this.$store.state.current_fiscal_year,
        payment_id: this.payment_id,
        amount_row_payment: this.amount_row_payment,
        snd_id: this.snd_id,
        bes: this.bes,
        bed: this.bed,
      })
      // eslint-disable-next-line no-undef
      axios
        .get('/payment/get_payments?' + query, {
          cancelToken: this.$store.state.source_axios.token,
        })
        .then((resource) => {
          if (resource != undefined && resource.data.status == 'success') {
            this.paginate_datas = resource.data.payments
            this.payments_loading = false
          }
        })
    },
  },
  mounted() {
    this.get_fiscal_years('payments')

    if (this.$store.state.current_fiscal_year) {
      this.get_payments()
    }
    this.$store.watch(
      () => {
        return this.$store.state.current_fiscal_year
      },
      () => {
        this.get_payments()
      },
      {
        deep: true,
      },
    )
  },
  created() {},
}
</script>
