<template>
  <show-snd-details
    :snd="snd"
    :attachments="attachments"
    :attachments_counterpart="attachments_counterpart"
  >
    <template #btns>
      <a :href="$route.query.list_url">
        <btn-ayandeh color="light" class="me-2">
          <font-awesome-icon icon="fa-arrow-right-long" class="align-middle" />
          بازگشت به لیست
        </btn-ayandeh>
      </a>
      <btn-ayandeh
        v-if="snd.verified"
        class="me-2"
        color="primary"
        @click="manage_sms"
      >
        <font-awesome-icon icon="fa-solid fa-envelope" class="me-1" />
        مدیریت پیامک های سند
      </btn-ayandeh>
      <btn-ayandeh
        v-if="!snd.verified"
        class="me-2"
        color="secondary"
        label="تایید سند"
        @click="show_modal_verify = true"
      />
<!--      <btn-ayandeh-->
<!--        @click="download_snd_pdf_for_admin"-->
<!--        :loading="loading_download"-->
<!--        :disabled="loading_download"-->
<!--      >-->
<!--        <font-awesome-icon icon="fa-solid fa-file-pdf" class="me-1" />-->
<!--        دانلود سند-->
<!--      </btn-ayandeh>-->
    </template>
  </show-snd-details>

  <modal-verify-snd
    ref="verify_snd"
    :fiscal_year_prop="$route.params.fiscal_year"
    v-model:visiable="show_modal_verify"
    :snd="snd"
    @verified="verified"
  />

  <modal-manage-sms
    :snd="snd"
    :fiscal_year_prop="$route.params.fiscal_year"
    v-model:visiable="show_modal_manage_snd"
  />
</template>

<script>
import ShowSndDetails from '@/components/ShowSndDetails'
import ModalVerifySnd from '@/views/sanad/ModalVerifySnd'
import ModalManageSms from '@/views/sanad/ModalManageSms'
export default {
  name: 'SanadDetail',
  data() {
    return {
      loading_get_sanad: false,
      snd: null,
      attachments: [],
      attachments_counterpart: [],
      show_modal_verify: false,
      show_modal_manage_snd: false,
      loading_download: false,
    }
  },
  components: { ModalManageSms, ModalVerifySnd, ShowSndDetails },
  mounted() {
    this.get_snd()
  },
  methods: {
    manage_sms() {
      this.show_modal_manage_snd = true
    },
    verified() {
      this.snd.verified = 1
    },
    get_snd() {
      this.loading_get_sanad = true
      var query = this.gen_query_string({
        fiscal_year: this.$route.params.fiscal_year,
        snd_id: this.$route.params.id,
      })
      // eslint-disable-next-line no-undef
      axios.get('/sanad/get_sanad?' + query).then(({ data }) => {
        if (data.status == 'success') {
          this.snd = data.snd
          this.attachments = data.attachments
          this.attachments_counterpart = data.attachments_counterpart
        }
        this.loading_get_sanad = false
      })
    },
    download_snd_pdf_for_admin() {
      this.loading_download = true
      var query = this.gen_query_string({
        fiscal_year: this.$route.params.fiscal_year,
        snd_id: this.$route.params.id,
      })
      // eslint-disable-next-line no-undef
      axios.get('/sanad/download_snd?' + query).then(({ data }) => {
        this.loading_download = false
        this.toast(data.msg, data.status)
        this.ajax_download(data.path, data.origin_name)
      })
    },
  },
}
</script>
