<template>
  <template v-if="type == 'b'">
    <div class="mb-1 overflow-hidden" v-if="attachments && attachments.length">
      <p class="bold mb-1">
        <font-awesome-icon
          icon="fa-solid fa-link"
          class="text-ayandeh align-middle"
        />
        {{ label }}
      </p>
      <div class="row">
        <div
          class="col-12 col-sm-6 col-md-4 mb-3"
          v-for="(attach, index) in attachments"
          :key="index"
        >
          <div class="attachment-b" :ref="'attach_' + attach.id">
            <div class="attach-details">
              <div class="attach-details-body">
                <div class="d-flex justify-content-between mb-3">
                  <font-awesome-icon
                    icon="fa-solid fa-paperclip"
                    class="text-muted align-middle"
                    size="xl"
                  />
                  <div>
                    <font-awesome-icon
                      v-if="deletable"
                      icon="fa-solid fa-trash-can"
                      class="delete c-pointer"
                      size="lg"
                      @click="delete_attach(attach, index)"
                    />
                  </div>
                </div>
                <div class="d-inline-block text-muted">
                  <span v-text="attach.file_type"></span>
                  -
                  <span
                    v-text="show_size_file(attach.size)"
                    class="ltr d-inline-block fs-12"
                  ></span>
                </div>
                <p class="mb-1">
                  {{ original_name(attach).substring(0, 15) }}
                  {{ original_name(attach).length > 15 ? '...' : '' }}
                </p>
              </div>
              <div class="attach-triangle"></div>
            </div>
            <div
              class="attach-preview have-image"
              :style="'background-image: url(' + attach.download_url + ')'"
              @click="show_image(attach)"
              v-if="
                attach.mime_type.search('image/png') != -1 ||
                attach.mime_type.search('image/jpeg') != -1
              "
            ></div>
            <div class="attach-preview d-flex" @click="download(attach)" v-else>
              <font-awesome-icon
                v-if="attach.mime_type.search(/audio/i) != -1"
                icon="fa-solid fa-music"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="
                  attach.mime_type.search(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  ) != -1
                "
                icon="fa-solid fa-file-excel"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="
                  attach.mime_type.search(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ) != -1
                "
                icon="fa-solid fa-file-word"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="attach.mime_type.search(/csv/i) != -1"
                icon="fa-solid fa-file-csv"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="attach.mime_type.search(/video/i) != -1"
                icon="fa-solid fa-file-video"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="attach.mime_type.search('text/plain') != -1"
                icon="fa-solid fa-file-lines"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else-if="attach.mime_type.search(/zip/i) != -1"
                icon="fa-solid fa-file-zipper"
                size="xl"
                class="m-auto ps-4"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-file-circle-exclamation"
                size="xl"
                class="m-auto ps-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="mb-1 overflow-hidden" v-if="attachments && attachments.length">
      <p class="bold mb-1">
        <font-awesome-icon
          icon="fa-solid fa-paperclip"
          class="text-ayandeh align-middle"
        />
        {{ label }}
      </p>
      <div
        class="attach_file"
        v-for="(attach, index) in attachments"
        :key="index"
        :ref="'attach_' + attach.id"
        data-toggle="tooltip"
        data-placement="top"
        :title="attach.original_name"
      >
        <loading-spinner :show="loading_attach" size="sm" />
        <div class="body_text">
          <p v-text="attach.original_name" class="mb-1"></p>
          <div class="d-inline-block text-muted">
            <span v-text="attach.file_type"></span>
            -
            <span
              v-text="show_size_file(attach.size)"
              class="ltr d-inline-block fs-12"
            ></span>
          </div>
        </div>
        <div class="d-flex">
          <div v-if="deletable">
            <font-awesome-icon
              icon="fa-solid fa-trash-can"
              class="delete"
              @click="delete_attach(attach, index)"
            />
          </div>
          <div class="icon-download">
            <font-awesome-icon
              icon="fa-solid fa-download"
              @click="download(attach)"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Swal from 'sweetalert2'
export default {
  name: 'ShowAttachments',
  components: { FontAwesomeIcon, LoadingSpinner },
  data() {
    return {
      loading_attach: false,
    }
  },
  emits: ['deleted'],
  props: {
    attachments: [Object, Array],
    deletable: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      default: 'فایل های پیوست',
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    original_name(attach) {
      return attach.original_name || '-/-'
    },
    show_size_file(bite) {
      if (bite < 1048576) {
        return Math.round(bite / 1024, 1) + ' KB'
      } else if (bite < 1073741824) {
        return Math.round(bite / 1024 / 1024, 1) + ' MB'
      } else if (bite >= 1073741824) {
        return Math.round(bite / 1024 / 1024 / 1024, 1) + ' GB'
      }
    },
    delete_attach(attach, index) {
      // eslint-disable-next-line no-undef
      Swal.fire({
        confirmButtonText: 'حذف',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'حذف پیوست',
        html:
          'آیا میخواهید فایل ' + '<br>' + attach.original_name + '<br>حذف شود؟',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_attach = attach.id
          // eslint-disable-next-line no-undef
          axios.get('/delete_attachment/' + attach.id).then(({ data }) => {
            this.toast(data.msg)
            this.$emit('deleted', attach, index)
            this.$refs['attach_' + attach.id][0].classList.add('deleted')
            this.loading_attach = false
          })
        }
      })
    },
    download(attach) {
      this.ajax_download(attach.download_url, attach.original_name)
    },
    show_image(attach) {
      var text =
        '<div><p class="mb-1">' +
        attach.original_name +
        '</p><p class="text-muted">' +
        attach.file_type +
        ' - ' +
        this.show_size_file(attach.size) +
        '</p></div>'
      Swal.fire({
        html: text,
        showDenyButton: true,
        confirmButtonText: 'دانلود فایل',
        denyButtonText: 'بازگشت',
        confirmButtonColor: '#0a3450',
        denyButtonColor: '#ccc',
        imageUrl: attach.download_url,
        imageAlt: attach.original_name,
      }).then((result) => {
        if (result.isConfirmed) {
          this.download(attach)
        }
      })
    },
  },
}
</script>

<style scoped></style>
