import AuthMaster from '@/layouts/AuthMaster'
import Login from '@/views/pages/Login'

export default {
  path: '/auth',
  redirect: '/auth/login',
  component: AuthMaster,
  children: [
    {
      path: 'login',
      name: 'AdminLogin',
      props: {
        label: 'ورود',
      },
      component: Login,
    },
  ],
}
