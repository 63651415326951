<template>
  <div>
    <p>داشبورد</p>
  </div>
</template>
<script>
export default {
  name: 'Dashboard',
  mounted() {
    console.log(this.toNumberFormat(1300000))
  }
}
</script>
