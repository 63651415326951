<template>
  <div>
    <template v-if="payment_able">
      <div class="card mb-4">
        <div class="card-body p-0">
          <div class="row g-0">
            <div
              class="col-12 col-md-4 border-end border-style-left-dash p-3 invoice-style d-grid"
            >
              <div class="align-self-sm-start">
                <div class="d-flex justify-content-between mb-2">
                  <span>وضعیت</span>
                  <span v-if="payment_able.cancel" class="text-end">
                    <span class="text-danger">لغو شده</span>
                    <small class="text-muted fs-10 d-block">
                      وضعیت قبلی :
                      {{ payment_able.status_title }}
                    </small>
                  </span>
                  <span
                    v-else
                    :class="{
                      'text-info': !payment_able.payed,
                      'text-success': payment_able.payed,
                      'text-danger': payment_able.cancel,
                    }"
                    v-text="payment_able.status_title"
                  ></span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <span>
                    <font-awesome-icon
                      icon="fa-solid fa-key"
                      class="text-icon-light"
                    />
                    شناسه سند پرداختی
                  </span>
                  <span>{{ payment_able.id }}</span>
                </div>
                <div
                  class="d-flex justify-content-between mb-2"
                  v-if="payment_able.order_id"
                >
                  <span>
                    <font-awesome-icon
                      icon="fa-solid fa-user"
                      class="text-icon-light"
                    />
                    سفارش
                  </span>
                  <span>
                    <span>
                      {{
                        payment_able.order.order_id +
                        ' - ' +
                        get_fullname(payment_able.order.user)
                      }}
                    </span>
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2" v-if="payment_able.order_id">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-keyboard"
                      class="text-icon-light"
                    />شماره بارنامه
                  </div>
                  <span v-text="payment_able.order.number_bar"></span>
                </div>
                <div class="d-flex justify-content-between mb-2" v-if="payment_able.order_id">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-truck"
                      class="text-icon-light"
                    />پلاک
                  </div>
                  <span v-text="payment_able.order.car.plaque"></span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calendar-plus"
                      class="text-primary"
                    />
                    تاریخ ایجاد
                  </div>
                  <span class="date">
                    {{ to_jalali(payment_able.created_at, 'long') }}
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2" v-if="payment_able.order_id">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calendar-plus"
                      class="text-primary"
                    />
                    تاریخ بارگیری
                  </div>
                  <span class="date">
                    {{ to_jalali(payment_able.order.date_loaded) }}
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2" v-if="payment_able.order_id">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calendar-plus"
                      class="text-primary"
                    />
                    تاریخ تخلیه
                  </div>
                  <span class="date">
                    {{ to_jalali(payment_able.order.date_disCharge) }}
                  </span>
                </div>
                <div
                  class="d-flex justify-content-between mb-2"
                  v-if="payment_able.date_pay"
                >
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calendar-check"
                      class="text-primary"
                    />
                    تاریخ پرداخت
                  </div>
                  <span class="date">
                    {{ to_jalali(payment_able.date_pay, 'long') }}
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <span>
                    <font-awesome-icon
                      icon="fa-solid fa-coins"
                      class="text-gold"
                    />
                    {{ payment_able.order_id ? 'مبلغ کرایه' : 'مبلغ پرداختنی' }}
                  </span>
                  <span>
                    <span
                      class="me-1"
                      v-text="toNumberFormat(payment_able.amount)"
                    ></span>
                    <currency />
                  </span>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calculator"
                      class="text-icon-light"
                    />
                    جمع اسناد در انتظار پرداخت
                    <font-awesome-icon
                      v-if="payment_able.payed"
                      icon="fa-solid fa-clock-rotate-left"
                      class="text-warning"
                      size="sm"
                    />
                  </div>
                  <loading-spinner
                    v-if="sum_payment_able_amount_loading"
                    :show="true"
                    size="sm"
                  />
                  <div v-else>
                    <font-awesome-icon
                      v-if="!payment_able.payed"
                      @click="get_all_paymentables"
                      icon="fa-solid fa-circle-exclamation"
                      class="text-muted c-pointer"
                      :beat="true"
                    />
                    {{
                      toNumberFormat(
                        payment_able.payed
                          ? payment_able.current_sum_amount_creditorable
                            ? payment_able.current_sum_amount_creditorable
                            : '-'
                          : sum_payment_able_amount,
                      )
                    }}
                    <currency />
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-calculator"
                      class="text-icon-light"
                    />
                    مانده فرامحاسب
                    <font-awesome-icon
                      v-if="payment_able.payed"
                      icon="fa-solid fa-clock-rotate-left"
                      class="text-warning"
                      size="sm"
                    />
                  </div>
                  <show-mandeh
                    v-if="payment_able.payed"
                    :value="
                      payment_able.current_fm_mandeh != null
                        ? payment_able.current_fm_mandeh
                        : '-'
                    "
                  />
                  <show-mandeh
                    v-else-if="
                      codes_mandeh[payment_able.creditorable.faramohaseb_id] !=
                      undefined
                    "
                    :value="
                      codes_mandeh[payment_able.creditorable.faramohaseb_id]
                    "
                  ></show-mandeh>
                  <loading-spinner v-else :show="true" size="sm" />
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <span>
                    <font-awesome-icon
                      icon="fa-solid fa-calculator"
                      class="text-icon-light"
                    />
                    مانده نهایی
                    <font-awesome-icon
                      v-if="payment_able.payed"
                      icon="fa-solid fa-clock-rotate-left"
                      class="text-warning"
                      size="sm"
                    />
                  </span>
                  <span>
                    <show-mandeh
                      v-if="payment_able.payed"
                      :value="
                        payment_able.current_total_mandeh != null
                          ? payment_able.current_total_mandeh
                          : '-'
                      "
                    />
                    <loading-spinner
                      v-else-if="loading_mandeh"
                      :show="true"
                      size="sm"
                    />
                    <show-mandeh v-else :value="total_mandeh" />
                  </span>
                </div>
                <div
                  class="d-flex justify-content-between mb-2 btn-ghost-success"
                >
                  <span>
                    <font-awesome-icon
                      icon="fa-solid fa-receipt"
                      class="text-icon-light"
                    />
                    جمع مبالغ پرداخت شده
                  </span>
                  <span>
                    {{ toNumberFormat(sum_amount_payed) }}
                    <currency />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8 p-3">
              <div class="d-flex justify-content-between titr_line_dott">
                <span>بابت</span>
                <span class="fs-13">
                  {{ payment_able.pay_for_title }}
                  <template v-if="payment_able.order_id">
                    <span class="px-1">
                      {{ payment_able.order.ton_loaded }}
                    </span>
                    تن بار
                  </template>
                  <template v-if="payment_able.description2">{{ payment_able.description2 }}</template>
                </span>
              </div>
              <div
                class="d-flex justify-content-between titr_line_dott"
                v-if="payment_able.order_id && payment_able.order.ton_shortage"
              >
                <span>کسری / خراب</span>
                <span class="fs-13">
                  تعداد
                  {{ payment_able.order.ton_shortage * 20 }}
                  کیسه به مبلغ
                  {{
                    toNumberFormat(
                      (get_price_per_ton_rial(payment_able.order) +
                        payment_able.order.price_rent +
                        payment_able.order.price_reward || 0) *
                        payment_able.order.ton_shortage,
                    )
                  }}
                  <currency />
                </span>
              </div>
              <div
                class="d-flex justify-content-between titr_line_dott"
                v-if="payment_able.other_sanad"
              >
                <span>شرح سند اضافی</span>
                <div class="fs-13" v-text="payment_able.other_sanad.description"></div>
              </div>
              <div
                class="d-flex justify-content-between titr_line_dott"
                v-if="payment_able.order_id"
              >
                <span>شرح مالی</span>
                <div class="fs-13" v-if="payment_able.pay_for === 'order_to_payment' && payment_able.other_sanad">
                  مبلغ
                  <span v-if="payment_able.other_sanad.calc_amount_type === 'by_tonage'"
                        v-text="toNumberFormat(Math.round(payment_able.other_sanad.amount * payment_able.order.ton_loaded))">
                  </span>
                  <span v-else
                        v-text="toNumberFormat(payment_able.other_sanad.amount)">
                  </span>
                  {{ payment_able.pay_for_title }}
                  ({{ payment_able.other_sanad.title_type }})
                  <span v-if="payment_able.other_sanad.calc_amount_type === 'by_tonage'">
                    برای هر تن
                    {{ toNumberFormat(payment_able.other_sanad.amount) }}
                    <currency />
                  </span>
                </div>
                <div v-else class="fs-13">
                  مبلغ
                  {{ payment_able.pay_for_title }}
                  برای هر تن
                  <span v-if="payment_able.pay_for === 'rent'">
                    {{ toNumberFormat(payment_able.order.price_rent) }}
                  </span>
                  <span v-else-if="payment_able.pay_for === 'reward'">
                    {{ toNumberFormat(payment_able.order.price_reward) }}
                  </span>
                  <span v-else-if="payment_able.pay_for === 'rent_and_reward'">
                    {{
                      toNumberFormat(
                        payment_able.order.price_rent +
                          payment_able.order.price_reward,
                      )
                    }}
                  </span>
                  <currency class="ms-1" />
                </div>
              </div>
              <div v-if="payment_able.description">
                <span class="text-muted">توضیحات : </span>
                {{ payment_able.description }}
              </div>
              <div class="row mt-4">
                <div class="col-12 col-md-6 border-end">
                  <p class="fw-bold text-center text-success">اطلاعات بستانکار</p>
                  <license-plate
                    v-if="payment_able.creditorable.car_number_base !== undefined"
                    :car="payment_able.creditorable"
                    size="sm"
                    class="mx-auto"
                  />
                  <div class="d-flex justify-content-between mb-2">
                    <span>نام بستانکار</span>
                    <div v-if="payment_able.creditorable.car_number_base !== undefined"
                         v-text="payment_able.creditorable.plaque"
                    >
                    </div>
                    <div
                      v-if="payment_able.creditorable.first_name !== undefined"
                      v-text="get_fullname(payment_able.creditorable)">
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>کد حسابداری بستانکار</span>
                    <span class="date"
                          v-text="payment_able.creditorable.faramohaseb_id">
                    </span>
                  </div>
                  <div class="d-flex justify-content-between mb-2">
                    <span>موبایل بستانکار</span>
                    <span class="date"
                          v-text="payment_able.creditorable.mobile ?? '-/-'">
                    </span>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6"
                >
                  <show-credit-card
                    v-if="payment_able.bank_information_id"
                    :account_bank="payment_able.bank_information"
                  />
                  <div class="text-center py-3" v-else>
                    <p class="text-info">
                      برای این سند پرداختی اطلاعات بانکی جهت واریز وجه انجام
                      نشده، لطفا اقدامات لازم جهت تعیین حساب بانکی را انجام
                      دهید.
                    </p>
                    <ayandeh-button color="light"> تعیین بانک </ayandeh-button>
                  </div>
                </div>
                <div class="col-12 border-top pt-4 pb-3">
                  <div class="text-center pb-4" v-if="payment_able.payed == 0">
                    <p class="text-muted mb-2">مبلغ قابل پرداخت</p>
                    <h3 class="fs-17 fw-bold d-inline-block text-danger me-2">
                      {{ toNumberFormat(amount_pay) }}
                    </h3>
                    <currency />
                  </div>
                  <div>
                    <template v-if="loading_mandeh == false && payment_able.cancel == 0">
                      <router-link
                        :to="{
                        name: 'NewPayment',
                        query: new_payment_queries_link,
                      }"
                      >
                        <btn-ayandeh>پرداخت کردن</btn-ayandeh>
                      </router-link>
                      <btn-ayandeh
                        color="success"
                        class="ms-2"
                        @click="change_to_payed"
                        :loading="change_to_payed_loading"
                        :disabled="change_to_payed_loading"
                        label="تغییر به حالت پرداخت شده"
                        v-if="amount_pay == 0 && payment_able.payed == 0"
                      />
                      <btn-ayandeh
                        color="danger"
                        class="ms-2"
                        @click="cancel_payment_able"
                        :loading="cancel_loading"
                        :disabled="cancel_loading"
                        label="لغو کردن"
                        v-if="payment_able.payed == 0"
                      />
                      <btn-ayandeh
                        color="primary"
                        class="ms-2"
                        @click="open_modal_send_sms_again"
                        :loading="send_sms_loading"
                        :disabled="send_sms_loading"
                        label="ارسال مجدد پیامک پرداختی"
                        v-if="
                        payment_able.payed == 0 || payment_able.payments.length
                      "
                      />
                    </template>
                    <btn-ayandeh
                      color="secondary"
                      class="ms-2"
                      @click="undo_cancel_payment_able"
                      :loading="undo_cancel_loading"
                      :disabled="undo_cancel_loading"
                      label="خروج از وضعیت لغو شده"
                      v-if="payment_able.cancel === 1 && payment_able.payed === 0"
                    />
                    <btn-ayandeh
                      color="success"
                      class="ms-2"
                      @click="handy_change_to_payed"
                      :loading="handy_payed_loading"
                      :disabled="handy_payed_loading"
                      label="پرداخت دستی"
                      v-if="amount_pay != 0 && payment_able.payed == 0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="payment_able.payments.length" class="mb-5">
        <p>پرداخت های مرتبط</p>
        <div class="row">
          <template v-for="payment in payment_able.payments" :key="payment.id">
            <div
              class="col-12 col-sm-6 col-md-3"
              v-for="det in payment.details"
              :key="det.id"
            >
              <show-payment-small :payment="payment" :payment-det="det" />
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="text-center pt-5" v-else>
      <span>درحال خواندن اطلاعات</span>
      <dots-loading />
    </div>
    <!--  Show Detail Other Payment Able-->
    <CModal
      :visible="visiable_modal_detail"
      @close="
        () => {
          visiable_modal_detail = false
        }
      "
    >
      <CModalHeader
        dismiss
        @close="
          () => {
            visiable_modal_detail = false
          }
        "
      >
        <CModalTitle>کلیه سند های در انتظار پرداخت شخص</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <template v-if="get_all_paymentAbles_loading">
          <div
            class="row border-bottom pb-2 mb-4"
            v-for="loading in 4"
            :key="loading"
          >
            <div class="col-6 text-start">
              <shine-loading size="sm" width="25"></shine-loading>
            </div>
            <div class="col-6 text-end">
              <shine-loading size="sm" width="25"></shine-loading>
            </div>
            <div class="col-12">
              <shine-loading size="lg" width="75"></shine-loading>
            </div>
          </div>
        </template>
        <div v-else class="list-group list-group-flush">
          <div
            class="list-group-item"
            v-for="payment_able in all_paymentables"
            :key="payment_able.id"
          >
            <div class="d-flex justify-content-between">
              <span class="text-muted">مبلغ سند پرداختنی</span>
              <div>
                <span class="text-success fs-17 fw-bold me-1">
                  {{ toNumberFormat(payment_able.amount) }}
                </span>
                <currency />
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-muted">جمع مبالغ پرداخت شده این سند</span>
              <div>
                <span class="me-1">
                  {{ toNumberFormat(calc_sum_amount_payed(payment_able)) }}
                </span>
                <currency />
              </div>
            </div>
            <div class="mt-1">
              <span class="text-muted">توضیحات : </span>
              بابت
              {{ payment_able.pay_for_title }}
              <template v-if="payment_able.order_id">
                سفارش شماره
                {{ payment_able.order.order_id }}
              </template>
            </div>
            <div class="text-end d-flex justify-content-between mt-2">
              <p class="date text-muted m-0">
                {{ to_jalali(payment_able.created_at, 'long') }}
              </p>
              <router-link
                :to="{
                  name: 'PaymentAbleShow',
                  params: { id: payment_able.id },
                }"
              >
                <ayandeh-button color="outline-secondary" class="btn-sm">
                  نمایش
                </ayandeh-button>
              </router-link>
            </div>
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <ayandeh-button :color="info" @click="visiable_modal_detail = false"
          >بستن</ayandeh-button
        >
      </CModalFooter>
    </CModal>


    <CModal :visible="visible_modal_send_again" @close="close_modal_send_sms_again">
      <CModalHeader @close="close_modal_send_sms_again">
        <CModalTitle class="fs-14">
          ارسال مجدد پیامک پرداخت
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>در صورتی که میخواهید پیامک به شماره تلفن های دیگری هم ارسال شود لطفا شماره موبایل های مورد نظر را در زیر وارد کنید.</p>
        <div class="mb-3">
          <p class="mb-1 text-info">
            موبایل های دیگری که میخواهید پیامک به آنها هم ارسال شود را وارد کنید
          </p>
          <multiple-input
            type="tel"
            label="موبایل"
            v-model:value="mobiles"
          ></multiple-input>
        </div>
      </CModalBody>
      <CModalFooter>
        <btn-ayandeh
          @click="close_modal_send_sms_again"
          color="light"
          class="me-2"
          label="انصراف"
        />
        <btn-ayandeh
          @click="send_sms_again"
          :loading="send_sms_loading"
          :disabled="send_sms_loading"
        >
          ارسال پیامک
          <font-awesome-icon icon="fa-download" class="align-middle ms-1" />
        </btn-ayandeh>
      </CModalFooter>
    </CModal>

  </div>
</template>

<script>
import Currency from '@/components/partial/Currency'
import LicensePlate from '@/components/partial/LicensePlate'
import ShowCreditCard from '@/components/partial/ShowCreditCard'
import ShowMandeh from '@/components/partial/ShowMandeh'
import ShowPaymentSmall from '@/components/payment/ShowPaymentSmall'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ShineLoading from '@/components/loading/ShineLoading'
import AyandehButton from '@/components/partial/AyandehButton'
import Swal from 'sweetalert2'
import DotsLoading from '@/components/loading/DotsLoading'
import MultipleInput from "@/components/partial/MultipleInput.vue";
import InputAyandeh from "@/components/inputs/InputAyandeh.vue";

export default {
  name: 'PaymentAbleShow',
  components: {
    InputAyandeh, MultipleInput,
    DotsLoading,
    AyandehButton,
    ShineLoading,
    FontAwesomeIcon,
    ShowPaymentSmall,
    ShowMandeh,
    ShowCreditCard,
    LicensePlate,
    Currency,
  },
  data() {
    return {
      payment_able: null,
      sum_payment_able_amount: 0,
      sum_payment_able_amount_loading: false,
      visiable_modal_detail: false,
      all_paymentables: [],
      get_all_paymentAbles_loading: false,
      change_to_payed_loading: false,
      cancel_loading: false,
      undo_cancel_loading: false,
      send_sms_loading: false,
      handy_payed_loading: false,
      visible_modal_send_again: false,
      mobiles: {
        0: '',
      },
    }
  },
  mounted() {
    this.get_payment_able()
  },
  methods: {
    get_payment_able() {
      var data = this.gen_query_string({
        id: this.$route.params.id,
      })
      this.loading = true
      // eslint-disable-next-line no-undef
      axios.get('/paymentable/get?' + data).then((resource) => {
        if (resource.data.status === 'success') {
          this.payment_able = resource.data.payment_able
          this.loading = false
          this.get_sum_creditorable_amount()
        }
      })
    },
    get_sum_creditorable_amount() {
      this.sum_payment_able_amount_loading = true
      // eslint-disable-next-line no-undef
      axios
        .get('/paymentable/get_sum_creditorable_amount/' + this.payment_able.id)
        .then((resource) => {
          if (resource.data.status == 'success') {
            this.sum_payment_able_amount = resource.data.sum_amount
            this.sum_payment_able_amount_loading = false
          }
        })
    },
    get_all_paymentables() {
      this.visiable_modal_detail = true
      if (this.all_paymentables.length == 0) {
        this.get_all_paymentAbles_loading = true
        var query = this.gen_query_string({
          creditorable_type: this.payment_able.creditorable_type,
          creditorable_id: this.payment_able.creditorable_id,
          payed: 0,
        })
        // eslint-disable-next-line no-undef
        axios.get('/paymentable/get?' + query).then(({ data }) => {
          if (data.status == 'success') {
            this.all_paymentables = data.payment_ables.data
          }
          this.get_all_paymentAbles_loading = false
        })
      }
    },
    change_to_payed() {
      this.change_to_payed_loading = true
      Swal.fire({
        icon: 'question',
        title: 'تغییر وضعیت',
        confirmButtonText: 'بله، انجام شود',
        showCancelButton: true,
        cancelButtonText: 'خیر',
        html: "آیا میخواهید این سند پرداختنی به وضعیت <span class='text-success'>پرداخت شده</span> تغییر کند؟",
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-undef
          axios
            .get('/paymentable/change_to_payed/' + this.payment_able.id)
            .then(({ data }) => {
              this.my_alert(data.msg, data.status)
              this.payment_able = data.payment_able
              this.change_to_payed_loading = false
            })
        }
      })
    },
    handy_change_to_payed() {
      Swal.fire({
        icon: 'question',
        title: 'پرداخت دستی',
        confirmButtonText: 'بله، انجام شود',
        showCancelButton: true,
        cancelButtonText: 'خیر',
        html: "آیا میخواهید این سند پرداختنی به صورت دستی به وضعیت <span class='text-success'>پرداخت شده</span> تغییر کند؟",
        input: "textarea",
        inputPlaceholder: "توضیحات (اجباری)",
        inputValidator: (value) => {
          if (!value) {
            return "لطفا فیلد توضیحات را پر کنید";
          }
        },
        preConfirm: (description) => {
          this.handy_payed_loading = true
          // eslint-disable-next-line no-undef
          axios
            .post('/paymentable/handy_change_to_payed/' + this.payment_able.id , {
              description: `${description}`,
            })
            .then(({ data }) => {
              this.my_alert(data.msg, data.status)
              this.payment_able = data.payment_able
              this.handy_payed_loading = false
            })
            .catch((e) => {
              this.handy_payed_loading = false
            })
        }
      })
    },
    cancel_payment_able() {
      Swal.fire({
        icon: 'error',
        title: 'لغو پرداختنی',
        showCancelButton: true,
        cancelButtonText: 'خیر',
        html: "آیا از <span class='text-danger'>لغو</span> این سند پرداختنی اطمینان دارید؟",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancel_loading = true
          // eslint-disable-next-line no-undef
          axios
            .get('/paymentable/cancel/' + this.payment_able.id)
            .then(({ data }) => {
              this.toast(data.msg, data.status)
              this.payment_able = data.payment_able
              this.cancel_loading = false
            })
            .catch(() => {
              this.cancel_loading = false
            })
        }
      })
    },
    undo_cancel_payment_able() {
      Swal.fire({
        icon: 'success',
        title: 'فعال کردن',
        confirmButtonText: 'بله',
        showCancelButton: true,
        cancelButtonText: 'خیر',
        html: 'آیا میخواهید این سند پرداختنی را از وضعیت لغو شده خارج کنید؟',
      }).then((result) => {
        if (result.isConfirmed) {
          this.undo_cancel_loading = true
          // eslint-disable-next-line no-undef
          axios
            .get('/paymentable/undo_cancel/' + this.payment_able.id)
            .then(({ data }) => {
              this.toast(data.msg, data.status)
              this.payment_able = data.payment_able
              this.undo_cancel_loading = false
            })
            .catch(() => {
              this.undo_cancel_loading = false
            })
        }
      })
    },
    open_modal_send_sms_again() {
      this.visible_modal_send_again = true
    },
    close_modal_send_sms_again() {
      this.visible_modal_send_again = false
      this.mobiles = {
        0: '',
      }
    },
    send_sms_again() {
      this.send_sms_loading = true
      // eslint-disable-next-line no-undef
      axios
        .post('/paymentable/send_again_sms_payed/' + this.payment_able.id, {
          mobiles: this.mobiles
        })
        .then(({ data }) => {
          this.my_alert(data.msg, data.status)
          this.send_sms_loading = false
          this.visible_modal_send_again = false
        })
        .catch(() => {
          this.send_sms_loading = false
        })
    },
  },
  computed: {
    new_payment_queries_link() {
      return {
        bed_id: this.payment_able.creditorable.faramohaseb_id,
        payment_able_id: this.payment_able.id,
        amount: this.amount_pay,
        bes_id: 105010058,
        type_payment_selected: 5,
        type_pay_selected: 'naghdi',
        redirect: this.$route.fullPath,
      }
    },
    total_mandeh() {
      return (
        this.sum_payment_able_amount +
        (this.codes_mandeh[this.payment_able.creditorable.faramohaseb_id] || 0)
      )
    },
    sum_amount_payed() {
      return this.calc_sum_amount_payed(this.payment_able)
    },
    amount_pay() {
      var amount = 0
      var calc1 = this.payment_able.amount - this.sum_amount_payed
      if (this.total_mandeh > 0) {
        amount = calc1 - this.total_mandeh
      } else {
        amount = calc1
      }
      if (amount <= 0) {
        return 0
      } else {
        return amount
      }
    },
  },
  watch: {
    payment_able(newValue) {
      this.loading_mandeh = true
      this.get_code_mandeh(newValue.creditorable.faramohaseb_id)
    },
  },
}
</script>

<style scoped></style>
