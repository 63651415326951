<template>
  <filter-card
    v-model:plate="plate"
    :updated_plate="updated_plate"
    v-model:number_bar="number_bar"
    v-model:order_id="order_id"
    v-model:factory_id="factory"
    @refresh="get_payment_ables"
  >
    <template v-slot:static>
      <div class="col-12 col-sm-6 col-md-3" v-if="options_pay_for.length">
        <div class="form-group">
          <label class="mb-2">فیلتر نوع سند</label>
          <v-select
            v-model="filter_pay_for"
            :options="options_pay_for"
            :reduce="(option) => option.value"
            placeholder="انتخاب نوع سند"
            dir="rtl"
          />
        </div>
      </div>
    </template>
  </filter-card>
  <div class="d-flex justify-content-between">
    <h1 class="title_card">پرداختنی ها</h1>
    <span>
      تعداد :
      {{ payment_ables.total || 0 }}
    </span>
  </div>
  <div class="table-ay1">
    <table>
      <thead>
        <tr>
          <th>شناسه</th>
          <th>
            مبلغ
            <currency :parentheses="true" />
          </th>
          <th>بابت</th>
          <th v-if="has_order">شماره سفارش</th>
          <th v-if="has_order">تاریخ بارگیری</th>
          <th>وضعیت</th>
          <th>بستانکار</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr v-for="x in 6" :key="x">
            <td v-for="i in td_count" :key="i">
              <shine-loading />
            </td>
          </tr>
        </template>
        <tr v-else-if="payment_ables.data.length == 0">
          <td :colspan="td_count" class="text-center py-4">
            هیچ پرداختنی برای نمایش وجود ندارد!
          </td>
        </tr>
        <tr v-else v-for="item in payment_ables.data" :key="item.id">
          <td>
            <div class="mobile_title">شناسه پرداخت داخلی :</div>
            {{ item.id }}
          </td>
          <td>
            <div class="mobile_title">مبلغ :</div>
            {{ toNumberFormat(item.amount) }}
            <currency class="d-inline-block d-md-none" />
          </td>
          <td>
            <div class="mobile_title">بابت :</div>
            {{ item.pay_for_title }}
          </td>
          <td v-if="has_order">
            <div class="mobile_title">شماره سفارش :</div>
            {{ item.order_id ? item.order.order_id : '-/-' }}
          </td>
          <td v-if="has_order">
            <div class="mobile_title">تاریخ بارگیری :</div>
            {{ item.order_id ? to_jalali(item.order.date_loaded) : '-/-' }}
          </td>
          <td>
            <div class="mobile_title">وضعیت :</div>
            {{ item.status_title }}
          </td>
          <td>
            <div class="mobile_title">بستانکار :</div>
            <license-plate v-if="item.creditorable.car_number_base != undefined" :car="item.creditorable" size="sm" />
            <div
              v-else-if="item.creditorable.first_name !== undefined"
              v-text="get_fullname(item.creditorable)">
            </div>
          </td>
          <td>
            <router-link
              :to="{ name: 'PaymentAbleShow', params: { id: item.id } }"
            >
              <btn-ayandeh label="جزئیات" class="btn-sm" :loading="loading" />
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!loading"
    :current_page="page"
    :paginate_data="payment_ables"
    class="mt-3"
    @go_to="get_payment_ables"
  ></Pagination>
</template>

<script>
import LicensePlate from '@/components/partial/LicensePlate'
import Pagination from '@/components/partial/Pagination'
import Currency from '@/components/partial/Currency'
import FilterCard from '@/components/partial/FilterCard'
import { mapActions } from 'vuex'

export default {
  name: 'PaymentAbleList',
  components: { FilterCard, Currency, Pagination, LicensePlate },
  data: () => {
    return {
      loading: false,
      payment_ables: {
        data: [],
      },
      plate: {},
      order_id: '',
      number_bar: '',
      factory: '',
      source: null,
      options_pay_for: [],
      filter_pay_for: '',
      updated_plate: 1,
    }
  },
  mounted() {
    this.status = 2
    this.set_url_query_to_data()
    this.get_payment_ables(this.page)
    this.get_factories()
    this.get_options_pay_for()
  },
  methods: {
    ...mapActions(['get_factories']),
    get_payment_ables(page = 1) {
      if (this.source) {
        this.source.cancel()
      }
      // eslint-disable-next-line no-undef
      this.source = axios.CancelToken.source()
      this.page = page
      this.loading = true
      var data = {
        status: this.$route.query.status,
        page: this.page,
        number_bar: this.number_bar,
        factory: this.factory || '',
        pay_for: this.filter_pay_for || '',
        order_id: this.order_id || '',
      }
      if (Object.keys(this.plate).length) {
        Object.assign(data, this.plate)
      }

      // eslint-disable-next-line no-undef
      axios
        .get('/paymentable/get?' + this.gen_query_string(data), {
          cancelToken: this.source.token,
        })
        .then((response) => {
          if (response != undefined && response.data.status == 'success') {
            this.payment_ables = response.data.payment_ables
            this.loading = false
          }
        })
    },
    get_options_pay_for() {
      // eslint-disable-next-line no-undef
      axios.get('/paymentable/get_options_pay_for').then((response) => {
        if (response != undefined && response.data.status == 'success') {
          this.options_pay_for = response.data.options
        }
      })
    },
  },
  computed: {
    has_order() {
      return this.payment_ables.data.filter((x) => x.order_id != null).length
    },
    td_count() {
      var count = 6
      if (this.has_order) {
        count += 3
      }
      return count
    },
  },
  watch: {
    factory() {
      this.get_payment_ables()
    },
    filter_pay_for() {
      this.get_payment_ables()
    },
  },
}
</script>
<style scoped></style>
