<template>
  <CModal
    :fullscreen="full_screen_modal_order_detail"
    size="xl"
    backdrop="static"
    :visible="visiable_var"
    @close="closed"
  >
    <CModalHeader
      dismiss
      @close="closed"
      class="d-flex justify-content-between"
    >
      <CModalTitle class="fs-14">
        جزئیات سفارش شماره
        {{ order ? order.order_id : '' }}
      </CModalTitle>
      <div>
        <font-awesome-icon
          icon="fa-solid fa-compress"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_order_detail = false"
          v-if="full_screen_modal_order_detail"
        />
        <font-awesome-icon
          icon="fa-solid fa-expand"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_order_detail = true"
          v-else
        />
      </div>
    </CModalHeader>
    <CModalBody class="p-0">
      <div
        style="height: 300px"
        class="text-center d-flex"
        v-if="loading_get_order"
      >
        <div class="m-auto">
          درحال خواندن اطلاعات
          <dots-loading />
        </div>
      </div>
      <template v-else-if="order">
        <div class="row m-0">
          <div class="col-12 col-md-3 border-end py-3">
            <div class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                class="me-1 text-muted"
                size="lg"
              />
              {{ get_fullname(order.card_export.user) }}
            </div>
            <div class="mb-3">
              وضعیت سفارش :
              <b
                v-text="order.status_title"
                :class="{
                  'text-danger': order.status == -1,
                  'text-info': order.status == 1,
                  'text-success': order.status > 1,
                }"
              ></b>
            </div>
            <template v-if="order.status == -1">
              <div>
                <div class="separate_dash_gray w-75"></div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="text-muted">تاریخ لغو</span>
                <span dir="ltr">
                  {{
                    order.cancel_date
                      ? to_jalali(order.cancel_date, 'long')
                      : '-/-'
                  }}
                </span>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span class="text-muted">لغو شده توسط</span>
                {{ order.cancel_by ? get_fullname(order.canceler) : '-/-' }}
              </div>
            </template>
            <div>
              <div class="separate_dash_gray w-75"></div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">شماره کارت</span>
              {{ order.card_export_id }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">کارخانه</span>
              {{ order.card_export.product.factory.name }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">نام کالا</span>
              {{ order.card_export.product.title }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">سال مالی</span>
              <span dir="ltr">{{ order.fiscal_year || '-/-' }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">ش فاکتور</span>
              <span dir="ltr">{{ order.fm_factor_id || '-/-' }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">ش سند فاکتور</span>
              <router-link
                v-if="order.fiscal_year"
                target="_blank"
                v-c-tooltip="{
                  content: 'نمایش جزئیات سند',
                  placement: 'right',
                  color: 'white',
                }"
                :to="{
                  name: 'SanadDetail',
                  params: {
                    fiscal_year: order.fiscal_year,
                    id: order.fm_sanad_id,
                  },
                }"
              >
                <font-awesome-icon
                  icon="angle-right"
                  class="ms-1"
                  size="xs"
                  :beat="true"
                />
                {{ order.fm_sanad_id }}
              </router-link>
              <span v-else>-/-</span>
            </div>
            <div
              class="d-flex justify-content-between mb-2"
              v-if="order.shortage_sanad_id"
            >
              <span class="text-muted">ش سند کسری</span>
              <router-link
                v-if="order.fiscal_year"
                v-c-tooltip="{
                  content: 'نمایش جزئیات سند',
                  placement: 'right',
                  color: 'white',
                }"
                target="_blank"
                :to="{
                  name: 'SanadDetail',
                  params: {
                    fiscal_year: order.fiscal_year,
                    id: order.shortage_sanad_id,
                  },
                }"
              >
                <font-awesome-icon
                  icon="angle-right"
                  class="ms-1"
                  size="xs"
                  :beat="true"
                />
                {{ order.shortage_sanad_id }}
              </router-link>
            </div>
            <div
              class="d-flex justify-content-between mb-2"
              v-if="order.other_sanad_id"
            >
              <span class="text-muted">ش اسناد دیگر</span>
              <router-link
                v-if="order.fiscal_year"
                v-c-tooltip="{
                  content: 'نمایش جزئیات سند',
                  placement: 'right',
                  color: 'white',
                }"
                target="_blank"
                :to="{
                  name: 'SanadDetail',
                  params: {
                    fiscal_year: order.fiscal_year,
                    id: order.other_sanad_id,
                  },
                }"
              >
                <font-awesome-icon
                  icon="angle-right"
                  class="ms-1"
                  size="xs"
                  :beat="true"
                />
                {{ order.other_sanad_id }}
              </router-link>
              <span v-else>-/-</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">اسناد اضافه</span>
              <p class="mb-0 px-1 c-pointer fw-bold text-ayandeh"
                 @click="show_modal_other_sanads">
                {{ order.other_sanads_count }}
              </p>
            </div>
            <div class="alert alert-secondary py-2" v-if="order.referreds.length">
              <p class="mb-0 border-bottom text-center pb-2">حواله های بارگیری</p>
              <div class="d-flex justify-content-between">
                <b>حواله</b>
                <b>تناژ</b>
              </div>
              <div class="d-flex justify-content-between" v-for="referred in order.referreds">
                <span v-text="referred.referred_code"></span>
                <span v-text="referred.pivot.ton_loaded"></span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 py-3">
            <!--  Number Bar  -->
            <div
              class="border-bottom border-style-bottom-dash d-flex justify-content-between pb-1 mb-2"
              @click="collapse_order_detail = !collapse_order_detail"
            >
              <div>
                <img
                  src="/img/icons/icons8-paper-64.png"
                  class="align-bottom"
                  width="25"
                  alt="سفارش"
                />
                سفارش
              </div>
              <font-awesome-icon
                icon="angle-down"
                :class="{ 'text-warning': collapse_order_detail }"
                :rotation="collapse_order_detail ? 180 : 0"
              />
            </div>
            <CCollapse class="row" :visible="collapse_order_detail">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                شماره بارنامه :
                {{ order.number_bar }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                تاریخ بارنامه :
                {{
                  order.date_number_bar
                    ? to_jalali(order.date_number_bar)
                    : '-/-'
                }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                صادر کننده بارنامه :
                {{
                  order.exportation_by ? get_fullname(order.exportator) : '-/-'
                }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                حداکثر تناژ برای بارگیری :
                {{ parseFloat(order.max_ton_loaded) }}
                <small>تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                پرداخت کرایه توسط :
                {{ order.rent_by_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                ترخیص توسط :
                {{ order.card_export.clearance_by_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2 text-danger" v-if="order.ton_shortage">
                تناژ کسری :
                {{ order.ton_shortage }}
                <small v-if="order.ton_loaded">تن</small>
              </div>
              <!--  Loaded Data  -->
              <div class="row m-0 p-0">
                <div class="col-12 col-sm-6 col-md-4 mb-2">
                  تناژ بارگیری :
                  {{ order.ton_loaded ? parseFloat(order.ton_loaded) : '-/-' }}
                  <small v-if="order.ton_loaded">تن</small>
                </div>
                <div class="col-12 col-sm-6 col-md-4 mb-2">
                  تاریخ بارگیری :
                  {{ order.date_loaded ? to_jalali(order.date_loaded) : '-/-' }}
                </div>
                <div class="col-12 col-sm-6 col-md-4 mb-2">
                  بارگیری توسط :
                {{
                  order.loaded_by ? get_fullname(order.loader) : '-/-'
                }}
                <span v-if="order.loaded_by && order.panel_set_loaded == 'export'">(صادراتی)</span>
                <span v-else-if="order.loaded_by && order.panel_set_loaded == 'local'">(داخلی)</span>
              </div>
              </div>
            </CCollapse>
            <!--  Car Data  -->
            <div
              class="border-bottom border-style-bottom-dash d-flex justify-content-between pb-1 mb-2 mt-3"
              @click="collapse_car = !collapse_car"
            >
              <div>
                <img
                  src="/img/icons/icons8-tire-40.png"
                  class="align-bottom"
                  width="25"
                  alt="اطلاعات خودرو"
                />
                اطلاعات خودرو
              </div>
              <font-awesome-icon
                icon="angle-down"
                :class="{ 'text-warning': collapse_car }"
                :rotation="collapse_car ? 180 : 0"
              />
            </div>
            <CCollapse class="row" :visible="collapse_car">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                نوع خودرو :
                {{ order.car.car_type.title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                نام راننده :
                {{ get_fullname(order.driver) }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                موبایل راننده :
                {{ order.driver.mobile }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <license-plate class="mb-0" :car="order.car" />
              </div>
            </CCollapse>
            <!--  DisCharge Data  -->
            <div
              class="border-bottom border-style-bottom-dash d-flex justify-content-between pb-1 mb-2 mt-3"
              @click="collapse_disCharge = !collapse_disCharge"
            >
              <div>
                <img
                  src="/img/icons/icons8-dump-truck-48.png"
                  class="align-bottom"
                  width="25"
                  alt="اطلاعات تخلیه"
                />
                اطلاعات تخلیه
              </div>
              <font-awesome-icon
                icon="angle-down"
                :class="{ 'text-warning': collapse_disCharge }"
                :rotation="collapse_disCharge ? 180 : 0"
              />
            </div>
            <CCollapse class="row" :visible="collapse_disCharge">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                محل تخلیه :
                {{ order.card_export.city.title }}
              </div>
              <template v-if="order.status > 2 || order.status === -1">
                <div class="col-12 col-sm-6 col-md-4 mb-2">
                  تاریخ تخلیه :
                  {{
                    order.date_disCharge
                      ? to_jalali(order.date_disCharge)
                      : '-/-'
                  }}
                </div>
                <div class="col-12 col-sm-6 col-md-4 mb-2">
                  ثبت کننده تخلیه :
                  {{
                    order.set_discharge_by
                      ? get_fullname(order.dis_charger)
                      : '-/-'
                  }}
                </div>
              </template>
            </CCollapse>
            <!--  Money data  -->
            <div
              class="border-bottom border-style-bottom-dash d-flex justify-content-between pb-1 mb-2 mt-3"
              @click="collapse_mali = !collapse_mali"
            >
              <div>
                <img
                  src="/img/icons/icons8-money-40.png"
                  class="align-bottom"
                  width="25"
                  alt="مالی"
                />
                مالی
              </div>
              <font-awesome-icon
                icon="angle-down"
                :class="{ 'text-warning': collapse_mali }"
                :rotation="collapse_mali ? 180 : 0"
              />
            </div>
            <CCollapse class="row" :visible="collapse_mali">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                نوع ارز فروش :
                {{ order.type_arz_title }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                فی فروش به
                {{ order.type_arz_title }}
                :
                <span
                  v-if="order.price_per_ton"
                  v-text="toNumberFormat(order.price_per_ton)"
                ></span>
                <span
                  v-if="order.type_arz"
                  v-text="parseFloat(order.price_per_ton_arz)"
                ></span>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2" v-if="order.type_arz">
                نرخ
                {{ order.type_arz_title }}
                :
                {{ toNumberFormat(order.price_arz) }}
                <currency />
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                فی کرایه :
                {{ toNumberFormat(order.price_rent) }}
                <currency />
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                فی انعام :
                <template v-if="order.price_reward">
                  {{ toNumberFormat(order.price_reward) }}
                  <currency />
                </template>
                <template v-else>ندارد</template>
              </div>
              <div class="row">
                <!--  Credit Card Details  -->
                <div class="col-12 col-md-5 mb-2 mt-4 p-0 text-center">
                  <template v-if="order.price_rent_bank">
                    <p class="mb-2">
                      حساب بانکی بابت پرداخت
                      <template
                        v-if="order.price_rent_bank == order.price_reward_bank"
                      >
                        کرایه و انعام
                      </template>
                      <template v-else>کرایه</template>
                    </p>
                    <show-credit-card :account_bank="order.credit_card_rent" />
                  </template>
                  <template v-if="order.price_reward_bank">
                    <div>
                      <div class="separate_dash_gray w-75"></div>
                    </div>
                    <p>حساب بانکی بابت پرداخت انعام</p>
                    <show-credit-card
                      :account_bank="order.credit_card_reward"
                    />
                  </template>
                </div>
                <!--  Payment Able Details And Status  -->
                <div class="col-12 col-md-7 mb-3 mt-4 p-0">
                  <template v-if="order.paymentables.length">
                    <p class="mb-2">اسناد پرداختی مربوط به این سفارش</p>
                    <div class="table-ay1">
                      <table>
                        <thead>
                          <tr>
                            <th>عنوان</th>
                            <th>سند مربوطه</th>
                            <th>وضعیت</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in order.paymentables" :key="row.id">
                            <td>
                              <div class="mobile_title">عنوان :</div>
                              بابت
                              {{ row.pay_for_title }}
                            </td>
                            <td>
                              <div class="mobile_title">سند مربوطه :</div>
                              {{ row.sanad_id || '-/-' }}
                            </td>
                            <td>
                              <div class="mobile_title">وضعیت :</div>
                              {{ row.status_title }}
                            </td>
                            <td class="text-left">
                              <router-link
                                :to="{
                                  name: 'PaymentAbleShow',
                                  params: { id: row.id },
                                }"
                              >
                                <btn-ayandeh
                                  color="light"
                                  class="btn-sm"
                                  label="جزئیات"
                                />
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
              </div>
            </CCollapse>
            <br />
            <show-attachments type="b" :attachments="order.attachments" />
          </div>
        </div>
      </template>
    </CModalBody>
  </CModal>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DotsLoading from '@/components/loading/DotsLoading'
import CardMix from '@/mixin/CardMix'
import LicensePlate from '@/components/partial/LicensePlate'
import Currency from '@/components/partial/Currency'
import ShowCreditCard from '@/components/partial/ShowCreditCard'
import ShowAttachments from '@/components/partial/ShowAttachments'
import {mapActions} from "vuex";

export default {
  name: 'ModalOrderDetails',
  components: {
    ShowAttachments,
    ShowCreditCard,
    Currency,
    LicensePlate,
    DotsLoading,
    FontAwesomeIcon,
  },
  mixins: [CardMix],
  data() {
    return {
      visiable_var: false,
      loading_get_order: false,
      full_screen_modal_order_detail: false,
      order: null,
      collapse_order_detail: false,
      collapse_mali: false,
      collapse_car: false,
      collapse_disCharge: false,
    }
  },
  props: {
    order_id: {
      type: Number,
      default: null,
      required: true,
    },
    visiable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.getCookie('f_s_order_det')) {
      this.full_screen_modal_order_detail = true
    }
    if (this.getCookie('collapse_order_detail')) {
      this.collapse_order_detail = true
    }
    if (this.getCookie('collapse_mali')) {
      this.collapse_mali = true
    }
    if (this.getCookie('collapse_car')) {
      this.collapse_car = true
    }
    if (this.getCookie('collapse_disCharge')) {
      this.collapse_disCharge = true
    }
  },
  methods: {
    ...mapActions([
      'toggle_modal_other_sanads',
      'get_other_sanads',
    ]),
    closed() {
      this.visiable_var = false
      this.$emit('update:visiable', this.visiable_var)
    },
    get_order() {
      this.loading_get_order = true

      // eslint-disable-next-line no-undef
      axios.get('/orders/get_order/' + this.order_id).then(({ data }) => {
        if (data.status == 'success') {
          this.order = data.order
        }
        this.loading_get_order = false
      })
    },
    show_modal_other_sanads() {
      this.toggle_modal_other_sanads(true)
      this.load_data_modal_other_sanads()
    },
    load_data_modal_other_sanads() {
      this.get_other_sanads({
        order_id: this.order.id,
        by_bed_bes_name: true,
      })
    },
  },
  watch: {
    visiable(newValue) {
      this.visiable_var = newValue
      if (newValue) {
        this.get_order()
      }
    },
    full_screen_modal_order_detail(newValue) {
      this.setCookie('f_s_order_det', newValue)
    },
    collapse_order_detail(newValue) {
      this.setCookie('collapse_order_detail', newValue)
    },
    collapse_mali(newValue) {
      this.setCookie('collapse_mali', newValue)
    },
    collapse_car(newValue) {
      this.setCookie('collapse_car', newValue)
    },
    collapse_disCharge(newValue) {
      this.setCookie('collapse_disCharge', newValue)
    },
  },
}
</script>
