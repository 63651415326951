export default {
  data() {
    return {
      type_arz: '',
      price_arz: null,
      price_per_ton_arz: null,
      price_per_ton: null,
      province_id: 17,
      city_id: null,
      tonage: '',
      user_id: null,
      factory_id: null,
      product_selected: null,
      anbar_id: null,
      new_anbar_title: '',
      price_rent: '',
      price_reward: '',
      rent_by: '',
      clearance_by: '',
      referred_ids: [],
    }
  },
}
