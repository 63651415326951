<template>
  <div>
    <nav class="navbar bg-body-tertiary bg-ayandeh">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img
            src="/img/logo/ayandeh_256.png"
            class="d-inline-block"
            width="47"
          />
          سیمان آینده
        </a>
      </div>
    </nav>
    <div class="container py-4">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LayoutSite',
}
</script>
