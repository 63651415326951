<template>
  <div v-if="order">
    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <div class="card-title px-3 text-ayandeh">اطلاعات سفارش</div>
            <CListGroup flush>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">نام مشتری :</span>
                <b v-text="get_fullname(order.card_export.user)"></b>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted"> کد حسابداری مشتری :</span>
                <span v-text="order.card_export.user.faramohaseb_id"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">شماره سفارش :</span>
                <span v-text="order.order_id"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">شماره کارت :</span>
                <span v-text="order.card_export_id"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">تناژ بارشده :</span>
                <div>
                  <span v-text="order.ton_loaded"></span>
                  <small class="ms-1">تن</small>
                </div>
              </CListGroupItem>
              <CListGroupItem class="d-flex justify-content-between">
                <span class="text-muted">تاریخ بارگیری :</span>
                <b class="date">
                  {{ to_jalali(order.date_loaded, 'long') }}
                </b>
              </CListGroupItem>
              <template v-if="order.type_arz">
                <CListGroupItem class="d-flex justify-content-between">
                  <span class="text-muted">فی فروش ارزی :</span>
                  <span>
                    {{ order.price_per_ton_arz + ' ' + order.type_arz }}
                  </span>
                </CListGroupItem>
                <CListGroupItem class="d-flex justify-content-between">
                  <span class="text-muted">نرخ {{ order.type_arz }} :</span>
                  <span>
                    {{ toNumberFormat(order.price_arz) }}
                    <currency />
                  </span>
                </CListGroupItem>
              </template>
            </CListGroup>
          </div>
        </div>
      </div>
      <!--      NumberBar      -->
      <div class="col-12 col-md-4 mb-3">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <div class="card-title px-3 text-ayandeh">اطلاعات بار</div>
            <CListGroup flush>
              <CListGroupItem class="d-flex justify-content-between">
                <span class="text-muted">نوع بار :</span>
                <b class="date">
                  {{ order.card_export.product.title }}
                </b>
              </CListGroupItem>
              <CListGroupItem
                v-for="(clearance, index) in order.card_export.clearance"
                :key="clearance.id"
              >
                <p class="mb-1 text-ayandeh fs-12">
                  ترخیص کار
                  <span
                    v-if="order.card_export.clearance.length > 1"
                    v-text="index + 1"
                  ></span>
                </p>
                <div class="d-flex justify-content-between">
                  <span v-text="clearance.fullname"></span>
                  <div>
                    <a
                      v-if="clearance.mobile1"
                      :href="'tel:' + clearance.mobile1"
                      v-text="clearance.mobile1"
                    ></a>
                    <template v-if="clearance.mobile2">
                      -
                      <a
                        :href="'tel:' + clearance.mobile2"
                        v-text="clearance.mobile2"
                      ></a>
                    </template>
                    <template v-if="clearance.mobile3">
                      -
                      <a
                        :href="'tel:' + clearance.mobile3"
                        v-text="clearance.mobile3"
                      ></a>
                    </template>
                  </div>
                </div>
              </CListGroupItem>
              <!--         Referred Details          -->
              <CListGroupItem>
                <p class="mb-1 text-ayandeh fs-12">حواله های بارگیری شده</p>
                <div
                  class="d-flex justify-content-between bold"
                  v-for="referred in order.referreds"
                  :key="referred.id"
                >
                  <span>حواله {{ referred.referred_code }}</span>
                  <span>{{ referred.pivot.ton_loaded }} تن</span>
                </div>
              </CListGroupItem>
            </CListGroup>
          </div>
        </div>
      </div>
      <!--      Driver      -->
      <div class="col-12 col-md-4 mb-3">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <div class="card-title px-3 text-ayandeh">اطلاعات راننده</div>
            <div class="px-3">
              <license-plate :car="order.car" />
            </div>
            <CListGroup flush>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">نام :</span>
                <span v-text="get_fullname(order.driver)"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">موبایل :</span>
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-copy"
                    class="me-2 c-pointer"
                    @click="copy_mobile(order.driver.mobile)"
                  />
                  <a :href="'tel:' + order.driver.mobile">
                    {{ order.driver.mobile }}
                  </a>
                </div>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">کد حسابداری راننده :</span>
                <span v-text="order.driver.faramohaseb_id || '-'"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">کد حسابداری خودرو :</span>
                <span v-text="order.car.faramohaseb_id || '-'"></span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">مبدا :</span>
                <span class="date">
                  {{ order.card_export.product.factory.name }}
                </span>
              </CListGroupItem>
              <CListGroupItem class="d-flex justify-content-between">
                <span class="text-muted">مقصد :</span>
                <span class="date">
                  {{ order.card_export.city.title }}
                </span>
              </CListGroupItem>
              <CListGroupItem
                class="d-flex justify-content-between border-bottom"
              >
                <span class="text-muted">شماره بارنامه :</span>
                <b v-text="order.number_bar"></b>
              </CListGroupItem>
              <CListGroupItem class="d-flex justify-content-between">
                <span class="text-muted">تاریخ بارنامه :</span>
                <span class="date">
                  {{ to_jalali(order.date_number_bar) }}
                </span>
              </CListGroupItem>
            </CListGroup>
          </div>
        </div>
      </div>
      <!--      Mali      -->
      <div class="col-12 mb-3">
        <div class="card">
          <div class="card-body px-0">
            <div class="row m-0 border-between-child">
              <div class="col-12 col-sm-6 col-md-4 p-3">
                <p class="text-ayandeh">
                  <font-awesome-icon
                    icon="fa-solid fa-coins"
                    class="text-gold me-1"
                  />
                  مبلغ فروش
                </p>
                <div class="d-flex justify-content-between mb-1">
                  <span class="text-muted">فی :</span>
                  <div>
                    {{ toNumberFormat(price_per_ton) }}
                    <currency />
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-1">
                  <span class="text-muted">جمع مبلغ بار :</span>
                  <div>
                    <div>
                      {{
                        toNumberFormat(
                          price_per_ton * (order.ton_loaded - tonage_shortage),
                        )
                      }}
                      <currency />
                    </div>
                    <div
                      v-if="tonage_shortage"
                      class="text-warning ltr text-start fs-12"
                    >
                      -{{ toNumberFormat(price_per_ton * tonage_shortage) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 p-3">
                <div class="d-flex justify-content-between mb-3">
                  <div class="text-ayandeh">
                    <font-awesome-icon
                      icon="fa-solid fa-dollar-sign"
                      class="text-success"
                      size="xs"
                    />
                    <font-awesome-icon
                      icon="fa-solid fa-truck-ramp-box"
                      class="ms-1"
                    />
                    کرایه
                  </div>
                  <div class="fs-11 text-primary">
                    (کرایه با
                    {{ order.rent_by_title }}
                    )
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-1">
                  <span class="text-muted">فی :</span>
                  <div>
                    {{ toNumberFormat(order.price_rent) }}
                    <currency />
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-1">
                  <span class="text-muted">جمع مبلغ کرایه :</span>
                  <div>
                    <div :class="{ 'text-danger': total_price_rent < 0 }">
                      <span class="me-1">
                        {{ toNumberFormat(total_price_rent)
                        }}<template v-if="total_price_rent < 0">-</template>
                      </span>
                      <currency />
                    </div>
                    <div
                      v-if="tonage_shortage"
                      class="text-warning ltr text-start fs-12"
                    >
                      -{{ toNumberFormat(order.price_rent * tonage_shortage) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 p-3">
                <template v-if="order.price_reward">
                  <p class="text-ayandeh">
                    <font-awesome-icon
                      icon="fa-solid fa-gift"
                      class="text-danger me-1"
                    />
                    انعام
                  </p>
                  <div class="d-flex justify-content-between mb-1">
                    <span class="text-muted">فی :</span>
                    <div>
                      {{ toNumberFormat(order.price_reward) }}
                      <currency />
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mb-1">
                    <span class="text-muted">جمع مبلغ انعام :</span>
                    <div>
                      <div :class="{ 'text-danger': total_price_reward < 0 }">
                        <span class="me-1">
                          {{ toNumberFormat(total_price_reward)
                          }}<template v-if="total_price_reward < 0">-</template>
                        </span>
                        <currency />
                      </div>
                      <div
                        v-if="tonage_shortage"
                        class="text-warning ltr text-start fs-12"
                      >
                        -{{
                          toNumberFormat(order.price_rent * tonage_shortage)
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <div class="position-relative h-100" v-else>
                  <font-awesome-icon
                    icon="fa-solid fa-gift"
                    class="text-muted absolute-center"
                    size="3x"
                  />
                  <font-awesome-icon
                    rotation="90"
                    icon="fa-solid fa-slash"
                    class="text-danger absolute-center"
                    size="3x"
                  />
                  <p class="text-center text-muted">بدون انعام</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <template v-if="order.rent_by == 'ayandeh'">
            <div class="col-12 col-sm-6 col-md-4 mb-3">
              <input-ayandeh
                v-model:value="bag_shortage"
                @keyup="CalcTonage"
                @paste="CalcTonage"
                dir="ltr"
                just="number"
                icon-font-awesome="fa-solid fa-sack-xmark"
                label="مقدار کسری به کیسه"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-3">
              <input-ayandeh
                v-model:value="tonage_shortage"
                @keyup="CalcBag"
                @paste="CalcBag"
                dir="ltr"
                just="number"
                icon-font-awesome="fa-solid fa-weight-scale"
                label="مقدار به تناژ"
              />
            </div>
          </template>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <input-date
              v-model:value="date_disCharge"
              label="تاریخ تخلیه"
            ></input-date>
          </div>
          <div class="col-12">
            <multiple-attach v-model:attach="attachs" />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <btn-ayandeh
          @click="submit()"
          color="success"
          :loading="loading"
          :disabled="disable_submit"
        >
          تایید تخلیه
        </btn-ayandeh>
        <btn-ayandeh
          @click="add_sanad()"
          color="info"
          class="ms-2"
          :disabled="loading"
        >
          افزودن سند حسابداری
          <font-awesome-icon icon="fa-solid fa-file-circle-plus" />
        </btn-ayandeh>
      </div>
    </div>

    <div class="alert alert-info mt-3" v-if="get_others_sanad_loading">
      در حال خواندن اطلاعات اسناد اضافه
      <loading-spinner :show="true" class="ms-2" size="sm" />
    </div>
    <div class="card mt-4"
         v-else
         v-for="(snd, index) in other_sanads"
         :key="index">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h3 class="text-ayandeh fs-14 fw-bold d-inline-block mb-0">
            جزئیات سند حسابداری {{ index + 1 }}
            <span class="text-primary" v-if="snd.type !== undefined">({{ snd.title_type }})</span>
          </h3>
          <div>
            <template v-if="snd.id !== undefined">
              <btn-ayandeh class="btn-sm me-2 sec"
                           color="outline-info"
                           @click="snd.editable = !snd.editable">
                <font-awesome-icon
                  icon="fa-solid fa-pen"
                />
              </btn-ayandeh>
            </template>
            <btn-ayandeh color="outline-primary" class="btn-sm" @click="remove_sanad(snd, index)">
              حذف
              <font-awesome-icon
                icon="fa-solid fa-times"
                class="ms-1"
              />
            </btn-ayandeh>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-5 mb-3">
            <label class="mb-2">
              بستانکار
              <small class="text-danger" v-if="!snd.bes_id ">(لطفا بستانکار را مشخص کنید)</small>
            </label>
            <v-select
              v-model="snd.bes_id"
              :options="$store.state.users_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب بستانکار"
              dir="rtl"
              :loading="$store.state.users_options_loading"
              :disabled="snd.editable"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label class="mb-2">
              بدهکار
              <small class="text-danger" v-if="!snd.bed_id ">(لطفا بدهکار را مشخص کنید)</small>
            </label>
            <v-select
              v-model="snd.bed_id"
              :options="snd.id !== undefined ? $store.state.users_options : beds"
              :reduce="(option) => option.value"
              placeholder="انتخاب بدهکار"
              dir="rtl"
              :disabled="snd.editable"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              :number-format="true"
              placeholder="مبلغ"
              v-model:value="snd.amount"
              ref="amount"
              dir="ltr"
              :disabled="snd.editable"
            >
              مبلغ
              <currency :parentheses="true" />
            </input-ayandeh>
            <p class="text-info" v-if="snd.calc_amount_type === 'by_tonage'">مبلغ فوق بابت هر تن میباشد</p>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <CFormLabel>
              <font-awesome-icon icon="fa-solid fa-file-line" />
              شرح سند حسابداری
            </CFormLabel>
            <CFormTextarea
              v-model="snd.description"
              rows="3"
              placeholder="شرح سند"
              :disabled="snd.editable"
            />
          </div>
        </div>

      </div>
    </div>

    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  </div>
  <div class="text-center pt-5" v-else>
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>
</template>

<script>
import InputDate from '@/components/inputs/InputDate'
import Currency from '@/components/partial/Currency'
import LicensePlate from '@/components/partial/LicensePlate'
import useClipboard from 'vue-clipboard3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions } from 'vuex'
import MultipleAttach from '@/components/partial/MultipleAttach'
import DisCharge from '@/mixin/DisCharge'
import BtnAyandeh from "@/components/partial/AyandehButton.vue"
import LoadingSpinner from "@/components/loading/LoadingSpinner.vue"
import Swal from "sweetalert2"
import collect from 'collect.js'


export default {
  name: 'DisCharge',
  components: {
    LoadingSpinner,
    BtnAyandeh,
    MultipleAttach,
    FontAwesomeIcon,
    LicensePlate,
    Currency,
    InputDate,
  },
  mixins: [DisCharge],
  data() {
    return {
      loading: false,
      order: null,
      date_disCharge: null,
      beds: [],
      other_sanads: [],
      attachs: null,
      get_others_sanad_loading: false,
    }
  },
  mounted() {
    this.get_order()
  },
  methods: {
    ...mapActions(['get_users']),
    get_order() {
      this.loading = true
      // eslint-disable-next-line no-undef
      axios
        .get('/orders/get_order/' + this.$route.params.id , { params: { set_other_sanads: 1}})
        .then(({ data }) => {
          if (data.status === 'success') {
            this.order = data.order

            this.beds = [
              {
                label: 'هزینه حمل - ' + 815100001,
                value: 815100001,
              },
              {
                label: 'مشتری - ' + this.order.card_export.user.faramohaseb_id,
                value: this.order.card_export.user.faramohaseb_id,
              },
              {
                label: this.order.car.plaque + ' - ' + this.order.car.faramohaseb_id,
                value: this.order.car.faramohaseb_id,
              },
            ]
            this.get_others_sanad()
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    },
    copy_mobile(string) {
      const { toClipboard } = useClipboard()
      toClipboard(string)
      this.toast('شماره موبایل کپی شد')
    },
    add_sanad(row = null) {
      if (this.$store.state.users_options.length === 0) {
        this.get_users({ is_in_fm: 1 })
      }

      if(row) {
        Object.assign(this.other_sanads, row)
      } else {
        this.other_sanads.push({
          bed_id: null,
          bes_id: null,
          amount: '',
          description: '',
          editable: false,
        })
      }
    },
    remove_sanad(snd, index) {
      if(snd.id != undefined) {
        Swal.fire({
          confirmButtonText: 'حذف شود',
          cancelButtonText: 'انصراف',
          showCancelButton: true,
          icon: 'question',
          title: 'حذف کد',
          html: 'آیا میخواهید سند مورد نظر را حذف کنید؟',
        }).then((result) => {
          if (result.isConfirmed) {
            this.other_sanads.splice(index, 1)
          }
        })
      } else {
        this.other_sanads.splice(index, 1)
      }
    },
    submit() {
      if (this.disable_submit) {
        return false
      }

      this.loading = true
      var config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      // eslint-disable-next-line no-undef
      axios
        .post(
          '/orders/verify_loading',
          {
            order_id: this.order.id,
            date_disCharge: this.date_disCharge,
            ton_shortage: this.tonage_shortage,
            bag_shortage: this.bag_shortage,
            other_sanads: this.other_sanads,
            attachs: this.attachs,
          },
          config,
        )
        .then(({ data }) => {
          this.loading = false
          if (data.status === 'success') {
            this.$router.replace(
              this.$router.resolve({ name: 'OrderList' }).path,
            )
          }
          this.my_alert(data.msg, data.status)
        })
    },
    get_others_sanad() {
      this.get_others_sanad_loading = true
      var app = this
      // eslint-disable-next-line no-undef
      axios
        .get('/others_sanad/get', { params: {
          order_id: this.order.id,
          }})
        .then(({ data }) => {
          if (data.status === 'success') {
            data.data.map(function (snd) {
              if(snd.bes_id === 'car') {
                snd.bes_id = app.order.car.faramohaseb_id
              }
              if(snd.bes_id === 'freightage') {
                snd.bes_id = app.order.exportator.faramohaseb_export_id
              }
              if(snd.bed_id === 'car') {
                snd.bed_id = app.order.car.faramohaseb_id
              }
              if(snd.bed_id === 'freightage') {
                snd.bed_id = app.order.exportator.faramohaseb_export_id
              }
              snd.editable = true
              return snd
            })
            this.add_sanad(data.data)
            this.get_others_sanad_loading = false
          }
        })
    },
  },
  computed: {
    price_per_ton() {
      return this.get_price_per_ton_rial(this.order)
    },
    total_price_reward() {
      return (
        this.order.price_reward *
        (this.order.ton_loaded - this.tonage_shortage || 0)
      )
    },
    total_price_rent() {
      return (
        this.order.price_rent *
        (this.order.ton_loaded - this.tonage_shortage || 0)
      )
    },
    // eslint-disable-next-line vue/return-in-computed-property
    disable_submit() {
      if (!this.$store.state.jalali_validate) {
        return true
      }
      // Check Other Snd
      var collection = collect(this.other_sanads)
      if (collection.where('bes_id', null).count())
        return true
      if (collection.where('bed_id', null).count())
        return true
      if (collection.where('amount', '=', '').count())
        return true
      return false
    },
  },
}
</script>
