import LayoutSite from '@/layouts/LayoutSite'
import ShowPaymentAble from '@/views/site/paymentable/ShowPaymentAble'
import SanadDetailByHash from '@/views/site/sanad/SanadDetailByHash'
import OrderDetail from '@/views/site/orders/OrderDetail'

export default {
  path: '/',
  name: 'HomePage',
  props: {
    label: 'صفحه اصلی',
  },
  redirect: '/admin',
  component: LayoutSite,
  children: [
    //  لینک مشاهده جزئیات پرداختی
    {
      path: '/r_p/:hash_code',
      name: 'SitePaymentAble',
      props: {
        label: 'پرداخت',
      },
      component: ShowPaymentAble,
    },
    //  لینک مشاهده اطلاعات سند (لینک مخصوص مشتری)
    {
      path: '/snd/:side/:fiscal_year_id/:snd_verified_hash_code/:mobile_code_hash_code',
      name: 'SiteShowSnd',
      props: {
        label: 'مشاهده سند',
      },
      component: SanadDetailByHash,
    },
    //  لینک مشاهده اطلاعات سند (لینک عمومی)
    {
      path: '/snd/:hash_fiscal_year_and_snd_id',
      name: 'SiteShowSnd2',
      props: {
        label: 'مشاهده سند',
      },
      component: SanadDetailByHash,
    },
    //  نمایش جزئیات سفارش با هش کد برای عموم
    {
      path: '/order/:hash_code',
      name: 'SiteShowOrderByHash',
      props: {
        label: 'مشاهده جزئیات سفارش',
      },
      component: OrderDetail,
    },
  ],
}
