<template>
  <div>
    {{
      paginate_datas.per_page * paginate_datas.current_page -
      paginate_datas.per_page +
      index +
      1
    }}
  </div>
</template>

<script>
export default {
  name: 'RowNumTable',
  props: {
    paginate_datas: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
}
</script>

<style scoped></style>
