<template>
  <div :class="classes" class="spinner-border" v-if="show"></div>
</template>

<script>
export default {
  name: 'loading-spinner',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      var clas = ''
      if (this.color) {
        clas += 'text-' + this.color
      }
      clas += ' ' + this.class
      if (this.size == 'sm') {
        clas += ' spinner-border-sm'
      }
      return clas
    },
  },
}
</script>
