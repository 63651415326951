<template>
  <tr v-for="x in count" :key="x">
    <td v-for="i in colspan" :key="i">
      <shine-loading />
    </td>
  </tr>
</template>

<script>
import ShineLoading from '@/components/loading/ShineLoading'
export default {
  name: 'LoadingTr',
  components: { ShineLoading },
  props: {
    count: {
      type: Number,
      default: 8,
    },
    colspan: {
      type: Number,
      required: true,
    },
  },
}
</script>
