<template>
  <div>
    مانده :
    <loading-spinner v-if="loading" size="sm" color="muted" show="loading" />
    <template v-else-if="isNaN(value)">{{ value || '-' }}</template>
    <template v-else>
      {{ toNumberFormat(value) }}
      <currency />
      <small v-if="value > 0" class="text-danger"> (بدهکار) </small>
      <small v-else-if="value < 0" class="text-success"> (بستانکار) </small>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ShowMandehNew',
  props: {
    value: Number,
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
