export default {
  sidebarVisible: '',
  sidebarUnfoldable: false,
  admin: null,
  fiscal_year_data: {
    name: null
  },
  jalali_validate: false,
  users_options: [],
  users_options_loading: false,
  local_users_options: [],
  local_users_options_loading: false,
  site_users_options: [],
  site_users_options_loading: false,
  payment_types: [],
  payment_types_loading: false,
  payment_types_local: [],
  payment_types_local_loading: false,
  type_pay_options: [],
  type_pay_options_loading: false,
  factories: [],
  loading_factory: false,
  products: [],
  loading_products: false,
  NProcess: false,
  source_axios: null,
  fiscal_years_options: [],
  current_fiscal_year: '',
  loading_fiscal_years: false,
  order: null,
  loading_get_order: false,
  // Car Plate
  number_base: '',
  three_digits: '',
  plate_letter: 'ع',
  two_digits: '',
  all_fiscal_year: [],
  all_fiscal_year_grouped: [],
  all_fiscal_year_site: [],
  loading_all_fiscal_year: false,
  fiscal_year: '',
  loading_get_sanad_data: false,
  count_verified_sanad: 0,
  count_not_verify_sanad: 0,
  count_all_sanads: 0,
  provinces: [],
  loading_provinces: false,
  cities: [],
  loading_cities: false,
  anbars: [],
  loading_anbars: false,
  referreds: [],
  loading_referreds: false,
  tr_selected: [],
  type_arz_loading: false,
  type_arz_arr: [],
  get_groups_fm_loading: false,
  groups_fm: [],
  default_anbar_fm: null,
  anbars_fm: [],
  loading_get_other_sanads: false,
  visiable_modal_other_sanad: false,
  get_deleted_others_sanad: false,
  other_sanads: [],
}
