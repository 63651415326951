<template>
  <CModal
    :transition="true"
    size="md"
    :visible="localModelValue"
    @close="closeModal"
  >
    <CModalHeader dismiss @close="closeModal">
      <CModalTitle class="fs-20">ایجاد سال مالی جدید</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="mb-4">
        <input-ayandeh
          placeholder="عنوان سال مالی را وارد کنید"
          v-model:value="fiscalYearTitle"
          :error-message="createFiscalError"
          @input="createFiscalYearValidate"
        >
          عنوان سال مالی *
        </input-ayandeh>
      </div>
    </CModalBody>
    <CModalFooter class="flex-row-reverse justify-content-start">
      <btn-ayandeh
        label="ذخیره"
        :loading="createFiscalLoading"
        :disabled="!!createFiscalError"
        @click="createFinancialYear"
      />
      <btn-ayandeh
        @click="closeModal"
        color="light"
        class="me-2"
        label="بازگشت"
      />
    </CModalFooter>
  </CModal>
</template>

<script setup>
import Swal from 'sweetalert2'
import { defineProps, defineEmits, ref, watch, computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const localModelValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const closeModal = () => {
  localModelValue.value = false
}

const fiscalYearTitle = ref('')
const createFiscalError = ref('')
const createFiscalLoading = ref(false)

const createFiscalYearValidate = () => {
  if (!fiscalYearTitle.value)
    createFiscalError.value = 'وارد کردن عنوان سال مالی الزامی است.'
  else createFiscalError.value = ''
}

const createFinancialYear = async () => {
  createFiscalYearValidate()
  if (!!createFiscalError.value) return
  createFiscalLoading.value = true
  try {
    const data = await axios.post('/fiscal_year/store', {
      fiscal_year_label: fiscalYearTitle.value,
    })
    createFiscalLoading.value = false
    if (data.data.status === 'error') {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: data.data.status,
        html: data.data.msg,
      })
    } else {
      localModelValue.value = false
      emit('updateList')
      getFiscalYears()
    }
  } catch (err) {
    createFiscalLoading.value = false
    console.error(err)
  }
}

watch(
  () => localModelValue.value,
  (val) => {
    if (!val) {
      fiscalYearTitle.value = ''
      createFiscalError.value = ''
    }
  },
)
</script>

<style lang="scss" scoped></style>
