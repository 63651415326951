<template>
  <filter-card
    v-model:plate="plate"
    v-model:number_bar="number_bar"
    v-model:order_id="order_id"
    v-model:referred_code="referred_code"
    v-model:factory_id="factory_id"
    @refresh="get_orders(1)"
  >
    <template v-slot:static>
      <!--    Status Filter     -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div class="form-group">
          <label>براساس وضعیت</label>
          <select class="form-control" v-model="status" @change="get_orders(1)">
            <option
              v-for="option in options_status_filter"
              :key="option.value"
              :value="option.value"
              v-text="option.label"
            ></option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="mb-2">
            <font-awesome-icon
              icon="fa-regular fa-user"
              class="text-icon-light"
            />
            فیلتر مشتری
          </label>
          <v-select
            v-model="user_id"
            :filter="userSearchVueSelect"
            :options="$store.state.site_users_options"
            :reduce="(option) => option.user_id"
            placeholder="فیلتر مشتری"
            dir="rtl"
            :loading="$store.state.site_users_options_loading"
          ></v-select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 my-3 my-md-0">
        <div class="form-group">
          <label class="mb-2">
            <font-awesome-icon
              icon="fa-solid fa-map-pin"
              class="text-icon-light"
            />
            شهر تخلیه (نام مرز)
          </label>
          <v-select
            v-model="city_id"
            :options="city_options"
            :reduce="(option) => option.value"
            placeholder="انتخاب مرز تخلیه"
            dir="rtl"
            :loading="$store.state.loading_cities"
          ></v-select>
        </div>
      </div>
      <!--    Date Filter     -->
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-12 col-md-7">
            <p class="mb-3 mb-md-0">فیلتر بر اساس تاریخ</p>
            <div class="input-group d-block d-md-flex mb-3">
              <select
                class="form-select w-xs-100 mb-3 mb-md-0"
                style="margin-top: 29px"
                v-model="filter_date_type"
                @change="get_orders(1)"
              >
                <option
                  v-for="option in options_date_filter"
                  :key="option.value"
                  v-text="option.label"
                  :value="option.value"
                ></option>
              </select>
              <input-date
                class="mb-3 mb-md-0"
                @change="check_get_order"
                v-model:value="from_date"
                selected=""
                :radius_right="false"
                :radius_left="false"
                label="از تاریخ"
              ></input-date>
              <input-date
                @change="check_get_order"
                :radius_right="false"
                v-model:value="to_date"
                selected=""
                label="تا تاریخ"
              ></input-date>
            </div>
          </div>
        </div>
      </div>
    </template>
  </filter-card>
  <div class="text-end mb-3" v-if="orders.total && has_permission([1, 2, 4])">
    <btn-ayandeh
      class="text-white"
      @click="open_modal_download_pdf"
      color="danger"
      :loading="loading_download"
      :disabled="loading_download"
    >
      <font-awesome-icon icon="fa-solid fa-file-pdf" class="me-1" />
      دانلود pdf
    </btn-ayandeh>
  </div>
  <div class="d-flex justify-content-between">
    <h1 class="title_card" v-text="title_page"></h1>
    <div>
      تعداد :
      <loading-spinner :show="true" size="sm" color="muted" v-if="loading" />
      <span v-else v-text="orders.total || 0"></span>
    </div>
  </div>
  <div class="table-ay1">
    <table>
      <thead>
        <tr>
          <th>ردیف</th>
          <th>ش سفارش</th>
          <th>نام مشتری</th>
          <th>نام محصول</th>
          <th>مقصد بار</th>
          <th>ش بارنامه</th>
          <th>تاریخ بارنامه</th>
          <template v-if="orders.data.length">
            <th v-if="has_order_loaded">تاریخ بارگیری</th>
            <th v-if="has_order_dis_charged">تاریخ تخلیه</th>
          </template>
          <th>پلاک</th>
          <th>اسناد اضافه</th>
          <th>وضعیت</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr v-for="x in 6" :key="x">
            <td v-for="i in colspan" :key="i">
              <shine-loading />
            </td>
          </tr>
        </template>
        <tr v-else-if="orders.data.length == 0">
          <td :colspan="colspan" class="text-center py-4">
            هیچ سفارشی برای نمایش وجود ندارد!
          </td>
        </tr>
        <template v-else v-for="(order, index) in orders.data" :key="index">
          <tr v-if="order == undefined" class="tr_deleted">
            <td colspan="9" class="p-0"></td>
            <div>لغو شده</div>
          </tr>
          <tr
            class="position-relative right-transition"
            :ref="'order' + index"
            v-else
            @click.right.prevent="show_more_menu(order)"
          >
            <td>
              <div class="mobile_title">ردیف :</div>
              {{
                orders.per_page * orders.current_page -
                orders.per_page +
                index +
                1
              }}
            </td>
            <td>
              <div class="mobile_title">شماره سفارش :</div>
              {{ order.order_id }}
            </td>
            <td>
              <div class="mobile_title">نام مشتری :</div>
              {{ get_fullname(order.user) }}
            </td>
            <td>
              <div class="mobile_title">نام محصول :</div>
              {{ order.product.title }}
            </td>
            <td>
              <div class="mobile_title">مقصد بار :</div>
              {{ order.city.title }}
            </td>
            <td>
              <div class="mobile_title">شماره بارنامه :</div>
              {{ order.number_bar }}
            </td>
            <td>
              <div class="mobile_title">تاریخ بارنامه :</div>
              {{ to_jalali(order.date_number_bar) }}
            </td>
            <td v-if="order.date_loaded || has_order_loaded">
              <div class="mobile_title">تاریخ بارگیری :</div>
              {{ to_jalali(order.date_loaded) }}
            </td>
            <td v-if="order.date_disCharge || has_order_dis_charged">
              <div class="mobile_title">تاریخ تخلیه :</div>
              {{ to_jalali(order.date_disCharge) }}
            </td>
            <td>
              <license-plate :car="order.car" size="sm" />
            </td>
            <td>
              <div class="mobile_title">اسناد اضافه :</div>
              <p
                class="p-2 mb-0 c-pointer text-ayandeh"
                v-text="order.other_sanads_count"
                @click="show_modal_other_sanads(order.id)"
              ></p>
            </td>
            <td>
              <div class="mobile_title">وضعیت :</div>
              <span
                :class="{
                  'text-danger': order.status == -1,
                  'text-info': order.status == 1,
                  'text-success': order.status > 1,
                }"
              >
                {{ order.status_title }}
              </span>
            </td>
            <td>
              <button
                v-c-tooltip="{
                  content: 'کلیک راست',
                  placement: 'right',
                }"
                @click="show_more_menu(order, index)"
                class="btn btn-light bg-transparent py-1 px-2 border-0 c-pointer"
              >
                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!loading && orders.to > 1"
    :current_page="page"
    :paginate_data="orders"
    class="mt-3"
    @go_to="get_orders"
  ></Pagination>

  <CModal
    size="sm"
    :transition="true"
    :visible="show_modal_more_option"
    @close="reset_modal_more"
  >
    <CModalBody>
      <p v-if="order_selected">سفارش {{ order_selected.order_id }}</p>
      <div
        style="height: 180px"
        class="d-flex"
        v-if="$store.state.loading_get_order"
      >
        <loading-spinner class="m-auto" :show="true" />
      </div>
      <template v-else>
        <div class="d-flex mb-2">
          <button
            class="btn btn-danger w-33 me-1"
            :disabled="order_selected.status == -1"
            @click="confirm_cancel_order(order_selected.id)"
            :loading="loading_cancel_order"
          >
            لغو
            <font-awesome-icon icon="fa-solid fa-ban" />
          </button>
          <button class="btn btn-info w-66" @click="edit_order">
            ویرایش
            <font-awesome-icon icon="fa-solid fa-pen" />
          </button>
        </div>
        <btn-ayandeh
          v-if="order_selected.status == 1"
          label="ثبت بارگیری"
          @click="showModalLoading"
          class="w-100 mb-2"
        />
        <btn-ayandeh
          @click="set_disCharge"
          v-else-if="order_selected.status == 2"
          class="w-100 mb-2"
        >
          <font-awesome-icon icon="fa-solid fa-truck-ramp-box" size="sm" />
          تایید تخلیه
        </btn-ayandeh>
        <btn-ayandeh class="w-100" color="success" @click="showModalDetail">
          <font-awesome-icon icon="fa-solid fa-eye" size="sm" />
          جزئیات
        </btn-ayandeh>
      </template>
    </CModalBody>
  </CModal>

  <CModal
    :transition="true"
    size="lg"
    :visible="show_modal_download_pdf"
    @close="show_modal_download_pdf = false"
  >
    <CModalHeader dismiss @close="show_modal_download_pdf = false">
      <CModalTitle class="fs-14">دانلود فایل pdf از سفارشات</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <div
          class="col-12 border-xs-left-0 d-sm-grid"
          :class="{
            'border-end': show_sum_amounts_in_pdf,
            'col-md-6': show_sum_amounts_in_pdf,
          }"
        >
          <div class="align-self-start">
            <!--    تفکیک    -->
            <CFormSwitch
              id="detail_kala"
              class="c-pointer"
              label="نمایش جزئیات به تفکیک کالا"
              v-model="detail_kala_in_pdf_orders"
            />
            <div class="ps-4" v-if="detail_kala_in_pdf_orders">
              <CFormSwitch
                id="show_more_price_arz"
                class="c-pointer"
                label="نمایش ریز اطلاعات"
                v-model="more_detail_kala_in_pdf_orders"
              />
            </div>
            <!--    کرایه    -->
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="detail_price_rent"
                label="نمایش مبالغ مربوط به کرایه"
                v-model="price_rent_in_pdf_orders"
              />
            </div>
            <div class="ps-4" v-if="price_rent_in_pdf_orders">
              <CFormSwitch
                id="more_show_more_price_arz"
                class="c-pointer"
                label="نمایش ریز اطلاعات"
                v-model="more_price_rent_in_pdf_orders"
              />
            </div>
            <!--    اسناد حسابداری    -->
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="detail_price_snd"
                label="نمایش مبالغ مربوط به اسناد حسابداری اضافه"
                v-model="price_snd_in_pdf_orders"
              />
            </div>
            <!--    جمع حساب    -->
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="sum_amounts"
                class="c-pointer"
                label="نمایش جمع حساب ها"
                v-model="show_sum_amounts_in_pdf"
              />
            </div>
            <!--    سفارشات    -->
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="show_orders"
                class="c-pointer"
                label="نمایش سفارشات درون فایل"
                v-model="show_orders_in_pdf"
              />
            </div>
            <div class="ps-4" v-if="show_orders_in_pdf">
              <CFormSwitch
                id="show_price_arz"
                class="c-pointer"
                label="نمایش نرخ ارز"
                v-model="show_price_arz_in_pdf"
              />
            </div>
          </div>
          <btn-ayandeh
            class="w-100 text-white align-self-end"
            color="danger"
            :loading="loading_download"
            :disabled="disabled_btn_download_pdf"
            @click="download_pdf_orders"
          >
            <font-awesome-icon icon="fa-solid fa-download" class="me-1" />
            دانلود فایل pdf
          </btn-ayandeh>
        </div>
        <div class="col-12 col-md-6" v-if="show_sum_amounts_in_pdf">
          <p class="mb-1">افزودن ردیف های دلخواه برای اعمال فاکتور pdf</p>
          <div class="mb-3">
            <btn-ayandeh class="text-white" color="info" @click="add_row">
              <font-awesome-icon icon="fa-solid fa-plus" />
            </btn-ayandeh>
          </div>
          <div
            class="alert alert-info mb-3"
            v-for="(row, index) in rows_addition_amount_pdf"
            :key="index"
          >
            <div class="text-end">
              <font-awesome-icon
                size="lg"
                icon="fa-solid fa-xmark"
                class="text-info c-pointer"
                @click="remove_row(index)"
              />
            </div>
            <div class="d-flex mb-2">
              <div class="form-group w-40">
                <label class="d-block">واحد ارز</label>
                <select
                  class="form-control"
                  v-model="row.type_arz"
                  @change="
                    row.type_arz_fa = $store.state.type_arz_arr.filter(
                      (value, index) => index == row.type_arz,
                    )[0]
                  "
                >
                  <option
                    v-for="(type_arz_fa, type_arz_en) in $store.state
                      .type_arz_arr"
                    :key="type_arz_en"
                    :value="type_arz_en"
                    v-text="type_arz_fa"
                  ></option>
                </select>
              </div>
              <div class="w-40 px-1">
                <input-ayandeh
                  type="text"
                  dir="ltr"
                  label="مبلغ"
                  :number-format="row.type_arz == '' ? true : false"
                  v-model:value="row.amount"
                />
              </div>
              <div class="d-flex w-20">
                <font-awesome-icon
                  icon="fa-regular fa-plus-square"
                  size="2xl"
                  class="text-success m-auto mb-0 c-pointer"
                  v-if="row.amount_type == '+'"
                  @click="row.amount_type = '-'"
                />
                <font-awesome-icon
                  icon="fa-regular fa-minus-square"
                  size="2xl"
                  class="text-danger m-auto mb-0 c-pointer"
                  v-else-if="row.amount_type == '-'"
                  @click="row.amount_type = '+'"
                />
              </div>
            </div>
            <input-ayandeh label="شرح" v-model:value="row.description" />
          </div>
        </div>
      </div>
    </CModalBody>
  </CModal>

  <modal-order-details
    v-model:order_id="order_selected_id"
    v-model:visiable="visiable_modal_detail"
  />

  <modal-final-loading
    v-model:order_id="order_selected_id"
    v-model:visiable="visiable_modal_loading"
    @loaded="loaded_order"
  />

  <modal-manage-sanad
    @refresh="load_data_modal_other_sanads"
    v-model:order_id="order_selected_showing_other_sanads"
  />
</template>

<script>
import LicensePlate from '@/components/partial/LicensePlate'
import Pagination from '@/components/partial/Pagination'
import FilterCard from '@/components/partial/FilterCard'
import InputDate from '@/components/inputs/InputDate'

import { mapActions } from 'vuex'
import Order from '@/mixin/Order'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ModalOrderDetails from '@/views/orders/ModalOrderDetails'
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import ModalFinalLoading from '@/views/orders/ModalFinalLoading'
import ModalManageSanad from '@/views/sanads_other/ModalManageSanad.vue'

export default {
  name: 'Orders',
  mixins: [Order],
  components: {
    ModalManageSanad,
    ModalFinalLoading,
    LoadingSpinner,
    ModalOrderDetails,
    FontAwesomeIcon,
    InputDate,
    FilterCard,
    Pagination,
    LicensePlate,
  },
  data: () => {
    return {
      loading: false,
      orders: {
        data: [],
        to: 0,
      },
      plate: {},
      status: 1,
      number_bar: '',
      order_id: '',
      referred_code: '',
      factory_id: '',
      from_date: '',
      to_date: '',
      city_id: [],
      filter_date_type: 'date_number_bar',
      options_date_filter: [
        {
          label: 'صدور بارنامه',
          value: 'date_number_bar',
        },
        {
          label: 'بارگیری',
          value: 'date_loaded',
        },
        {
          label: 'تخلیه',
          value: 'date_disCharge',
        },
      ],
      options_status_filter: [
        {
          label: 'همه',
          value: '',
        },
        {
          label: 'در انتظار بارگیری',
          value: 1,
        },
        {
          label: 'در انتظار تخلیه',
          value: 2,
        },
        {
          label: 'تخلیه شده',
          value: 3,
        },
      ],
      source: null,
      show_modal_more_option: false,
      visiable_modal_detail: false,
      visiable_modal_loading: false,
      user_id: '',
      show_modal_download_pdf: false,
      loading_download: false,
      download_pdf: false,
      // Permissions
      detail_kala_in_pdf_orders: false,
      more_detail_kala_in_pdf_orders: false,
      price_rent_in_pdf_orders: false,
      more_price_rent_in_pdf_orders: false,
      price_snd_in_pdf_orders: false,
      show_price_arz_in_pdf: false,
      show_orders_in_pdf: false,
      show_sum_amounts_in_pdf: false,
      rows_addition_amount_pdf: [],
      order_selected_showing_other_sanads: null,
    }
  },
  mounted() {
    this.visiable_modal_loading = this.visiable_modal_detail = false
    this.set_url_query_to_data()
    this.get_orders(this.page)
    this.get_factories()
    this.get_site_users(2)
    this.get_cities({
      cities_has_card: true,
    })
  },
  computed: {
    order_selected_id() {
      return this.$store.state.order ? this.$store.state.order.id : ''
    },
    order_selected() {
      return this.$store.state.order
    },
    disabled_btn_download_pdf: function () {
      if (
        this.loading_download ||
        (this.rows_addition_amount_pdf.length &&
          this.rows_addition_amount_pdf.filter(
            (x) => x.amount == '' || x.description == '',
          ).length)
      ) {
        return true
      }
      return false
    },
    colspan() {
      var colspan = 11
      if (this.has_order_loaded) colspan++
      if (this.has_order_dis_charged) colspan++
      return colspan
    },
    has_order_dis_charged() {
      return this.orders.data.filter((x) => x.date_disCharge != null).length
    },
    has_order_loaded() {
      return this.orders.data.filter((x) => x.date_loaded != null).length
    },
    title_page() {
      if (this.status == '') {
        return 'همه سفارشات'
      } else {
        return (
          'سفارشات ' +
          this.options_status_filter.filter((x) => x.value == this.status)[0]
            .label
        )
      }
    },
  },
  methods: {
    ...mapActions([
      'get_order',
      'get_factories',
      'get_site_users',
      'get_types_arz',
      'get_other_sanads',
      'get_cities',
      'toggle_modal_other_sanads',
    ]),
    loaded_order() {
      this.get_orders(this.page)
    },
    showModalDetail() {
      this.show_modal_more_option = false
      this.visiable_modal_detail = true
    },
    showModalLoading() {
      this.show_modal_more_option = false
      this.visiable_modal_loading = true
    },
    add_row() {
      this.rows_addition_amount_pdf.push({
        type_arz: '',
        type_arz_fa: 'ریال',
        amount: '',
        amount_type: '-',
        description: '',
      })
    },
    remove_row(index) {
      this.rows_addition_amount_pdf.splice(index, 1)
    },
    open_modal_download_pdf() {
      this.show_modal_download_pdf = true
      if (this.$store.state.type_arz_arr.length == 0) {
        this.get_types_arz()
      }
    },
    download_pdf_orders() {
      this.loading_download = this.download_pdf = true
      this.get_orders(this.page)
    },
    set_disCharge() {
      this.show_modal_more_option = false
      setTimeout(
        function () {
          this.$router.push({
            name: 'DisCharge',
            params: { id: this.order_selected.id },
          })
        }.bind(this),
        200,
      )
    },
    edit_order() {
      this.show_modal_more_option = false
      setTimeout(
        function () {
          this.$router.push({
            name: 'EditOrder',
            params: {
              id: this.order_selected.id,
            },
            query: {
              redirect: this.$route.fullPath,
            },
          })
        }.bind(this),
        200,
      )
    },
    confirm_cancel_order(order_id) {
      Swal.fire({
        input: 'textarea',
        inputPlaceholder: 'توضیحات لازم برای لغو (اختیاری)',
        confirmButtonText: 'لغو سفارش',
        confirmButtonColor: 'danger',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'لغو سفارش',
        text: 'آیا میخواهید سفارش مورد نظر لغو شود؟',
        preConfirm: (description) => {
          this.cancel_order(order_id, `${description}`)
          this.show_modal_more_option = false
        },
      })
    },
    show_more_menu(order, index) {
      this.get_order(order.id)
      this.order_selected_index = index
      this.show_modal_more_option = true
    },
    reset_modal_more() {
      this.show_modal_more_option = false
      this.$store.commit('storeOrder', null)
      this.$store.commit('storeOrder', null)
    },
    get_orders(page = 1) {
      if (this.source) {
        this.source.cancel()
      }

      if (this.download_pdf) {
        this.loading_download = true
      } else {
        this.loading = true
      }

      // eslint-disable-next-line no-undef
      this.source = axios.CancelToken.source()
      this.page = page
      var data = {
        page: this.page,
        status: this.status,
        number_bar: this.number_bar,
        order_id: this.order_id,
        referred_code: this.referred_code,
        factory: this.factory_id || '',
        filter_date_type: this.filter_date_type,
        from_date: this.from_date || '',
        to_date: this.to_date || '',
        user_id: this.user_id || '',
        city_ids:
          !this.city_id || this.city_id?.length === 0 ? [] : [this.city_id],
        download_pdf: this.download_pdf ? true : '',
        title_page: this.title_page,
        detail_kala_in_pdf: this.detail_kala_in_pdf_orders || '',
        more_detail_kala_in_pdf: this.more_detail_kala_in_pdf_orders || '',
        price_rent_in_pdf: this.price_rent_in_pdf_orders || '',
        more_price_rent_in_pdf: this.more_price_rent_in_pdf_orders || '',
        price_snd_in_pdf: this.price_snd_in_pdf_orders || '',
        show_orders_in_pdf: this.show_orders_in_pdf || '',
        show_price_arz_in_pdf: this.show_price_arz_in_pdf || '',
        show_sum_amounts_in_pdf: this.show_sum_amounts_in_pdf || '',
        date_filter_label: this.options_date_filter.filter(
          (x) => x.value == this.filter_date_type,
        )[0].label,
        rows_addition_amount_pdf: this.rows_addition_amount_pdf,
      }
      if (Object.keys(this.plate).length) {
        Object.assign(data, this.plate)
      }
      // eslint-disable-next-line no-undef
      axios
        .post('/orders/get_orders', data, {
          cancelToken: this.source.token,
        })
        .then((resource) => {
          if (
            resource &&
            resource.data != undefined &&
            resource.data.status == 'success'
          ) {
            if (this.download_pdf) {
              this.show_modal_download_pdf = false
              this.toast(resource.data.msg)
              this.ajax_download(resource.data.path, resource.data.origin_name)
              this.download_pdf = this.loading_download = false
            } else {
              this.loading = false
              this.orders = resource.data.orders
            }
          }
        })
    },
    check_get_order() {
      if (this.$store.state.jalali_validate) {
        this.get_orders(1)
      }
    },
    show_modal_other_sanads(order_id) {
      this.order_selected_showing_other_sanads = order_id
      this.toggle_modal_other_sanads(true)
      this.load_data_modal_other_sanads()
    },
    load_data_modal_other_sanads() {
      var orderId
      if (this.order_selected_id) {
        orderId = this.order_selected_id
      } else {
        orderId = this.order_selected_showing_other_sanads
      }
      this.get_other_sanads({
        order_id: orderId,
        by_bed_bes_name: true,
      })
    },
  },
  watch: {
    factory_id() {
      this.get_orders(1)
    },
    status(newValue) {
      this.set_data_to_url({
        status: newValue,
      })
    },
    user_id(newValue) {
      this.set_data_to_url({
        user_id: newValue,
      })
      this.get_orders(1)
    },
    city_id(value) {
      this.get_orders(1)
    },
  },
}
</script>
