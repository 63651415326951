<template>
  <filter-card
    v-model:factory_id="factory_id"
    v-model:card_id="card_id"
    v-model:product_id="product_id"
    @refresh="get_cards(1)"
  >
    <template #static>
      <!--    User Filter     -->
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="mb-2">
            <font-awesome-icon
              icon="fa-regular fa-user"
              class="text-icon-light"
            />
            فیلتر مشتری
          </label>
          <v-select
            v-model="user_id"
            :filter="userSearchVueSelect"
            :options="$store.state.site_users_options"
            :reduce="(option) => option.user_id"
            placeholder="فیلتر مشتری"
            dir="rtl"
            :loading="$store.state.site_users_options_loading"
          ></v-select>
        </div>
      </div>
      <!--    Status Filter     -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div class="form-group">
          <label>براساس وضعیت</label>
          <select class="form-control" v-model="status" @change="refresh">
            <option
              v-for="option in options_status_filter"
              :key="option.value"
              :value="option.value"
              v-text="option.label"
            ></option>
          </select>
        </div>
      </div>
      <!--    City Filter     -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div class="form-group">
          <label class="mb-2">
            <font-awesome-icon
              icon="fa-solid fa-map-pin"
              class="text-icon-light"
            />
            شهر تخلیه (نام مرز)
          </label>
          <v-select
            v-model="city_id"
            :options="city_options"
            :reduce="(option) => option.value"
            placeholder="انتخاب مرز تخلیه"
            dir="rtl"
            :loading="$store.state.loading_cities"
          ></v-select>
        </div>
      </div>
      <div class="col-12 text-end">
        <btn-ayandeh
          @click="reset_filter"
          color="outline-danger"
          class="btn-sm"
        >
          <font-awesome-icon icon="filter-circle-xmark" />
          حذف فیلتر
        </btn-ayandeh>
      </div>
    </template>
  </filter-card>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h1 class="title_card">کارت ها</h1>
        <span>
          تعداد :
          <loading-spinner
            size="sm"
            :show="loading_get_cards"
          ></loading-spinner>
          <template v-if="!loading_get_cards">{{
            paginate_datas.total
          }}</template>
        </span>
      </div>
      <div class="table-ay1">
        <table>
          <thead>
            <tr>
              <th>شماره کارت</th>
              <th>نام مشتری</th>
              <th>نام محصول</th>
              <th>محل تخلیه</th>
              <th>اسناد اضافه</th>
              <th>وضعیت</th>
              <th style="min-width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <loading-tr v-if="loading_get_cards" :colspan="7" />
            <empty-tr
              v-else-if="paginate_datas.data.length == 0"
              :colspan="7"
            />
            <tr
              v-else
              class="position-relative right-transition"
              v-for="(card, index) in paginate_datas.data.filter(
                (x) => x != undefined,
              )"
              :key="card.id"
              :ref="'tr_' + index"
            >
              <td>
                <div class="mobile_title">شماره کارت :</div>
                {{ card.id }}
              </td>
              <td>
                <div class="mobile_title">نام مشتری :</div>
                {{ get_fullname(card.user) }}
              </td>
              <td>
                <div class="mobile_title">نام محصول :</div>
                {{ card.product.title }}
              </td>
              <td>
                <div class="mobile_title">محل تخلیه :</div>
                {{ card.city.title }}
              </td>
              <td>
                <div class="mobile_title">اسناد اضافه :</div>
                <p
                  class="p-2 mb-0 c-pointer text-ayandeh"
                  v-text="card.other_sanads_count"
                  @click="show_modal_other_sanads(card.id)"
                ></p>
              </td>
              <td>
                <div class="mobile_title">وضعیت :</div>
                <span v-if="card.status" class="text-success">فعال</span>
                <span v-else class="text-danger">غیر فعال</span>
              </td>
              <td class="ltr">
                <btn-ayandeh
                  @click="show_detail(card.id)"
                  class="btn-sm"
                  label="جزئیات"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      v-if="paginate_datas.data.length"
      :current_page="page"
      :paginate_data="paginate_datas"
      class="mt-3"
      @go_to="get_cards"
    ></Pagination>
  </div>

  <modal-card-details
    v-model:card_id="card_selected_id"
    v-model:visiable="visiable_modal_detail"
  />

  <modal-manage-sanad
    @refresh="refreshed_modal_manage_snd"
    v-model:card_id="card_selected_id"
  />
</template>
<script>
import LoadingTr from '@/components/loading/LoadingTr'
import EmptyTr from '@/components/partial/EmptyTr'
import Pagination from '@/components/partial/Pagination'
import FilterCard from '@/components/partial/FilterCard'
import ModalCardDetails from '@/views/cards/ModalCardDetails'
import { mapActions } from 'vuex'
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import ModalManageSanad from '@/views/sanads_other/ModalManageSanad.vue'

export default {
  name: 'CardList',
  components: {
    ModalManageSanad,
    LoadingSpinner,
    ModalCardDetails,
    FilterCard,
    Pagination,
    EmptyTr,
    LoadingTr,
  },
  data() {
    return {
      card_selected_id: null,
      loading_get_cards: false,
      status: '',
      source: null,
      visiable_modal_detail: false,
      options_status_filter: [
        {
          label: 'همه',
          value: '',
        },
        {
          label: 'غیر فعال',
          value: 0,
        },
        {
          label: 'فعال',
          value: 1,
        },
      ],
      factory_id: '',
      product_id: '',
      user_id: '',
      card_id: '',
      city_id: '',
    }
  },
  beforeMount() {
    this.set_url_query_to_data()
  },
  mounted() {
    this.visiable_modal_detail = false
    this.get_factories()
    this.get_products()
    this.get_site_users(2)
    this.get_cards(1)
    this.get_cities({
      cities_has_card: true,
    })
  },
  methods: {
    ...mapActions([
      'get_factories',
      'get_products',
      'get_site_users',
      'get_cities',
      'get_other_sanads',
      'toggle_modal_other_sanads',
    ]),
    show_detail(card_id) {
      this.card_selected_id = card_id
      this.visiable_modal_detail = true
    },
    refresh() {
      this.set_data_to_url({
        status: this.status,
      })
      this.get_cards(1)
    },
    get_cards(page) {
      if (this.source) {
        this.source.cancel()
      }
      this.page = page
      // eslint-disable-next-line no-undef
      this.source = axios.CancelToken.source()

      this.loading_get_cards = true
      this.reset_paginate_data()
      // eslint-disable-next-line no-undef
      axios
        .get('/card/get_cards', {
          cancelToken: this.source.token,
          params: {
            status: this.status,
            page: this.page,
            factory_id: this.factory_id,
            product_id: this.product_id,
            card_id: this.card_id,
            user_id: this.user_id,
            city_id: this.city_id,
          },
        })
        .then((response) => {
          if (response !== undefined && response.data.status === 'success') {
            this.paginate_datas = response.data.cards
            this.loading_get_cards = false
          }
        })
    },
    show_modal_other_sanads(card_id) {
      this.toggle_modal_other_sanads(true)
      this.card_selected_id = card_id
      this.load_data_modal_other_sanads()
    },
    load_data_modal_other_sanads() {
      this.get_other_sanads({
        card_id: this.card_selected_id,
        by_bed_bes_name: true,
      })
    },
    refreshed_modal_manage_snd() {
      this.load_data_modal_other_sanads()
      this.get_cards(this.page)
    },
    reset_filter() {
      this.status = this.$route.params.status ?? ''
      this.factory_id = ''
      this.product_id = ''
      this.user_id = ''
      this.card_id = ''
      this.city_id = ''
    },
  },
  watch: {
    user_id(newValue) {
      this.set_data_to_url({
        user_id: newValue,
      })
      this.get_cards(1)
    },
    city_id(newValue) {
      this.set_data_to_url({
        city_id: newValue,
      })
      this.get_cards(1)
    },
  },
}
</script>

<style scoped></style>
