<template>
  <h1 class="fs-15 fw-bold">جزئیات پرداخت</h1>
  <!--  Show Payment  -->
  <div class="card" v-if="payment_loading == false && payment">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-3 d-none d-md-block text-end">
          <font-awesome-icon
            class="c-pointer p-2"
            icon="fa-solid fa-list-ul"
            v-if="show_type == 'grid'"
            size="xl"
            @click="show_type = 'list'"
          />
          <font-awesome-icon
            class="c-pointer p-2"
            icon="fa-solid fa-grip"
            v-if="show_type == 'list'"
            size="xl"
            @click="show_type = 'grid'"
          />
        </div>
        <div class="col-12 mb-3" :class="{ 'col-md-4': show_type == 'grid' }">
          <p class="text-ayandeh bold mb-1">اطلاعات کلی پرداخت</p>
          <div class="row m-0">
            <div
              class="col-12 bg-ayandeh text-white p-3"
              :class="{
                'rounded-top': show_type == 'grid',
                'rounded-start': show_type == 'list',
                'col-md-5': show_type == 'list',
              }"
            >
              <div class="d-flex justify-content-between mb-2">
                <div>
                  <i class="fa fa-user"></i>
                  شخص بدهکار :
                </div>
                <div class="">
                  {{ payment.bed_name }}
                  <small
                    class="d-block text-end"
                    v-text="payment.id ? payment.bed_id : ''"
                  ></small>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2" title="فرامحاسب">
                شماره پرداخت :
                <div v-text="payment.fm_pardakht_id"></div>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                v-if="payment.local_payment_id"
                title="فرامحاسب"
              >
                ش پرداخت متناظر در داخلی :
                <div
                  v-text="local_payment ? local_payment.fm_pardakht_id : '-/-'"
                ></div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                شماره سند :
                <div v-text="payment.fm_snd_id"></div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                تاریخ :
                <div v-text="to_jalali(payment.date, 'jYYYY/jMM/jDD')"></div>
              </div>
            </div>
            <div
              class="col-12 bg-custom-ligh-1 p-3"
              :class="{
                'rounded-bottom': show_type == 'grid',
                'rounded-end': show_type == 'list',
                'col-md-7': show_type == 'list',
              }"
            >
              <div
                class="d-flex justify-content-between mb-2"
                :class="{ titr_line_dott: show_type == 'list' }"
              >
                <span>سال مالی :</span>
                <span
                  class="d-inline-block ltr"
                  v-text="payment.fiscal_year"
                ></span>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                :class="{ titr_line_dott: show_type == 'list' }"
                v-if="payment.payment_able_id"
              >
                <span>شناسه سند پرداختنی :</span>
                <span
                  class="d-inline-block ltr"
                  v-text="payment.payment_able_id"
                ></span>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                :class="{ titr_line_dott: show_type == 'list' }"
              >
                <span>نوع شخص بدهکار :</span>
                <span v-if="payment.user_id">کاربر سایت</span>
                <span v-else-if="payment.car_id">خودرو سایت</span>
                <span v-else>سایر کد ها</span>
              </div>
              <div v-if="payment.car_id">
                <show-plaque :car="payment.car" size="sm"></show-plaque>
              </div>
              <div>
                <div class="separate_dash w-75"></div>
              </div>
              <div
                class="d-flex justify-content-between mb-2"
                :class="{ titr_line_dott: show_type == 'list' }"
              >
                <span> جمع مبلغ پرداختی : </span>
                <div>
                  <currency :parentheses="true" />
                  {{ toNumberFormat(get_sum_amount_payment(payment)) }}
                </div>
              </div>
              <div class="mb-3">
                <span class="text-ayandeh bold">شرح پرداخت :</span>
                <br />
                {{ payment.description || '-' }}
              </div>
              <div
                v-if="payment.local_payment_id && payment.bed_id == 105010058"
              >
                <p class="text-ayandeh bold">اطلاعات پرداخت در سیستم داخلی</p>
                <div
                  class="d-flex justify-content-between mb-2"
                  :class="{ titr_line_dott: show_type == 'list' }"
                >
                  <span>شخص بدهکار : </span>
                  <span v-text="local_payment.bed_name"></span>
                </div>
              </div>
              <btn-ayandeh
                class="btn-sm"
                color="info"
                @click="show_modal_edit_payment(payment)"
              >
                <font-awesome-icon icon="fa-solid fa-pen" />
                ویرایش
              </btn-ayandeh>
            </div>
          </div>
        </div>
        <div class="col-12" :class="{ 'col-md-8': show_type == 'grid' }">
          <div class="list_group open">
            <p class="text-ayandeh bold mb-1">ردیف های پرداخت</p>
            <div
              class="list_option bg-custom-ligh-1 rounded p-3 mb-2"
              v-for="det in payment.details"
              :key="det.id"
            >
              <div class="row">
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                >
                  <span>مبلغ پرداخت شده</span>
                  <div>
                    <currency :parentheses="true" />
                    {{ toNumberFormat(det.amount) }}
                  </div>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                >
                  <span>ساعت پرداخت</span>
                  <span
                    class="ltr"
                    v-text="to_jalali(det.date_pay, 'HH:mm')"
                  ></span>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                >
                  <span>نوع</span>
                  <span class="ltr" v-text="det.type_payment_title"></span>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between titr_line_dott"
                >
                  <span>
                    {{ det.type_payment == 2 ? 'شماره چک' : 'شماره پیگیری' }}
                  </span>
                  <span v-text="det.tracking_code"></span>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                >
                  <span>حساب</span>
                  <span v-text="det.bes_name"></span>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 mb-md-0 titr_line_dott"
                  v-if="det.type_payment == 2"
                >
                  <span>تاریخ سر رسید چک</span>
                  <span
                    v-text="to_jalali(det.date_check, 'jYYYY/jMM/jDD')"
                  ></span>
                </div>
                <div
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 mb-md-0 titr_line_dott"
                  v-else-if="det.type_pay"
                >
                  <span>شیوه پرداخت</span>
                  <span v-text="det.type_pay_title"></span>
                </div>
                <div
                  v-if="det.fee"
                  class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                >
                  <span>کارمزد</span>
                  <div>
                    {{ det.fee }}
                    <currency :parentheses="true" />
                  </div>
                </div>

                <div class="col-12">
                  <span>شرح : </span>
                  <span v-text="det.description || '-'"></span>
                </div>
                <div class="col-12 mt-3" v-if="det.attachments.length">
                  <show-attachments
                    :deletable="true"
                    @deleted="deleted_attach"
                    ref="show_attachments"
                    :attachments="det.attachments"
                  ></show-attachments>
                </div>
                <template
                  v-if="det.local_payment_detail_id && det.bes_id == 105010058"
                >
                  <div class="col-12 mt-3">
                    <p class="text-ayandeh bold">اطلاعات ردیف سیستم داخلی</p>
                  </div>
                  <div
                    class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                  >
                    <span>نوع</span>
                    <div
                      class="shine-loading w-25"
                      v-if="local_payment_loading"
                    >
                      <div class="line sm"></div>
                    </div>
<!--                    <span-->
<!--                      class="ltr"-->
<!--                      v-text="find_local_det(det).type_payment_title"-->
<!--                      v-else-->
<!--                    ></span>-->
                  </div>
                  <div
                    class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                  >
                    <span>حساب</span>
                    <div
                      class="shine-loading w-25"
                      v-if="local_payment_loading"
                    >
                      <div class="line sm"></div>
                    </div>
<!--                    <span v-text="find_local_det(det).bes_name" v-else></span>-->
                  </div>
                  <div
                    v-if="find_local_det(det).fee"
                    class="col-12 col-sm-6 d-flex justify-content-between mb-2 titr_line_dott"
                  >
                    <span>کارمزد</span>
                    <div
                      class="shine-loading w-25"
                      v-if="local_payment_loading"
                    >
                      <div class="line sm"></div>
                    </div>
                    <div v-else>
                      {{ find_local_det(det).fee }}
                      <currency :parentheses="true" />
                    </div>
                  </div>
                </template>
<!--                <div-->
<!--                  class="col-12 mt-3"-->
<!--                  v-if="-->
<!--                    det.local_payment_detail_id &&-->
<!--                    find_local_det(det).attachments.length-->
<!--                  "-->
<!--                >-->
<!--                  <show-attachments-->
<!--                    label="فایل های پیوست داخلی"-->
<!--                    ref="show_attachments"-->
<!--                    :attachments="find_local_det(det).attachments"-->
<!--                  />-->
<!--                </div>-->
                <div class="col-12 pt-3">
                  <btn-ayandeh
                    class="btn-sm"
                    color="danger"
                    :loading="loading_delete_det"
                    @click="delete_detail(det)"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash-can" />
                  </btn-ayandeh>
                  <btn-ayandeh
                    :disabled="loading_delete_det"
                    class="btn-sm mx-2"
                    color="info"
                    @click="show_modal_edit_payment_detail(det)"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen" />
                  </btn-ayandeh>
                  <btn-ayandeh
                    :disabled="loading_delete_det"
                    class="btn-sm"
                    color="light"
                    @click="add_attach_for_payment_detail(det)"
                  >
                    <font-awesome-icon icon="fa-solid fa-paperclip" />
                    پیوست
                  </btn-ayandeh>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  Loading Get Data  -->
  <div class="text-center pt-5" v-else>
    <span>درحال خواندن اطلاعات</span>
    <dots-loading />
  </div>

  <!--  Edit Main Payment -->
  <CModal
    :visible="edit_payment"
    @close="edit_payment = false"
    class="modal-xl"
  >
    <CModalHeader
      dismiss
      @close="
        () => {
          edit_payment = false
        }
      "
    >
      <CModalTitle class="fs-14" v-if="payment">
        <font-awesome-icon icon="fa-solid fa-pen" size="lg" class="me-2" />
        ویرایش پرداخت شماره
        <b>{{ payment.fm_pardakht_id }}</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <payment-inputs-bed-part
          v-model:date_pay="date_pay"
          :default_date_pay="date_pay"
          v-model:bed_id="bed_id"
          v-model:bed_id_local="bed_id_local"
          v-model:desc_payment="desc_payment"
          v-model:store_in_local_prop="store_payment_in_local_system"
          :bes_ids="bes_ids"
          :payment_selected="payment"
        />
      </div>
    </CModalBody>
    <CModalFooter class="text-start">
      <btn-ayandeh :loading="loading_edit_payment" @click="update_payment">
        ذخیره
      </btn-ayandeh>
      <btn-ayandeh color="light" @click="edit_payment = false">
        بستن
      </btn-ayandeh>
    </CModalFooter>
  </CModal>

  <!--  Edit Payment Detail -->
  <CModal backdrop="static" :visible="edit_payment_detail" class="modal-xl">
    <CModalHeader dismiss>
      <CModalTitle class="fs-14" v-if="payment">
        <font-awesome-icon icon="fa-solid fa-pen" size="lg" class="me-2" />
        ویرایش ردیف پرداخت شماره
        <b>{{ payment.fm_pardakht_id }}</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="form-group">
            <label>نوع</label>
            <v-select
              v-model="type_payment_selected"
              :options="$store.state.payment_types"
              :reduce="(option) => option.value"
              placeholder="انتخاب نوع"
              dir="rtl"
              :loading="$store.state.payment_types_loading"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-8 mb-3">
          <div
            class="form-group mb-1"
            id="type_payment_selected"
            v-show="type_payment_selected != ''"
          >
            <label class="w-100">انتخاب حساب</label>
            <v-select
              v-model="bes_id"
              :options="hesab_option_select"
              :reduce="(option) => option.value"
              :selectable="(option) => option.value != bed_id"
              placeholder="انتخاب حساب"
              dir="rtl"
              :loading="loading_hesab"
            ></v-select>
          </div>
          <div v-if="bes_id != '' && type_payment_selected != 2">
            مانده :
            <loading-spinner
              v-if="loading_mandeh_bes"
              size="sm"
              color="muted"
              :show="loading_mandeh_bes"
            />
            <show-mandeh
              class="d-inline-block"
              v-else-if="codes_mandeh[bes_id]"
              :value="codes_mandeh[bes_id]"
            />
            <span v-else>-</span>
          </div>
        </div>
        <!--    Store In Local System     -->
        <div class="col-12" v-if="store_payment_row_in_local_system">
          <div class="alert alert-info">
            <p>ثبت پرداخت در سیستم داخلی</p>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 mb-3">
                <div class="form-group">
                  <label class="mb-2">نوع</label>
                  <v-select
                    v-model="type_payment_selected_local"
                    :options="$store.state.payment_types_local"
                    :reduce="(option) => option.value"
                    placeholder="انتخاب نوع"
                    dir="rtl"
                    :loading="$store.state.payment_types_local_loading"
                  ></v-select>
                </div>
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                v-if="type_payment_selected_local == 2"
              >
                <input-date
                  v-model:value="date_check_local"
                  placeholder="روز/ماه/سال"
                  label="تاریخ سررسید چک"
                />
              </div>
              <div
                class="col-12 col-sm-6 col-md-4 mb-3"
                v-if="type_payment_selected_local == 3"
              >
                <input-ayandeh
                  :number-format="true"
                  placeholder="کارمزد حواله را وارد کنید"
                  v-model:value="fee_local"
                  dir="ltr"
                >
                  مبلغ کارمزد
                  <currency :parentheses="true" />
                </input-ayandeh>
              </div>
              <div class="col-12 col-sm-6 col-md-8 mb-3">
                <div
                  class="form-group mb-1"
                  v-show="type_payment_selected_local != ''"
                >
                  <label class="w-100 mb-2">انتخاب حساب</label>
                  <v-select
                    v-model="bes_id_local"
                    :options="hesab_option_select_local"
                    :reduce="(option) => option.value"
                    placeholder="انتخاب حساب"
                    dir="rtl"
                    :loading="loading_hesab_local"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="مبلغ پرداخت شده را وارد کنید"
            v-model:value="amount"
            ref="amount"
            dir="ltr"
          >
            مبلغ پرداخت شده
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <input-ayandeh
            type="number"
            :label="type_payment_selected == 2 ? 'شماره چک' : 'کد پیگیری'"
            placeholder="شماره تراکنش/ کد رهگیری/ پیگیری"
            v-model:value="tracking_code"
            dir="ltr"
          />
        </div>
        <div
          class="col-12 col-sm-6 col-md-4 mb-3"
          v-if="type_payment_selected == 3"
        >
          <input-ayandeh
            :number-format="true"
            placeholder="کارمزد حواله را وارد کنید"
            v-model:value="fee"
            dir="ltr"
          >
            مبلغ کارمزد
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
          <input-date
            v-if="type_payment_selected == 2"
            v-model:value="date_check"
            placeholder="روز/ماه/سال"
            label="تاریخ سررسید چک"
          />
          <div class="form-group" v-else>
            <label class="mb-2">شیوه پرداخت</label>
            <select
              class="form-control"
              name="type_pay"
              v-model="type_pay_selected"
            >
              <option value="">انتخاب شیوه پرداخت</option>
              <option
                v-for="(type_pay, index) in $store.state.type_pay_options"
                :key="index"
                :value="index"
                v-text="type_pay"
              ></option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group mb-2">
            <label for="desc_row">شرح</label>
            <textarea
              class="form-control"
              id="desc_row"
              rows="2"
              v-model="desc_row_payment"
              placeholder="اختیاری"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <multiple-attach ref="attach_multiple" v-model:attach="attachs" />
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="text-start">
      <btn-ayandeh
        :loading="loading_edit_payment_det"
        :disabled="!form_naghdi_complete || loading_edit_payment_det"
        @click="verify_and_update_payment_detail"
      >
        ذخیره
      </btn-ayandeh>
      <btn-ayandeh color="light" @click="edit_payment_detail = false">
        بستن
      </btn-ayandeh>
    </CModalFooter>
  </CModal>

  <!--  Add AttachMent -->
  <CModal :visible="add_attachment_modal" @close="add_attachment_modal = false">
    <CModalHeader dismiss @close="add_attachment_modal = false">
      <CModalTitle class="fs-14" v-if="payment">
        <font-awesome-icon icon="fa-solid fa-paperclip" class="me-2" />
        افزودن فایل پیوست برای پرداخت شماره
        <b>{{ payment.fm_pardakht_id }}</b>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <div class="col-12 mb-3">
          <multiple-attach v-model:attach="attachs" />
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh
        :loading="loading_store_attach"
        :disabled="loading_store_attach || disabled_store_attach_btn"
        @click="store_attachments()"
      >
        ذخیره پیوست
      </btn-ayandeh>
      <btn-ayandeh color="light" @click="cancel_add_attach_payment_detail">
        بستن
      </btn-ayandeh>
    </CModalFooter>
  </CModal>
</template>

<script>
import payment_mixin from '@/mixin/Payment'
import DotsLoading from '@/components/loading/DotsLoading'
import Currency from '@/components/partial/Currency'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Swal from 'sweetalert2'
import PaymentInputsBedPart from '@/components/payment/PaymentInputsBedPart'
import ShowAttachments from '@/components/partial/ShowAttachments'
import MultipleAttach from '@/components/partial/MultipleAttach'

export default {
  name: 'ShowPayment',
  components: {
    MultipleAttach,
    ShowAttachments,
    PaymentInputsBedPart,
    FontAwesomeIcon,
    Currency,
    DotsLoading,
  },
  mixins: [payment_mixin],
  data() {
    return {
      payment: null,
      local_payment: null,
      edit_payment: false,
      payment_loading: false,
      local_payment_loading: false,
      editing_payment: null,
      visiable_modal_edit_payment: false,
      show_type: 'list',
      // Editing Data
      store_payment_in_local_system: false,
      loading_edit_payment: false,
      loading_delete_det: false,
      // Edit Payment Detail
      edit_payment_detail: false,
      loading_edit_payment_det: false,
      // Attach Payment Detail
      attachs: null,
      add_attachment_modal: false,
      attaching_payment_detail: {
        id: null,
      },
      loading_store_attach: false,
    }
  },
  mounted() {
    this.get_payment({
      id: this.$route.params.id,
    })
  },
  methods: {
    deleted_attach() {
      console.log('deletedd')
    },
    delete_detail(det) {
      var text =
        'آیا می خواهید پرداخت مورد نظر با مبلغ ' +
        "<b class='text-danger'>" +
        this.toNumberFormat(det.amount) +
        '</b> ' +
        this.currency +
        ' را حذف کنید؟'
      Swal.fire({
        confirmButtonText: 'بله حذف شود',
        cancelButtonText: 'خیر',
        showCancelButton: true,
        icon: 'question',
        title: 'حذف ردیف پرداخت',
        html: text,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_delete_det = true
          // eslint-disable-next-line no-undef
          axios.get('/payment/delete_detail/' + det.id).then(({ data }) => {
            if (data.status == 'success') {
              this.payment.details = data.payment_details
              if (data.payment_details.length == 0) {
                this.$router.push({ name: 'PaymentList' })
                // this.$router.replace(
                //   this.$router.resolve({ name: 'PaymentList' }).path,
                // )
              }
            }
            this.loading_delete_det = false
          })
        }
      })
    },
    show_modal_edit_payment(payment) {
      this.edit_payment = true
      this.editing_payment = payment
    },
    show_modal_edit_payment_detail(detail) {
      this.get_payment_types()
      this.get_type_pay_options()

      this.edit_payment_detail = true
      this.editing_payment_detail = detail
    },
    add_attach_for_payment_detail(detail) {
      this.add_attachment_modal = true
      this.attaching_payment_detail = detail
    },
    get_payment(params) {
      this.payment_loading = true
      var query = this.gen_query_string(params)
      // eslint-disable-next-line no-undef
      axios.get('/payment/get_payment?' + query).then(({ data }) => {
        if (data.status == 'success') {
          if (params.local_sys != undefined) {
            this.local_payment = data.payment
          } else {
            this.payment = data.payment
            if (data.payment == null) {
              this.my_alert('پرداخت مورد نظر یافت نشد', 'info')
            }
          }
          this.payment_loading = this.local_payment_loading = false
        }
      })
    },
    update_payment() {
      this.loading_edit_payment = true
      var form_data = {
        id: this.payment.id,
        bed_id: this.bed_id,
        bed_id_local: this.bed_id_local,
        date_pay: this.date_pay,
        description: this.desc_payment,
      }
      // eslint-disable-next-line no-undef
      axios.post('/payment/update_payment', form_data).then(({ data }) => {
        if (data.status == 'success') {
          this.payment = data.payment
          this.editing_payment = false
          if (this.payment.local_payment_id) {
            this.get_payment({
              id: this.payment.local_payment_id,
              local_sys: true,
            })
          }
        }
        this.my_alert(data.msg, data.status)
        this.loading_edit_payment = false
      })
    },
    find_local_det(det) {
      if (this.local_payment && this.local_payment.details != undefined) {
        return this.local_payment.details.filter(
          (x) => x.id == det.local_payment_detail_id,
        )[0]
      } else {
        return {
          fee: '',
          bes_name: '',
          type_payment_title: '',
        }
      }
    },
    verify_and_update_payment_detail() {
      if (!this.form_naghdi_complete) {
        this.my_alert('لطفا اطلاعات خواسته شده را بصورت صحیح پر کنید!')
        return false
      }

      this.loading_edit_payment = true
      var data = {
        bed_id: this.bed_id,
        date_pay: this.date_pay,
        tracking_code: this.tracking_code,
        amount: this.amount,
      }
      // eslint-disable-next-line no-undef
      axios
        .get(
          '/payment/check_duplicate_payment_det',
          this.gen_query_string(data),
        )
        .then((response) => {
          var data = response.data
          if (data.status == 'success') {
            this.update_detail_payment()
          } else if (data.status == 'error') {
            this.my_alert(data.msg, data.status)
          } else if (data.status == 'info') {
            Swal.fire({
              icon: 'question',
              html: data.msg,
              showDenyButton: true,
              showCloseButton: true,
              confirmButtonText: 'بله، ثبت شود',
              denyButtonText: 'انصراف',
            }).then((result) => {
              if (result.isConfirmed) {
                this.update_detail_payment()
              }
            })
          }
          this.loading_edit_payment = false
        })
    },
    update_detail_payment() {
      var date_check
      if (this.type_payment_selected == 2) {
        date_check = this.date_check
      } else {
        date_check = null
      }

      this.loading_edit_payment_det = true

      var formData = {
        id: this.editing_payment_detail.id,
        type_payment: this.type_payment_selected,
        type_pay: this.type_pay_selected,
        amount: this.amount.toString().replaceAll(/[^0-9]/g, ''),
        tracking_code: this.tracking_code,
        time_pay: this.time_pay,
        bes_id: this.bes_id,
        description: this.desc_row_payment || '',
        date_check: date_check,
        attachs: this.attachs,
        store_payment_row_in_local_system: this
          .store_payment_row_in_local_system
          ? true
          : '',
      }

      if (
        this.editing_payment_detail.local_payment_detail_id ||
        this.store_payment_row_in_local_system
      ) {
        Object.assign(formData, {
          type_payment_local: this.type_payment_selected_local,
          fee_local: this.fee_local,
          bes_id_local: this.bes_id_local,
          date_check_local: this.date_check_local,
        })
      }

      // eslint-disable-next-line no-undef
      axios
        .post('/payment/update_payment_detail', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            this.edit_payment_detail = false

            setTimeout(
              function () {
                this.payment = data.payment
                if (this.payment.local_payment_id) {
                  this.get_payment({
                    id: this.payment.local_payment_id,
                    local_sys: true,
                  })
                }
                this.editing_payment_detail = null
              }.bind(this),
              400,
            )
          }
          this.my_alert(data.msg, data.status)
          this.loading_edit_payment_det = false
        })
    },
    store_attachments() {
      this.loading_store_attach = true
      var formData = {
        attachmentable_type: 'payment',
        attachmentable_id: this.attaching_payment_detail.id,
        attachs: this.attachs,
      }

      // eslint-disable-next-line no-undef
      axios
        .post('/attachment/store', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            this.payment.details.filter(
              (x) => x.id == this.attaching_payment_detail.id,
            )[0].attachments = data.data.attachments
            this.cancel_add_attach_payment_detail()
          }
          this.my_alert(data.msg, data.status)
          this.loading_store_attach = false
        })
        .catch(() => {
          this.my_alert('error', 'خطایی رخ داد')
          this.loading_store_attach = false
        })
    },
    cancel_add_attach_payment_detail() {
      this.attaching_payment_detail = {
        id: null,
      }
      this.attachs = { 0: '' }
      this.add_attachment_modal = false
    },
  },
  computed: {
    bes_ids() {
      if (this.payment) {
        return this.payment.details.map((x) => {
          return x.bes_id
        })
      } else {
        return {}
      }
    },
    disabled_store_attach_btn() {
      if (this.attachs == null) {
        return true
      } else if (
        this.attachs != null &&
        Object.keys(this.attachs).length == 0
      ) {
        return true
      } else if (
        this.attachs != null &&
        Object.keys(this.attachs).length == 1 &&
        this.attachs[0] == ''
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    edit_payment(newValue) {
      if (!newValue) {
        this.editing_payment = null
      }
    },
    payment(newValue) {
      if (newValue) {
        if (newValue.local_payment_id) {
          this.get_payment({
            id: newValue.local_payment_id,
            local_sys: false,
          })
        }
      }
    },
    editing_payment(newValue) {
      if (newValue) {
        this.bed_id = newValue.bed_id
        this.desc_payment = newValue.description
        this.date_pay = this.to_jalali(newValue.date, 'jYYYY/jMM/jDD')

        if (newValue.local_payment_id) {
          this.bed_id_local = this.local_payment.bed_id
        }
      } else {
        this.bed_id = this.desc_payment = this.date_pay = this.bed_id_local = ''
      }
    },
    editing_payment_detail(newValue) {
      if (newValue) {
        this.type_payment_selected = newValue.type_payment
        this.desc_row_payment = newValue.description
        this.fee = newValue.fee
        this.tracking_code = parseInt(newValue.tracking_code)
        this.type_pay_selected = newValue.type_pay
        this.amount = this.toNumberFormat(newValue.amount)
        this.time_pay = this.to_jalali(newValue.date_pay, 'HH:mm')

        setTimeout(
          function () {
            this.bes_id = newValue.bes_id.toString()
          }.bind(this),
          200,
        )
        if (newValue.local_payment_detail_id) {
          var local_payment_detail = this.find_local_det(newValue)
          this.type_payment_selected_local = local_payment_detail.type_payment
          this.fee_local = local_payment_detail.fee
          this.get_payment_types(true)

          setTimeout(
            function () {
              this.bes_id_local = local_payment_detail.bes_id.toString()
            }.bind(this),
            200,
          )
        }
        // Just For Valid Payment
        this.bed_id = this.payment.bed_id
        this.date_pay = this.to_jalali(this.payment.date, 'jYYYY/jMM/jDD')
        this.$store.state.jalali_validate = true
      } else {
        this.bes_id = this.desc_row_payment = this.bes_id_local = ''
      }
    },
  },
}
</script>
