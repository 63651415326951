<template>
  <div class="bg-white bg-triangle p-3">
    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted">مبلغ</span>
      <div>
        <p class="fw-bold text-success mb-0 d-inline-block me-1">
          {{ toNumberFormat(paymentDet.amount) }}
        </p>
        <currency />
      </div>
    </div>
    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted">تاریخ</span>
      <span class="date">{{ to_jalali(paymentDet.date_pay, 'long') }}</span>
    </div>
    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted">کد پیگیری</span>
      <span>{{ paymentDet.tracking_code }}</span>
    </div>
    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted">شناسه حسابداری</span>
      <span>{{ payment.fm_pardakht_id }}</span>
    </div>
    <div class="d-flex justify-content-between mb-1">
      <span class="text-muted">نوع</span>
      <span class="date">{{ paymentDet.type_pay_title }}</span>
    </div>
    <div class="border-bottom mt-3 mb-2">
      <font-awesome-icon icon="fa-solid fa-paperclip" />
      <p class="d-inline-block mb-0 ms-1 fs-11">فایل های پیوست</p>
    </div>
    <div
      class="list-group btn-o list-group-horizontal"
      v-for="attach in paymentDet.attachments"
      :key="attach.id"
    >
      <div
        class="list-group-item text-center text-icon-light p-1 c-pointer"
        @click="show_file(attach.download_url)"
      >
        <font-awesome-icon
          icon="fa-solid fa-image"
          class="align-sub text-ayandeh"
        />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Currency from '@/components/partial/Currency'
import Swal from 'sweetalert2'
export default {
  name: 'ShowPaymentSmall',
  components: { Currency },
  props: {
    payment: {
      type: Object,
    },
    paymentDet: {
      type: Object,
    },
  },
  methods: {
    show_file(url) {
      Swal.fire({
        title: '',
        confirmButtonText: 'دانلود فایل',
        showCloseButton: true,
        text: 'فایل پیوست رسید تراکنش',
        imageUrl: url,
        imageWidth: 400,
        imageAlt: 'فایل پیوست',
      }).then((result) => {
        if (result.isConfirmed) {
          this.ajax_download(url)
          this.toast('در حال دانلود فایل', 'info')
        }
      })
    },
  },
}
</script>

<style scoped type="scss">
.bg-triangle {
  position: relative;
}
.bg-triangle:before {
  content: '';
  display: block;
  height: 10px;
  width: 100%;
  background-image: url('/public/img/plain-triangle.png');
  background-position: center;
  position: absolute;
  transform: rotate(180deg);
  left: 0;
  bottom: -9px;
}
.bg-triangle:after {
  content: '';
  display: block;
  height: 10px;
  width: 100%;
  background-image: url('/public/img/plain-triangle.png');
  background-position: center;
  position: absolute;
  left: 0;
  top: -10px;
}
.list-group-item {
  width: 30px;
  height: 30px;
}
</style>
