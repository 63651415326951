<template>
  <div class="layout_loading">
    <loading-spinner :show="true" size="sm" color="white" />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/loading/LoadingSpinner'
export default {
  name: 'LayoutLoading',
  components: { LoadingSpinner },
}
</script>

<style scoped></style>
