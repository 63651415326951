<template>
  <CModal
    :fullscreen="full_screen_modal_order_detail"
    size="xl"
    backdrop="static"
    :visible="visiable_var"
    @close="closed"
  >
    <CModalHeader :dismiss="false" class="d-flex justify-content-between">
      <CModalTitle class="fs-14">
        جزئیات کارت شماره
        <div class="d-inline-block" v-if="loading_get_card" style="width: 70px">
          <shine-loading :width="100" size="sm" />
        </div>
        <span v-else-if="card" v-text="card.id"></span>
      </CModalTitle>
      <div>
        <font-awesome-icon
          icon="fa-solid fa-compress"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_order_detail = false"
          v-if="full_screen_modal_order_detail"
        />
        <font-awesome-icon
          icon="fa-solid fa-expand"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_order_detail = true"
          v-else
        />
      </div>
    </CModalHeader>
    <CModalBody :class="{ 'd-none': !edit_card }">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            type="number"
            placeholder="تناژ اولیه کارت"
            v-model:value="tonage"
            dir="ltr"
          >
            <font-awesome-icon
              icon="fa-solid fa-weight-hanging"
              class="text-icon-light"
            />
            تناژ کارت
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>واحد ارزی فروش</label>
            <select class="form-control" v-model="type_arz">
              <option value="">انتخاب</option>
              <option value="rial">ریال</option>
              <option value="dollar">دلار</option>
              <option value="rupee">روپیه</option>
            </select>
          </div>
        </div>
        <template v-if="type_arz != '' && type_arz != 'rial'">
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              :number-format="true"
              placeholder="فی ارز انتخاب شده"
              v-model:value="price_arz"
              dir="ltr"
            >
              نرخ ارز
              <currency :parentheses="true" />
            </input-ayandeh>
          </div>
          <div class="col-12 col-sm-6 col-md-3 mb-3">
            <input-ayandeh
              placeholder="فی فروش ارزی"
              v-model:value="price_per_ton_arz"
              dir="ltr"
            >
              فی فروش ارزی
            </input-ayandeh>
          </div>
        </template>
        <div
          class="col-12 col-sm-6 col-md-3 mb-3"
          v-else-if="type_arz == 'rial'"
        >
          <input-ayandeh
            :number-format="true"
            placeholder="فی فروش"
            v-model:value="price_per_ton"
            dir="ltr"
          >
            فی فروش
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>پرداخت کرایه توسط</label>
            <select class="form-control" v-model="rent_by">
              <option value="">انتخاب</option>
              <option value="ayandeh">شرکت آینده</option>
              <option value="user">مشتری</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <div class="form-group">
            <label>ترخیص توسط</label>
            <select class="form-control" v-model="clearance_by">
              <option value="">انتخاب</option>
              <option value="ayandeh">شرکت آینده</option>
              <option value="user">مشتری</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="کرایه هر تن بار"
            v-model:value="price_rent"
            dir="ltr"
          >
            <font-awesome-icon
              icon="fa-solid fa-money-bill"
              class="text-icon-light"
            />
            کرایه
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-sm-6 col-md-3 mb-3">
          <input-ayandeh
            :number-format="true"
            placeholder="انعام هر تن بار"
            v-model:value="price_reward"
            dir="ltr"
          >
            <font-awesome-icon
              :icon="['fas', 'gift']"
              class="text-icon-light"
            />
            انعام
            <currency :parentheses="true" />
          </input-ayandeh>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="form-group">
            <label class="mb-2">اتصال حواله به کارت</label>
            <v-select
              v-model="referred_ids"
              :options="referred_options"
              :reduce="(option) => option.value"
              placeholder="انتخاب حواله"
              dir="rtl"
              :multiple="true"
              :loading="$store.state.loading_referreds"
            ></v-select>
          </div>
        </div>
        <div class="col-12 mt-4">
          <btn-ayandeh
            :loading="loading_update_card"
            :disabled="loading_update_card"
            @click="update_card"
          >
            ذخیره ویرایش
          </btn-ayandeh>
          <btn-ayandeh
            color="light"
            class="ms-2"
            :disabled="loading_update_card"
            @click="disable_edit_card"
          >
            انصراف
          </btn-ayandeh>
        </div>
      </div>
    </CModalBody>
    <CModalBody class="p-0" :class="{ 'right-100': edit_card }">
      <div
        style="height: 300px"
        class="text-center d-flex"
        v-if="loading_get_card"
      >
        <div class="m-auto">
          درحال خواندن اطلاعات
          <dots-loading />
        </div>
      </div>
      <template v-else-if="card">
        <div class="row m-0">
          <div class="col-12 col-md-3 border-end py-3">
            <div class="mb-3 d-flex" v-if="card.status == 1">
              <btn-ayandeh class="w-50" color="info" @click="enable_edit_card">
                <font-awesome-icon
                  icon="fa-regular fa-pen-to-square"
                  size="sm"
                  class="c-pointer"
                />
                ویرایش
              </btn-ayandeh>
              <btn-ayandeh
                class="w-50 ms-1"
                color="danger"
                @click="disable_card"
              >
                <font-awesome-icon
                  icon="fa-solid fa-ban"
                  size="sm"
                  class="c-pointer"
                />
                غیر فعال
              </btn-ayandeh>
            </div>
            <div class="mb-3 px-4">
              <CChartDoughnut :data="orderDataChart" />
            </div>
            <div class="mb-2">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                class="me-1 text-muted"
                size="lg"
              />
              {{ get_fullname(card.user) }}
            </div>
            <div class="mb-2">{{ card.product.title }}</div>
            <div>
              <div class="separate_dash_gray w-75"></div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">محل تخلیه</span>
              {{ card.city.title }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">انبار تخلیه</span>
              <div v-if="card.anbars.length">
                <template v-for="anbar in card.anbars" :key="anbar.id">
                  <span >{{ anbar.title }}</span><br>
                </template>
              </div>
              <span v-else>-/-</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">کارخانه</span>
              {{ card.product.factory.name }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">تناژ اولیه</span>
              {{ card.tonage }}
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="text-muted">اسناد اضافه</span>
              <p class="mb-0 px-1 c-pointer fw-bold text-ayandeh"
                 @click="show_modal_other_sanads">
                {{ card.other_sanads_count }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-9 py-3">
            <div class="row mb-2">
              <div class="col-12 mb-3">
                وضعیت کارت :
                <b v-if="card.status" class="text-success">فعال</b>
                <b v-else class="text-danger">غیر فعال</b>
                <div class="alert alert-info" v-if="card.status == 0">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      تاریخ غیر فعال سازی :
                      <b class="d-inline-block ltr">
                        {{
                          card.date_disable
                            ? to_jalali(card.date_disable, 'long')
                            : '-/-'
                        }}
                      </b>
                    </div>
                    <div class="col-12 col-md-6">
                      غیر فعال شده توسط :
                      <b>
                        {{
                          card.disabled_by
                            ? get_fullname(card.admin_disableable)
                            : '-/-'
                        }}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-solid fa-circle-nodes"
                  class="text-warning me-1"
                />
                تعداد کل سفارشات :
                {{ count_all_order }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-regular fa-rectangle-xmark"
                  class="text-warning me-1"
                />
                سفارشات لغو شده :
                {{ count_canceled_order }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-solid fa-hourglass-half"
                  class="text-warning me-1"
                />
                در انتظار بارگیری :
                {{ Math.round(sum_number_bar, 2) }}
                <small class="text-secondary">تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-solid fa-truck-ramp-box"
                  class="text-warning me-1"
                />
                بارگیری شده :
                {{ Math.round(sum_ton_loaded, 2) }}
                <small class="text-secondary">تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-solid fa-truck-ramp-box"
                  class="text-warning me-1"
                />
                تخلیه شده :
                {{ Math.round(sum_disCharged, 2) }}
                <small class="text-secondary">تن</small>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <font-awesome-icon
                  icon="fa-solid fa-mound"
                  class="text-warning me-1"
                />
                تناژ باقی مانده :
                {{ Math.round(card.remaining_tonage, 2) }}
                <small class="text-secondary">تن</small>
              </div>
            </div>
            <CAccordion flush>
              <CAccordionItem :item-key="1">
                <CAccordionHeader>اطلاعات بیشتر</CAccordionHeader>
                <CAccordionBody>
                  <div class="row mb-4">
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-regular fa-money-bill-1"
                        class="text-info me-1"
                      />
                      واحد پولی فروش :
                      {{ card.type_arz_title }}
                    </div>
                    <template v-if="card.price_arz">
                      <div class="col-12 col-sm-6 col-md-4 mb-2">
                        <font-awesome-icon
                          icon="fa-solid fa-money-bill-transfer"
                          class="text-info me-1"
                        />
                        نرخ
                        {{ card.type_arz_title }}
                        :
                        {{ toNumberFormat(card.price_arz) }}
                        <currency class="text-secondary" />
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 mb-2">
                        <font-awesome-icon
                          icon="fa-solid fa-money-check-dollar"
                          class="text-info me-1"
                        />
                        فی فروش به
                        {{ card.type_arz_title }}
                        :
                        {{ card.price_per_ton_arz }}
                      </div>
                    </template>
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-solid fa-sack-dollar"
                        class="text-info me-1"
                      />
                      فی فروش به ریال :
                      <span v-if="card.price_per_ton">{{
                        toNumberFormat(card.price_per_ton)
                      }}</span>
                      <span v-else>{{
                        toNumberFormat(card.price_arz * card.price_per_ton_arz)
                      }}</span>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-solid fa-sack-dollar"
                        class="text-info me-1"
                      />
                      کرایه توسط :
                      {{ card.rent_by_title }}
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-solid fa-sack-dollar"
                        class="text-info me-1"
                      />
                      ترخیص توسط :
                      {{ card.clearance_by_title }}
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-solid fa-sack-dollar"
                        class="text-info me-1"
                      />
                      مبلغ کرایه :
                      {{ toNumberFormat(card.price_rent) }}
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 mb-2">
                      <font-awesome-icon
                        icon="fa-solid fa-gift"
                        class="text-info me-1"
                      />
                      مبلغ انعام :
                      {{
                        card.price_reward
                          ? toNumberFormat(card.price_reward)
                          : 'ندارد'
                      }}
                    </div>
                  </div>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink
                  class="text-ayandeh"
                  href="javascript:void(0)"
                  :active="active_tab == 'all'"
                  @click="
                    () => {
                      active_tab = 'all'
                    }
                  "
                  >همه</CNavLink
                >
              </CNavItem>
              <CNavItem>
                <CNavLink
                  class="text-ayandeh"
                  href="javascript:void(0)"
                  :active="active_tab == 'waiting_loaded'"
                  @click="
                    () => {
                      active_tab = 'waiting_loaded'
                    }
                  "
                >
                  در انتظار بارگیری
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  class="text-ayandeh"
                  href="javascript:void(0)"
                  :active="active_tab == 'loaded'"
                  @click="
                    () => {
                      active_tab = 'loaded'
                    }
                  "
                  >بار شده</CNavLink
                >
              </CNavItem>
              <CNavItem>
                <CNavLink
                  class="text-ayandeh"
                  href="javascript:void(0)"
                  :active="active_tab == 'disCharged'"
                  @click="
                    () => {
                      active_tab = 'disCharged'
                    }
                  "
                  >تخلیه شده</CNavLink
                >
              </CNavItem>
              <CNavItem>
                <CNavLink
                  class="text-ayandeh"
                  href="javascript:void(0)"
                  :active="active_tab == 'canceled'"
                  @click="
                    () => {
                      active_tab = 'canceled'
                    }
                  "
                  >لغو شده</CNavLink
                >
              </CNavItem>
            </CNav>
            <CTabContent class="pt-1">
              <!--     All Orders       -->
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                :visible="active_tab == 'all'"
              >
                <div class="table-ay1 rounded-0">
                  <table>
                    <thead>
                      <tr>
                        <th>شماره سفارش</th>
                        <th>تناژ</th>
                        <th>تاریخ بارنامه</th>
                        <th>تاریخ بارگیری</th>
                        <th>تاریخ تخلیه</th>
                        <th>وضعیت</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <empty-tr v-if="all_orders.length == 0" :colspan="7" />
                      <tr v-else v-for="order in all_orders" :key="order.id">
                        <td>{{ order.order_id }}</td>
                        <td>
                          {{ order.max_ton_loaded || order.ton_loaded }}
                        </td>
                        <td>
                          {{
                            order.date_number_bar
                              ? to_jalali(order.date_number_bar)
                              : '-/-'
                          }}
                        </td>
                        <td>
                          {{
                            order.date_loaded
                              ? to_jalali(order.date_loaded)
                              : '-/-'
                          }}
                        </td>
                        <td>
                          {{
                            order.date_disCharge
                              ? to_jalali(order.date_disCharge)
                              : '-/-'
                          }}
                        </td>
                        <td>
                          <span
                            :class="{ 'text-danger': order.status == -1 }"
                            v-text="order.status_title"
                          ></span>
                        </td>
                        <td>
                          <font-awesome-icon
                            @click="show_more_detail_order(order)"
                            icon="fa-regular fa-window-restore"
                            class="c-pointer px-1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTabPane>
              <!--     Waiting Load Bar Orders       -->
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                :visible="active_tab == 'waiting_loaded'"
              >
                <div class="table-ay1 rounded-0">
                  <table>
                    <thead>
                      <tr>
                        <th>شماره سفارش</th>
                        <th>تناژ</th>
                        <th>تاریخ بارنامه</th>
                      </tr>
                    </thead>
                    <tbody>
                      <empty-tr
                        v-if="number_bar_orders.length == 0"
                        :colspan="4"
                      />
                      <tr
                        v-else
                        v-for="order in number_bar_orders"
                        :key="order.id"
                      >
                        <td>{{ order.order_id }}</td>
                        <td>
                          {{ order.max_ton_loaded || order.ton_loaded }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_number_bar) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTabPane>
              <!--     Loaded Orders       -->
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                :visible="active_tab == 'loaded'"
              >
                <div class="table-ay1 rounded-0">
                  <table>
                    <thead>
                      <tr>
                        <th>شماره سفارش</th>
                        <th>تناژ</th>
                        <th>تاریخ بارنامه</th>
                        <th>تاریخ بارگیری</th>
                      </tr>
                    </thead>
                    <tbody>
                      <empty-tr v-if="loaded_orders.length == 0" :colspan="4" />
                      <tr v-else v-for="order in loaded_orders" :key="order.id">
                        <td>{{ order.order_id }}</td>
                        <td>
                          {{ order.max_ton_loaded || order.ton_loaded }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_number_bar) }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_loaded) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTabPane>
              <!--     disCharged Orders       -->
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                :visible="active_tab == 'disCharged'"
              >
                <div class="table-ay1 rounded-0">
                  <table>
                    <thead>
                      <tr>
                        <th>شماره سفارش</th>
                        <th>تناژ</th>
                        <th>تاریخ بارنامه</th>
                        <th>تاریخ بارگیری</th>
                        <th>تاریخ تخلیه</th>
                      </tr>
                    </thead>
                    <tbody>
                      <empty-tr
                        v-if="disCharged_orders.length == 0"
                        :colspan="4"
                      />
                      <tr
                        v-else
                        v-for="order in disCharged_orders"
                        :key="order.id"
                      >
                        <td>{{ order.order_id }}</td>
                        <td>
                          {{ order.max_ton_loaded || order.ton_loaded }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_number_bar) }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_loaded) }}
                        </td>
                        <td>
                          {{ to_jalali(order.date_disCharge) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTabPane>
              <!--     Canceled Orders       -->
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                :visible="active_tab == 'canceled'"
              >
                <div class="table-ay1 rounded-0">
                  <table>
                    <thead>
                      <tr>
                        <th>شماره سفارش</th>
                        <th>تناژ</th>
                        <th>تاریخ ایجاد</th>
                        <th>تاریخ لغو</th>
                        <th>لغو توسط</th>
                      </tr>
                    </thead>
                    <tbody>
                      <empty-tr
                        v-if="canceled_orders.length == 0"
                        :colspan="5"
                      />
                      <tr
                        v-else
                        v-for="order in canceled_orders"
                        :key="order.id"
                      >
                        <td>{{ order.order_id }}</td>
                        <td>
                          {{ order.max_ton_loaded || order.ton_loaded }}
                        </td>
                        <td>
                          {{ to_jalali(order.created_at) }}
                        </td>
                        <td>
                          {{ to_jalali(order.cancel_date) }}
                        </td>
                        <td>
                          {{
                            order.canceler
                              ? get_fullname(order.canceler)
                              : '-/-'
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CTabPane>
            </CTabContent>
          </div>
        </div>
      </template>
    </CModalBody>
  </CModal>
  <COffcanvas
    placement="start"
    :visible="visible_more"
    @hide="
      () => {
        visible_more = !visible_more
      }
    "
  >
    <COffcanvasHeader>
      <COffcanvasTitle>جزئیات بیشتر</COffcanvasTitle>
      <CCloseButton
        class="text-reset"
        @click="
          () => {
            visible_more = false
          }
        "
      />
    </COffcanvasHeader>
    <COffcanvasBody>
      <div v-if="order_selected != null">
        <div class="d-flex justify-content-between mb-3">
          <span>شماره بارنامه</span>
          <span v-text="order_selected.number_bar"></span>
        </div>
        <license-plate class="mb-5" :car="order_selected.car"></license-plate>
        <router-link
          target="_blank"
          :to="{
            name: 'OrderList',
            query: { status: '', order_id: order_selected.order_id },
          }"
        >
          <btn-ayandeh class="w-100"> مشاهده در بخش سفارشات </btn-ayandeh>
        </router-link>
      </div>
    </COffcanvasBody>
  </COffcanvas>
</template>

<script>
import ShineLoading from '@/components/loading/ShineLoading'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DotsLoading from '@/components/loading/DotsLoading'
import { CChartDoughnut } from '@coreui/vue-chartjs'
import EmptyTr from '@/components/partial/EmptyTr'
import LicensePlate from '@/components/partial/LicensePlate'
import CardMix from '@/mixin/CardMix'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
export default {
  name: 'ModalCardDetails',
  components: {
    LicensePlate,
    EmptyTr,
    CChartDoughnut,
    DotsLoading,
    FontAwesomeIcon,
    ShineLoading,
  },
  mixins: [CardMix],
  data() {
    return {
      visible_more: false,
      active_tab: 'all',
      visiable_var: false,
      loading_get_card: false,
      card: null,
      count_all_order: 0,
      sum_ton_loaded: 0,
      sum_number_bar: 0,
      sum_disCharged: 0,
      count_canceled_order: 0,
      full_screen_modal_order_detail: false,
      order_selected: null,
      edit_card: false,
      loading_update_card: false,
    }
  },
  props: {
    card_id: {
      type: Number,
      default: null,
      required: true,
    },
    visiable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.getCookie('f_s_order_det')) {
      this.full_screen_modal_order_detail = true
    }
  },
  computed: {
    all_orders() {
      return this.card.order_export
    },
    number_bar_orders() {
      return this.card.order_export.filter((x) => x.status == 1)
    },
    loaded_orders() {
      return this.card.order_export.filter((x) => x.status == 2)
    },
    disCharged_orders() {
      return this.card.order_export.filter((x) => x.status == 3)
    },
    canceled_orders() {
      return this.card.order_export.filter((x) => x.status == -1)
    },
    orderDataChart() {
      return {
        labels: [
          'بار شده',
          'در انتظار بارگیری',
          'تخلیه شده',
          'در انتظار بارنامه',
        ],
        datasets: [
          {
            backgroundColor: ['#51b98c', '#00D8FF', '#22b622', '#ccc'],
            data: [
              Math.round(this.sum_ton_loaded, 2),
              Math.round(this.sum_number_bar, 2),
              Math.round(this.sum_disCharged, 2),
              Math.round(this.card.remaining_tonage, 2),
            ],
          },
        ],
      }
    },
  },
  watch: {
    visiable(newValue) {
      this.visiable_var = newValue
      if (newValue) {
        this.get_card()
      }
    },
    full_screen_modal_order_detail(newValue) {
      this.setCookie('f_s_order_det', newValue)
    },
  },
  methods: {
    ...mapActions([
      'get_referreds',
      'toggle_modal_other_sanads',
      'get_other_sanads',
    ]),
    disable_card() {
      Swal.fire({
        confirmButtonText: 'غیر فعال سازی',
        confirmButtonColor: 'danger',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'غیر فعال کردن کارت',
        text: 'آیا میخواهید کارت مورد نظر غیر فعال گردد؟',
      }).then((result) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-undef
          axios.get('/card/disable_card/' + this.card_id).then(({ data }) => {
            if (data.status == 'success') {
              this.card = data.card
            }
          })
        }
      })
    },
    disable_edit_card() {
      this.edit_card = false
    },
    enable_edit_card() {
      this.edit_card = true
      this.tonage = this.card.tonage
      this.type_arz = this.card.price_per_ton ? 'rial' : this.card.type_arz
      this.price_arz = this.card.price_arz
        ? this.toNumberFormat(this.card.price_arz)
        : ''
      this.price_per_ton_arz = this.card.price_per_ton_arz
      this.price_per_ton = this.card.price_per_ton
        ? this.toNumberFormat(this.card.price_per_ton)
        : ''
      this.price_rent = this.toNumberFormat(this.card.price_rent)
      this.price_reward = this.card.price_reward
        ? this.toNumberFormat(this.card.price_reward)
        : ''
      this.rent_by = this.card.rent_by
      this.clearance_by = this.card.clearance_by

      if (this.$store.state.referreds.length == 0) {
        this.get_referreds(
          this.gen_query_string({
            product_id: this.card.product.id,
            minimal: 1,
          }),
        )
      }

      this.referred_ids = this.card.referreds.map((x) => {
        return x.id
      })
    },
    show_more_detail_order(order) {
      this.order_selected = order
      this.visible_more = true
    },
    closed() {
      this.edit_card = false
      this.visiable_var = false
      this.$emit('update:visiable', this.visiable_var)
    },
    get_card() {
      this.loading_get_card = true

      // eslint-disable-next-line no-undef
      axios.get('/card/get_card/' + this.card_id).then(({ data }) => {
        if (data.status == 'success') {
          this.card = data.card
          this.sum_ton_loaded = data.sum_ton_loaded
          this.count_all_order = data.count_all_order
          this.count_canceled_order = data.count_canceled_order
          this.sum_number_bar = data.sum_number_bar
          this.sum_disCharged = data.sum_disCharged
        }

        this.loading_get_card = false
      })
    },
    update_card() {
      this.loading_update_card = true
      var data = {
        card_id: this.card.id,
        tonage: this.tonage,
        city_id: this.city_id,
        price_rent: this.price_rent,
        price_reward: this.price_reward,
        rent_by: this.rent_by,
        clearance_by: this.clearance_by,
        referred_ids: this.referred_ids,
        type_arz: this.type_arz,
        price_arz: this.price_arz,
        price_per_ton_arz: this.price_per_ton_arz,
        price_per_ton: this.price_per_ton,
      }
      // eslint-disable-next-line no-undef
      axios.post('/card/update', data).then(({ data }) => {
        if (data.status == 'success') {
          this.edit_card = false
          this.card = data.card
        }
        this.loading_update_card = false
        this.my_alert(data.msg, data.status)
      })
    },
    show_modal_other_sanads() {
      this.toggle_modal_other_sanads(true)
      this.load_data_modal_other_sanads()
    },
    load_data_modal_other_sanads() {
      this.get_other_sanads({
        card_id: this.card.id,
        by_bed_bes_name: true,
      })
    },
  },
}
</script>

<style>
.accordion-button {
  color: #757575 !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}
</style>
