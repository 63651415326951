<template>
  <div class="plate">
    <div class="right">
      <div class="mt-1">ایران</div>
      <input
        required
        ref="number_base"
        v-model="$store.state.number_base"
        :data-index="3"
        @keyup="changed_plaque"
        type="bumber"
        class="car_number_base form-control not_arrow ltr text-center fs-15 bold mx-auto p-0"
        maxlength="2"
        autocomplete="off"
        style="width: 35px; height: 20px"
      />
    </div>
    <div class="center">
      <input
        required
        ref="three_digits"
        v-model="$store.state.three_digits"
        type="bumber"
        @keyup="changed_plaque"
        :data-index="2"
        class="car_number_three_digits form-control not_arrow ltr text-center bold p-0"
        maxlength="3"
        autocomplete="off"
        style="width: 50px"
      />
      <select
        v-model="$store.state.plate_letter"
        class="car_number_letter form-control d-inline-block h-100 py-0 px-1"
        @change="changed_plaque"
        style="width: 70px"
      >
        <option value="">-</option>
        <option
          v-for="(letter, index) in alefba"
          :key="index"
          :value="letter"
          v-text="letter"
        ></option>
      </select>
      <input
        required
        ref="two_digits"
        v-model="$store.state.two_digits"
        :data-index="1"
        type="bumber"
        @keyup="changed_plaque"
        class="car_number_two_digits form-control not_arrow ltr text-center bold p-0"
        maxlength="2"
        autocomplete="off"
        style="width: 35px"
      />
    </div>
    <div class="left">
      <img src="/img/icons/iran_flag.jpg" />
      <span class="ir">I.R.</span>
      <span class="iran">IRAN</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Plate',
  props: ['plate', 'car', 'complete'],
  data() {
    return {}
  },
  emits: ['update:plate'],
  mounted() {
    if (this.car) {
      this.$store.state.two_digits = this.car.car_number_two_digits
      this.$store.state.three_digits = this.car.car_number_three_digits
      this.$store.state.plate_letter = this.car.car_number_letter
      this.$store.state.number_base = this.car.car_number_base
      this.update_plate()
    }
  },
  methods: {
    changed_plaque(event) {
      var index = event.target.getAttribute('data-index')
      var length = event.target.value.length

      if (index == 1 && length == 2) {
        this.$refs.three_digits.focus()
      } else if (index == 2) {
        if (length == 0 && event.type == 'keyup' && event.code == 'Backspace') {
          this.$refs.two_digits.focus()
        } else if (length == 3) {
          this.$refs.number_base.focus()
        }
      } else if (
        index == 3 &&
        length == 0 &&
        event.type == 'keyup' &&
        event.code == 'Backspace'
      ) {
        this.$refs.three_digits.focus()
      }
      this.update_plate()
    },
    update_plate() {
      this.$emit('update:plate', {
        number_base: this.$store.state.number_base || '',
        three_digits: this.$store.state.three_digits || '',
        two_digits: this.$store.state.two_digits || '',
        letter: this.$store.state.plate_letter || '',
      })
      this.$emit('update:complete', this.is_complete())
    },
    is_complete() {
      if (
        this.$store.state.two_digits.length == 2 &&
        this.$store.state.three_digits.length == 3 &&
        this.$store.state.number_base.length == 2 &&
        this.$store.state.letter.length != 0
      ) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped></style>
