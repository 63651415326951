<template>
  <div>
    <div class="d-sm-flex justify-content-between mb-4">
      <h1 class="title_card mb-3 mb-sm-0">سال های مالی</h1>
      <div class="d-flex justify-content-between gap-2">
        <btn-ayandeh
          class=""
          color="primary"
          @click="moveFinancialYearModal = true"
        >
          <font-awesome-icon icon="fas fa-exchange-alt" class="me-1" />
          انتقال سال مالی
        </btn-ayandeh>
        <btn-ayandeh
          class="text-white"
          color="info"
          @click="createFinancialYearModal = true"
        >
          <font-awesome-icon icon="plus" class="me-1" />
          ایجاد سال مالی
        </btn-ayandeh>
      </div>
    </div>
    <div class="table-ay1">
      <table>
        <thead>
          <tr>
            <th>ردیف</th>
            <th>سال مالی</th>
            <th>عنوان سال مالی</th>
            <th>تاریخ ایجاد</th>
            <th>پیشفرض</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="fiscalYearLoading">
            <tr v-for="x in 6" :key="x">
              <td v-for="i in 6" :key="i">
                <shine-loading />
              </td>
            </tr>
          </template>
          <tr v-else-if="fiscalYearList.length == 0">
            <td colspan="7" class="text-center py-4">
              هیچ سال مالی برای نمایش وجود ندارد!
            </td>
          </tr>
          <tr v-else v-for="(fiscal, index) in fiscalYearList" :key="index">
            <td>
              <div class="mobile_title">ردیف :</div>
              {{ index + 1 }}
            </td>
            <td>
              <div class="mobile_title">سال مالی :</div>
              {{ fiscal.name }}
            </td>
            <td>
              <div class="mobile_title">عنوان سال مالی :</div>
              {{ fiscal.label }}
            </td>
            <td>
              <div class="mobile_title">تاریخ ایجاد :</div>
              {{ fiscalCreatedDate(fiscal.created_at) }}
            </td>
            <td>
              <div class="mobile_title">پیشفرض :</div>
              <font-awesome-icon
                v-if="fiscal.default"
                icon="fa fa-check-circle"
                class="nav-icon"
              />
              <span v-else> - </span>
            </td>
            <td class="ltr">
              <button
                v-if="!fiscal.default"
                @click="openRemoveFiscalModal(fiscal)"
                class="btn bg-transparent text-red py-1 px-2 border-0 c-pointer"
              >
                <font-awesome-icon
                  icon="fa-solid fa-trash-can"
                  style="color: red"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <create-fiscal-year-modal
      v-model="createFinancialYearModal"
      @update-list="getFiscalYears"
    />
    <move-fiscal-year-modal
      v-model="moveFinancialYearModal"
      :fiscal-year-list="fiscalYearList"
    />
    <remove-fiscal-year-modal
      v-model="removeFinancialYearModal"
      :fiscal-id="fiscalYearId"
      @update-list="getFiscalYears"
    />
  </div>
</template>

<script setup>
import axios from 'axios'
import moment from 'moment-jalaali'
import { ref, onMounted } from 'vue'
import CreateFiscalYearModal from './CreateFiscalYearModal/index.vue'
import MoveFiscalYearModal from './MoveFiscalYearModal/index.vue'
import RemoveFiscalYearModal from './RemoveFiscalYearModal/index.vue'
const createFinancialYearModal = ref(false)
const moveFinancialYearModal = ref(false)
const removeFinancialYearModal = ref(false)
const fiscalYearLoading = ref(false)
const fiscalYearList = ref([])
const fiscalYearId = ref(null)

const getFiscalYears = async () => {
  fiscalYearLoading.value = true
  try {
    fiscalYearList.value = (
      await axios.get('/fiscal_year/get_list')
    ).data.fiscal_years
    fiscalYearLoading.value = false
  } catch (err) {
    fiscalYearLoading.value = false
    console.log(err)
  }
}

onMounted(async () => {
  await getFiscalYears()
})

const fiscalCreatedDate = (date) => {
  return moment(date).format('HH:mm jYYYY/jMM/jDD')
}

const openRemoveFiscalModal = (fiscal) => {
  fiscalYearId.value = fiscal.id
  removeFinancialYearModal.value = true
}
</script>

<style lang="scss" scoped></style>
