<template>
  <CModal
    :fullscreen="full_screen_modal_loading"
    size="xl"
    backdrop="static"
    :visible="visiable_var"
    @close="closed"
  >
    <CModalHeader
      dismiss
      @close="closed"
      class="d-flex justify-content-between"
    >
      <CModalTitle class="fs-14">
        ثبت بارگیری سفارش شماره
        {{ $store.state.order ? $store.state.order.order_id : '' }}
      </CModalTitle>
      <div>
        <font-awesome-icon
          icon="fa-solid fa-compress"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_loading = false"
          v-if="full_screen_modal_loading"
        />
        <font-awesome-icon
          icon="fa-solid fa-expand"
          size="lg"
          class="ms-4 p-1 c-pointer text-muted"
          @click="full_screen_modal_loading = true"
          v-else
        />
      </div>
    </CModalHeader>
    <CModalBody>
      <div
        style="height: 300px"
        class="text-center d-flex"
        v-if="$store.state.loading_get_order"
      >
        <div class="m-auto">
          درحال خواندن اطلاعات
          <dots-loading />
        </div>
      </div>
      <template v-else-if="$store.state.order">
        <div class="row m-0">
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1"
            v-if="$store.state.order.type_arz"
          >
            <div class="bg-gray-1 p-1 px-3">
              <div class="d-flex justify-content-between">
                <div class="">
                  فی فروش ارزی
                  <template v-if="tax_percent">+ مالیات</template>
                  :
                </div>
                <div class="">
                  <span class="me-1" v-if="$store.state.order.type_arz">
                    {{ $store.state.order.price_per_ton_arz }}
                  </span>
                  <small>{{ $store.state.order.type_arz_title }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-3">
              <div class="d-flex justify-content-between">
                <div class="">
                  فی فروش ریالی
                  <template v-if="tax_percent">+ مالیات</template>
                  :
                </div>
                <div>
                  {{ toNumberFormat(fe_with_tax) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1"
            v-if="$store.state.order.tax_percent"
          >
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">فی فروش خالص :</div>
                <div class="">
                  <span class="me-1">{{ toNumberFormat(final_fe) }}</span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">کرایه حمل هر تن :</div>
                <div class="">
                  <span class="me-1">
                    {{ toNumberFormat($store.state.order.price_rent) }}
                  </span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">کرایه حمل کل :</div>
                <div class="">
                  <span class="me-1">{{ toNumberFormat(price_haml_col) }}</span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <template v-if="price_reward">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
              <div class="bg-gray-1 p-1 px-2">
                <div class="d-flex justify-content-between">
                  <div class="">انعام هر تن :</div>
                  <div>
                    <span class="me-1">{{
                      toNumberFormat($store.state.order.price_reward)
                    }}</span>
                    <currency />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
              <div class="bg-gray-1 p-1 px-2">
                <div class="d-flex justify-content-between">
                  <div class="">انعام کل :</div>
                  <div class="">
                    <span class="me-1">{{
                      toNumberFormat(price_reward_col)
                    }}</span>
                    <currency />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div>مبلغ بار:</div>
                <div>
                  <span class="me-1">{{ toNumberFormat(total_price_fe) }}</span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1"
            v-if="tax_percent"
          >
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">
                  <span>مبلغ مالیات :</span>
                </div>
                <div class="">
                  <span class="me-1">
                    {{ toNumberFormat(total_price_tax) }}
                  </span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">مبلغ کل فاکتور :</div>
                <div class="">
                  <span class="text-success me-1">{{
                    toNumberFormat(total_price_all)
                  }}</span>
                  <currency />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 px-1">
            <div class="bg-gray-1 p-1 px-2">
              <div class="d-flex justify-content-between">
                <div class="">تناژ کل :</div>
                <div class="">
                  <span class="me-1">{{ total_ton }}</span>
                  <small class="text-muted">تن</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row ltr m-0">
          <div class="col-12 col-md-5 border-right rtl">
            <div class="row">
              <div class="col-12 mb-3 px-1">
                <div class="bg-blue-1 p-1 px-3">
                  <div class="d-flex justify-content-between">
                    <span>نام کالا :</span>
                    <span>
                      {{ $store.state.order.card_export.product.title }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 mb-3 px-1">
                <div class="bg-blue-1 p-1 px-3">
                  <div class="d-flex justify-content-between">
                    <div class="">شماره بارنامه :</div>
                    <div class="">
                      <span>{{ $store.state.order.number_bar }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 mb-3 px-1">
                <div class="bg-blue-1 p-1 px-3">
                  <div class="d-flex justify-content-between">
                    <div class="pr-0 pl-1">تاریخ بارنامه :</div>
                    <div class="p-0 text-right">
                      <span>
                        {{ to_jalali($store.state.order.date_number_bar) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 mb-3 px-1">
                <div class="bg-blue-1 p-1 px-3">
                  <div class="d-flex justify-content-between">
                    <div class="pr-0 pl-1">حداکثر تناژ :</div>
                    <div>{{ $store.state.order.max_ton_loaded }} تن</div>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 px-1">
                <license-plate :car="$store.state.order.car" />
              </div>
            </div>
          </div>

          <div class="col-12 col-md-7 rtl">
            <div class="row">
              <div class="col-12 col-sm-6 mb-3">
                <input-date
                  :radius_right="false"
                  selected="today"
                  v-model:value="date_loaded"
                  label="تاریخ بارگیری"
                  placeholder="تاریخ بارگیری را وارد کنید"
                />
              </div>

              <div class="col-12 content_referred">
                <layout-loading v-if="loading" />
                <p class="title_ref">حواله ها</p>
                <div
                  class="item_referred"
                  v-for="(referred, key) in all_referred"
                  :key="key"
                >
                  <font-awesome-icon
                    icon="fa-square"
                    class="remove_referred"
                    v-if="Object.keys(all_referred).length == 1"
                  />
                  <font-awesome-icon
                    icon="far fa-minus-square"
                    class="c-pointer remove_referred"
                    v-else
                    @click="removeReferred(key)"
                  />
                  <div class="item_body">
                    <div class="form-group me-1">
                      <input-ayandeh
                        type="number"
                        v-model:value="referred.referred_code"
                        :data-referred-key="key"
                        name="referred_codes[]"
                        @blur="processReferred"
                        required
                        class="ltr text-left"
                        placeholder="کد حواله"
                      />
                    </div>
                    <div class="form-group">
                      <input-ayandeh
                        v-model:value="referred.referred_ton"
                        required
                        class="form-control ltr text-end"
                        placeholder="تناژ"
                      />
                    </div>
                    <div
                      class="contract_det"
                      v-show="referred.contract_code != ''"
                    >
                      <p class="mb-0">
                        موجودی حواله :
                        <span
                          class="d-inline-block ltr"
                          v-text="referred.remaining_inventory"
                        ></span>
                      </p>
                      <p class="mb-0">
                        شماره قرارداد : {{ referred.contract_code }}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <font-awesome-icon
                    icon="fa-plus-square"
                    class="add_referred"
                    size="xl"
                    @click="addReferred"
                  />
                </div>
              </div>

              <div class="mt-4">
                <div
                  class="kalas_selected alert alert-info mb-3"
                  v-for="(kala, index_kala) in kalas"
                  :key="index_kala"
                >
                  <div class="row">
                    <div class="col-12 mb-2">
                      <b class="mb-0" v-text="kala.kala_title"></b>
                    </div>
                    <div class="col-12 mb-2">
                      <span>حواله ها : </span>
                      <b class="mb-0" v-text="kala.referreds_code_text"></b>
                    </div>
                    <div class="col-12 col-sm-6 mb-2">
                      <span>موجودی کل کالا : </span>
                      <b
                        class="mb-0"
                        v-text="kala.kala_remaining_inventory"
                      ></b>
                    </div>
                    <div class="col-12 col-sm-6 mb-2">
                      <span>درصد بازاریابی : </span>
                      <b class="mb-0" v-text="kala.percent_vis || '-/-'"></b>
                    </div>

                    <div class="col-12" v-if="!$store.state.default_anbar_fm">
                      <div class="form-group">
                        <label class="required mb-1">انتخاب انبار</label>
                        <select v-model="kala.anbar_id" class="form-control">
                          <option value="">انتخاب انبار</option>
                          <option
                            v-for="anb in $store.state.anbars_fm"
                            :key="anb.Cod"
                            :value="anb.Cod"
                            v-text="anb.Nam"
                          ></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CModalBody>
    <CModalFooter class="ltr" v-if="$store.state.order">
      <btn-ayandeh label="انصراف" color="light" @click="closed" />
      <btn-ayandeh
        color="info"
        class="ms-1"
        @click="submit_form"
        :disabled="disabled_btn_submit"
        :loading="loading_submit"
        label="بارگیری"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import LicensePlate from '@/components/partial/LicensePlate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LayoutLoading from '@/components/loading/LayoutLoading'
import InputAyandeh from '@/components/inputs/InputAyandeh'
import InputDate from '@/components/inputs/InputDate'

export default {
  name: 'ModalFinalLoading',
  components: {
    InputDate,
    InputAyandeh,
    LayoutLoading,
    FontAwesomeIcon,
    LicensePlate,
  },
  data() {
    return {
      visiable_var: false,
      loading_get_order: false,
      full_screen_modal_loading: false,
      anbars: [],
      multi_product: false,
      all_referred: [
        {
          kala_id: '',
          kala_title: '',
          referred_code: '',
          referred_ton: '',
          contract_code: '',
          remaining_inventory: '',
          kala_remaining_inventory: '',
        },
      ],
      loading: false,
      date_loaded: '',
      kalas: [],
      loading_submit: false,
      source_ref: null,
    }
  },
  props: {
    order_id: {
      type: Number,
      default: null,
      required: true,
    },
    visiable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.get_default_anbar_fm()
    this.get_anbars_fm()
    if (this.getCookie('f_s_loading')) {
      this.full_screen_modal_loading = true
    }
  },
  methods: {
    ...mapActions(['get_order', 'get_default_anbar_fm', 'get_anbars_fm']),
    reset_modal() {
      this.visiable_var = false
      this.loading_get_order = false
      this.multi_product = false
      this.loading = false
      this.loading_submit = false
      this.kalas = []
      this.all_referred = [
        {
          kala_id: '',
          kala_title: '',
          referred_code: '',
          referred_ton: '',
          contract_code: '',
          remaining_inventory: '',
          kala_remaining_inventory: '',
        },
      ]
    },
    closed() {
      this.reset_modal()
      this.visiable_var = false
      this.$emit('update:visiable', this.visiable_var)
    },
    removeReferred(index) {
      this.all_referred.splice(index, 1)
    },
    addReferred() {
      this.all_referred.push({
        referred_code: '',
        referred_ton: '',
        contract_code: '',
        remaining_inventory: '',
      })
    },
    processReferred(e) {
      if (e && !e.target.value) return false

      if (this.source_ref) {
        this.source_ref.cancel()
      }
      this.loading = true
      var referred_codes = []
      var duplicate = undefined
      // eslint-disable-next-line no-undef
      this.all_referred.forEach((referred) => {
        referred.contract_code = ''
        duplicate = referred_codes.find(
          (element) => element == referred.referred_code,
        )
        if (duplicate != undefined) {
          return false
        }
        referred_codes.push(referred.referred_code)
      })

      if (duplicate !== undefined) {
        this.my_alert('کد حواله ' + duplicate + ' قبلا وارد شده است', 'error')
        this.loading = false
        return false
      }

      this.source_ref = axios.CancelToken.source()

      // eslint-disable-next-line no-undef
      axios
        .post('/orders/process_referred_code', {
          referred_codes: referred_codes,
          product_id: this.$store.state.order.product_id,
          multi_product: this.multi_product,
        }, {
          cancelToken: this.source_ref.token,
        })
        .then(
          function ({ data }) {
            this.loading = false
            if (data.status === 'success') {
              this.kalas = data.kalas

              // eslint-disable-next-line no-undef
              this.all_referred.forEach((referred) => {
                var sss = data.referreds[referred.referred_code]
                referred.contract_code = sss.contract.contract_code
                referred.remaining_inventory = sss.remaining_inventory
                referred.kala_remaining_inventory = sss.kala_remaining_inventory
                referred.percent_vis = sss.percent_vis
                referred.kala_title = sss.kala_title
                referred.kala_id = sss.kala_id
              })
            } else if (data.status === 'warning') {
              Swal.fire({
                title: 'فاکتور چند ردیفه',
                text: 'آیا میخواهید در این فاکتور کالاهای مختلفی را بارگیری کنید؟',
                showDenyButton: true,
                confirmButtonText: 'بله',
                denyButtonText: 'خیر',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.multi_product = true
                } else {
                  this.removeReferred(Object.keys(this.all_referred).length - 1)
                }
                this.processReferred(e)
              })
            } else if (data.status == 'error') {
              this.my_alert(data.msg, 'error')
            }
          }.bind(this),
        )
        .catch((e) => {

        })
    },
    submit_form() {
      this.loading_submit = true
      let kala_anbar = {}
      Object.entries(this.kalas).forEach((kala) => {
        if (kala[1].anbar_id) {
          kala_anbar[kala[1].kala_id] = kala[1].anbar_id
        } else {
          kala_anbar[kala[1].kala_id] = this.$store.state.default_anbar_fm || ''
        }
      })
      // eslint-disable-next-line no-undef
      axios
        .post('/orders/set_load', {
          order_id: this.order_id,
          kala_anbars: kala_anbar,
          date_loaded: this.date_loaded,
          all_referred: this.all_referred,
        })
        .then(({ data }) => {
          if (data.status === 'success') {
            this.closed()
            this.$emit('loaded')
          } else {
            this.loading_submit = true
            this.my_alert(data.msg, data.status)
          }
        })
    },
  },
  watch: {
    visiable(newValue) {
      this.visiable_var = newValue
      if (newValue && this.$store.state.order == null) {
        this.get_order(this.order_id)
      }
    },
    full_screen_modal_loading(newValue) {
      this.setCookie('f_s_loading', newValue)
    },
  },
  computed: {
    fe_with_tax() {
      if (this.$store.state.order.type_arz) {
        return (
          this.$store.state.order.price_per_ton_arz *
          this.$store.state.order.price_arz
        )
      }
      return this.$store.state.order.price_per_ton
    },
    price_reward() {
      return this.$store.state.order.price_reward || 0
    },
    tax_percent() {
      return this.$store.state.order.tax_percent || 0
    },
    final_fe() {
      return (this.fe_with_tax / (100 + this.tax_percent)) * 100
    },
    fe_tax() {
      return (this.final_fe / 100) * this.tax_percent
    },
    total_price_fe() {
      return parseFloat((this.final_fe * this.total_ton).toFixed(2))
    },
    price_haml_col() {
      return this.$store.state.order.price_rent || 0 * this.total_ton
    },
    price_reward_col() {
      return this.$store.state.order.price_reward || 0 * this.total_ton
    },
    total_price_all() {
      return (
        this.total_price_fe +
        this.price_haml_col +
        this.price_reward_col +
        this.total_price_tax
      )
    },
    total_price_tax() {
      return this.fe_tax * this.total_ton
    },
    total_ton() {
      return this.sum('referred_ton', this.all_referred)
    },
    disabled_btn_submit() {
      if (this.loading_submit) {
        return true
      }
      if (
        this.all_referred.filter(
          (x) =>
            x.referred_ton == '' ||
            x.referred_ton == 0 ||
            x.referred_code == '',
        ).length
      ) {
        return true
      }
      if (
        Object.keys(this.kalas).length &&
        !this.$store.state.default_anbar_fm &&
        Object.entries(this.kalas).filter((x) => x[1].anbar_id == '').length > 0
      ) {
        return true
      }
      return false
    },
  },
}
</script>
