import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
window.axios = axios
window.axios.defaults.baseURL = 'https://admin.ayandehcement.com/api/export/v1/'
// window.axios.defaults.baseURL = 'http://127.0.0.1:8000/api/export/v1/'
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('token')
if (localStorage.getItem('fiscal_year_ex_switched'))
  window.axios.defaults.headers.common['fiscal-year-ex-switched'] =
    localStorage.getItem('fiscal_year_ex_switched')
// window.axios.defaults.withCredentials = true
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import LoadingSpinner from '@/components/loading/LoadingSpinner'
import ShineLoading from '@/components/loading/ShineLoading'
import AyandehButton from '@/components/partial/AyandehButton'
import Currency from '@/components/partial/Currency'
import DotsLoading from '@/components/loading/DotsLoading'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import InputAyandeh from '@/components/inputs/InputAyandeh'
import mixin from '@/mixin'
import moment from 'moment-jalaali'
import { vMask } from '@bardoui/vmask'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faSortUp,
  faSackXmark,
  faScaleBalanced,
  faWeightScale,
  faCalendarDays,
  faSquareCaretLeft,
  faSquareCaretRight,
  faCopy,
  faGift,
  faTruckRampBox,
  faDollarSign,
  faCoins,
  faSlash,
  faTimesCircle,
  faFileLines,
  faFileCirclePlus,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faCircleXmark,
  faFile,
  faPlus,
  faSquarePlus,
  faFilter,
  faAngleDown,
  faCalendarDay,
  faUser,
  faFileInvoiceDollar,
  faPersonChalkboard,
  faCalculator,
  faPaperclip,
  faImage,
  faCircleExclamation,
  faReceipt,
  faCalendarCheck,
  faCalendarPlus,
  faKey,
  faClockRotateLeft,
  faPen,
  faTrashCan,
  faListUl,
  faGrip,
  faDownload,
  faEllipsisVertical,
  faBan,
  faTruckFast,
  faDolly,
  faPeopleCarryBox,
  faInfo,
  faEye,
  faListCheck,
  faMobileAlt,
  faGear,
  faGears,
  faLeftLong,
  faFilePdf,
  faBarsProgress,
  faLink,
  faDownLong,
  faMusic,
  faFileCsv,
  faFileExcel,
  faFileWord,
  faFileVideo,
  faFileZipper,
  faFileCircleExclamation,
  faEnvelope,
  faCheck,
  faCheckDouble,
  faCircle,
  faCircleCheck,
  faGaugeHigh,
  faTableColumns,
  faHandHoldingDollar,
  faMagnifyingGlass,
  faBoxesPacking,
  faCheckToSlot,
  faAngleLeft,
  faUserTie,
  faCircleNodes,
  faExpand,
  faCompress,
  faMound,
  faHourglassHalf,
  faMoneyBillTransfer,
  faMoneyCheckDollar,
  faSackDollar,
  faWeightHanging,
  faIndustry,
  faBoxesStacked,
  faMapPin,
  faWarehouse,
  faMoneyBill,
  faArrowRightLong,
  faQuestionCircle,
  faSquare,
  faKeyboard,
  faTruck,
  faFilterCircleXmark,
  faExchangeAlt,
  faStream,
  faSyncAlt,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

// Regular
import {
  faCircle as faCircleR,
  faRectangleXmark as faRectangleXmark_r,
  faMoneyBill1 as faMoneyBill1_r,
  faWindowRestore as faWindowRestore_r,
  faUser as faUser_r,
  faPenToSquare as faPenToSquare_r,
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faAngleRight,
  faSyncAlt,
  faStream,
  faFilterCircleXmark,
  faTruck,
  faKeyboard,
  faCircleXmark,
  faSquare,
  faQuestionCircle,
  faPlusSquare,
  faMinusSquare,
  faSortUp,
  faArrowRightLong,
  faPenToSquare_r,
  faMoneyBill,
  faWarehouse,
  faMapPin,
  faBoxesStacked,
  faIndustry,
  faWeightHanging,
  faUser_r,
  faWindowRestore_r,
  faSackDollar,
  faMoneyCheckDollar,
  faMoneyBillTransfer,
  faMoneyBill1_r,
  faHourglassHalf,
  faMound,
  faExpand,
  faCompress,
  faCircleNodes,
  faUserTie,
  faAngleLeft,
  faCheckToSlot,
  faRectangleXmark_r,
  faSackXmark,
  faScaleBalanced,
  faWeightScale,
  faCalendarDays,
  faSquareCaretLeft,
  faSquareCaretRight,
  faCopy,
  faGift,
  faTruckRampBox,
  faDollarSign,
  faCoins,
  faSlash,
  faTimesCircle,
  faFileLines,
  faFileCirclePlus,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faCircleXmark,
  faFile,
  faPlus,
  faSquarePlus,
  faFilter,
  faAngleDown,
  faCalendarDay,
  faUser,
  faFileInvoiceDollar,
  faPersonChalkboard,
  faCalculator,
  faPaperclip,
  faImage,
  faCircleExclamation,
  faReceipt,
  faCalendarCheck,
  faCalendarPlus,
  faKey,
  faClockRotateLeft,
  faPen,
  faTrashCan,
  faListUl,
  faGrip,
  faDownload,
  faEllipsisVertical,
  faBan,
  faTruckFast,
  faDolly,
  faPeopleCarryBox,
  faInfo,
  faEye,
  faListCheck,
  faMobileAlt,
  faGear,
  faGears,
  faLeftLong,
  faFilePdf,
  faBarsProgress,
  faLink,
  faDownLong,
  faMusic,
  faFileCsv,
  faFileExcel,
  faFileWord,
  faFileVideo,
  faFileZipper,
  faFileCircleExclamation,
  faEnvelope,
  faCheck,
  faCheckDouble,
  faCircle,
  faCircleR,
  faCircleCheck,
  faGaugeHigh,
  faTableColumns,
  faHandHoldingDollar,
  faMagnifyingGlass,
  faBoxesPacking,
  faExchangeAlt,
)

axios.interceptors.response.use(
  function (response) {
    if (response.data.status == 'success' && response.data.msg != undefined) {
      mixin.methods.toast(response.data.msg)
    }
    return response
  },
  function (error) {
    if (error.response != undefined) {
      var status = error.response.status
      if (status == 422) {
        var txt = error.response.data.message + '<br/>'
        Object.values(error.response.data.errors).forEach(function (err) {
          txt += '<p class="text-muted fs-14 mb-1">' + err + '</p>'
        })
        mixin.methods.my_alert(txt, 'error')
      } else if (status == 401) {
        mixin.methods.my_alert(
          'لطفا ابتدا وارد حساب کاربری تان شوید',
          'warning',
        )
        // mixin.methods.log_out()
        router.resolve({ name: 'AdminLogin' })
      } else if (status == 404) {
        mixin.methods.my_alert('آدرس مورد نظر یافت نشد', 'error')
      } else {
        mixin.methods.my_alert(
          error.response.data.message || error.response.data.msg,
          'error',
        )
      }
    } else if (error.message && error.code != 'ERR_CANCELED') {
      mixin.methods.my_alert(error.message, 'error')
    }
  },
)

const app = createApp(App)
app.use(router)
app.use(CoreuiVue)
app.mixin(mixin)
app.use(store)
app.use(VueSweetalert2)
app.provide('icons', icons)

// Components
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('currency', Currency)
app.component('CIcon', CIcon)
app.component('loading-spinner', LoadingSpinner)
app.component('shine-loading', ShineLoading)
app.component('DocsExample', DocsExample)
app.component('btn-ayandeh', AyandehButton)
app.component('v-select', vSelect)
app.component('input-ayandeh', InputAyandeh)
app.component('dots-loading', DotsLoading)

// Directives

app.directive('toEn', {
  updated: (el) => {
    el.value = mixin.methods.to_en(el.value)
    el.dispatchEvent(new Event('input'))
  },
})

app.config.globalProperties.foo = 'bar'

app.directive('jalaliValidate', {
  updated: (el) => {
    if (el.value) {
      var m = moment(el.value, 'jYYYY/jM/jD')
      if (m.isValid()) {
        store.commit('storeJalaliValidate', true)
        el.classList.add('is-valid')
        el.classList.remove('is-invalid')
      } else {
        store.commit('storeJalaliValidate', false)
        el.classList.add('is-invalid')
        el.classList.remove('is-valid')
      }
    }
  },
})

app.directive('mask', vMask)
app.config.isCustomElement = (tag) => tag.startsWith('smart-')
app.mount('#app')
