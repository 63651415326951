<template>
  <CRow>
    <CCol :md="4" :sm="6" class="mb-3">
      <router-link
        :to="{ name: 'CardList', query: { status: 1 } }"
        class="text-white"
      >
        <CWidgetStatsF color="success" :padding="false" title="کارت های فعال">
          <template #value>
            <loading-spinner
              color="secondary"
              v-if="loading_count"
              size="sm"
              :show="true"
            />
            <span v-else v-text="count_active"></span>
          </template>
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-check-to-slot" size="2xl" />
          </template>
        </CWidgetStatsF>
      </router-link>
    </CCol>
    <CCol :md="4" :sm="6" class="mb-3">
      <router-link
        :to="{ name: 'CardList', query: { status: 0 } }"
        class="text-white"
      >
        <CWidgetStatsF
          color="danger"
          :padding="false"
          title="کارت های غیر فعال"
        >
          <template #value>
            <loading-spinner
              color="secondary"
              v-if="loading_count"
              size="sm"
              :show="true"
            />
            <span v-else v-text="count_de_active"></span>
          </template>
          <template #icon>
            <font-awesome-icon
              icon="fa-regular fa-rectangle-xmark"
              size="2xl"
            />
          </template>
        </CWidgetStatsF>
      </router-link>
    </CCol>
    <CCol :xs="12">
      <router-link :to="{ name: 'CardList' }" class="text-white">
        <CButton color="primary" variant="ghost">
          <font-awesome-icon icon="fa-solid fa-angles-left" class="me-2" />
          همه کارت ها
        </CButton>
      </router-link>
    </CCol>
  </CRow>
</template>

<script>
import LoadingSpinner from '@/components/loading/LoadingSpinner'
export default {
  name: 'CardMenu',
  components: { LoadingSpinner },
  data() {
    return {
      loading_count: false,
      count_active: 0,
      count_de_active: 0,
    }
  },
  mounted() {
    this.get_counts()
  },
  methods: {
    get_counts() {
      this.loading_count = true
      // eslint-disable-next-line no-undef
      axios.get('/card/get_count_cards').then(({ data }) => {
        if (data.status == 'success') {
          this.count_active = data.count_active
          this.count_de_active = data.count_de_active
        }
        this.loading_count = false
      })
    },
  },
}
</script>

<style scoped></style>
