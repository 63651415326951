<template>
  <filter-card
    v-model:snd_id="snd_id"
    v-model:daryaft_id="daryaft_id"
    v-model:payment_id="payment_id"
    v-model:fact_fo="fact_fo"
    v-model:fact_kha="fact_kha"
    v-model:checkbox_1="code_filter"
    checkbox_1_label="فیلتر کد حسابداری"
    @refresh="get_sanads(page)"
  >
    <template #static>
      <div class="col-12">
        <div class="row" v-if="code_filter">
          <div class="col-12 col-md-6 mb-3">
            <user-select placeholder="کد حسابداری بدهکار"
                         :base-params="{ fiscal_year: fiscal_year_snd }"
                         v-model:label_selected="code_name_bed_title"
                         v-model:value="code_name_bed"/>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <user-select placeholder="کد حسابداری بستانکار"
                         :base-params="{ fiscal_year: fiscal_year_snd }"
                         v-model:label_selected="code_name_bes_title"
                         v-model:value="code_name_bes"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 position-relative">
        <layout-loading v-if="$store.state.loading_all_fiscal_year" />
        <div class="form-group">
          <label>سال مالی</label>
          <select
            class="form-control ltr"
            v-model="fiscal_year_snd"
            :loading="$store.state.loading_all_fiscal_year"
          >
            <optgroup
              v-for="(fiscal_years, index) in $store.state
                .all_fiscal_year_site"
              :key="index"
              :label="index"
            >
              <option
                v-for="item in fiscal_years"
                :key="item.id"
                :value="item.name"
                v-text="item.label"
              ></option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label>وضعیت</label>
          <select v-model="status" class="form-control">
            <option value="verified">تایید شده</option>
            <option value="not_verified">تایید نشده</option>
            <option value="both">هر دو</option>
            <option value="all_snd">همه اسناد</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-md-3">
        <p class="mb-1">تاریخ سند</p>
        <date-picker v-model="snd_date"
                     @change="get_sanads(1)"
                     range
                     color="dimgray"
                     clearable
                     format="jYYYY/jMM/jDD"
                     display-format="jYYYY/jMM/jDD" />
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label>نوع سند</label>
          <v-select
            v-model="snd_kinds"
            multiple
            :options="snd_kind_options"
            :reduce="(option) => option.value"
            placeholder="انتخاب نوع سند"
            dir="rtl"
          ></v-select>
        </div>
      </div>
    </template>
  </filter-card>

  <div class="card mb-5">
    <div class="card-body">
      <CAlert color="warning" dismissible @dismiss="alert">
        برای علامت گزاری ردیف ها
        <strong>دابل کلیک</strong>
        و یا از کلید های ترکیبی
        <strong>ctrl + click</strong>
        استفاده کنید.
      </CAlert>
      <br />
      <div class="text-end mb-2">
        <btn-ayandeh
          v-if="$store.state.tr_selected.length"
          class="btn-sm mt-3 me-2"
          color="light"
          :loading="loading_verify_snd"
          :disabled="loading_verify_snd"
          :label="
            'تایید اسناد انتخاب شده (' + $store.state.tr_selected.length + ')'
          "
          @click="verify_multiple_snd()"
        />
        <btn-ayandeh
          v-if="fiscal_year_snd"
          class="btn-sm mt-3 me-2"
          color="secondary"
          :loading="loading_sync_fm_via_site"
          :disabled="loading_sync_fm_via_site"
          @click="sync_fm_via_site"
        >
          <font-awesome-icon icon="fa-solid fa-sync-alt" class="me-1" />
          همگام سازی با فرامحاسب
        </btn-ayandeh>
        <btn-ayandeh
          v-if="paginate_datas.total"
          class="btn-sm mt-3"
          color="danger"
          :loading="loading_download_snds"
          :disabled="loading_download_snds"
          @click="modal_download_pdf_snd"
        >
          <font-awesome-icon icon="fa-solid fa-file-pdf" class="me-1" />
          دانلود pdf
          <template v-if="$store.state.tr_selected.length">({{ $store.state.tr_selected.length }})</template>
          <template v-else>همه</template>
        </btn-ayandeh>
      </div>
      <div class="d-flex justify-content-between">
        <h1 class="title_card">فهرست اسناد</h1>
        <span>
          تعداد :
          <loading-spinner
            :show="loading_get_sanads"
            size="sm"
            color="muted"
            class="align-middle"
          />
          <template v-if="!loading_get_sanads">
            {{ paginate_datas.total || 0 }}
          </template>
          <div class="text-end" v-if="$store.state.tr_selected.length">
          <select-all :values="all_tr_values" />
        </div>
        </span>
      </div>
      <div class="table-ay1">
        <table>
          <thead>
            <tr>
              <th>ردیف</th>
              <th>نام کد حسابداری</th>
              <th>شماره سند</th>
              <th @click="sort_date = !sort_date" class="c-pointer">
                <small>تاریخ سند</small>
                <font-awesome-icon
                  icon="sort-up"
                  class="align-middle ms-1"
                  :class="{
                    'text-success': sort_date,
                    'text-secondary': !sort_date,
                  }"
                />
              </th>
              <th>شرح</th>
              <th>
                بد
                <currency :parentheses="true" />
              </th>
              <th>
                بس
                <currency :parentheses="true" />
              </th>
              <th style="min-width: 75px">وضعیت</th>
              <th
                :style="{
                  'min-width':
                    ($store.state.tr_selected.length ? 150 : 100) + 'px',
                }"
              ></th>
            </tr>
          </thead>
          <tbody>
          <loading-tr v-if="loading_get_sanads && page == 1" :colspan="9" />
            <empty-tr
              v-else-if="sanads.length == 0"
              :colspan="9"
            />
            <tr
              @click.ctrl="toggle_select_row_table(snd.id)"
              @dblclick="toggle_select_row_table(snd.id)"
              :class="{
                selected: $store.state.tr_selected.indexOf(snd.id) != -1,
              }"
              class="position-relative right-transition"
              v-for="(snd, index) in sanads"
              :key="snd.IdSnd"
              :ref="'tr_' + index"
            >
              <td>
                <div class="mobile_title">ردیف :</div>
                {{ index + 1 }}
              </td>
              <td>
                <div class="mobile_title">نام کد حسابداری :</div>
                <div
                  v-c-tooltip="{
                        content: snd.details
                          .map((item) => {
                            return item.IdTafsil
                          })
                          .join(' - '),
                        palcement: 'left',
                      }"
                >
                  {{ snd.details.map(x => x.tafsil ? x.tafsil.Nam : '*').join('، ') }}
                </div>
              </td>
              <td>
                <div class="mobile_title">شماره سند :</div>
                <div class="ltr">
                  {{ snd.IdSnd }}
                  <br><small v-text="snd.fiscal_year"></small>
                </div>
              </td>
              <td>
                <div class="mobile_title">تاریخ سند :</div>
                {{ snd.SDate }}
              </td>
              <td>
                <div class="mobile_title">شرح :</div>
                {{ snd.ShSnd || '-/-' }}
              </td>
              <td class="ltr">
                <div class="mobile_title">
                  بدهکار
                  <currency :parentheses="true" />
                  :
                </div>
                <span
                  :class="{
                    'text-danger':
                      sum('Bed', snd.details) != sum('Bes', snd.details),
                  }"
                >
                  {{ toNumberFormat(sum('Bed', snd.details)) }}
                </span>
              </td>
              <td class="ltr">
                <div class="mobile_title">
                  بستانکار
                  <currency :parentheses="true" />
                  :
                </div>
                <span
                  :class="{
                    'text-danger':
                      sum('Bed', snd.details) != sum('Bes', snd.details),
                  }"
                >
                  {{ toNumberFormat(sum('Bes', snd.details)) }}
                </span>
              </td>
              <td>
                <div class="mobile_title">
                  وضعیت
                  <currency :parentheses="true" />
                  :
                </div>
                <small
                  :class="{
                    'text-warning': snd.verified == 0,
                    'text-success': snd.verified,
                  }"
                  v-text="snd.verified ? 'تایید شده' : 'در انتظار تایید'"
                >
                </small>
              </td>
              <td class="ltr">
                <div class="form-check" v-if="$store.state.tr_selected.length">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="snd.id"
                    v-model="$store.state.tr_selected"
                  />
                </div>

                <router-link
                  :to="{
                    name: 'SanadDetail',
                    params: { fiscal_year: fiscal_year_snd, id: snd.id },
                    query: { list_url: $route.fullPath },
                  }"
                >
                  <btn-ayandeh class="btn-sm" label="جزئیات" />
                </router-link>
                <btn-ayandeh
                  v-if="snd.verified == 0"
                  class="btn-sm me-2"
                  color="light"
                  label="تایید سند"
                  @click="verify_snd(snd)"
                />
              </td>
            </tr>
            <loading-tr-spin v-if="loading_get_sanads && page > 1" colspan="9" />
          </tbody>
        </table>
        <div class="text-end" v-if="$store.state.tr_selected.length">
          <select-all :values="all_tr_values" />
        </div>
      </div>
    </div>
<!--    <Pagination-->
<!--      :current_page="page"-->
<!--      :paginate_data="paginate_datas"-->
<!--      class="mt-3"-->
<!--      @go_to="get_sanads"-->
<!--    ></Pagination>-->
    <div></div>
  </div>
  <div class="bottom-sheet-fixed" v-if="Object.keys(sum_data).length">
    <div class="card p-3 rounded-bottom-0">
      <div class="row">
        <div class="col-12 col-md-4">
          جمع کل:
          <b class="ms-2" v-text="toNumberFormat(sum_data.sum_bes_total)"></b> <span class="text-muted">بس</span>
          / <b class="ms-2" v-text="toNumberFormat(sum_data.sum_bed_total)"></b> <span class="text-muted">بد</span>
        </div>
        <div class="col-12 col-sm-6 col-md-4" v-if="sum_data.mandeh_bed !== null">
            مانده {{ code_name_bed_title }} :
          <b class="text-primary ms-2" v-text="sum_data.mandeh_bed"></b>
        </div>
        <div class="col-12 col-sm-6 col-md-4"  v-if="sum_data.mandeh_bes !== null">
          مانده {{ code_name_bes_title }} :
          <b class="text-primary ms-2" v-text="sum_data.mandeh_bes"></b>
        </div>
      </div>
    </div>
  </div>

  <modal-verify-snd
    ref="verify_snd"
    :fiscal_year_prop="fiscal_year_snd"
    v-model:visiable="show_modal_verify"
    :snd="snd_selected"
    @verified="verified"
  />

  <CModal
    :transition="true"
    size="sm"
    :visible="show_modal_download_pdf"
    @close="show_modal_download_pdf = false"
  >
    <CModalHeader dismiss @close="show_modal_download_pdf = false">
      <CModalTitle class="fs-14">دانلود فایل pdf از سفارشات</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <div class="col-12 border-xs-left-0 d-sm-grid">
          <div class="align-self-start">
            <div class="pt-2">
              <CFormSwitch
                id="download_sanads"
                label="دانلود اسناد"
                v-model="download_sanads_checkbox"
              />
              <div class="mb-2 pt-2 ps-3 bg-gray-1 rounded-2" v-if="download_sanads_checkbox">
                <CFormSwitch
                  id="show_sharh_snd"
                  label="نمایش شرح سند"
                  v-model="show_sharh_snd"
                />
                <CFormSwitch
                  id="show_sharh_snd_detail"
                  label="نمایش شرح سطر"
                  v-model="show_sharh_snd_detail"
                />
              </div>
            </div>
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="download_sanads_attachs"
                label="دانلود اسناد + پیوست ها"
                v-model="download_sanads_and_attachs_checkbox"
              />
              <div class="mb-2 pt-2 ps-3 bg-gray-1 rounded-2" v-if="download_sanads_and_attachs_checkbox">
                <CFormSwitch
                  id="show_sharh_snd"
                  label="نمایش شرح سند"
                  v-model="show_sharh_snd"
                />
                <CFormSwitch
                  id="show_sharh_snd_detail"
                  label="نمایش شرح سطر"
                  v-model="show_sharh_snd_detail"
                />
              </div>
            </div>
            <div class="border-top border-style-top-dash pt-2">
              <CFormSwitch
                id="download_attachs"
                label="دانلود پیوست ها"
                v-model="download_attachs_checkbox"
              />
              <div class="mb-2 pt-2 ps-3 bg-gray-1 rounded-2" v-if="download_attachs_checkbox">
                <CFormSwitch
                  id="show_snd_id"
                  label="نمایش ش سند مرتبط با پیوست"
                  v-model="show_snd_id"
                />
              </div>
            </div>
            <div class="border-top pt-2 text-ayandeh">
                <CFormSwitch
                  id="download_aggregated_snd"
                  label="سند تجمیع شده"
                  v-model="download_aggregated_snd"
                />
            </div>
          </div>
          <btn-ayandeh
            class="w-100 text-white align-self-end"
            color="danger"
            :loading="loading_download_snds"
            :disabled="loading_download_snds"
            @click="download_all_snd"
          >
            <font-awesome-icon icon="fa-solid fa-download" class="me-1" />
            دانلود فایل pdf
          </btn-ayandeh>
        </div>
      </div>
    </CModalBody>
  </CModal>

</template>
<script>
import { mapActions } from 'vuex'
import RowNumTable from '@/components/partial/RowNumTable'
import LoadingTr from '@/components/loading/LoadingTr'
import EmptyTr from '@/components/partial/EmptyTr'
import FilterCard from '@/components/partial/FilterCard'
import LayoutLoading from '@/components/loading/LayoutLoading'
import Pagination from '@/components/partial/Pagination'
import Currency from '@/components/partial/Currency'
import ModalVerifySnd from '@/views/sanad/ModalVerifySnd'
import SndMix from '@/mixin/SndMix'
import SelectAll from '@/components/inputs/SelectAll'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import UserSelect from "@/components/partial/UserSelect"
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";
import LoadingTrSpin from "@/components/loading/LoadingTrSpin.vue";


export default {
  name: 'SanadList',
  mixins: [SndMix],
  components: {
    LoadingTrSpin,
    UserSelect,
    FontAwesomeIcon,
    SelectAll,
    ModalVerifySnd,
    Currency,
    Pagination,
    LayoutLoading,
    FilterCard,
    EmptyTr,
    LoadingTr,
    RowNumTable,
    DatePicker: Vue3PersianDatetimePicker
  },
  data() {
    return {
      loading_get_sanads: false,
      snd_selected: null,
      show_modal_verify: false,
      loading_verify_snd: false,
      status: null,
      source: null,
      snd_id: '',
      payment_id: '',
      daryaft_id: '',
      fact_fo: '',
      fact_kha: '',
      code_name_bed: '',
      code_name_bed_title: '',
      code_name_bes: '',
      code_name_bes_title: '',
      // active_codes_option: [],
      sort_date: false,
      download_snds: false,
      loading_download_snds: false,
      loading_sync_fm_via_site: false,
      code_filter: false,
      download_sanads_checkbox: true,
      download_sanads_and_attachs_checkbox: false,
      download_attachs_checkbox: false,
      download_aggregated_snd: false,
      show_modal_download_pdf: false,
      snd_date: [],
      snd_kinds: [],
      show_sharh_snd: false,
      show_sharh_snd_detail: false,
      show_snd_id: false,
      snd_kind_options: [
        {
          "label": "اول دوره",
          "value": 1
        },
        {
          "label": "سند دستی",
          "value": 2
        },
        {
          "label": "حقوق صاحب سهام",
          "value": 5
        },
        {
          "label": "حقوق",
          "value": 11
        },
        {
          "label": "پرداخت",
          "value": 15
        },
        {
          "label": "پرداخت بابت فاکتور خرید",
          "value": 16
        },
        {
          "label": "دریافت",
          "value": 18
        },
        {
          "label": "دریافت بابت فاکتور فروش",
          "value": 19
        },
        {
          "label": "دریافت بابت برگشت از خرید",
          "value": 20
        },
        {
          "label": "فاکتور خرید",
          "value": 21
        },
        {
          "label": "فاکتور برگشت از فروش",
          "value": 22
        },
        {
          "label": "فاکتور فروش",
          "value": 24
        },
        {
          "label": "پاس شدن چک دریافتی",
          "value": 30
        },
        {
          "label": "استرداد چک",
          "value": 32
        },
        {
          "label": "تبدیل به چک موجود",
          "value": 33
        },
        {
          "label": "پاس شدن چک پرداختی",
          "value": 35
        },
        {
          "label": "استرداد چک",
          "value": 36
        },
        {
          "label": "تبدیل چک",
          "value": 37
        },
        {
          "label": "انبار گردانی",
          "value": 45
        },
        {
          "label": "انتقال از موجودی کالا به موجودی بلند مدت",
          "value": 51
        },
        {
          "label": "سند اختتامیه",
          "value": 100
        }
      ],
      sum_data: {},
      sanads: [],
    }
  },
  beforeMount() {
    if (this.$route.query.from_date_snd !== undefined) {
      this.snd_date[0] = this.$route.query.from_date_snd
    }
    if (this.$route.query.to_date_snd !== undefined) {
      this.snd_date[1] = this.$route.query.to_date_snd
    }
    if (this.$route.query.code_name_bes !== undefined) {
      this.code_name_bes = this.$route.query.code_name_bes
    }
    if (this.$route.query.code_name_bed !== undefined) {
      this.code_name_bed = this.$route.query.code_name_bed
    }
  },
  mounted() {
    this.set_url_query_to_data()

    this.set_current_or_cookie_or_query_fiscal_year()
    if (this.$store.state.all_fiscal_year_site.length == 0) {
      this.get_all_fiscal_years_site()
    }

    this.infiniteScroll()
  },
  methods: {
    ...mapActions([
      'get_all_fiscal_years_site',
      'change_tr_selected',
      'get_users'
    ]),
    modal_download_pdf_snd() {
      this.show_modal_download_pdf = true
    },
    download_all_snd() {
      this.download_snds = true
      this.get_sanads(this.page)
    },
    sync_fm_via_site() {
      this.loading_sync_fm_via_site = true
      // eslint-disable-next-line no-undef
      axios
        .get('/sanad/get_last_sync_date', { params: {
            fiscal_year: this.fiscal_year_snd,
          }})
        .then(({ data }) => {
          this.loading_sync_fm_via_site = false
          Swal.fire({
            html: "همگام سازی اسناد و کد های حسابداری با سایت انجام شود؟<br><br><span class='text-secondary fs-14'>آخرین همگام سازی:</span> " + data.last_sync_date,
            showDenyButton: true,
            icon: 'question',
            confirmButtonText: 'همگام سازی',
            denyButtonText: 'بازگشت',
            confirmButtonColor: '#0a3450',
            denyButtonColor: '#ccc',
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading_sync_fm_via_site = true
              // eslint-disable-next-line no-undef
              axios
                .get('/sanad/sync_fm_via_site', { params: {
                    fiscal_year: this.fiscal_year_snd,
                  }})
                .then(({ data }) => {
                  this.my_alert(data.msg, data.status)
                  this.loading_sync_fm_via_site = false
                })
            }
          })
        })
    },
    get_sanads(page) {
      if (this.source) {
        this.source.cancel()
      }
      this.page = page
      // eslint-disable-next-line no-undef
      this.source = axios.CancelToken.source()

      var query = {
        fiscal_year: this.fiscal_year_snd,
        status: this.status,
        fact_kha: this.fact_kha,
        fact_fo: this.fact_fo,
        payment_id: this.payment_id,
        daryaft_id: this.daryaft_id,
        snd_id: this.snd_id,
        code_name_bed: this.code_name_bed,
        code_name_bes: this.code_name_bes,
        sort_date: this.sort_date,
        from_date_snd: this.snd_date.length ? this.snd_date[0] : '',
        to_date_snd: this.snd_date.length ? this.snd_date[1] : '',
        page: this.page,
        snd_kinds: this.snd_kinds.length ? this.snd_kinds : '',
        download_snds: this.download_snds,
        snd_selected_for_download: this.$store.state.tr_selected.length ? this.$store.state.tr_selected.join(',') : '',
        download_just_sanads: this.download_sanads_checkbox || '',
        download_sanads_and_attachs: this.download_sanads_and_attachs_checkbox || '',
        download_aggregated_snd: this.download_aggregated_snd || '',
        download_just_attachs: this.download_attachs_checkbox || '',
        show_snd_id_in_attach: this.show_snd_id || '',
        show_sharh_snd: this.show_sharh_snd || '',
        show_sharh_snd_detail: this.show_sharh_snd_detail || '',
      }

      if (this.download_snds) {
        this.loading_download_snds = true
      } else {
        this.loading_get_sanads = true
        if(page == 1) {
          this.reset_paginate_data()
          this.change_tr_selected([])
          this.sanads = []
        }
      }

      // eslint-disable-next-line no-undef
      axios
        .get('/sanad/get_sanads', {
          params: query,
          cancelToken: this.source.token,
        })
        .then((response) => {
          if (response !== undefined && response.data.status === 'success') {
            if (this.download_snds) {
              this.download_snds = this.loading_download_snds = false
              this.toast(response.data.msg)
              this.show_modal_download_pdf = false
              this.ajax_download(response.data.path, response.data.origin_name)
            } else {
              this.paginate_datas = response.data.sanads
              this.sanads = this.sanads.concat(response.data.sanads.data)
              this.sum_data = response.data.sum_data
              this.loading_get_sanads = false
            }
          }
        })
    },
    verify_snd(snd) {
      this.show_modal_verify = true
      this.snd_selected = snd
    },
    verified(snd_verified_id) {
      var index = this.paginate_datas.data
        .map((x) => x.IdSnd)
        .indexOf(snd_verified_id)
      this.$refs['tr_' + index][0].style.right = '100%'
      setTimeout(
        function () {
          delete this.paginate_datas.data[index]
          this.$refs['tr_' + index][0].style.right = '0'
        }.bind(this),
        200,
      )
    },
    verify_multiple_snd() {
      Swal.fire({
        html:
          'آیا از تایید ' +
          this.$store.state.tr_selected.length +
          ' سند انتخاب شده اطمینان دارید؟',
        showDenyButton: true,
        icon: 'question',
        confirmButtonText: 'تایید اسناد',
        denyButtonText: 'بازگشت',
        confirmButtonColor: '#0a3450',
        denyButtonColor: '#ccc',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_verify_snd = true
          // eslint-disable-next-line no-undef
          axios
            .post('/sanad/verify_multiple_snd', {
              fiscal_year: this.fiscal_year_snd,
              snd_ids: this.$store.state.tr_selected,
            })
            .then(({ data }) => {
              if (data.status == 'success') {
                this.change_tr_selected([])
                this.get_sanads(1)
              }
              this.my_alert(data.msg, data.status)
              this.loading_verify_snd = false
            })
        }
      })
    },
    infiniteScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 10;

        if (bottomOfWindow) {
          if(this.paginate_datas.next_page_url && !this.loading_get_sanads) {
            this.get_sanads(this.page + 1)
          }
        }
      };
    }
  },
  computed: {
    all_tr_values() {
      return this.sanads.map((x) => x.id)
    },
  },
  watch: {
    fiscal_year_snd(newValue) {
      this.set_data_to_url({
        fiscal_year: newValue,
      })
      if(this.code_filter) {
        this.get_users({
          all_codes: true,
          fiscal_year: this.fiscal_year_snd,
        })
      }
      this.get_sanads(this.page)
    },
    status(newValue) {
      this.set_data_to_url({
        status: newValue,
      })
      this.get_sanads(1)
    },
    code_name_bed(newValue) {
      this.set_data_to_url({
        code_name_bed: newValue,
      })
      this.get_sanads(1)
    },
    code_name_bes(newValue) {
      this.set_data_to_url({
        code_name_bes: newValue,
      })
      this.get_sanads(1)
    },
    // active_codes(newValue) {
    //   var array = []
    //   newValue.forEach(function (value) {
    //     array.push({
    //       label: value.code_id + ' - ' + (value.code_name || ''),
    //       value: value.code_id,
    //     })
    //   })
    //   this.active_codes_option = array
    // },
    sort_date() {
      this.get_sanads(this.page)
    },
    code_filter(newValue) {
      if(newValue && this.$store.state.local_users_options.length == 0) {
        this.get_users({
          all_codes: true,
          fiscal_year: this.fiscal_year_snd,
        })
      }
    },
    download_sanads_checkbox(newValue) {
      if(newValue) {
        this.download_sanads_and_attachs_checkbox = this.download_attachs_checkbox = false
      } else {
        this.show_sharh_snd = this.show_sharh_snd_detail = false
      }
    },
    download_sanads_and_attachs_checkbox(newValue) {
      if(newValue) {
        this.download_sanads_checkbox = this.download_attachs_checkbox = false
      } else {
        this.show_sharh_snd = this.show_sharh_snd_detail = false
      }
    },
    download_attachs_checkbox(newValue) {
      if(newValue) {
        this.download_sanads_checkbox = this.download_sanads_and_attachs_checkbox = false
      }
    },
    snd_date(newValue) {
      if(newValue.length) {
        this.set_data_to_url({
          from_date_snd: newValue[0],
          to_date_snd: newValue[1],
        })
      }
    },
    snd_kinds() {
      this.get_sanads(1)
    }
  },
}
</script>

<style scoped></style>
