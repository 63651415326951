<template>
  <ul class="dots_loading">
    <li>.</li>
    <li>.</li>
    <li>.</li>
  </ul>
</template>

<script>
export default {
  name: 'DotsLoading',
}
</script>
