<template>
  <div
    class="credit_card_body mb-3"
    ref="body"
    style="--bank_color: red"
    v-if="account_bank"
  >
    <div class="pt-3 text-right px-3 d-flex justify-content-between">
      <p ref="bank_name" class="bold"></p>
      <i class="fas fa-credit-card fs-18 text-muted"></i>
    </div>
    <p
      class="mb-0 px-3 text-right"
      v-text="account_bank.owner_name || '--'"
    ></p>
    <div class="number_card fs-18 font-weight-bold">
      <p v-text="account_bank.credit_card.match(/.{1,4}/g).join(' ')"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowCreditCard',
  props: {
    account_bank: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.account_bank) {
      var bank_name = this.get_card_bank_name(this.account_bank.credit_card)
      this.$refs.body.setAttribute('style', '--bank_color: #' + bank_name[0])
      this.$refs.bank_name.textContent = bank_name[1]
    }
  },
}
</script>

<style scoped></style>
