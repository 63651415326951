<template>
  <CModal :transition="true" size="lg" :visible="visible_var" @close="closed">
    <CModalHeader dismiss @close="closed">
      <CModalTitle class="fs-14">ایجاد کارخانه جدید</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row">
        <div class="col-12 col-md-4 mb-3">
          <input-ayandeh
            label="نام کارخانه"
            placeholder="نام کارخانه به فارسی"
            v-model:value="factory_name"
            name="factory_name"
          />
        </div>
        <div class="col-12 col-md-4 mb-3">
          <input-ayandeh
            placeholder="موبایل کارخانه را وارد کنید"
            v-model:value="mobile"
            name="mobile"
            type="tel"
            just="number"
            mask="#### ### ####"
          >
            موبایل کارخانه
            <small class="text-muted">(اختیاری)</small>
          </input-ayandeh>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <input-ayandeh
            placeholder="شناسه ملی را وارد کنید"
            v-model:value="national_code"
            name="national_code"
            type="tel"
            just="number"
            mask="##########"
          >
            شناسه ملی کارخانه
            <small class="text-muted">(اختیاری)</small>
          </input-ayandeh>
        </div>
        <div class="col-12 col-md-8 mb-3">
          <div class="mb-3">
            <CFormLabel for="formFile">آپلود لوگو کارخانه</CFormLabel>
            <CFormInput id="formFile" type="file" @change="uploadImage" />
          </div>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="justify-content-start">
      <btn-ayandeh
        @click="store_factory"
        :loading="loading_store_factory"
        :disabled="v$.$invalid || loading_store_factory"
        label="ایجاد کارخانه"
      />
      <btn-ayandeh @click="closed" color="light" class="me-2" label="بازگشت" />
    </CModalFooter>
  </CModal>
</template>

<script>
import { required } from '@vuelidate/validators'

export default {
  name: 'ModalCreateFactory',
  data() {
    return {
      visible_var: false,
      factory_name: '',
      factory_mobile: '',
      factory_national_code: '',
      loading_store_factory: false,
      factory_logo: null,
    }
  },
  validations() {
    return {
      factory_name: { required },
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    uploadImage(e) {
      this.factory_logo = e.target.files[0]
    },
    closed() {
      this.reset_modal()
      this.$emit('update:visible', false)
    },
    reset_modal() {
      this.visible_var = false
      this.factory_name = ''
      this.factory_mobile = ''
      this.factory_national_code = ''
      this.factory_logo = null
    },
    store_factory() {
      // eslint-disable-next-line no-unreachable
      this.loading_store_factory = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      }

      let formData = new FormData()
      formData.append('factory_logo', this.factory_logo)
      formData.append('factory_name', this.factory_name)
      formData.append('factory_mobile', this.factory_mobile)
      formData.append('factory_national_code', this.factory_national_code)

      // eslint-disable-next-line no-undef
      axios
        .post('/factory/store_factory', formData, config)
        .then(({ data }) => {
          if (data.status == 'success') {
            this.closed()
            this.$emit('created_factory')
          }
          this.my_alert(data.msg, data.status)
          this.loading_store_factory = false
        })
    },
  },
  watch: {
    visible(newValue) {
      this.visible_var = newValue
    },
  },
}
</script>
