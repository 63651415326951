<template>
  <div class="shine-loading">
    <div
      class="line"
      :class="{ sm: size == 'sm' }"
      :style="{ width: width + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ShineLoading',
  props: {
    size: {
      type: String,
      default: 'sm',
    },
    width: {
      type: Number,
      default: 100,
    },
  },
}
</script>

<style scoped></style>
