<template>
  <button
    :class="'btn btn-' + color + ' ' + clases"
    :disabled="disabled || loading"
    :type="type"
  >
    <slot></slot>
    {{ label }}
    <loading-spinner :show="loading" size="sm" class="ms-2 align-middle" :class="loading_class" />
  </button>
</template>

<script>
export default {
  name: 'AyandehButton',
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'ayandeh',
    },
    class: {
      type: String,
      default: '',
    },
    loading_class: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    clases() {
      return this.class
    },
  },
}
</script>
