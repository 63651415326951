<template>
  <CModal
    :transition="true"
    size="xl"
    :backdrop="backdrop_modal"
    :visible="$store.state.visiable_modal_other_sanad"
    class="modal_other_sanad_list"
    @close="closed"
  >
    <CModalHeader dismiss @close="closed">
      <CModalTitle class="fs-14">لیست اسناد</CModalTitle>
    </CModalHeader>
    <CModalBody style="min-height: 400px">
      <!--   Add Other Sanad   -->
      <div v-show="active_mode === 'add'">
        <div class="text-end">
          <btn-ayandeh color="info" @click="$refs.card_manager.add_sanad()">
            <font-awesome-icon icon="fa-solid fa-plus" />
          </btn-ayandeh>
        </div>

        <card-manage-other-snd
          ref="card_manager"
          v-model:validate="validate_other_sanads"
          v-model:other_sanads_prop="other_sanads_for_add"
        />
      </div>
      <!--   List Other Sanad   -->
      <div v-show="active_mode === 'edit'">
        <card-manage-other-snd
          ref="card_manager_edit"
          :show_in_card_section="false"
          :deletable="false"
          rows_title="ویرایش سند"
          v-model:validate="validate_other_sanads"
          v-model:other_sanads_prop="other_sanads_for_edit"
        />
      </div>
      <!--   List Other Sanad   -->
      <div v-show="active_mode === 'list'">
        <div class="d-block d-sm-flex justify-content-between mb-2">
          <CFormSwitch
            id="btn_check_show_deleted"
            label="نمایش حذف شده ها"
            v-model="$store.state.get_deleted_others_sanad"
          />
          <btn-ayandeh color="info" @click="add_new">
            <font-awesome-icon icon="fa-solid fa-plus" class="align-middle" />
            افزودن اسند
          </btn-ayandeh>
        </div>
        <div class="table-ay1">
          <table>
            <thead>
              <tr>
                <th>شناسه</th>
                <th>ش سند فرامحاسب</th>
                <th>نوع</th>
                <th>مبلغ</th>
                <th>بستانکار</th>
                <th>بدهکار</th>
                <th>توضیحات</th>
                <th>تاریخ ایجاد</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="$store.state.loading_get_other_sanads">
                <tr v-for="x in 6" :key="x">
                  <td v-for="i in 9" :key="i">
                    <shine-loading />
                  </td>
                </tr>
              </template>
              <empty-tr
                v-else-if="Object.keys($store.state.other_sanads).length === 0"
                :colspan="8"
              />
              <tr
                v-else
                v-for="(snd, index) in $store.state.other_sanads"
                :key="index"
                :class="{ 'bg-light-danger': snd.deleted }"
              >
                <td>
                  <div class="mobile_title">شناسه:</div>
                  {{ snd.id }}
                </td>
                <td>
                  <div class="mobile_title">ش سند فرامحاسب:</div>
                  {{ snd.fm_sanad_id ?? '-/-' }}
                </td>
                <td>
                  <div class="mobile_title">نوع:</div>
                  {{ snd.title_type }}
                </td>
                <td>
                  <div class="mobile_title">مبلغ:</div>
                  <div>
                    {{ toNumberFormat(snd.amount) }}
                    <small
                      class="text-muted"
                      v-if="snd.calc_amount_type === 'by_tonage'"
                      >بابت هر تن</small
                    >
                  </div>
                </td>
                <td>
                  <div class="mobile_title">بستانکار:</div>
                  <div>
                    <p v-text="snd.bes_name" class="mb-0"></p>
                    <small
                      v-if="snd.bes_id && get_num(snd.bes_id).length"
                      v-text="get_num(snd.bes_id)"
                    ></small>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">بدهکار:</div>
                  <div>
                    <p v-text="snd.bed_name" class="mb-0"></p>
                    <small
                      v-if="snd.bed_id && get_num(snd.bed_id).length"
                      v-text="get_num(snd.bed_id)"
                    ></small>
                  </div>
                </td>
                <td>
                  <div class="mobile_title">توضیحات:</div>
                  <p
                    v-if="snd.description.length > 10"
                    v-text="snd.description"
                    @click="show_description(snd)"
                    class="description_other_sanad c-pointer"
                  ></p>
                  <p
                    v-else
                    v-text="snd.description"
                    class="description_other_sanad"
                  ></p>
                </td>
                <td>
                  <div class="mobile_title">تاریخ ایجاد:</div>
                  <span
                    v-text="to_jalali(snd.created_at, 'long')"
                    class="d-inline-block ltr"
                  ></span>
                </td>
                <td>
                  <span v-if="snd.deleted" class="text-danger">حذف شده</span>
                  <btn-ayandeh
                    color="outline-info me-2"
                    v-if="snd.type !== 'shortage' && snd.deleted === 0"
                    @click="edit_sanad(snd)"
                    loading_class="text-info"
                    :loading="loading_update_other_snd === snd.id"
                    :disabled="loading_update_other_snd"
                    size="sm"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-pen"
                      class="text-info c-pointer"
                    />
                  </btn-ayandeh>
                  <btn-ayandeh
                    color="outline-danger"
                    v-if="snd.type !== 'shortage' && snd.deleted === 0"
                    @click="delete_sanad(snd, index)"
                    size="sm"
                    loading_class="text-danger"
                    :loading="loading_delete_other_snd === snd.id"
                    :disabled="loading_delete_other_snd"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash-can"
                      class="c-pointer"
                    />
                  </btn-ayandeh>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <btn-ayandeh @click="closed" color="light" class="me-2" label="بستن" />

      <btn-ayandeh
        @click="show_list"
        color="outline-info"
        class="me-2"
        v-if="active_mode !== 'list'"
        label="بازگشت"
      />
      <btn-ayandeh
        color="success"
        @click="store_other_sanad"
        v-if="active_mode === 'add'"
        :loading="loading_store_other_snd"
        :disabled="loading_store_other_snd || validate_other_sanads === false"
        label="ذخیره"
      />
      <btn-ayandeh
        color="success"
        @click="update_other_sanad"
        v-if="active_mode === 'edit'"
        :loading="loading_update_other_snd"
        :disabled="loading_update_other_snd || validate_other_sanads === false"
        label="ویرایش"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import EmptyTr from '@/components/partial/EmptyTr.vue'
import BtnAyandeh from '@/components/partial/AyandehButton.vue'
import CardManageOtherSnd from '@/views/sanads_other/CardManageOtherSnd.vue'
import Swal from 'sweetalert2'
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ModalManageSanad',
  components: { LoadingSpinner, BtnAyandeh, EmptyTr, CardManageOtherSnd },
  data() {
    return {
      active_mode: 'list',
      other_sanads_for_add: [],
      other_sanads_for_edit: [],
      validate_other_sanads: true,
      loading_store_other_snd: false,
      loading_update_other_snd: false,
      loading_delete_other_snd: false,
    }
  },
  props: {
    card_id: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
  },
  emits: ['refresh', 'update:card_id', 'update:order_id', 'get_other_sanads'],
  mounted() {
    this.$store.watch(
      () => {
        return this.$store.state.get_deleted_others_sanad
      },
      () => {
        this.$emit('refresh')
      },
      {
        deep: true,
      },
    )
  },
  methods: {
    ...mapActions(['toggle_modal_other_sanads']),
    closed() {
      this.show_list()
      this.toggle_modal_other_sanads(false)
    },
    add_new() {
      this.active_mode = 'add'
      this.$refs.card_manager.add_sanad()
    },
    show_list() {
      this.active_mode = 'list'
      this.reset_adding()
    },
    reset_adding() {
      this.validate_other_sanads = true
      this.$refs.card_manager.reset_all_data()
    },
    edit_sanad(snd) {
      this.active_mode = 'edit'
      this.$refs.card_manager_edit.reset_all_data()
      this.$refs.card_manager_edit.add_sanad(snd)
    },
    delete_sanad(snd, index) {
      this.backdrop_modal = 'static'
      Swal.fire({
        confirmButtonText: 'حذف شود',
        cancelButtonText: 'انصراف',
        showCancelButton: true,
        icon: 'question',
        title: 'حذف',
        html: 'آیا میخواهید سند مورد نظر را حذف کنید؟',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading_delete_other_snd = snd.id
          axios
            .get('/others_sanad/delete_snd', {
              params: {
                sanad_id: snd.id,
              },
            })
            .then((response) => {
              if (
                response !== undefined &&
                response.data.status === 'success'
              ) {
                this.$store.state.other_sanads.splice(index, 1)
                this.$emit('refresh')
              }
              this.my_alert(response.data.msg, response.data.status)
              this.loading_delete_other_snd = false
            })
            .catch(() => {
              this.loading_delete_other_snd = false
            })
        } else if (!result.isConfirmed) {
          this.backdrop_modal = ''
        }
      })
    },
    store_other_sanad() {
      this.backdrop_modal = 'static'
      this.loading_store_other_snd = true
      axios
        .post('/others_sanad/store_other_sanad', {
          other_sanads: this.other_sanads_for_add,
          card_id: this.card_id,
          order_id: this.order_id,
        })
        .then(({ data }) => {
          if (data.status === 'success') {
            this.show_list()
            this.$emit('refresh')
          }
          this.my_alert(data.msg, data.status)
          this.loading_store_other_snd = false
        })
        .catch(() => {
          this.loading_store_other_snd = false
        })
    },
    update_other_sanad() {
      this.backdrop_modal = 'static'
      this.loading_update_other_snd = true
      axios
        .post('/others_sanad/update_other_sanad', {
          other_sanad: this.other_sanads_for_edit[0],
        })
        .then(({ data }) => {
          if (data.status === 'success') {
            this.show_list()
            this.$emit('refresh')
          }
          this.my_alert(data.msg, data.status)
          this.loading_update_other_snd = false
        })
        .catch(() => {
          this.loading_update_other_snd = false
        })
    },
    show_description(snd) {
      this.backdrop_modal = 'static'
      this.my_alert(snd.description, 'info', 'توضیحات سند اضافه')
    },
  },
}
</script>

<style scoped>
.description_other_sanad {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  margin-bottom: 0;
}
</style>
