<template>
  <div class="form-check">
    <input
      class="form-check-input c-pointer"
      type="checkbox"
      @change="changed"
      v-model="value"
      id="select_all_check"
    />
    <label class="form-check-label c-pointer" for="select_all_check">همه</label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SelectAll',
  props: {
    values: {
      type: Array,
    },
  },
  data() {
    return {
      value: false,
    }
  },
  mounted() {
    this.$store.watch(
      () => {
        return this.$store.state.tr_selected
      },
      () => {
        // let difference = this.values.filter(x => !this.$store.state.tr_selected.indexOf(x));
        // var compare = this.compare_arrays(
        //   this.$store.state.tr_selected,
        //   this.values,
        // )
        // console.log('sss', difference)

        if (this.$store.state.tr_selected.length == this.values.length) {
          console.log(111)
          this.value = true
        } else {
          console.log('000')
          this.value = false
        }
      },
      {
        deep: true,
      },
    )
  },
  methods: {
    ...mapActions(['change_tr_selected']),
    changed() {
      if (this.value) {
        this.change_tr_selected(this.values)
      } else {
        this.change_tr_selected([])
      }
    },
  },
  watch: {
    // value(newValue) {
    // }
  },
}
</script>

<style scoped></style>
