<template>
  <div>
    <CFormLabel class="d-flex justify-content-between">
      <font-awesome-icon
        icon="fa-solid fa-square-caret-right"
        :class="{
          'text-muted': !$store.state.jalali_validate || disabled,
          'c-pointer': $store.state.jalali_validate || disabled,
        }"
        class="text-ayandeh px-2"
        size="xl"
        title="روز قبل"
        @click="prev_day()"
      />
      <div class="user-select-none">
        <font-awesome-icon
          icon="fa-solid fa-calendar-days"
          class="text-muted"
        />
        {{ label }}
      </div>
      <font-awesome-icon
        icon="fa-solid fa-square-caret-left"
        :class="{
          'text-muted': !$store.state.jalali_validate || disabled,
          'c-pointer': $store.state.jalali_validate || disabled,
        }"
        class="text-ayandeh px-2"
        size="xl"
        title="روز یعد"
        @click="next_day()"
      />
    </CFormLabel>
    <input
      v-model="date_shown"
      class="form-control ltr"
      :class="{
        'rounded-right-0': radius_right == false,
        'rounded-left-0': radius_left == false,
      }"
      placeholder="روز/ماه/سال"
      v-to-en
      v-jalali-validate
      @keyup="changed_date()"
      v-mask="'####/##/##'"
      autocomplete="off"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import moment from 'moment-jalaali'
export default {
  name: 'InputDate',
  props: {
    selected: {
      type: String,
      default: 'today',
    },
    value: {},
    label: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    radius_right: {
      type: Boolean,
      default: true,
    },
    radius_left: {
      type: Boolean,
      default: true,
    },
    update_url_on_change: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      date: null,
      date_shown: null,
      set_time_out: null,
    }
  },
  beforeMount() {
    this.date = moment()
  },
  mounted() {
    if (this.selected == 'today') {
      this.date_shown = this.date.format('jYYYY/jMM/jDD')
    } else if (this.selected == 'start_year') {
      this.date_shown = this.date.startOf('year').format('jYYYY/jMM/jDD')
    } else if (this.selected != '') {
      this.date_shown = this.selected
      this.date = moment(this.selected, 'jYYYY/jMM/jDD')
    } else {
      this.date_shown = ''
    }
  },
  emits: ['update:value', 'validate', 'change'],
  methods: {
    next_day() {
      if (this.$store.state.jalali_validate) {
        this.date = this.date.add(1, 'day')
        this.date_shown = this.date.format('jYYYY/jMM/jDD')
      }
    },
    prev_day() {
      if (this.$store.state.jalali_validate) {
        this.date = this.date.add(-1, 'day')
        this.date_shown = this.date.format('jYYYY/jMM/jDD')
      }
    },
    changed_date() {
      this.date = moment(this.date_shown, 'jYYYY/jMM/jDD')
      this.$emit('change')
      this.run_emit_change()
    },
    run_emit_change() {
      this.$emit('change')
      if (this.update_url_on_change) {
        this.set_data_to_url({
          [this.update_url_on_change]: this.date_shown,
        })
      }
    },
  },
  watch: {
    date_shown(newValue) {
      this.$emit('update:value', newValue)
      this.run_emit_change()
    },
  },
}
</script>

<style scoped></style>
