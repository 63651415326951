<template>
  <div class="form-group">
    <label class="mb-2">
      <font-awesome-icon
        icon="fa-regular fa-user"
        class="text-icon-light"
      />
      {{ placeholder }}
    </label>
    <v-select
      v-model="selected"
      @search="doSearch"
      :filterable="false"
      :options="options.data"
      :reduce="(option) => option.value"
      :placeholder="'انتخاب ' + placeholder"
      :loading="users_options_loading"
      :clear-search-on-select="false"
      dir="rtl"
    >
      <template #no-options>هیچ موردی یافت نشد</template>
      <template #list-footer>
        <div class="d-flex justify-content-between px-3 mt-2 pt-1 border-top-dotted">
          <button :disabled="!options.prev_page_url"
                  @click="prev_page"
                  class="btn btn-sm btn-info">
            <font-awesome-icon icon="fa-angle-right" class="align-middle" />
            قبلی
          </button>
          <div v-if="options.last_page" class="text-muted mt-1">
            صفحه
            <b v-text="page" class="fw-bold"></b>
            از
            {{ options.last_page }}
          </div>
          <button :disabled="!options.next_page_url"
                  @click="next_page"
                  class="btn btn-sm btn-info">
            بعدی
            <font-awesome-icon icon="fa-angle-left" class="align-middle" />
          </button>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'UserSelect',
  data() {
    return {
      selected: null,
      users_options_loading: false,
      source: null,
      page: 1,
      options: {
        data: [],
        prev_page_url: null,
        next_page_url: null,
        last_page: 0,
      },
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    baseParams: {
      type: Object,
      default: {}
    },
    value: {
      type: String,
      default: null
    },
  },
  mounted() {
    if(this.value) {
      Object.assign(this.baseParams,{ search: this.value})
      this.selected = parseInt(this.value)
    }
    this.get_users()
  },
  methods: {
    prev_page() {
      this.page--;
      this.get_users()
    },
    next_page() {
      this.page++;
    },
    doSearch(q) {
      this.users_options_loading = true

      this.page = 1
      this.get_users({
        search: q,
      })
    },
    get_users(params = {}) {
      if (this.source){
        this.source.cancel()
      }

      // eslint-disable-next-line no-undef
      this.source = axios.CancelToken.source()
      this.users_options_loading = true

      Object.assign(params, {
        page: this.page,
        paginate: 1,
        all_codes: true,
        with_name: true,
        fiscal_year: this.fiscal_year_snd,
      })

      Object.assign(params, this.baseParams)

      this.options.data = []
      // eslint-disable-next-line no-undef
      axios.get('/users/get_users', {
        params: params,
        cancelToken: this.source.token
      }).then((response) => {
        if (response !== undefined &&
          response.data !== undefined &&
          response.data.status === 'success'
        ) {
            this.options = response.data.users

          if(this.selected) {
            this.do_emit_title()
          }
        }
        this.users_options_loading = false
      })
        .catch(() => {
          this.users_options_loading = false
        })
    },
    do_emit_title() {
      var label = ''
      if(this.selected) {
        var select = this.options.data.filter((x) => x.value == this.selected )
        label = select.length ? select[0].name : ''
      }
      this.$emit('update:label_selected', label)
    }
  },
  emits: ['update:value','update:label_selected'],

  watch: {
    selected(newValue) {
     this.do_emit_title()
      this.$emit('update:value', newValue)
    }
  }
}
</script>

<style scoped>

</style>
