<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        حساب کاربری
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> آپدیت ها
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> پیام ها
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> تسک ها
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> نظرات
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        تنظیمات
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> پروفایل </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> تنظیمات </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> تراکنش ها
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> پروژه ها
        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem> <CIcon icon="cil-shield-alt" /> قفل کردن </CDropdownItem>
      <CDropdownItem @click="log_out()">
        <CIcon icon="cil-lock-locked" />
        خروج
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar_def from '@/assets/images/avatars/default-avatar.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar_def,
      itemsCount: 42,
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    avatar() {
      var admin = this.$store.state.admin
      if (admin && admin.avatar) {
        return admin.avatar
      }
      return avatar_def
    },
  },
}
</script>
