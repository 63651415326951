<!--suppress ALL -->
<template>
  <div class="mb-3 text-left">
    <div
      class="input-group mb-3"
      v-for="(attach, index) in attachs"
      :key="index"
    >
      <label class="form-label d-block w-100 mb-1" :for="'attach' + index">
        <font-awesome-icon icon="fa-solid fa-file" />
        پیوست
        {{ parseInt(index) + 1 }}
        <div
          class="d-inline-block"
          v-if="attachs[index].name"
          v-text="' - حجم فایل : ' + show_size_file(attachs[index].size)"
        ></div>
      </label>
      <input
        class="form-control"
        type="file"
        :id="'attach' + index"
        :index="index"
        @change="change_attach"
      />
      <span
        class="input-group-text c-pointer text-danger-light"
        v-if="Object.keys(attachs).length > 1"
        @click="delete attachs[index]"
      >
        <font-awesome-icon
          icon="fa-solid fa-circle-xmark"
          class="text-danger"
        />
      </span>
    </div>
    <btn-ayandeh @click="attachs[index_attach] = ''">
      افزودن پیوست دیگر
      <font-awesome-icon
        icon="fa-solid fa-file-circle-plus"
        class="align-middle mr-2"
      />
    </btn-ayandeh>
  </div>
</template>

<script>
export default {
  name: 'MultipleAttach',
  data() {
    return {
      attachs: { 0: '' },
    }
  },
  props: {
    attach: String,
  },
  emits: ['update:attach'],
  computed: {
    index_attach() {
      var length = Object.keys(this.attachs).length
      var i = 1
      while (i) {
        if (this.attachs[length] == undefined) {
          i = 0
        } else {
          length++
        }
      }
      return length
    },
  },
  methods: {
    change_attach(el) {
      var index = el.target.getAttribute('index')
      var file = el.target.files[0]
      if (file) {
        this.attachs[index] = file
      } else if (this.attachs[index] != undefined) {
        delete this.attachs[index]
      }

      if (Object.keys(this.attachs).length == 0) {
        this.reset()
      } else {
        this.$emit('update:attach', this.attachs)
      }
    },
    show_size_file(bite) {
      if (bite < 1048576) {
        return Math.round(bite / 1024, 1) + ' KB'
      } else if (bite < 1073741824) {
        return Math.round(bite / 1024 / 1024, 1) + ' MB'
      } else if (bite >= 1073741824) {
        return Math.round(bite / 1024 / 1024 / 1024, 1) + ' GB'
      }
    },
    reset() {
      this.attachs = { 0: '' }
      this.$emit('update:attach', this.attachs)
    },
  },
}
</script>

<style scoped></style>
