<template>
  <small class="fs-11 fw-lighter">
    <template v-if="parentheses">(</template>{{ currency
    }}<template v-if="parentheses">)</template>
  </small>
</template>

<script>
export default {
  name: 'Currency',
  props: {
    parentheses: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
