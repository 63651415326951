<template>
  <div>
    <CNavItem class="c-pointer" v-if="has_permission([1, 2])">
      <CNavLink @click="openModal = true">
        <span class="d-none d-md-inline-block me-2">
          {{ fiscalYearData?.label }}
        </span>
        <font-awesome-icon icon="fa-solid fa-calendar-check" class="fs-18" />
      </CNavLink>
    </CNavItem>
  </div>
  <CModal
    :transition="true"
    size="md"
    :visible="openModal"
    @close="() => (openModal = false)"
  >
    <CModalHeader dismiss @close="() => (openModal = false)">
      <CModalTitle class="fs-20">سوئیچ سال مالی</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="mb-4">
        <div class="form-group">
          <label class="form-label"
            >سال مالی <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            v-model="fiscalYear"
            @change="switchFiscalYearValidate"
          >
            <option value="" disabled selected>انتخاب سال مالی</option>
            <option
              v-for="option in fiscalYearList"
              :key="option.id"
              :value="option.name"
              v-text="option.label"
            ></option>
          </select>
          <span class="fs-12 text-danger mt-1">{{ fiscalYearError }}</span>
        </div>
      </div>
    </CModalBody>
    <CModalFooter class="flex-row-reverse justify-content-start">
      <btn-ayandeh
        label="ذخیره"
        :loading="switchFiscalLoading"
        :disabled="!!fiscalYearError"
        @click="switchFiscalYearHandler"
      />
      <btn-ayandeh
        @click="openModal = false"
        color="light"
        class="me-2"
        label="بازگشت"
      />
    </CModalFooter>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex'
import SndMix from '@/mixin/SndMix'
export default {
  mixins: [SndMix],
  data() {
    return {
      openModal: false,
      fiscalYearError: '',
      fiscalYearList: [],
      fiscalYear: null,
      switchFiscalLoading: false,
    }
  },
  async mounted() {
    await this.getFiscalYears()
  },
  computed: {
    fiscalYearData() {
      return this.$store.state.fiscal_year_data
    },
  },
  watch: {
    fiscalYearData(val) {
      if (val.name) {
        this.fiscalYear = val.name
        if (
          this.getCookie('snd_fiscal_year') &&
          this.getCookie('snd_fiscal_year')?.length < 10
        ) {
          this.fiscal_year_snd = val.name
          this.get_sanad_data(val.name)
        }
      }
    },
    openModal(val) {
      if (!val) {
        this.fiscalYearError = ''
      } else this.getFiscalYears()
    },
  },
  methods: {
    ...mapActions(['get_sanad_data']),
    async getFiscalYears() {
      try {
        this.fiscalYearList = (
          await axios.get('/fiscal_year/get_list')
        ).data.fiscal_years
      } catch (err) {
        console.log(err)
        I
      }
    },
    switchFiscalYearValidate() {
      if (!this.fiscalYear) this.fiscalYearError = 'انتخاب سال مالی الزامی است.'
      else this.fiscalYearError = ''
    },

    async switchFiscalYearHandler() {
      this.switchFiscalYearValidate()
      if (!!this.fiscalYearError) return
      else {
        this.switchFiscalLoading = true
        try {
          const data = await axios.get('/fiscal_year/switch', {
            params: {
              fiscal_year: this.fiscalYear,
            },
          })
          if (data?.data?.fiscal_year_hash) {
            localStorage.setItem(
              'fiscal_year_ex_switched',
              data.data.fiscal_year_hash,
            )
            axios.defaults.headers.common['fiscal-year-ex-switched'] =
              localStorage.getItem('fiscal_year_ex_switched')

            location.reload()
          }
          this.switchFiscalLoading = false
          this.openModal = false
        } catch (err) {
          this.switchFiscalLoading = false
          console.error(err)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
